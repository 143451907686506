<template>
  <form v-if="validationSchema" @submit.prevent="onSubmit">
    <slot name="hidden" />
    <template v-if="!currentSchema.spec?.meta?.noHeader">
      <div v-if="headerType == 'create'" class="bg-surface-lvl-00 z-10 flex w-full px-4 py-4">
        <Button class="mr-2" type="button" icon-only :variant="ButtonVariant.Tertiary" @click="exit">
          <Icon class="text-secondary cursor-pointer" src="close" />
        </Button>
        <p class="text-title-body text-quarterary mt-1.5">{{ name }}</p>
        <Divider class="mx-2" rotation="vertical" />
        <p class="text-title-body text-secondary mt-1.5">{{ stepNames[currentStepIdx] }}</p>
      </div>
      <div v-if="headerType == 'default'" class="bg-surface-lvl-00 z-10 flex w-full py-2 pr-2.5 pl-4">
        <p class="text-body-lg-heavy text-secondary mt-1.5">{{ name }}</p>
        <Button class="ml-auto" type="button" icon-only :variant="ButtonVariant.Tertiary" @click="exit">
          <Icon class="cursor-pointer" src="close" />
        </Button>
      </div>
      <div v-if="headerType == 'login'" class="bg-surface-lvl-00 z-10 mb-2 flex items-center">
        <slot name="image" />
      </div>
    </template>
    <div class="form-content flex h-full min-h-0 px-4">
      <slot>
        <FormStep v-for="(schema, idx) in validationSchema" :key="idx">
          <slot :name="`step-${stepKey}`" :schema="schema" :can-submit="canSubmit">
            <FormBuilderVeeStep :schema="schema">
              <template #header>
                <div v-if="hideTitle" class="invisible" />
                <slot :name="`step-${stepKey}-header`" />
              </template>

              <template v-for="field in schemaFields(schema)" #[`${field}`]>
                <slot :name="`step-${stepKey}-${field}`" :schema="schema" :field="field" />
              </template>

              <template v-for="field in schemaFields(schema)" #[`${field}-label`]>
                <slot :name="`step-${stepKey}-${field}-label`" :schema="schema" :field="field" />
              </template>
            </FormBuilderVeeStep>
          </slot>
        </FormStep>
      </slot>
    </div>
    <slot name="footer">
      <template v-if="!currentSchema.spec?.meta?.noFooter">
        <div v-if="footerType == 'create'" class="bg-surface-lvl-00 relative z-10 flex justify-between px-6 py-4">
          <Button
            class="mr-auto w-full max-w-max"
            type="button"
            :class="hasPrevious ? 'opacity-100' : 'opacity-0'"
            variant="ButtonVariant.Tertiary"
            @click="goToPrev"
            >{{ t("previous") }}</Button
          >
          <div class="ml-auto flex gap-2">
            <slot name="footer-buttons" />
            <Button
              v-if="hasContinueButton && canSubmit"
              type="submit"
              :variant="isLastStep && lastStepPositive ? ButtonVariant.Positive : ButtonVariant.Primary"
              :loading="loading"
            >
              {{ continueButtonText }}
            </Button>
          </div>
          <div
            v-if="showProgress && !currentSchema.spec.meta?.hideProgressBar"
            class="absolute right-0 left-0 -z-10 flex justify-center px-4 py-2.5"
          >
            <div v-for="(_, idx) in validationSchema" :key="idx">
              <div
                v-if="!_.spec.meta?.autoskip && !_.spec.meta?.hideInProgressBar"
                class="mt-2 h-0.5 transition-all duration-300"
                :class="[
                  idx <= currentStepIdx ? 'bg-inversed-00' : 'bg-02',
                  idx == currentStepIdx ? 'w-10' : 'w-5',
                  { 'mx-1': idx == 0, 'mr-1': idx !== 0 },
                ]"
              />
            </div>
          </div>
        </div>
        <div v-if="footerType == 'default'" class="bg-surface-lvl-00 z-10 flex p-4">
          <Button
            v-if="hasPrevious"
            class="w-full max-w-max"
            type="button"
            :variant="ButtonVariant.Tertiary"
            @click="goToPrev"
            >{{ t("previous") }}</Button
          >
          <div class="ml-auto flex gap-2">
            <Button v-if="canCancel" type="button" :variant="ButtonVariant.Default" @click="exit">
              {{ cancelButtonText || t("cancel") }}
            </Button>
            <Button
              v-if="hasSkip"
              class="w-full max-w-max"
              type="button"
              :variant="ButtonVariant.Default"
              @click="skip"
              >{{ t("skip_this_step") }}</Button
            >
            <Button
              v-if="hasContinueButton"
              class="w-full max-w-max"
              type="submit"
              :disabled="!canSubmit || hasSkip"
              :variant="isLastStep && lastStepPositive ? ButtonVariant.Positive : ButtonVariant.Primary"
              :loading="loading"
            >
              {{ continueButtonText }}
            </Button>
          </div>
        </div>
        <div v-if="footerType == 'login'" class="bg-surface-lvl-00 z-10 flex flex-col gap-5">
          <Button
            v-if="hasContinueButton"
            class="mt-5 w-full"
            type="submit"
            :disabled="!canSubmit"
            :variant="isLastStep && lastStepPositive ? ButtonVariant.Positive : ButtonVariant.Primary"
            :size="ComponentSize.lg"
            :loading="loading"
          >
            {{ continueButtonText }}
          </Button>
          <slot name="footer-buttons" />
        </div>
      </template>
    </slot>
  </form>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";

  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      validationSchema?: any[];
      loading?: boolean;
      submitButtonText?: string;
      lastStepPositive?: boolean;
      showProgress?: boolean;
      splitFooter?: boolean;
      customFooter?: boolean;
      name?: string;
      stepNames?: string[];
      headerType?: "default" | "create" | "login";
      footerType?: "default" | "create" | "login";
      canCancel?: boolean;
      cancelButtonText?: string;
      hideTitle?: boolean;
    }>(),
    {
      loading: false,
      submitButtonText: "",
      lastStepPositive: false,
      showProgress: false,
      splitFooter: false,
      name: "",
      customFooter: false,
      stepNames: () => [],
      headerType: "default",
      footerType: "default",
      canCancel: false,
      cancelButtonText: "",
      hideTitle: false,
      validationSchema: undefined,
    }
  );

  const emit = defineEmits(["submit", "exit", "skip", "next"]);
  const {
    currentStepIdx,
    isLastStep,
    hasPrevious,
    hasSkip,
    stepKey,
    hasContinueButton,
    continueButtonText,
    schemaFields,
    goToPrev,
    goToNext,
    form,
    canSubmit,
    skip,
    exit,
    onSubmit,
    goToLastStep,
    resetForm,
    currentSchema,
    setTouched,
    setStep,
  } = useFormBuilder({
    submitButtonText: props.submitButtonText,
    validationSchema: props.validationSchema,
    emit,
  });

  const { values, setValues, setFieldValue } = form;
  defineExpose({
    values,
    currentStepIdx,
    isLastStep,
    hasPrevious,
    canSubmit,
    stepKey,
    setValues,
    setFieldValue,
    goToLastStep,
    goToNext,
    resetForm,
    currentSchema,
    setTouched,
    setStep,
    onSubmit,
  });
</script>
