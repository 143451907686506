import md5lib from "md5";

export const md5 = (message: string): string => md5lib(message);

export const isString = (value: unknown) => typeof value === "string" || value instanceof String;

export const removeHtmlTags = (str: string) => str?.replace(/<[^>]*>?/gm, "") || "";

export const stringToIntegerValue = (value?: string | null) => {
  if (!value) {
    return 0;
  }
  return value.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
};

export const getFileNameFromFileUrl = (url: string) => {
  if (!url || typeof url !== "string") return "";
  //handle potential query string in url
  url = url.split("?")[0];

  return url.split("/").pop() || "";
};

/**
 * Checks if the message is valid HTML.
 * @param {string} message - The message to check.
 * @returns {boolean} - True if the message is valid HTML, false otherwise.
 */
export const isMessageValidHtml = (message: string) => {
  const div = document.createElement("div");
  div.innerHTML = message;
  return div.innerHTML === message;
};
