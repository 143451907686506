<template>
  <div
    :key="workspace.subdomain || workspace.account.id"
    class="hover:bg-01 flex cursor-pointer items-center gap-2.5 self-stretch rounded-[6px] px-2 py-1.5"
    :class="{
      'bg-00': isActive,
    }"
    @click="() => setWorkspace(workspace)"
  >
    <Avatar
      size="xs"
      :entity="{
        name: workspace.account.name,
        image: workspace.account.logo,
      }"
      random-color
      :type="AvatarType.Webshop"
    />
    <div class="text-primary mr-2 mr-auto flex flex-col justify-center overflow-hidden text-sm font-medium">
      <span class="truncate">{{ workspace.account.name }}</span>
      <span v-if="workspace.account.business" class="text-tertiary truncate text-xs text-nowrap text-ellipsis">{{
        buildDomainForWorkspace(workspace)
      }}</span>
    </div>
    <div
      class="bg-01 text-tertiary text-body-default-heavy flex h-6 items-center justify-center rounded-sm px-1 py-0.5"
    >
      {{ isMac ? "⌘" : "ctrl" }}{{ index + 1 }}
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { AvatarType } from "~/types/global";
  import type { IWorkspace } from "~/types/auth";

  const props = defineProps<{ workspace: IWorkspace; index: number }>();
  const isMac = getOS() === "Mac";

  const { setWorkspace, checkIsActive, buildDomainForWorkspace } = useWorkspacesStore();

  const isActive = computed(() => checkIsActive(props.workspace));
</script>
