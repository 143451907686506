import EventBus from "@/plugins/eventbus";
import { inject } from "vue";
import type { IConfirmDialogOptions, IDialogOptions, IDialogService, IPromptDialogOptions } from "./types";

/**
 * A unique identifier to access the provided/injected method
 */
export const DialogSymbol = Symbol();

declare global {
  const $alert: IDialogService["alert"];
  const $confirm: IDialogService["confirm"];
  const $prompt: IDialogService["prompt"];
  const $modal: IDialogService["modal"];
}

/**
 * Composable which allows accessing the Dialog service in Composition API
 * @returns Dialog
 */
export function useDialog() {
  const Dialog = inject(DialogSymbol);
  if (!Dialog) {
    throw new Error("No Dialog provided!");
  }

  return Dialog;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const DialogService = {
  install: (app) => {
    const DialogService: IDialogService = {
      alert: (options: IDialogOptions) => {
        EventBus.$emit("DIALOG_OPEN", "alert", options);
      },
      confirm: (options: IConfirmDialogOptions) => {
        EventBus.$emit("DIALOG_OPEN", "confirm", options);
      },
      prompt: (options: IPromptDialogOptions) => {
        EventBus.$emit("DIALOG_OPEN", "prompt", options);
      },
      modal: (options: IDialogOptions) => {
        EventBus.$emit("DIALOG_OPEN", "modal", options);

        return () => {
          EventBus.$emit("DIALOG_CLOSE");
        };
      },
    };
    app.config.globalProperties.$Dialog = DialogService;
    if (typeof window !== "undefined") {
      window.$alert = DialogService.alert;
      window.$confirm = DialogService.confirm;
      window.$prompt = DialogService.prompt;
      window.$modal = DialogService.modal;
    }
    app.provide(DialogSymbol, DialogService);
  },
};
