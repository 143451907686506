export default (currentHeader: Ref<string | null | any>) => {
  const flowWrapper = ref<HTMLElement | null | undefined>();
  const goToCurrentHeader = () => {
    if (!flowWrapper.value) {
      return;
    }
    if (!currentHeader.value) {
      return;
    }

    const headers = flowWrapper.value.querySelectorAll(":scope .settings-flow-header");
    if (!headers) {
      return;
    }

    //find the header where its inner text matches the currentHeader
    const el = [...headers].find((header) => header.innerText === currentHeader.value);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  watch(currentHeader, () => {
    nextTick(() => {
      goToCurrentHeader();
    });
  });

  return {
    flowWrapper,
    goToCurrentHeader,
  };
};
