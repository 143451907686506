<template>
  <div
    class="group hover:bg-00 w-full overflow-hidden rounded-lg transition-all duration-[400ms]"
    :class="open ? 'bg-00' : ''"
  >
    <div class="flex cursor-pointer items-center px-3 py-2" @click="emit('openItem', index)">
      <div>
        <p class="text-body-default-heavy text-secondary">{{ title }}</p>
        <p
          class="text-body-sm group-hover:text-tertiary transition-all duration-[400ms]"
          :class="open ? 'text-tertiary' : 'text-quarterary'"
        >
          {{ subtitle }}
        </p>
      </div>
      <Icon
        class="ml-auto transform transition-all duration-[400ms]"
        src="caretDown"
        :size="ComponentSize.sm"
        filled
        :class="open ? '-rotate-180' : '-rotate-0'"
      />
    </div>
    <div class="grid overflow-hidden transition-all duration-[400ms]" :class="open ? 'grid-rows-1fr' : 'grid-rows-0'">
      <div class="overflow-hidden px-5 transition-all duration-[400ms]" :class="open ? 'py-2' : 'py-0'">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  withDefaults(
    defineProps<{
      title: string;
      subtitle: string;
      open: boolean;
      index: number;
    }>(),
    {
      title: "",
      subtitle: "",
      open: false,
      index: 0,
    }
  );

  const emit = defineEmits(["openItem"]);
</script>
