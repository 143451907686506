import EventBus from "@/plugins/eventbus";
import { inject, ref } from "vue";
import type { INewTicketService } from "./types";

export const open = ref(false);

/**
 * A unique identifier to access the provided/injected method
 */
export const NewTicketSymbol = Symbol();

/**
 * Composable which allows accessing the NewTicket service in Composition API
 * @returns NewTicket
 */
export function useNewTicket() {
  const NewTicket = inject(NewTicketSymbol);
  if (!NewTicket) {
    throw new Error("No NewTicket provided!");
  }

  return NewTicket;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const NewTicketService = {
  install: (app) => {
    const NewTicketService: INewTicketService = {
      toggle: () => {
        EventBus.$emit("NEWTICKET_TOGGLE");
      },
      open: () => {
        EventBus.$emit("NEWTICKET_OPEN");
      },
      add: (options, package_number, id) => {
        EventBus.$emit("NEWTICKET_ADD", options, package_number, id);
      },
      close: () => {
        EventBus.$emit("NEWTICKET_CLOSE");
      },
    };
    app.config.globalProperties.$NewTicket = NewTicketService;
    if (typeof window !== "undefined") window.$newticket = NewTicketService;
    app.provide(NewTicketSymbol, NewTicketService);
  },
};
