<template>
  <div :id="'drop-area_' + props.index" class="relative">
    <input
      ref="input"
      class="hidden"
      type="file"
      :accept="accept"
      :multiple="multiple"
      @change="(val) => addFile(val, multiple, false)"
    />
    <div v-if="type == 'button'">
      <Button class="w-full" variant="default" type="button" :size="ComponentSize.lg" @click="clickOnInput()">
        <slot name="buttonText" />
      </Button>
      <FileUploadFile v-for="(file, index) in files" :file="file" @remove-file="removeFile(index)" />
    </div>
    <div v-if="type == 'simple'">
      <div v-if="!imagePreview" class="flex flex-col gap-1">
        <FileUploadFile v-for="(file, index1) in files" :key="index1" :file="file" @remove-file="removeFile(index1)" />
      </div>
      <div v-if="imagePreview" class="flex flex-wrap gap-2">
        <ImagePreview
          v-for="(file, index2) in files"
          :key="index2"
          :file="file"
          :src="returnsrc(file)"
          @remove-file="removeFile(index)"
        />
      </div>
      <Button
        class="max-h-max !gap-1 pt-1"
        :variant="ButtonVariant.PrimaryLink"
        :size="ComponentSize.sm"
        @click="clickOnInput()"
      >
        <template #prefix>
          <Icon src="plus" />
        </template>
        <p class="mt-0.5">{{ t("add_documentation") }}</p>
      </Button>
      <Overlay v-if="loading" class="absolute top-0 left-0 h-full w-full" />
    </div>
    <div v-if="type == 'full'">
      <div
        class="relative flex h-[90px] w-full items-center justify-center rounded-xl border text-center transition-all duration-300"
        :class="[
          error ? 'border-destructive' : 'border-00 border-dashed',
          emitValue && emitValue?.length !== 0 && !multiple ? 'hidden' : '',
        ]"
      >
        <div>
          <p class="text-body-default text-tertiary text-center">
            {{ t("drag_and_drop_or") }}
          </p>
          <Button
            class="!inline-block text-center"
            type="button"
            :variant="ButtonVariant.PrimaryLink"
            @click="clickOnInput()"
            >{{ t("select_file_manually") }}</Button
          >
          <Overlay v-if="loading" class="absolute top-0 left-0 h-full w-full" />
        </div>
      </div>
      <div
        v-if="message"
        class="mt-1 mb-3 flex w-full items-center justify-center gap-1.5"
        :class="emitValue && emitValue?.length !== 0 && !multiple ? 'hidden' : ''"
      >
        <Icon class="text-foreground-disabled" src="info" />
        <p class="text-body-sm text-quarterary">{{ message }}</p>
      </div>
      <div v-if="!imagePreview" class="mt-3 flex flex-col gap-1">
        <FileUploadFile v-for="(file, idx) in files" :key="idx" :file="file" @remove-file="removeFile(idx)" />
      </div>
      <div v-if="imagePreview" class="mt-3 flex flex-wrap gap-2">
        <ImagePreview
          v-for="(file, idx) in files"
          :key="idx"
          :file="file"
          :src="returnsrc(file)"
          @remove-file="removeFile(idx)"
        />
      </div>
      <Message v-if="error" variant="invalid">{{ error }}</Message>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import { ref } from "vue";

  defineOptions({
    name: "FileUpload",
  });

  const emit = defineEmits(["update:modelValue"]);

  const props = defineProps({
    s3: {
      type: Boolean,
      default: false,
    },
    shipmentId: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "full",
    },
    imagePreview: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "image/*,.pdf,.docx,.xlsx",
    },
    message: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
  });
  const _modelValue = defineModel<string | File>();

  const files = ref([]);

  const loading = ref(false);

  const input = ref(null);

  const { t } = useI18n();

  const { uploadTempFile } = useS3Upload();

  const clickOnInput = () => {
    if (input.value) {
      input.value.value = null;
      input.value.click();
    }
  };

  const addFile = (val: any, multiple: boolean, drop: boolean) => {
    loading.value = true;
    const localFiles = drop ? Array.from(val) : Array.from(val.target.files);

    const uploadJobs = localFiles.map((f: File) => {
      if (props.s3) {
        const uniqId = md5(new Date().toISOString());
        return uploadTempFile(f, `ticket_${props.shipmentId}/${uniqId}`).then((res) => {
          if (!res) {
            return;
          }
          files.value.push({
            name: f.name,
            size: f.size,
            value: `ticket_${props.shipmentId}/${uniqId}/${f.name}`,
            link: `https://cr-temporary.s3.eu-central-1.amazonaws.com/ticket_${props.shipmentId}/${uniqId}/${f.name}`,
          });
        });
      } else {
        new Promise((resolve) => {
          files.value.push(f);
          resolve(true);
        });
      }
    });

    Promise.all(uploadJobs)
      .then(() => {
        emitValue.value = files.value;
        emit("update:modelValue", files.value);
        loading.value = false;
      })
      .catch(() => {
        loading.value = false;
      });
  };

  const returnsrc = (image: any) => {
    if (typeof image !== "object") {
      if (image.includes("https://")) {
        return image;
      } else {
        return `https://cr-temporary.s3.eu-central-1.amazonaws.com/${image}`;
      }
    }
    if (image.link) {
      return image.link;
    }
    return image.value;
  };

  const { value: emitValue } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    validateOnMount: false,
    controlled: !!props.name,
  });

  const removeFile = (index: number) => {
    files.value.splice(index, 1);
    emitValue.value = files.value;
    emit("update:modelValue", files.value);
  };

  let dropArea = null;

  onMounted(() => {
    if (emitValue.value) {
      if (props.multiple) {
        files.value = emitValue.value;
      } else {
        const name = emitValue.value.split("/");
        files.value = [{ link: emitValue.value, name: name[5].split("?")[0] }];
      }
    }
    dropArea = document.getElementById(`drop-area_${props.index}`);
    if (!dropArea) {
      return;
    }
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      if (dropArea) {
        dropArea.addEventListener(eventName, preventDefaults, false);
        document.body.addEventListener(eventName, preventDefaults, false);
      }
    });

    // Highlight drop area when item is dragged over it
    ["dragenter", "dragover"].forEach((eventName) => {
      if (dropArea) {
        dropArea.addEventListener(eventName, highlight, false);
      }
    });
    ["dragleave", "drop"].forEach((eventName) => {
      if (dropArea) {
        dropArea.addEventListener(eventName, unhighlight, false);
      }
    });

    // Handle dropped files
    dropArea.addEventListener("drop", handleDrop, false);
  });

  // Prevent default drag behaviors

  function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function highlight() {
    dropArea.classList.add("bg-00");
  }

  function unhighlight() {
    dropArea.classList.remove("bg-00");
  }

  function handleDrop(e) {
    const dt = e.dataTransfer;
    const files = dt.files;

    addFile(files, props.multiple, true);
  }

  defineExpose({
    removeFile,
  });
</script>
