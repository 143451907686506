<template>
  <CellDefault ref="cell" class="sticky right-0 z-0 py-2 pr-5 group-[.pinned]/table:shadow-md">
    <div class="actions-cell flex justify-end">
      <template v-for="(action, index) in visibleActions" :key="index">
        <Tooltip v-if="hasActions" :text="action.label">
          <Button
            :variant="ButtonVariant.Tertiary"
            :size="ComponentSize.sm"
            :href="action.link ? resolveActionLink(action, item) : ''"
            :target="action.target || '_self'"
            icon-only
            @click.stop="() => (action.handler ? action.handler(item) : null)"
          >
            <Icon class="aspect-square" :src="getIconForAction(action, true)" :filled="action.iconFilled" />
          </Button>
        </Tooltip>
      </template>
      <template v-if="shouldShowMoreActions">
        <Button
          ref="trigger"
          class="dropdown-toggle !rounded-lg"
          :variant="ButtonVariant.Tertiary"
          :size="ComponentSize.sm"
          :active="showDropdown"
          icon-only
          @click.stop="toggleDropdown"
        >
          <Icon class="aspect-square" src="bold/dots_three_vertical" />
        </Button>

        <Dropdown
          ref="dropdown"
          v-model:open="showDropdown"
          :max-width="`${width}px`"
          :toggle-element="trigger"
          :calculate-position="calculatePosition"
        >
          <div class="dropdown__content flex flex-col space-y-1 p-1.5">
            <template v-for="(action, index) in actions" :key="index">
              <Divider v-if="action == null" />
              <DropdownItem
                v-else
                :key="action.key"
                :href="action.link ? resolveActionLink(action, item) : ''"
                :variant="getActionTextVariant(action)"
                :target="action.target || '_self'"
                @click.stop="() => (action.handler ? action.handler(item) : null)"
              >
                <div class="flex w-full items-center gap-2">
                  <Icon v-if="getIconForAction(action)" :src="getIconForAction(action)" :filled="action.iconFilled" />

                  <div class="overflow-hidden text-nowrap text-ellipsis">{{ action.label }}</div>
                </div>
              </DropdownItem>
            </template>
          </div>
        </Dropdown>
      </template>
    </div>
  </CellDefault>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import { cleanActionsList } from "~/utils/actions";
  import { computed, ref } from "vue";
  import type { ITableData, ITableHeader, ITableRowAction } from "../../../types";

  import CellDefault from "../Default.vue";

  defineOptions({
    name: "TableRowCellActions",
  });
  const props = withDefaults(
    defineProps<{
      header: ITableHeader;
      item: ITableData;
    }>(),
    {}
  );

  const maxVisibleActions = 2;
  const cell = ref<HTMLElement>();
  const width = 185;

  const actions = computed(() => {
    return cleanActionsList(props.header.data?.actions, props.item);
  });

  const filteredNonNullActions = computed<ITableRowAction[]>(() => {
    return actions.value.filter((action) => action) as ITableRowAction[];
  });

  const hasActions = computed(() => {
    return filteredNonNullActions.value.length > 0;
  });

  const visibleActions = computed(() => {
    //return first 2 actions
    return filteredNonNullActions.value.slice(0, maxVisibleActions);
  });

  const shouldShowMoreActions = computed(() => {
    return filteredNonNullActions.value.length > maxVisibleActions;
  });

  const { dropdown, calculatePosition, trigger, showDropdown, toggleDropdown } = useDropdown({
    repositionFromBottom: false,
  });
</script>
