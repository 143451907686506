<template>
  <div
    ref="el"
    class="group/tab relative flex h-full w-fit cursor-pointer items-center gap-1 text-sm font-medium whitespace-nowrap group-[.has-scrollbar]/bar:gap-0 group-[.has-scrollbar]/bar:text-xs"
    :class="{
      'text-secondary': !active,
      'active text-primary': active,
    }"
  >
    <div class="flex items-center gap-1" tabindex="0">
      <Icon v-if="view?.icon" :src="view?.icon" />
      <slot name="prefix" />
      <slot />
      <div v-if="count !== undefined" class="border-02 text-body-sm rounded-full border px-2 font-medium">
        {{ count }}
      </div>
    </div>
    <div
      v-if="variant === 'default'"
      class="group-hover/tab:border-02 absolute bottom-0 w-full group-hover/tab:border"
      :class="{
        '!border-inversed-00 !border': active,
      }"
    />
  </div>
</template>

<script setup lang="ts">
  defineOptions({
    name: "Tab",
  });

  const el = ref<HTMLElement>();

  const props = withDefaults(
    defineProps<{
      variant?: "default" | "nude";
      active?: boolean;
      view?: any;
      count?: number;
    }>(),
    {
      variant: "default",
      active: false,
      view: null,
      count: undefined,
    }
  );

  const emits = defineEmits(["active"]);

  watchEffect(() => {
    if (props.active) {
      emits("active", el);

      if (import.meta.client) {
        //create a custom event to emit
        const event = new CustomEvent("active", {
          detail: {
            tab: el.value,
          },
        });

        //dispatch the event
        el.value?.dispatchEvent(event);
      }
    }
  });

  defineExpose({
    el,
  });
</script>
