<template>
  <div
    class="cursor-pointer"
    :class="{
      'group bg-00 hover:bg-01 rounded-xl px-4 py-3': framed,
    }"
  >
    <div class="relative">
      <label class="flex gap-2.5" :for="id">
        <input
          :id="id"
          v-model="inputValue"
          class="peer border-01 bg-00 hover:border-02 focus-visible:before:border-focus disabled:border-disabled relative aspect-square h-5 w-5 cursor-pointer appearance-none rounded-full border-2 transition-all duration-200 focus-visible:outline-none focus-visible:before:border-2 disabled:cursor-default"
          :disabled="readonly"
          type="radio"
          :indeterminate="indeterminate"
          :class="{
            '!hover:border-inversed-01 !hover:bg-inversed-01 !border-inversed-00 bg-inversed-00 disabled:bg-disabled':
              indeterminate,
            'border-inversed-00 bg-inversed-00 hover:border-inversed-01 disabled:bg-disabled': inputValue,
          }"
        />
        <div
          class="absolute top-1.5 left-1.5 h-2 w-2 rounded-full transition duration-200"
          :class="{ 'bg-white': inputValue }"
        />
        <div v-if="slots.default" class="text-body-default group-hover:text-primary cursor-pointer">
          <slot />
        </div>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
  const id = `checkbox-${useId()}`;
  const slots = defineSlots();

  const props = defineProps({
    readonly: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    selected: {
      type: Boolean,
      default: false,
    },
    framed: {
      type: Boolean,
      default: false,
    },
  });

  const { value: inputValue } = useField(() => props.name, undefined, {
    type: "checkbox",
    checkedValue: true,
    uncheckedValue: false,
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  watch(
    () => props.selected,
    (value) => {
      inputValue.value = value;
    },
    { immediate: true }
  );
</script>
