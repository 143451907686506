<template>
  <th
    class="group/cell text-body-sm h-9 border-b border-inherit bg-inherit p-0 px-3 text-inherit"
    :data-header="headerKey"
    :class="{
      'sticky right-0': headerKey === 'actions',
      'text-right': align === 'right',
      'text-center': align === 'center',
      'text-left': align === 'left',
    }"
  >
    <slot>{{ prettifiedLabel }}</slot>
  </th>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import { useI18n } from "vue-i18n";
  import type { ITableHeader } from "../types";

  const props = defineProps<{
    header?: ITableHeader;
    table: any;
    headerDef: any;
    headerKey: string;
  }>();

  const { te, t } = useI18n();

  const align = computed(() => {
    if (headerType.value === "price") return "right";

    return props.header?.align || "left";
  });

  const headerType = computed(() => {
    return props.header?.type;
  });

  // This code defines a computed property called 'prettifiedLabel'.
  const prettifiedLabel = computed(() => {
    if (props.header?.labelless) return "";
    if (headerType.value === "actions" || props.headerKey === "select") return "";
    // Check if the 'label' property exists under the 'header' object in the 'props' data.
    if (props.header?.label) return props.header?.label;

    // If the label does not exist, check if there is a translation available for the key defined under 'table.headers'.
    if (te(`table.headers.${props.headerKey}`)) {
      // If a translation is found, return the translated value.
      return t(`table.headers.${props.headerKey}`);
    }

    // If neither the label nor a translation is available, then format the key by splitting it into individual words based on '_', '.' and whitespace characters,
    // capitalize the first letter of the first word, and join them back together with spaces.
    return (
      props.headerKey.charAt(0).toUpperCase() +
      props.headerKey
        .slice(1)
        .split(/_|\.|\s/)
        .join(" ")
    );
  });
</script>
