<template>
  <div class="box-content flex h-9 items-center gap-3 px-5 py-2.5">
    <div class="text-body-default">
      {{ t("override_shipping_method_description") }}
    </div>
    <Toggle :model-value="localRule?.description !== null" @update:model-value="toggle" />
    <InputText
      v-if="localRule?.description !== null"
      v-model="localRule!.description"
      class="flex-grow"
      @update:model-value="(val) => emit('emitValue', val)"
    />
  </div>
</template>

<script setup lang="ts">
  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      rule: IRule;
    }>(),
    {}
  );

  let originalDescription: string | null = "";

  onMounted(() => {
    originalDescription = props.rule.description;
  });

  onUnmounted(() => {
    if (localRule.value && localRule.value.description === "") {
      localRule.value.description = originalDescription;
    }
  });

  const localRule = ref<IRule>();

  watch(
    () => props.rule,
    (rule) => {
      localRule.value = { ...rule };
    },
    { immediate: true }
  );

  const toggle = (val: boolean) => {
    if (!localRule.value) return;
    if (val) {
      localRule.value.description = originalDescription || "";
    } else {
      localRule.value.description = null;
    }
  };

  const emit = defineEmits(["emitValue"]);
</script>
