import { SearchResultType } from "~/types/search";
import { defineStore } from "pinia";
import type { SearchResult, SearchResultItem } from "~/types/search";
import type { IShipmentSimple } from "~/types/shipment";
import type { ITicketSimple } from "~/types/ticket";

export const useGlobalSearchStore = defineStore(
  "GlobalSearch",
  () => {
    const search = ref<string>("");
    const loading = ref<boolean>(false);
    const usingKeyboard = ref<boolean>(true);

    // Current
    const currentResultIndex = ref<number>();
    const currentResult = computed(() => {
      if (currentResultIndex.value === undefined) return;
      return listedResults.value[currentResultIndex.value];
    });

    // Results
    const results = ref<SearchResult>();
    const listedResults = computed(() => {
      if (!search.value || search.value.length < 3) return recentSearches.value;

      if (!results.value) return [];
      return [...results.value.shipments, ...results.value.tickets, ...results.value.returns];
    });

    // Recents
    const recentSearches = ref<SearchResultItem[]>([]);

    // Shipment information
    const shipments = ref<IShipmentSimple[]>([]);
    const tickets = ref<ITicketSimple[]>([]);
    const currentInformation = computed<IShipmentSimple | ITicketSimple | undefined>(() => {
      if (!currentResult.value) return;

      switch (currentResult.value.type) {
        case SearchResultType.shipment:
        case SearchResultType.return:
          return shipments.value.find((shipment) => shipment.id === currentResult.value!.id);
        case SearchResultType.document:
          break;
        case SearchResultType.ticket:
          return tickets.value.find((ticket) => ticket.id === currentResult.value!.id);
        case SearchResultType.transfer:
          break;
        default:
          assertNever(currentResult.type);
          break;
      }
    });

    return {
      currentInformation,
      currentResult,
      currentResultIndex,
      listedResults,
      loading,
      recentSearches,
      results,
      search,
      shipments,
      tickets,
      usingKeyboard,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
      pick: ["recentSearches"],
    },
  }
);
