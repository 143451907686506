<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <FlowSection :title="t('settings.checkout.label.title')" :description="t('settings.checkout.label.description')">
      <template #headerButtons>
        <Button class="mt-auto" :variant="ButtonVariant.Primary" @click="() => (createOpen = true)">{{
          t("create_checkout")
        }}</Button>
      </template>
    </FlowSection>
    <Divider />

    <FlowSection
      :title="t('settings.checkout.userdefined_parameters.label.title')"
      :description="t('settings.checkout.userdefined_parameters.label.description')"
    >
      <template #headerButtons>
        <Button class="mt-auto" :variant="ButtonVariant.Default" @click="() => (paramsOpen = true)">{{
          t("view_parameters")
        }}</Button>
      </template>
    </FlowSection>
    <Divider />
    <div v-show="hasNoCheckouts" class="mx-auto w-full max-w-[481px] py-6">
      <EmptyView :title="''" :description="''">
        <template #description>
          <div>
            <p class="text-title-subsection text-primary mb-2">{{ t("no_checkouts") }}</p>
            <p class="text-body-default text-tertiary">{{ t("create_checkout_desc") }}</p>
          </div>
        </template>
        <template #illustration>
          <img :src="illustration" alt="No checkouts illustration" />
        </template>
      </EmptyView>
    </div>
    <div v-if="!hasNoCheckouts">
      <template v-for="(checkout, idx) in checkouts" :key="checkout.id">
        <ListItem
          class="w-full"
          :checkout="checkout"
          @delete="() => removeItem(idx)"
          @edit="() => editItem(checkout)"
          @edit:rules="() => editItem(checkout, 'setup_rules')"
          @duplicate="() => duplicateItem(checkout)"
        />
        <Divider class="my-6" :spacing="false" />
      </template>
    </div>
  </div>

  <SetupModal
    :checkout-id="checkoutId"
    :open="createOpen"
    :initial-step="openStep"
    @close="closeSetup"
    @reload="fetchCheckouts"
  />
  <ParamModal :open-params="paramsOpen" :checkout-id="checkoutId" @close="closeParamSetup" />
</template>

<script setup lang="ts">
  import illustration from "~/assets/illustrations/emptyViews/nc-improve-ease-of-use.svg";
  import { ButtonVariant } from "~/types/global";
  import type { ICheckoutIndex, ISettingsFlow } from "../types";

  import ListItem from "../components/checkout/ListItem.vue";
  import ParamModal from "../components/checkout/ParamModal.vue";
  import SetupModal from "../components/checkout/SetupModal.vue";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );

  const { t } = useI18n();
  const createOpen = ref(false);
  const openStep = ref<string>();
  const paramsOpen = ref(false);
  const checkoutId = ref<number | undefined>();

  const emits = defineEmits(["update:loading"]);

  const loading = ref<boolean>(false);

  const checkouts = ref<ICheckoutIndex[]>([]);

  const hasNoCheckouts = computed(() => checkouts.value.length === 0 && !loading.value);

  const fetchCheckouts = () => {
    setLoading(true);
    return homeFetch("workspace/checkouts")
      .then((response) => {
        if (response?.data) {
          checkouts.value = response.data as ICheckoutIndex[];
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  onMounted(() => {
    fetchCheckouts().then(() => {
      goToCurrentHeader();
    });
  });

  const removeItem = (idx: number) => {
    checkouts.value.splice(idx, 1);
  };

  const editItem = (checkout: ICheckoutIndex, step?: string) => {
    checkoutId.value = checkout.id;
    createOpen.value = true;
    openStep.value = step;
  };

  const closeSetup = () => {
    createOpen.value = false;
    checkoutId.value = null;
    openStep.value = undefined;
  };

  const closeParamSetup = () => {
    paramsOpen.value = false;
    checkoutId.value = null;
  };

  const duplicateItem = (checkout: ICheckoutIndex) => {
    $confirm({
      message: t("checkout_duplicate_confirm", { checkout: checkout.name }),
      onConfirm: () => {
        setLoading(true);

        homeFetch(`workspace/checkouts/${checkout.id}/duplicate`, { method: "POST" })
          .then(fetchCheckouts)
          .finally(() => {
            setLoading(false);
          });
      },
      destructive: true,
    });
  };

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const setLoading = (l: boolean) => {
    loading.value = l;
    emits("update:loading", l);
  };
</script>

<script lang="ts">
  export const useCheckoutSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const { hasCapability } = useCapabilities();
    const icon = "regular/CreditCard";
    const title = t("settings.checkout.title");
    const pageTitle = t("settings.checkout.page_title");
    const key = "checkouts";
    const condition = computed(
      () => !useWorkspacesStore().isDFM && (hasCapability("account.checkout") || hasCapability("account.checkout.edit"))
    );
    const group = "setup";

    return {
      icon,
      pageTitle,
      title,
      key,
      condition,
      group,
    };
  };
</script>
