interface DropdownOptions {
  repositionFromBottom?: boolean;
}

export default (options: DropdownOptions = { repositionFromBottom: true }) => {
  const dropdown = ref<Modal>();
  const trigger = ref<HTMLElement>();
  const triggerWidth = ref("auto");

  const getTriggerWidth = () => {
    if (trigger.value?.el?.offsetWidth) triggerWidth.value = trigger.value?.el?.offsetWidth + "px";
  };

  watch(trigger, () => {
    if (trigger.value?.el?.offsetWidth) triggerWidth.value = trigger.value?.el?.offsetWidth - 5 + "px";
  });

  const showDropdown = ref(false);

  const toggleDropdown = () => {
    getTriggerWidth();
    showDropdown.value = !showDropdown.value;
  };

  const setStyleProperty = (property: string, value: string) => {
    dropdown.value?.setStyleProperty(property, value);
  };

  const calculatePosition = (cb?: (() => void) | null) => {
    //calculate dropdown position based on tr position
    const triggerRect = trigger.value?.el
      ? trigger.value?.el.getBoundingClientRect()
      : trigger.value?.getBoundingClientRect();
    if (triggerRect) {
      const top = triggerRect.bottom + 4;
      let left = triggerRect.left;

      //get dropdown height
      const dropdownHeight = dropdown.value?.height || 0;

      //get dropdown width
      const dropdownWidth = dropdown.value?.width || 0;

      //if top is below the bottom of the table, move it up
      if (top + dropdownHeight > window.innerHeight) {
        if (options.repositionFromBottom) setStyleProperty("bottom", `16px`);
        else setStyleProperty("top", `${top - dropdownHeight}px`);
      } else {
        setStyleProperty("top", `${top}px`);
      }

      //if left is outside the window, move it to the left with 10px to spare
      if (left + dropdownWidth > window.innerWidth) left = window.innerWidth - dropdownWidth - 20;
      // setStyleProperty("top", `${top}px`);
      setStyleProperty("left", `${left}px`);
    }

    if (cb && typeof cb === "function") cb();
  };
  return {
    dropdown,
    trigger,
    triggerWidth,
    showDropdown,
    toggleDropdown,
    setStyleProperty,
    calculatePosition,
  };
};
