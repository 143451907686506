<template>
  <div v-for="field in service.fields" :key="field">
    <ListSelector
      v-if="field.type == 'list'"
      class="mb-6"
      simple
      :name="`services.${service.carrier_key}.${field.key}`"
      radio-position="top"
      :values="field.options"
      value-key="key"
    >
      <template #title="{ value }">
        <p class="text-body-default text-secondary">{{ value.label }}</p>
      </template>
    </ListSelector>
    <Datepicker
      v-if="field.type === 'date'"
      class="filter-value w-full max-w-[200px]"
      type="datetime"
      :presets="[]"
      default-today
      :label="field.label"
      :name="`services.${service.key}[${field.key}]`"
      @update:model-value="(val) => emit('dateUpdate', { val, service, field })"
    />
    <InputText
      v-if="field.type === 'number'"
      class="w-full max-w-[200px]"
      type="number"
      :name="`services.${service.carrier_key}.${field.key}`"
      :label="field.label"
    >
      <template v-if="field.suffix" #suffix>
        {{ field.suffix }}
      </template>
    </InputText>
  </div>
</template>

<script setup lang="ts">
  defineProps<{
    service: any;
  }>();
  const emit = defineEmits(["dateUpdate"]);
</script>
