<template>
  <fieldset class="flex gap-2.5" :required="required">
    <input
      v-for="index in otpLength"
      :key="index"
      :ref="(el) => (inputFields[index - 1] = el as Element)"
      class="bg-00 flex [appearance:textfield] items-center justify-center text-center select-all [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
      :class="{
        'h-11 w-11 rounded-xl px-[13.5px] py-[6.5px] text-2xl': size === 'base',
        'h-[82px] w-[62px] rounded-full text-3xl': size === 'xl',
        'text-disabled': disabled,
        'font-mono focus:placeholder-transparent': placeholder === '*' && !hasValueOnIndex(index - 1),
      }"
      type="number"
      :value="parseInt(otp[index - 1])"
      :maxlength="1"
      :min="0"
      :max="9"
      inputmode="numeric"
      pattern="[0-9]*"
      autocomplete="one-time-code"
      :autofocus="index === 1"
      :disabled="disabled"
      :placeholder="placeholder"
      @input="(evt) => handleInput(index - 1, evt.target.value)"
      @keydown="handleKeyDown(index - 1)"
      @paste="handlePaste(index - 1)"
      @focus="handleFocus(index - 1)"
      @blur="handleBlur(index - 1)"
    />
  </fieldset>
  <input
    :id="id"
    ref="hiddenInput"
    type="hidden"
    :name="name"
    :value="otp.join('')"
    @input="(evt) => handleChange(evt, false)"
  />
  <Message v-if="error" variant="invalid">{{ error }}</Message>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      size?: "base" | "xl";
      disabled?: boolean;
      name?: string;
      error?: string;
      otpLength?: number;
      id?: string;
      required?: boolean;
      placeholder?: string;
    }>(),
    {
      size: "base",
      disabled: false,
      name: "",
      otpLength: 6,
      error: undefined,
      id: undefined,
      placeholder: undefined,
    }
  );

  const otp = ref<string[]>([]);
  const hiddenInput = ref<Element | null>(null);
  const inputFields = ref<Element[]>([]);

  watch(otp, () => {
    nextTick(() => {
      hiddenInput.value?.dispatchEvent(new Event("input"));
    });
  });

  onMounted(() => {
    if (inputValue.value?.length) setValueFromString(inputValue.value);
  });

  const handleInput = (index: number, val) => {
    const otpClone = [...otp.value];
    otpClone[index] = val + "";
    if (otpClone[index].length > 1) {
      const thisIndexVal = otpClone[index].slice(0, 1);
      const nextIndexVal = otpClone[index].slice(1, 2);
      otpClone[index] = thisIndexVal;

      if (index < props.otpLength - 1) {
        otpClone[index + 1] = nextIndexVal;
      }
    }

    otp.value = otpClone;

    nextTick(() => {
      nextTick(() => {
        if (otpClone[index].length === 1 && index < props.otpLength - 1) {
          const nextInput = inputFields.value[index + 1];

          if (nextInput) {
            nextInput.focus();
          }
        }
      });
    });
  };

  const handleKeyDown = (index: number) => {
    if (!event?.key) return;
    //if is any arrow key then prevent default and return
    if (event.key.includes("Arrow")) {
      event.preventDefault();
      return;
    }

    if (event.key === "Backspace" && index > 0) {
      const otpClone = [...otp.value];
      otpClone[index] = "";
      otp.value = otpClone;
      event?.preventDefault();
      const previousInput = inputFields.value[index - 1];
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const handlePaste = () => {
    if (!event) return;

    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");

    if (!pastedData.match(/^[0-9]+$/) || pastedData.length !== props.otpLength) {
      event.preventDefault();
      return;
    }

    setValueFromString(pastedData);

    event.preventDefault();
  };

  const setValueFromString = (value: string) => {
    otp.value = value.split("");
    //focus on the last input
    const lastInput = inputFields.value[props.otpLength - 1];
    if (lastInput) {
      lastInput.focus();
    }
  };

  const handleFocus = (index: number) => {
    if (index > 0 && otp.value[index - 1]?.length === 0) {
      const previousInput = inputFields.value[index - 1];
      if (previousInput) {
        previousInput.focus();
      }
    }
  };

  const handleBlur = (index) => {
    if (!otp.value[index]) return;

    if (otp.value[index].length === 0 && index < props.otpLength - 1) {
      const nextInput = inputFields.value[index + 1];
      if (nextInput) {
        nextInput.blur();
      }
    }
  };

  const hasValueOnIndex = (index: number) => {
    return otp.value[index] !== "" && otp.value[index] !== undefined;
  };

  const error = computed(() => {
    return props.error || errorMessage.value;
  });

  const {
    value: inputValue,
    errorMessage,
    handleChange,
  } = useField(() => props.name, undefined, {
    syncVModel: true,
  });
</script>
