import EventBus from "@/plugins/eventbus";
import { inject, ref } from "vue";
import type { IPrintingService } from "./types";

export const open = ref(false);

declare global {
  const $printing: IPrintingService;
}

/**
 * A unique identifier to access the provided/injected method
 */
export const NewPrintingSymbol = Symbol();

/**
 * Composable which allows accessing the Printing service in Composition API
 * @returns printing
 */
export function usePrinting() {
  const Printing = inject(NewPrintingSymbol);
  if (!Printing) {
    throw new Error("No Printing provided!");
  }

  return Printing;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const PrintingService = {
  install: (app) => {
    const PrintingService: IPrintingService = {
      toggle: () => {
        EventBus.$emit("PRINTING_TOGGLE");
      },
      open: () => {
        EventBus.$emit("PRINTING_OPEN");
      },
      close: () => {
        EventBus.$emit("PRINTING_CLOSE");
      },
      add: (package_number, selectAll, selected, filters) => {
        EventBus.$emit("PRINTING_ADD_LABEL", package_number, selectAll, selected, filters);
      },
      addTransfer: (transferId) => {
        EventBus.$emit("PRINTING_ADD_TRANSFER", transferId);
      },
    };
    app.config.globalProperties.$Printing = PrintingService;
    if (typeof window !== "undefined") window.$printing = PrintingService;
    app.provide(NewPrintingSymbol, PrintingService);
  },
};
