<template>
  <Modal
    id="settings-modal"
    ref="modal"
    v-model:open="open"
    class="shadow-lvl-05"
    max-width="1020px"
    :content-padding="false"
  >
    <div class="flex h-[756px] max-h-full w-[1020px] max-w-full">
      <SettingsSidebar
        :flows="flows"
        :current-flow="currentFlow"
        :current-header="currentHeader"
        @entry:click="setCurrentFlowKey"
      />
      <div class="relative flex w-full">
        <div ref="flowContainer" class="box-content flex w-full flex-col">
          <div class="py-6">
            <div class="mb-6 flex flex-col gap-[7.5px] px-10">
              <div class="text-title-body">
                {{ currentPageTitle }}
              </div>
              <div v-if="currentPageDescription" class="text-body-lg text-quarterary">
                {{ currentPageDescription }}
              </div>
            </div>
            <div class="px-10 pb-16">
              <ClientOnly>
                <KeepAlive>
                  <component
                    :is="settingsComponent"
                    :loading="loading"
                    :current-header="currentHeader"
                    @update:loading="setLoading"
                  />
                </KeepAlive>
              </ClientOnly>
            </div>
          </div>
        </div>
        <Overlay v-if="loading" />
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import EventBus from "@/plugins/eventbus";
  import useHasScrollbar from "~/composables/useHasScrollbar";
  import { onMounted, onUnmounted, ref, watch } from "vue";
  import type { ISettingsFlow } from "../types";

  import NoSuchSetting from "../flows/NoSuchSetting.vue";
  import NotificationSettings, { useNotificationSettings } from "../flows/NotificationSettings.vue";
  import ProfileSettings, { useProfileSettings } from "../flows/ProfileSettings.vue";
  import SecuritySettings, { useSecuritySettings } from "../flows/SecuritySettings.vue";
  import { currentFlowKey, currentHeader, open } from "../service";
  import SettingsSidebar from "./Sidebar/index.vue";

  const { t } = useI18n();

  const modal = ref<HTMLDivElement | null | undefined>();

  const loading = ref<boolean>(false);
  const flowContainer = ref<HTMLElement | null | undefined>();

  const setLoading = (l: boolean) => (loading.value = l);
  const allFlows = [useProfileSettings(), useSecuritySettings(), useNotificationSettings()];

  const flows = computed<ISettingsFlow[]>(() => {
    return allFlows.filter((flow) => {
      if (flow.condition) {
        return flow.condition();
      }
      return true;
    });
  });

  watch(open, (o) => {
    if (o) {
      setCurrentFlowKey(flows.value[0].key);
    }
  });

  watch(
    () => flowContainer.value,
    () => {
      if (flowContainer.value) {
        useHasScrollbar(flowContainer.value);
      }
    }
  );

  const settingsComponent = computed(() => {
    switch (currentFlow.value?.key) {
      case "profile":
        return ProfileSettings;
      case "security":
        return SecuritySettings;
      case "notifications":
        return NotificationSettings;
      default:
        return NoSuchSetting;
    }
  });

  const addFlow = (boarding: ISettingsFlow) => {
    flows.value.push(boarding);
  };

  const currentFlow = computed(() => {
    if (!currentFlowKey.value) return null;
    return flows.value.find((b) => b.key === currentFlowKey.value);
  });

  const startSettings = (key: string) => {
    open.value = true;
    nextTick(() => {
      setCurrentFlowKey(key);
    });
  };

  const removeFlow = (key: string) => {
    const index = flows.value.findIndex((setup) => setup.key === key);
    if (index !== -1) {
      flows.value.splice(index, 1);
    }
  };

  const setCurrentFlowKey = (key: string, header?: string) => {
    currentFlowKey.value = key;
    currentHeader.value = null;
    nextTick(() => {
      if (header) currentHeader.value = header;
    });
  };

  onMounted(() => {
    EventBus.$on("PREFERENCES_TOGGLE", () => {
      open.value = !open.value;
    });
    EventBus.$on("PREFERENCES_OPEN", () => {
      open.value = true;
    });
    EventBus.$on("PREFERENCES_CLOSE", () => {
      open.value = false;
    });
    EventBus.$on("PREFERENCES_ADD", addFlow);
    EventBus.$on("PREFERENCES_REMOVE", removeFlow);
    EventBus.$on("PREFERENCES_START", startSettings);
  });

  onUnmounted(() => {
    EventBus.$off("PREFERENCES_TOGGLE");
    EventBus.$off("PREFERENCES_OPEN");
    EventBus.$off("PREFERENCES_CLOSE");
    EventBus.$off("PREFERENCES_ADD");
    EventBus.$off("PREFERENCES_REMOVE");
    EventBus.$off("PREFERENCES_START");
  });

  const currentPageTitle = computed(() => {
    if (!currentFlow.value) return t("settings.no_such_setting");

    if (currentFlow.value?.pageTitle) return currentFlow.value.pageTitle;
    else return currentFlow.value.title;
  });

  const currentPageDescription = computed(() => {
    if (!currentFlow.value) return "";

    return currentFlow.value.description;
  });
</script>
