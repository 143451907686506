<template>
  <header class="flex items-center gap-2.5">
    <Avatar
      size="xs"
      :entity="
        data
          ? {
              name: data.carrier,
              image: getCarrierLogoUrl(data.carrier),
            }
          : {}
      "
    />
    <div class="flex flex-col">
      <p class="text-body-lg text-secondary font-medium">
        {{ data?.package_number || "-" }}
      </p>
      <p class="text-body-default text-tertiary">
        {{ data?.reference || "-" }}
      </p>
    </div>
  </header>
  <TrackingIndicator :event="data?.latest_tracking_event" timestamp />
  <div
    class="text-body-default [&>*:nth-child(even)]:text-secondary [&>*:nth-child(odd)]:odd:text-quarterary grid w-full grid-cols-[max-content_1fr] gap-x-2 gap-y-3.5 [&>*:nth-child(odd)]:odd:break-words"
  >
    <p>{{ t("carrier") }}</p>
    <p>{{ data?.carrier_name || "-" }}</p>
    <p>{{ t("product") }}</p>
    <p>{{ data?.title || "-" }}</p>
    <p>{{ t("dimensions") }}</p>
    <p>
      {{
        `${t("heightShort")}${data?.height || "-"}cm x ${t("widthShort")}${data?.width || "-"}cm x ${t("lengthShort")}${data?.length || "-"}cm`
      }}
    </p>
    <p>{{ t("weight") }}</p>
    <p>{{ useFormatWeight(data?.weight || 0) }}</p>
    <p>{{ t("price") }}</p>
    <p>{{ priceWithCurrency }}</p>
    <p>{{ t("labelless") }}</p>
    <p>
      {{ data?.labelless_code ? t("yes") : t("no") }}
    </p>
    <p>{{ t("pallet_number") }}</p>
    <p>
      {{ data?.consignment?.consignment_number || "-" }}
    </p>
    <p>{{ t("insurance") }}</p>
    <p>{{ data?.insurance || "-" }}</p>
    <p>{{ t("ordered_via") }}</p>
    <p>{{ data?.order_context || "API" }}</p>
    <p class="self-center">{{ t("document", 99) }}</p>
    <Badge>
      <Icon src="FileText" />
      {{ data?.documents_count || 0 }}
      {{ t("document", data?.documents_count || 99) }}
    </Badge>
  </div>
</template>

<script lang="ts" setup>
  import type { IShipmentSimple } from "~/types/shipment";

  defineOptions({
    name: "GlobalSearchInfoShipment",
  });

  const props = defineProps<{
    data: IShipmentSimple;
  }>();
  const { t } = useI18n();

  const priceWithCurrency = computed(() => {
    if (!props.data?.price) return "-";
    return useFormatPrice(useDecidePriceExclOrIncl(props.data.price), true, props.data.price.currency);
  });
</script>
