<template>
  <form v-if="validationSchema" @submit.prevent="onSubmit">
    <slot name="hidden" />
    <div class="flex w-full px-4 py-4">
      <Button class="mr-2" type="button" icon-only :variant="ButtonVariant.Tertiary" @click="exit">
        <Icon class="text-secondary cursor-pointer" src="close" />
      </Button>
      <p class="text-title-body text-quarterary mt-1.5">{{ name }}</p>
      <Divider class="mx-2" rotation="vertical" />
      <p class="text-title-body text-secondary mt-1.5">{{ stepNames[currentStepIdx] }}</p>
    </div>
    <slot />
    <div class="flex p-4">
      <Button
        v-if="hasPrevious"
        class="w-full max-w-max"
        type="button"
        :variant="ButtonVariant.Tertiary"
        @click="goToPrev"
        >{{ t("previous") }}</Button
      >
      <Button
        v-if="hasSkip"
        class="mr-3 ml-auto w-full max-w-max"
        type="button"
        :variant="ButtonVariant.Default"
        @click="skip"
        >{{ t("skip_this_step") }}</Button
      >
      <Button
        v-if="hasContinueButton"
        class="w-full max-w-max"
        type="submit"
        :class="{ 'ml-auto': !hasSkip }"
        :disabled="!canSubmit || hasSkip"
        :variant="isLastStep && lastStepPositive ? 'positive' : 'primary'"
        :loading="loading"
      >
        {{ continueButtonText }}
      </Button>
    </div>
  </form>
</template>

<script setup lang="ts">
  import { ButtonVariant } from "~/types/global";

  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      validationSchema?: any[];
      loading?: boolean;
      submitButtonText?: string;
      lastStepPositive?: boolean;
      showProgress?: boolean;
      splitFooter?: boolean;
      customFooter?: boolean;
      name?: string;
      stepNames?: string[];
    }>(),
    {
      loading: false,
      submitButtonText: "",
      lastStepPositive: false,
      showProgress: false,
      splitFooter: false,
      name: "",
      customFooter: false,
      stepNames: () => [],
      validationSchema: undefined,
    }
  );

  const emit = defineEmits(["submit", "exit", "skip"]);
  const {
    currentStepIdx,
    isLastStep,
    hasPrevious,
    hasSkip,
    stepKey,
    hasContinueButton,
    continueButtonText,
    goToPrev,
    form,
    canSubmit,
    skip,
    exit,
    onSubmit,
    goToLastStep,
    goToStepByKeyName,
    resetForm,
    setErrors,
  } = useCreateShipment({
    submitButtonText: props.submitButtonText,
    validationSchema: props.validationSchema,
    emit,
  });

  const { values, setValues, setFieldValue } = form;

  defineExpose({
    values,
    currentStepIdx,
    isLastStep,
    hasPrevious,
    canSubmit,
    stepKey,
    setValues,
    setFieldValue,
    goToLastStep,
    goToStepByKeyName,
    resetForm,
    setErrors,
  });
</script>
