<template>
  <div
    ref="toast"
    class="toast fixed z-[99999] flex w-full justify-center bg-transparent focus:outline-none"
    role="alert"
    :class="{
      'right-2 bottom-10': position === 'bottom-right',
      'bottom-10 left-2': position === 'bottom-left',
      'top-10 right-2': position === 'top-right',
      'top-10 left-2': position === 'top-left',
      'top-10 left-1/2 -translate-x-1/2 transform': position === 'top-center',
      'bottom-10 left-1/2 -translate-x-1/2 transform': position === 'bottom-center',
    }"
  >
    <transition-group class="flex flex-col items-center space-y-2" name="toast-message" tag="div">
      <ToastMessage
        v-for="message in messagesWithoutTarget"
        :key="message.id"
        :message="message"
        @dismiss="remove($event, true)"
      />
    </transition-group>

    <Teleport v-for="[target, g] in Object.entries(groupedMessagesWithTarget)" :key="target" :to="target">
      <ToastMessage v-for="message in g" :key="message.id" :message="message" @dismiss="remove($event, true)" />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
  import EventBus from "@/plugins/eventbus";
  import { onMounted, onUnmounted, ref, watch } from "vue";
  import type { IToast } from "./ToastMessage.vue";

  import { messages } from "../service";
  import ToastMessage from "./ToastMessage.vue";

  interface Props {
    position?: "top-left" | "top-right" | "bottom-left" | "bottom-right" | "top-center" | "bottom-center";
    duration?: number;
    groups?: boolean;
    reverse?: boolean;
  }
  const emit = defineEmits(["added", "dismissed", "removed", "cleared"]);

  const props = withDefaults(defineProps<Props>(), {
    position: "bottom-center",
    duration: 5000,
    groups: true,
    reverse: false,
  });

  const toast = ref<HTMLDialogElement | null>(null);

  const messagesWithTarget = computed(() => messages.value.filter((message) => message.target));
  const messagesWithoutTarget = computed(() => messages.value.filter((message) => !message.target));

  const groupedMessagesWithTarget = computed<Record<string, IToast[]>>(() => {
    const groups: Record<string, IToast[]> = {};
    messagesWithTarget.value.forEach((message) => {
      if (!groups[message.target]) groups[message.target] = [];
      groups[message.target].push(message);
    });
    return groups;
  });

  function open() {
    if (toast.value === null) return;

    //We manually set open instead of using show() to prevet focus grabbing
    toast.value.open = true;
  }

  function close() {
    if (!toast.value) return;

    //check if close is available and a function before calling it
    if (toast.value.close && typeof toast.value.close === "function") {
      toast.value.close();
    } else {
      toast.value.open = false;
    }
  }

  const hashCode = (s) => Math.abs(s.split("").reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0));

  let messageId = 1;

  onMounted(() => {
    EventBus.$on("TOAST_ADD", (ev) => {
      emit("added", ev);
      if (!ev.group)
        ev.group = hashCode(`${ev.color}${ev.title}${ev.text}${getDateRoundedToNearestNthSeconds(5)}`).toString(16);
      // If there's a default duration and no message duration is set, use the default
      if (props.duration && !ev.duration && ev.duration !== 0) ev.duration = props.duration;
      if (props.position && !ev.position && ev.position !== 0) ev.position = props.position;

      if (ev.id) {
        remove(ev);
      }

      // Find the existing message if one with the same group-key already exists
      const existingGroup = ev.group && messages.value.find((msg) => msg.group === ev.group);

      if (props.groups === false || !existingGroup) {
        const message = {
          ...ev,
          id: ev.id || messageId,
          count: 1,
        };
        if (props.reverse) messages.value.unshift(message);
        else messages.value.push(message);

        open();
        if (!ev.id) messageId++;
      } else {
        existingGroup.count++;
      }
    });

    EventBus.$on("TOAST_CLEAR", () => {
      emit("cleared");
      messages.value = [];
      close();
    });

    EventBus.$on("TOAST_REMOVE", (messageId) => {
      remove({ id: messageId });
    });
  });

  const getDateRoundedToNearestNthSeconds = (n) => {
    const d = new Date();
    const seconds = Math.floor(d.getSeconds() / n) * n;
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), seconds, 0);
  };

  onUnmounted(() => {
    EventBus.$off("TOAST_ADD");
    EventBus.$off("TOAST_CLEAR");
    close();
  });

  const remove = (ev, wasDismissed = false) => {
    if (!ev?.id) return;

    if (wasDismissed) emit("dismissed", ev);
    else emit("removed", ev);
    messages.value = messages.value.filter((message) => {
      return message.id !== ev.id;
    });
  };

  watch(messages, (newMessages) => {
    if (newMessages.length > 0) open();
    else close();
  });
</script>
