<template>
  <div class="flex min-w-[250px] flex-col gap-2">
    <div v-for="(service, key) in props.services" :key="key" class="h-11 w-full">
      <Button
        class="!h-11 w-full"
        type="button"
        :variant="ButtonVariant.Tertiary"
        text-left
        :active="selectedService == key"
        @click="selectSetvice(service, key)"
      >
        <p>{{ service.label }}</p>
        <Icon
          class="text-tertiary ml-auto transition-opacity duration-300"
          :class="isSelected(service) ? 'opacity-100' : 'opacity-0'"
          src="check"
        />
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant } from "~/types/global";

  const props = defineProps<{
    services: any;
    selectedServices: Array<string>;
  }>();

  const emit = defineEmits(["valueChange"]);

  const setService = ref(null);

  const selectedService = computed(() => {
    return setService.value ? setService.value : Object.keys(props.services)[0];
  });

  const isSelected = (service) => {
    return props.selectedServices.includes(returnKey(service));
  };

  const returnKey = (service: object) => {
    return service.carrier_key == "service_codes" ? service.fields.key : service.carrier_key;
  };

  const selectSetvice = (service, key) => {
    setService.value = key;
    emit("valueChange", service);
  };
</script>
