<template>
  <Modal
    v-if="open"
    :package-number="package_number"
    :select-all="selectAll"
    :selected="selected"
    :filters="filters"
    :transfer-id="transferId"
  />
</template>

<script setup lang="ts">
  import EventBus from "@/plugins/eventbus";

  import { open } from "../service";
  import Modal from "./modal.vue";

  const package_number = ref("");
  const transferId = ref<number>();
  const selectAll = ref(false);
  const selected = ref<string[] | number[]>([]);
  const filters = ref({});

  onMounted(() => {
    EventBus.$on("PRINTING_TOGGLE", () => {
      open.value = !open.value;
    });
    EventBus.$on("PRINTING_OPEN", () => {
      open.value = true;
    });
    EventBus.$on("PRINTING_CLOSE", () => {
      open.value = false;
      package_number.value = "";
      selectAll.value = false;
      selected.value = [];
      filters.value = {};
    });
    EventBus.$on(
      "PRINTING_ADD_LABEL",
      (package_number_s: string, selectAll_s: boolean, selected_s: string[] | number[], filters_s: object) => {
        package_number.value = package_number_s || "";
        selectAll.value = selectAll_s || false;
        selected.value = selected_s || [];
        filters.value = filters_s || {};
      }
    );
    EventBus.$on("PRINTING_ADD_TRANSFER", (transferId_s: number) => {
      transferId.value = transferId_s;
    });
  });

  onUnmounted(() => {
    EventBus.$off("PRINTING_TOGGLE");
    EventBus.$off("PRINTING_OPEN");
    EventBus.$off("PRINTING_CLOSE");
    EventBus.$off("PRINTING_ADD_LABEL");
    EventBus.$off("PRINTING_ADD_TRANSFER");
  });
</script>
