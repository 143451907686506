import EventBus from "@/plugins/eventbus";
import { inject, ref } from "vue";
import type { IToast } from "./components/ToastMessage.vue";

declare global {
  const $toast: {
    add: (message: IToast) => void;
    clear: () => void;
    remove: (messageId: string | number) => void;
  };
}

export const messages = ref<any[]>([]);

/**
 * A unique identifier to access the provided/injected method
 */
export const ToastSymbol = Symbol();

/**
 * Composable which allows accessing the Toast service in Composition API
 * @returns Toast
 */
export function useToast() {
  const Toast = inject(ToastSymbol);
  if (!Toast) {
    throw new Error("No Toast provided!");
  }

  return Toast;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const ToastService = {
  install: (app) => {
    const ToastService = {
      add: (message) => {
        EventBus.$emit("TOAST_ADD", message);
      },
      clear: () => {
        EventBus.$emit("TOAST_CLEAR");
      },
      remove: (messageId: string | number) => {
        EventBus.$emit("TOAST_REMOVE", messageId);
      },
    };

    app.config.globalProperties.$Toast = ToastService;
    if (typeof window !== "undefined") window.$toast = ToastService;
    app.provide(ToastSymbol, ToastService);
  },
};
