<template>
  <div
    class="flex shrink-0 rounded-full transition-all duration-300"
    tabindex="0"
    :class="[
      inputValue ? 'bg-positive hover:bg-positive-dark' : 'bg-02',
      disabled ? '!bg-disabled cursor-not-allowed' : 'focus:shadow-focus-blue cursor-pointer',
      size === 'sm' ? 'h-4 w-7 p-1' : 'h-5 w-[38px] p-[3px]',
    ]"
    @click="onClick"
  >
    <div
      class="shadow-lvl-01 shadow-surface-lvl-01 rounded-full transition-all duration-300"
      :class="[
        inputValue && size == 'md' ? 'ml-[18px]' : 'ml-0',
        inputValue && size == 'sm' ? 'ml-[11px]' : 'ml-0',
        disabled ? 'bg-disabled-dark' : 'bg-white',
        size === 'sm' ? 'h-2 w-2' : 'h-3.5 w-3.5',
      ]"
    />
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      name?: string;
      selected?: boolean;
      disabled?: boolean;
      size?: "sm" | "md";
      parentClicked?: boolean;
    }>(),
    {
      name: "",
      selected: false,
      disabled: false,
      size: "md",
      parentClicked: false,
    }
  );

  const _modelValue = defineModel<boolean>();
  const emit = defineEmits(["update:modelValue"]);

  const onClick = () => {
    if (props.disabled) return;
    setValue();
  };

  watch(
    () => props.parentClicked,
    () => {
      setValue();
    }
  );

  const { value: inputValue } = useField(() => props.name, undefined, {
    type: "checkbox",
    checkedValue: true,
    uncheckedValue: false,
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  const setValue = () => {
    if (props.disabled) return;
    inputValue.value = !inputValue.value;
    emit("update:modelValue", inputValue.value);
  };
</script>
