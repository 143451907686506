<template>
  <Modal
    ref="modal"
    :click-to-close="false"
    :open="true"
    max-width="342px"
    min-width="342px"
    :content-padding="false"
    :center="true"
    anchor="top"
  >
    <ModalHeader class="border-b-0">
      {{ t("connect_webshop") }}
    </ModalHeader>
    <div v-if="hasWebshops" class="text-body-default text-tertiary flex flex-col gap-4">
      <i18n-t class="px-4" keypath="activate_checkout_on_webshop" tag="p">
        <template #checkout>
          <span class="text-body-default-heavy text-secondary">{{ checkout.name }}</span>
        </template>
      </i18n-t>

      <div class="max-h-80 overflow-auto px-4">
        <ListSelector v-model="selectedWebshops" :values="webshops" value-key="id" :ticker="false" multi>
          <template #title="{ value }">
            <div class="text-secondary flex gap-4">
              <div class="mt-0.5">
                <Icon src="bold/ShoppingBagOpen" :size="ComponentSize.g" />
              </div>
              <div class="flex flex-col">
                <div class="text-body-lg-heavy">{{ value.name }}</div>
                <div class="text-body-default text-tertiary">{{ value.shop_url || t("not_specified") }}</div>
              </div>
            </div>
          </template>
        </ListSelector>
      </div>
    </div>
    <div v-else-if="loading" class="relative mx-4 flex h-24 flex-col items-center justify-center p-5">
      <Overlay>
        <div class="text-body-default-heavy text-tertiary mt-2">{{ t("loading_webshops") }}</div>
      </Overlay>
    </div>
    <div v-else class="flex flex-col items-center justify-center p-4">
      <img class="mb-4" src="~/assets/illustrations/emptyViews/ec-no-result.svg" alt="No webshops illustration" />
      <p class="text-body-default text-tertiary text-center">{{ t("checkout_no_webshops") }}</p>
    </div>
    <ModalFooter class="flex-col items-stretch gap-2 border-t-0">
      <template v-if="hasWebshops">
        <Button
          :disabled="!hasChanged"
          :loading="actionLoading"
          :variant="ButtonVariant.Primary"
          @click="attachWebshops"
          >{{ t("connect_n_webshop", { n: selectedWebshops.length }, selectedWebshops.length) }}</Button
        >

        <Button @click="close">{{ t("cancel") }}</Button>
      </template>
      <template v-else-if="loading">
        <Button :variant="ButtonVariant.Primary" @click="close">{{ t("close") }}</Button>
      </template>

      <template v-else>
        <Button :variant="ButtonVariant.Primary" @click="close">{{ t("close") }}</Button>
        <Button @click="goToWebshops"
          >{{ t("go_to_webshops") }} <template #suffix><Icon src="bold/ArrowRight" /></template>
        </Button>
      </template>
    </ModalFooter>
  </Modal>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import _ from "lodash";
  import type { ICheckoutIndex, IWebshop } from "../../types";

  const { t } = useI18n();

  const emit = defineEmits(["close"]);
  const actionLoading = ref(false);
  const loading = ref(false);
  const hasWebshops = computed(() => webshops.value.length > 0);
  const props = defineProps<{
    checkout: ICheckoutIndex;
  }>();

  const initialSelectedWebshops = props.checkout.webshops.map((shop) => shop.id);

  onMounted(() => {
    getWebshops();
    selectedWebshops.value = props.checkout.webshops.map((shop) => shop.id);
  });

  const hasChanged = computed(() => {
    return !_.isEqual(selectedWebshops.value, initialSelectedWebshops);
  });

  const webshops = ref<IWebshop[]>([]);
  const selectedWebshops = ref<number[]>([]);

  const getWebshops = () => {
    loading.value = true;
    return homeFetch("workspace/webshops")
      .then((response) => {
        if (response?.data) {
          webshops.value = response.data;
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const attachWebshops = () => {
    const body = {
      webshop_ids: selectedWebshops.value,
    };
    actionLoading.value = true;

    return homeFetch(`workspace/checkouts/${props.checkout.id}/attach-webshops`, { method: "POST", body })
      .then((response) => {
        if (response?.data) {
          props.checkout.webshops = response.data.shops;
          close();
        }
      })
      .finally(() => {
        actionLoading.value = false;
      });
  };

  const goToWebshops = () => {
    emit("close");
    window.$workspace_settings.start("webshops");
  };

  const close = () => {
    emit("close");
  };
</script>
