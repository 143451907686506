<template>
  <Input
    v-bind="$attrs"
    ref="input"
    tag="textarea"
    :model-value="inputValue"
    :error="error"
    size="xl"
    @update:model-value="(val) => setValue(val, false)"
    @blur="(evt) => handleBlur(evt, true)"
    @click="() => emit('click')"
  />
</template>

<script setup lang="ts">
  import { computed, ref } from "vue";

  const props = withDefaults(
    defineProps<{
      disabled?: boolean;
      name?: string;
      error?: string;
    }>(),
    {
      disabled: false,
      name: "",
      error: undefined,
    }
  );
  const _modelValue = defineModel<string>();

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    setValue,
  } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  const error = computed(() => {
    return props.error || errorMessage.value;
  });

  const input = ref<HTMLElement | null>(null);

  const el = computed(() => {
    return input.value?.input;
  });

  const emit = defineEmits(["click"]);

  defineExpose({
    el,
  });
</script>
