export default () => {
  const { workspace } = storeToRefs(useWorkspacesStore());

  const roles = computed(() => {
    const { t } = useI18n();

    return [
      { value: "1", label: t("settings.users.roles.owner"), subtext: t("settings.users.roles.ownerDescription") },
      { value: "7", label: t("settings.users.roles.admin"), subtext: t("settings.users.roles.adminDescription") },
      {
        value: "3",
        label: t("settings.users.roles.support"),
        subtext: t("settings.users.roles.supportDescription"),
      },
      { value: "8", label: t("settings.users.roles.reader"), subtext: t("settings.users.roles.readerDescription") },
    ];
  });

  const capabilities = computed(() => {
    if (!workspace.value) return [];

    return workspace.value?.capabilities;
  });

  const hasCapability = (capability: string): boolean => {
    if (!capabilities.value?.length) return false;

    if (capabilities.value.includes("owner")) return true;

    //if capability ends with * then remove * and check if capability starts with the value
    if (capability.endsWith("*")) {
      const capabilityStart = capability.slice(0, -1);
      return capabilities.value.some((cap) => cap.startsWith(capabilityStart));
    }

    return capabilities.value.includes(capability);
  };

  return { capabilities, hasCapability, roles };
};
