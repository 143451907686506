<template>
  <div class="flex flex-col gap-3">
    <div class="flex items-center gap-3">
      <div>
        <div class="mb-1 flex items-center gap-2">
          <p class="text-title-subsection text-secondary truncate">{{ portal.name }}</p>
        </div>
        <p Class=" text-quarterary text-body-sm ">
          <span v-if="portal.created_at">{{ DateTime.fromISO(portal.created_at).toFormat("DDD") }}</span>
          <span v-if="portal.created_at && isLegacy"> • </span>
          <span v-if="isLegacy">{{ t("legacy_return_portal") }}</span>
        </p>
      </div>
      <div class="ml-auto flex items-center gap-2">
        <Tooltip :text="t('go_to_returnportal')">
          <a :href="portal.endpoint" target="_blank" rel="noopener noreferrer">
            <Button :variant="ButtonVariant.Tertiary" icon-only>
              <Icon src="arrow_up_right" />
            </Button>
          </a>
        </Tooltip>
        <DropdownSelect
          v-if="!isLegacy"
          v-model="selectedCheckout"
          class="mr-2 ml-auto"
          trigger-button-:variant="ButtonVariant.Tertiary"
          :trigger-text="t('add_checkout')"
          :options="checkouts"
          @select="(val) => selectCheckout(val)"
        />

        <DropdownActions
          trigger-icon-prefix="bold/DotsThreeVertical"
          trigger-text=""
          :actions="actions"
          :button-size="ComponentSize.default"
        />
      </div>
    </div>
    <div v-if="!isLegacy" class="grid grid-cols-3 gap-3">
      <div class="border-00 flex h-24 flex-col justify-between rounded-xl border p-3">
        <p class="text-body-sm text-quarterary">
          {{ t("Webaddress") }}
        </p>
        <Tooltip :text="portal.customer_url ?? portal.endpoint">
          <p class="text-body-lg-heavy text-secondary ml-auto w-full truncate">
            {{ portal.customer_url ?? portal.endpoint }}
          </p>
        </Tooltip>
      </div>
      <div class="border-00 flex h-24 flex-col justify-between rounded-xl border p-3">
        <p class="text-body-sm text-quarterary">
          {{ t("carriers_activated") }}
        </p>
        <AvatarGroup v-if="mappedCarriers && mappedCarriers.length" :entities="mappedCarriers" size="2xs" />
        <Tooltip v-else :text="t('no_carriers_activated')">
          <p class="text-body-lg-heavy text-secondary ml-auto w-full truncate">{{ t("no_carriers_activated") }}</p>
        </Tooltip>
      </div>
      <div class="border-00 flex h-24 flex-col justify-between rounded-xl border p-3">
        <p class="text-body-sm text-quarterary">
          {{ t("checkout") }}
        </p>
        <Tooltip v-if="portal.settings.checkout" :text="portal.settings.checkout.name">
          <p class="text-body-lg-heavy text-secondary ml-auto w-full truncate">{{ portal.settings.checkout.name }}</p>
        </Tooltip>
        <Tooltip v-else :text="t('no_checkout_added')">
          <p class="text-body-lg-heavy text-secondary ml-auto w-full truncate">{{ t("no_checkout_added") }}</p>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import { DateTime } from "luxon";
  import type { IReturnPortal } from "~/types/returns";

  const { t } = useI18n();

  const emit = defineEmits(["update", "editPortal"]);

  defineOptions({
    name: "ReturnPortalRow",
  });

  onMounted(() => {});

  const props = withDefaults(
    defineProps<{
      portal: IReturnPortal;
      checkouts: { label: string; value: string; icon: string }[];
    }>(),
    {}
  );

  const isLegacy = computed(() => {
    return !props.portal.settings.smartcheckout;
  });
  const selectedCheckout = computed(() => {
    return props.portal?.settings?.checkout?.id;
  });

  const actionLoading = ref<boolean>(false);

  const actions = computed(() => {
    return [
      {
        label: t("edit"),
        value: "edit",
        handler: () => emit("editPortal", props.portal),
      },
      {
        label: t("share"),
        value: "share",
        handler: copyLink,
      },
      null,
      {
        label: t("delete"),
        value: "delete",
        handler: deleteReturnPortal,
        destructive: true,
      },
    ];
  });

  const mappedCarriers = computed(() => {
    if (!props.portal?.settings?.checkout?.shipping_methods) {
      if (returnCarriers.value?.length) {
        return [{ name: returnCarriers.value, image: getCarrierLogoUrl(returnCarriers.value) }];
      }
      return [];
    }
    const carriers = [];
    for (const carrier of props.portal.settings.checkout.shipping_methods) {
      carriers.push({
        name: carrier.carrier_product_service.name,
        image: getCarrierLogoUrl(carrier.carrier_product_service.carrier),
      });
    }
    return carriers;
  });

  const copyLink = () => {
    copyToClipboard(props.portal.endpoint);
  };

  const deleteReturnPortal = () => {
    $confirm({
      message: t("return_portal_delete_confirm", { checkout: props.portal.name }),
      onConfirm: () => {
        homeFetch(`workspace/return-portals/${props.portal.uid}`, { method: "DELETE" })
          .then(() => {
            $toast.add({
              title: t("portal_deleted"),
            });
            emit("update");
          })
          .finally(() => {
            actionLoading.value = false;
          });
      },
      destructive: true,
    });
  };

  const selectCheckout = (val: number) => {
    const shop = { ...props.shop };
    shop.checkout_id = val;
    homeFetch(`workspace/return-portals/${props.portal.uid}/attach-checkout`, {
      method: "PUT",
      body: {
        checkout_id: val,
      },
    }).then(() => {
      emit("update");
    });
  };

  const returnCarriers = computed(() => {
    return props.portal.product?.split("_")[0];
  });
</script>
