<template>
  <Input
    v-bind="$attrs"
    ref="input"
    type="number"
    tag="input"
    :model-value="inputValue"
    :error="error"
    :size="size"
    :min="min"
    :max="max"
    @update:model-value="(val) => setValue(val, false)"
    @blur="(evt) => handleBlur(evt, true)"
    @click="() => emit('click')"
  >
    <template v-for="(slot, index) of slotNames" :key="index" #[slot]>
      <slot :name="slot" />
    </template>
  </Input>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";
  import { computed, ref } from "vue";

  const slots = useSlots();
  const slotNames = Object.keys(slots) as "default"[];
  const _modelValue = defineModel<string | number>();

  const props = withDefaults(
    defineProps<{
      size?: InputSize;
      disabled?: boolean;
      selected?: boolean;
      active?: boolean;
      name?: string;
      error?: string;
      min?: number;
      max?: number;
    }>(),
    {
      size: ComponentSize.default,
      disabled: false,
      selected: false,
      active: false,
      name: "",
      error: undefined,
      min: undefined,
      max: undefined,
    }
  );

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    setValue,
  } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  const error = computed(() => {
    return props.error || errorMessage.value;
  });

  const input = ref<HTMLElement | null>(null);

  const el = computed(() => {
    return input.value?.input;
  });

  const emit = defineEmits(["click"]);

  defineExpose({
    el,
  });
</script>

<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
