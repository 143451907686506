<template>
  <div>
    <div
      class="flex w-full gap-2"
      :title="item.label"
      :class="{
        'cursor-pointer': hasOnClick,
      }"
      @click="onClick"
    >
      <Icon
        v-if="slots.default"
        class="my-auto shrink-0 transform transition-all duration-300"
        src="bold/CaretDown"
        filled
        :class="{
          '-rotate-90': !localExpanded,
          'opacity-0': !selected && expandOnToggle,
        }"
      />
      <div class="flex w-full min-w-0 basis-full items-center gap-2">
        <Avatar
          v-if="item.image"
          size="xs"
          :entity="{
            name: item.label,
            image: item.image,
          }"
        />
        <div class="flex w-full min-w-0 flex-col">
          <div class="text-body-lg-heavy overflow-hidden text-ellipsis whitespace-nowrap">{{ item.label }}</div>
          <p v-if="item.description" class="text-body-default text-quarterary">{{ item.description }}</p>
        </div>
        <Toggle :model-value="selected" @update:model-value="toggle" @click.stop.prevent="" />
      </div>
    </div>
    <div v-if="localExpanded" class="mt-3 flex w-full gap-2">
      <slot v-bind="item" />
    </div>
  </div>
</template>

<script setup lang="ts">
  export interface IToggleListItemEntry {
    label: string;
    description?: string;
    image?: string;
    value: string | number;
  }

  const slots = defineSlots();

  const selected = defineModel<boolean>({ default: false });
  const localExpanded = ref(false);

  const props = withDefaults(
    defineProps<{
      item: IToggleListItemEntry;
      expanded?: boolean;
      expandOnToggle?: boolean;
    }>(),
    {
      expanded: false,
      expandOnToggle: false,
    }
  );
  const toggle = (val: boolean) => {
    if (props.expandOnToggle) {
      localExpanded.value = val;
    }

    selected.value = val;
  };

  const hasOnClick = computed(() => {
    if (!slots.default) {
      return false;
    }

    if (props.expandOnToggle && !selected.value) {
      return false;
    }

    return true;
  });

  const onClick = () => {
    if (!hasOnClick.value) return;

    localExpanded.value = !localExpanded.value;
  };

  const expand = () => {
    localExpanded.value = true;
  };

  const collapse = () => {
    localExpanded.value = false;
  };

  watch(
    () => props.expanded,
    (val) => {
      localExpanded.value = val;
    }
  );

  defineExpose({
    expand,
    collapse,
  });
</script>
