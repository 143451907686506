<template>
  <div class="relative flex flex-col gap-1 text-left">
    <label v-if="label" class="text-body-sm-heavy">{{ label }}</label>

    <DropdownTriggerButton
      v-if="triggerType == 'button'"
      ref="trigger"
      class="w-full"
      :button-size="size"
      :title="prettyModelValue"
      :disabled="readonly"
      :variant="triggerButtonVariant"
      @click="toggleDropdown"
    >
      <div class="flex">
        <img
          v-if="selectedOption && selectedOption.image"
          class="mr-2"
          :src="selectedOption.image"
          width="20"
          height="20"
        />
        <Avatar
          v-else-if="selectedOption && selectedOption.avatar"
          class="mr-2"
          :entity="selectedOption.avatar"
          size="3xs"
        />

        <slot name="triggerText">
          {{ prettyModelValue || triggerText || t("select_element") }}
        </slot>
      </div>
      <template #prefix>
        <Icon v-if="selectedOption && selectedOption.icon" class="text-inherit" :src="selectedOption.icon" />
      </template>
    </DropdownTriggerButton>
    <DropdownTriggerInput
      v-else-if="triggerType == 'input'"
      ref="trigger"
      class="w-full"
      :size="size"
      :title="prettyModelValue"
      :disabled="readonly"
      :model-value="prettyModelValue || triggerText || t('select_element')"
      @click="toggleDropdown"
      @keyup.tab.exact.stop.prevent="toggleAndSetFocus()"
    >
      <template #prefix>
        <img
          v-if="selectedOption && selectedOption.image"
          class="mr-2"
          :src="selectedOption.image"
          width="20"
          height="20"
        />
        <Avatar
          v-else-if="selectedOption && selectedOption.avatar"
          class="mr-2"
          :entity="selectedOption.avatar"
          size="3xs"
        />
      </template>
    </DropdownTriggerInput>

    <Dropdown
      v-if="trigger?.el"
      ref="dropdown"
      v-model:open="showDropdown"
      anchor="top"
      :toggle-element="trigger.el"
      :calculate-position="calculatePosition"
      :min-width="triggerWidth"
    >
      <template v-if="filterable">
        <DropdownItemSearch
          ref="searchElm"
          v-model="search"
          @keydown.down.prevent="() => setFocusOnItem(-1, 'down')"
          @keydown.up.prevent="() => setFocusOnItem(-1, 'up')"
        />
        <Divider :spacing="false" />
      </template>
      <div
        ref="optionsWrapper"
        class="max-h-64 w-full overflow-x-hidden overflow-y-auto p-1.5"
        :class="{
          'pt-1.5': filterable,
        }"
        :style="`min-width:${triggerWidth}; max-width:${itemMaxWidth}`"
      >
        <slot name="options">
          <template
            v-for="(option, index) in filteredOptions"
            :key="`${search}_${option ? option.value : 'seperator'}`"
          >
            <DropdownItem
              v-if="option"
              class="overflow-hidden text-nowrap text-ellipsis"
              :close-on-click="!multiple"
              :aria-label="option.label"
              :selected="isSelected(option)"
              @click="() => selectOption(option.value)"
              @keydown.enter.prevent="() => selectOption(option.value)"
              @keydown.down.prevent="() => setFocusOnItem(index, 'down')"
              @keydown.up.prevent="() => setFocusOnItem(index, 'up')"
            >
              <div class="flex items-center gap-1">
                <img v-if="option.image" :src="option.image" loading="lazy" width="20" height="20" />
                <Avatar v-else-if="option.avatar" :entity="option.avatar" size="3xs" />
                <Icon v-if="option.icon" :src="option.icon" />
                <div class="flex-col gap-2">
                  <div class="overflow-hidden text-ellipsis">{{ option.label }}</div>
                  <div v-if="option.subtext" class="text-body-sm text-quarterary text-wrap">{{ option.subtext }}</div>
                </div>
              </div>
            </DropdownItem>

            <Divider v-else />
          </template>

          <DropdownItem v-if="filteredOptions.length === 0" disabled>
            {{ emptyText || t("no_options_available") }}
          </DropdownItem>
        </slot>
      </div>
    </Dropdown>
    <Message v-if="error" variant="invalid">{{ error }}</Message>
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant } from "~/types/global";
  import { useI18n } from "vue-i18n";

  defineOptions({
    name: "DropdownSelect",
  });

  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      options?: { label: string; subtext?: string; value: string; image?: string | null; icon?: string | null }[];
      filterable?: boolean;
      triggerText?: string;
      emptyText?: string;
      size?: "default" | "small" | "medium" | "large";
      name?: string;
      label?: string;
      readonly?: boolean;
      triggerType?: "button" | "input";
      triggerButtonVariant?: ButtonVariant;
      preSelected?: boolean;
      multiple?: boolean;
      error?: any;
      itemMaxWidth?: string;
    }>(),
    {
      options: () => [],
      filterable: false,
      size: "default",
      name: "",
      label: "",
      readonly: false,
      triggerType: "button",
      triggerButtonVariant: ButtonVariant.Default,
      preSelected: false,
      multiple: false,
      error: null,
      triggerText: undefined,
      emptyText: undefined,
      itemMaxWidth: "auto",
    }
  );

  const multiple = computed(() => props.multiple);

  onMounted(() => {
    if (props.preSelected && props.options?.length > 0) {
      selectOption(props.options[0].value);
      toggleDropdown();
    }
  });

  const options = computed(() => props.options);

  const modelValue = defineModel<string>({ default: "" });

  const emit = defineEmits(["select"]);

  const isSelected = (option) => {
    if (multiple.value) {
      return modelValue.value?.includes(option.value);
    }
    return modelValue.value === option.value;
  };

  const selectedOption = computed(() => props.options.find(isSelected));

  const { dropdown, trigger, showDropdown, toggleDropdown, calculatePosition, triggerWidth } = useDropdown();

  const { searchElm, optionsWrapper, search, filteredOptions, setFocusOnItem, selectOption, prettyModelValue } =
    useSelect({
      dropdown,
      options,
      modelValue,
      emit,
      open: showDropdown,
      toggleDropdown,
      multiple: multiple.value,
    });

  const { value: selectedValue } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  watchEffect(() => {
    if (props.name) {
      if (selectedValue.value && !modelValue.value) {
        modelValue.value = selectedValue.value;
      }
      selectedValue.value = modelValue.value;
    }
  });

  const toggleAndSetFocus = () => {
    toggleDropdown();
    setTimeout(() => {
      setFocusOnItem(-1, "down");
    }, 300);
  };
</script>
