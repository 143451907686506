<template>
  <div class="flex h-[67vh] gap-[60px] overflow-y-auto px-6 py-3 text-center">
    <SideBar
      :selected-services="selectedServices"
      :services="ref_services"
      @value-change="(val) => selectService(val)"
    />
    <div
      v-for="service in ref_services"
      :key="service.label"
      class="w-full"
      :class="visibleService.label == service.label ? 'block' : 'hidden'"
    >
      <p class="text-title-section text-secondary mb-3 text-left">{{ service.label }}</p>
      <p class="text-body-default text-tertiary mb-6 text-left">{{ service.description }}</p>
      <div class="mb-6 w-full">
        <SettingsToggle
          :name="`services.${returnKey(service)}.isSet`"
          :text="t('add_unit_to_shipment', { unit: service.label })"
          @update:model-value="(val) => addValidationRules(val, service)"
        />
      </div>
      <div v-if="service.type == 'select' && selectedServices.includes(returnKey(service))" class="mb-6 w-full">
        <SelectComponent :service="service" @date-update="(val) => emit('dateUpdate', val)" />
      </div>
      <div v-if="service.type == 'dot_delivery' && selectedServices.includes(returnKey(service))" class="mb-6 w-full">
        <DotDeliveryComponent :service="service" @date-update="(val) => emit('dateUpdate', val)" />
      </div>
      <div v-if="service.type == 'multi_select' && selectedServices.includes(returnKey(service))" class="mb-6 w-full">
        <MultiSelectComponent :service="service" />
      </div>
      <div
        v-if="service.type == 'field_array' && selectedServices.includes(returnKey(service))"
        class="mb-6 flex w-full gap-5"
      >
        <div v-for="field in service.fields" :key="field.key" class="max-w-[100px] text-left">
          <InputText
            class="w-full"
            type="number"
            :name="`services.${returnKey(service)}.${field.key}`"
            :label="field.label"
            suffix="stk."
          />
        </div>
      </div>
      <div v-if="service.type == 'object_array' && selectedServices.includes(returnKey(service))" class="mb-6 w-full">
        <ObjectComponent :service="service" />
      </div>
      <div v-if="service.link">
        <p class="text-body-lg-heavy text-tertiary mb-3 text-left">{{ t("useful_links") }}</p>
        <a :href="JSON.parse(service.link)" target="_blank" rel="noopener noreferrer">
          <Button class="mb-2 flex w-full max-w-max" type="button" :variant="ButtonVariant.PrimaryLink" text-left>
            {{ t("read_more_about", { entity: service.label.toLocaleLowerCase() }) }}
            <Icon class="mt-0.5" src="arrow_up_right" />
          </Button>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant } from "~/types/global";

  import DotDeliveryComponent from "./extraServiceComponents/dotDeliveryComponent.vue";
  import MultiSelectComponent from "./extraServiceComponents/multiSelectComponent.vue";
  import ObjectComponent from "./extraServiceComponents/objectComponent.vue";
  import SelectComponent from "./extraServiceComponents/selectComponent.vue";
  import SideBar from "./extraServiceComponents/sideBar.vue";

  const { t } = useI18n();
  const yup = useYup();

  const emit = defineEmits(["addExtraService", "dateUpdate", "updateSelectValue", "updateValidation"]);

  const selectedService = ref(null);

  const props = defineProps<{
    services: any;
    values: any;
    mode: string;
  }>();

  const ref_services = ref(props.services);

  const { translateFieldsToValidation } = useValidation();

  watch(
    () => props.services,
    (val) => {
      ref_services.value = val;
    }
  );

  const returnKey = (service: object) => {
    return service.carrier_key == "service_codes" ? service.fields.key : service.carrier_key;
  };

  const selectedServices = computed(() => {
    return Object.keys(props.values.services).filter((key) => {
      return props.values.services[key]?.isSet;
    });
  });

  const visibleService = computed(() => {
    return selectedService.value ? selectedService.value : Object.values(ref_services)[3][0];
  });

  const addValidationRules = async (val, service) => {
    if (service.carrier_key == "service_codes") {
      if (val) {
        emit("updateValidation", {
          fields: {
            [service.fields.key]: yup.object({
              isSet: yup.boolean(),
            }),
          },
          step: 3,
        });
      } else {
        emit("updateValidation", {
          fields: {
            [service.fields.key]: yup.object({}),
          },
          step: 3,
        });
      }
    } else {
      const fields = await translateFieldsToValidation(service, val);
      emit("updateValidation", { fields: fields, step: 3 });
    }
  };

  const selectService = (val) => {
    selectedService.value = val;
  };

  onMounted(() => {
    if (selectedServices.value.length > 0) {
      //run addValidationRules for each selected service
      if (props.mode !== "duplicate") {
        return;
      }
      selectedServices.value.forEach(() => {
        addValidationRules(
          true,
          ref_services.value.find((service) => service.carrier_key == returnKey(service))
        );
      });
    }
  });
</script>
