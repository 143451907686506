import { useGlobalSearchStore } from "~/stores/GlobalSearch";
import { SearchResultCategory, SearchResultType } from "~/types/search";
import _ from "lodash";
import type { SearchResultItem } from "@/types/search";

export const highlightSearch = (title: string, search: string): string[] => {
  if (!search) return [title];
  const index = title.toLowerCase().indexOf(search.toLowerCase());
  if (index === -1) {
    return [title];
  }

  //split title into 3 parts: before search, search, after search
  return [title.slice(0, index), title.slice(index, index + search.length), title.slice(index + search.length)];
};

export const globalSearchResultTitleAttribute = (result: SearchResultItem): string => {
  if (!result.highlight) return "";
  const { t } = useNuxtApp().$i18n;
  const highlightKey = Object.keys(result.highlight)?.map((x) => x.replace(".keyword", "").replace("meta.", ""))[0];

  if (highlightKey.split(".").length > 1)
    return t(`global_search.highlights.${highlightKey.replaceAll(".", "_")}`) || "";

  return t(highlightKey.replaceAll(".", "_")) || "";
};

export const globalSearchResultType = (type: SearchResultType): string => {
  const { t } = useNuxtApp().$i18n;
  switch (type) {
    case SearchResultType.shipment:
      return t("global_search.shipment");
    case SearchResultType.ticket:
      return t("global_search.ticket");
    case SearchResultType.transfer:
      return t("global_search.transfer");
    case SearchResultType.document:
      return t("global_search.document");
    case SearchResultType.return:
      return t("global_search.return");
    default: {
      return assertNever(type);
    }
  }
};

export const globalSearchCategoryIcon = (result: SearchResultCategory): string => {
  switch (result) {
    case SearchResultCategory.shipments:
      return "custom/regular/forsendelse";
    case SearchResultCategory.transfers:
      return "regular/ArrowsLeftRight";
    case SearchResultCategory.tickets:
      return "regular/ClipboardText";
    case SearchResultCategory.documents:
      return "regular/File";
    case SearchResultCategory.recents:
      return "regular/ClockCounterClockwise";
    case SearchResultCategory.returns:
      return "custom/regular/returnering";
    default: {
      return assertNever(result);
    }
  }
};

export const globalSearchCategoryTitle = (category: SearchResultCategory): string => {
  const { t } = useNuxtApp().$i18n;
  switch (category) {
    case SearchResultCategory.shipments:
      return t("shipment", 99);
    case SearchResultCategory.tickets:
      return t("ticket", 99);
    case SearchResultCategory.transfers:
      return t("sorting", 99);
    case SearchResultCategory.documents:
      return t("document", 99);
    case SearchResultCategory.recents:
      return t("recent_searches");
    case SearchResultCategory.returns:
      return t("return");
    default: {
      return assertNever(category);
    }
  }
};

export const globalSearchResultOnClick = (
  result: SearchResultItem
):
  | {
      name: string;
      params?: { id: string };
      query?: Record<string, any>;
    }
  | undefined => {
  switch (result.type) {
    case SearchResultType.return:
    case SearchResultType.shipment:
      return {
        name: "shipments-id",
        params: { id: result.id.toString() },
      };
    case SearchResultType.transfer:
      return {
        name: "sortings-id",
        params: { id: result.id.toString() },
      };

    case SearchResultType.ticket:
      return {
        name: "tickets-id",
        params: { id: result.id.toString() },
      };
    case SearchResultType.document:
      console.error("Document not implemented");
      return undefined;
    // return () => downloadDocument({result});
    default: {
      return assertNever(result.type);
    }
  }
};

export const addResultToRecentSearches = (result: SearchResultItem) => {
  const gsStore = useGlobalSearchStore();

  let newRecentSearches = gsStore.recentSearches.filter((search) => !_.isEqual(search, result));
  newRecentSearches.unshift(result);

  if (newRecentSearches.length > 3) {
    newRecentSearches = newRecentSearches.slice(0, 3);
  }

  gsStore.recentSearches = newRecentSearches;
};

export const highlightAttributeStringValue = (result: SearchResultItem): string => {
  if (!result.highlight) return "";
  const keys = Object.keys(result.highlight);
  return result.highlight[keys[0]][0].toString();
};
