export default (el: HTMLElement) => {
  const hasScrollbar = ref(false);

  const checkHasScrollbar = () => {
    const hasVerticalScrollbar = el.scrollHeight > el.clientHeight;
    const hasHorizontalScrollbar = el.scrollWidth > el.clientWidth;

    hasScrollbar.value = hasVerticalScrollbar || hasHorizontalScrollbar;

    if (hasScrollbar.value) {
      el.classList.add("has-scrollbar");
      if (hasVerticalScrollbar) {
        el.classList.add("has-vertical-scrollbar");
      }
      if (hasHorizontalScrollbar) {
        el.classList.add("has-horizontal-scrollbar");
      }
    } else {
      el.classList.remove("has-scrollbar");
    }
  };

  onMounted(() => window.addEventListener("resize", checkHasScrollbar));
  onUnmounted(() => window.removeEventListener("resize", checkHasScrollbar));

  checkHasScrollbar();

  return hasScrollbar;
};
