export const useFormatPrice = (
  price: number | string | null | undefined,
  decimals: boolean | "auto",
  currency: false | string = "DKK"
) => {
  const { $i18n } = useNuxtApp();
  const { hasCapability } = useCapabilities();
  if (!hasCapability("account.prices")) return $i18n.t("no_rights");

  if (price === "" || price === null || price === undefined) return "";

  //if price is string convert to number
  if (typeof price === "string") {
    price = price.trim();
    //split string by space and take first part
    const splits = price.split(" ");

    price = parseFloat(splits[0]);
    if (currency !== false) {
      currency = splits[1] ?? currency;
    }
  }

  if (isNaN(price)) return "";

  const options: Intl.NumberFormatOptions = {
    style: "currency",
    currencyDisplay: "code",
  };

  if (currency === false) {
    options.style = "decimal";
  } else {
    currency = currency.trim().toUpperCase();
    if (!currency) currency = "DKK";
    options.currency = currency;
  }

  if (decimals) {
    if (decimals === "auto") {
      options.maximumFractionDigits = 2;
    } else {
      options.minimumFractionDigits = 2;
      options.maximumFractionDigits = 2;
    }
  } else {
    options.maximumFractionDigits = 0;
  }

  return price.toLocaleString($i18n.locale.value, options);
};

export const useDecidePriceExclOrIncl = (price: {
  price_excl_tax: number | string;
  price_incl_tax: number | string;
}) => {
  if (!price) return "-";

  const { accountIsBusiness } = useWorkspacesStore();

  return accountIsBusiness ? price.price_excl_tax : price.price_incl_tax;
};
