<template>
  <Modal ref="modal" v-model:open="open" class="shadow-lvl-05" max-width="1020px" max-height="756px">
    <div class="flex flex-col">
      <div class="flex items-center justify-between gap-4 pl-4">
        <div class="text-body-default-heavy">
          {{ t("boarding.get_started") }}
        </div>
        <Button :variant="ButtonVariant.Tertiary" type="button" @click="open = false">
          <Icon src="close" />
        </Button>
      </div>

      <div class="relative flex flex-col gap-4 overflow-auto p-4">
        <div class="flex flex-col gap-5">
          <div class="flex flex-col gap-2">
            <div class="text-title-screen">{{ t("boarding.welcome_to_homerunner", { name: userName }) }}</div>
            <div class="text-body-lg text-tertiary">{{ t("boarding.complete_setup_description") }}.</div>
          </div>
        </div>
        <div
          ref="flowContainer"
          class="group border-00 flex gap-10 overflow-auto rounded-xl border p-5 pb-4 [&.has-scrollbar]:shadow-inner"
        >
          <div class="w-full">
            <div
              v-if="!currentFlow"
              ref="flowsGrid"
              class="grid grid-cols-1 gap-3 group-[.has-scrollbar]:pb-4 sm:grid-cols-2 lg:grid-cols-3"
            >
              <SetupGrid
                v-for="flow in flows"
                :key="flow.title"
                :flow="flow"
                @click="() => setCurrentFlowKey(flow.key)"
              />
            </div>
            <div v-else class="flex grid-cols-12 flex-col gap-4 md:grid md:gap-10">
              <SetupSidebar class="col-span-3" :flow="currentFlow" />
              <Formbuilder
                class="col-span-9"
                :validation-schema="currentFlow?.schema"
                :submit-button-text="t('continue')"
                @exit="open = false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import EventBus from "@/plugins/eventbus";
  import useHasScrollbar from "~/composables/useHasScrollbar";
  import { ButtonVariant } from "~/types/global";
  import { onMounted, onUnmounted, ref, watch } from "vue";
  import type { IBoardingFlow } from "../types";

  import useSetupExternalWarehouse from "../flows/useSetupExternalWarehouse";
  import useSetupInvoicingResponsible from "../flows/useSetupInvoicingResponsible";
  import useSetupShop from "../flows/useSetupShop";
  import { currentFlowKey, flows, open } from "../service";
  import Formbuilder from "./Formbuilder.vue";
  import SetupGrid from "./SetupGrid.vue";
  import SetupSidebar from "./SetupSidebar.vue";

  const { t } = useI18n();
  const { userName } = storeToRefs(useWorkspacesStore());
  const modal = ref<HTMLDivElement | null | undefined>();

  const flowContainer = ref<HTMLElement | null | undefined>();
  const flowsGrid = ref<HTMLElement | null | undefined>();

  onMounted(() => {
    flows.value = [useSetupShop(), useSetupExternalWarehouse(), useSetupInvoicingResponsible()];
  });

  watch(
    () => flowContainer.value,
    () => {
      if (flowContainer.value) {
        useHasScrollbar(flowContainer.value);
      }
    }
  );

  const addFlow = (boarding: IBoardingFlow) => {
    flows.value.push(boarding);
  };

  const currentFlow = computed(() => {
    if (!currentFlowKey.value) return null;
    return flows.value.find((b) => b.key === currentFlowKey.value);
  });

  const startBoarding = (key: string) => {
    const flow = flows.value.find((flow) => flow.key === key);
    if (flow) {
      setCurrentFlowKey(flow.key);
      open.value = true;
    } else {
      if (window.$toast)
        window.$toast.add({
          title: t("boarding.not_found"),
          group: "boarding.not_found",
        });
    }
  };

  const removeFlow = (key: string) => {
    const index = flows.value.findIndex((setup) => setup.key === key);
    if (index !== -1) {
      flows.value.splice(index, 1);
    }
  };

  const setCurrentFlowKey = (key: string) => {
    currentFlowKey.value = key;
  };

  onMounted(() => {
    EventBus.$on("BOARDING_TOGGLE", () => {
      open.value = !open.value;
    });
    EventBus.$on("BOARDING_OPEN", () => {
      open.value = true;
    });
    EventBus.$on("BOARDING_CLOSE", () => {
      open.value = false;
    });
    EventBus.$on("BOARDING_ADD", addFlow);
    EventBus.$on("BOARDING_REMOVE", removeFlow);
    EventBus.$on("BOARDING_START", startBoarding);
  });

  onUnmounted(() => {
    EventBus.$off("BOARDING_TOGGLE");
    EventBus.$off("BOARDING_OPEN");
    EventBus.$off("BOARDING_CLOSE");
    EventBus.$off("BOARDING_ADD");
    EventBus.$off("BOARDING_REMOVE");
    EventBus.$off("BOARDING_START");
  });
</script>
