<template>
  <div ref="wrapper" class="relative">
    <Input
      ref="input"
      :label="label || t('search_by_address')"
      :size="size"
      :model-value="inputValue"
      :error="error"
      :name="name"
      :message="message"
      :placeholder="placeholder"
      @update:model-value="(val) => setValue(val, false)"
      @focus="openSuggestions"
      @blur="handleBlur"
    />

    <div
      v-if="open"
      class="border-00 bg-surface-lvl-00 absolute top-16 left-0 z-50 max-h-[323px] w-full overflow-auto rounded-xl border-2 p-1.5"
    >
      <button
        v-for="option in predictions"
        :key="option.placePrediction.placeId"
        class="group hover:bg-01 flex w-full items-center gap-1 rounded-lg px-3 py-1.5 text-left transition-all duration-300"
        type="button"
        @click="selectPrediction(option)"
      >
        <div class="flex items-center gap-1">
          <p class="text-body-default text-primary">{{ option.placePrediction.text.text }}</p>
        </div>
      </button>
      <Overlay v-if="loading" class="h-16 min-h-16" />
      <div
        v-if="predictions.length === 0 && !loading"
        class="text-body-default text-secondary flex rounded-lg px-3 py-1.5"
      >
        {{ emptyText || t("no_results_found") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";
  import _ from "lodash";

  export type GoogleAddressData = {
    geoLocation: Partial<GeoLocation>;
    businessStatus: string;
    displayName: { text: string };
  };

  const props = withDefaults(
    defineProps<{
      size?: InputSize;
      name?: string;
      label?: string;
      placeholder?: string;
      error?: string;
      country?: string;
      emptyText?: string;
      highlightHouseNumber?: boolean;
    }>(),
    {
      size: ComponentSize.default,
      name: "",
      country: "dk",
      label: undefined,
      placeholder: undefined,
      error: undefined,
      emptyText: "",
      highlightHouseNumber: true,
    }
  );

  const _modelValue = defineModel<string>();
  const predictions = ref<Record<string, any>[]>([]);
  const open = ref<boolean>(false);
  const wrapper = useTemplateRef("wrapper");

  const {
    value: inputValue,
    handleBlur,
    setValue,
  } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    validateOnMount: false,
    controlled: !!props.name,
  });

  const { t } = useI18n();
  const country = computed(() => props.country);
  const input = useTemplateRef("input");
  const initialInputValue = ref("");
  const loading = ref(false);

  const message = computed(() => {
    if (!props.highlightHouseNumber) return "";
    //if inputvalue is not empty and does not contain numbers, return error message
    if (inputValue.value && !/\d+$/.test(inputValue.value as string)) {
      return t("remember_to_add_house_number");
    }

    return "";
  });

  onBeforeMount(() => {
    if (inputValue.value) {
      initialInputValue.value = (inputValue.value as string) || "";
    }
  });

  watchEffect(() => {
    if (!wrapper.value) {
      return;
    }

    wrapper.value.addEventListener("focusout", handleFocusOutside);
  });

  watchEffect(() => {
    if (input.value?.input) input.value?.input.addEventListener("input", handleInput);
  });

  const handleInput = (event: Event) => {
    const value = (event.target as HTMLInputElement).value;

    setValue(value, false);
    if (value.length < 3) {
      predictions.value = [];
      return;
    }

    debouncedGetPredictions(value);
  };

  const handleFocusOutside = (event: FocusEvent) => {
    if (!wrapper.value) return;
    // If focus is still in the form, do nothing
    if (wrapper.value.contains(event.relatedTarget as Node)) return;

    closeSuggestions();
  };

  const getPredictions = (value: string) => {
    loading.value = true;

    homeFetch("places/autocomplete", {
      method: "POST",
      body: {
        input: value,
        includedRegionCodes: [country.value],
        includedPrimaryTypes: [],
      },
    }).then((response) => {
      const { suggestions } = response as unknown as { suggestions: Record<string, any>[] };

      loading.value = false;
      predictions.value = suggestions || [];
    });
  };

  const debouncedGetPredictions = _.debounce(getPredictions, 350);

  const selectPrediction = (prediction: Record<string, any>) => {
    homeFetch(`places/autocomplete/${prediction.placePrediction.placeId}`, {
      query: {
        fields: "adrFormatAddress,businessStatus,displayName",
      },
    }).then((response) => {
      const { adrFormatAddress, businessStatus, displayName } = response as unknown as {
        adrFormatAddress: string;
        businessStatus: string;
        displayName: { text: string };
      };

      /**
       * Regex pattern to match address components within span elements.
       * The pattern matches elements with class names "street-address", "postal-code", "locality", or "country-name".
       */
      const regex = /<span class="(street-address|postal-code|locality|country-name)">([^<]*)<\/span>/g;

      /**
       * Finds all matches of the regex pattern in the adrFormatAddress string.
       * Each match contains the class name and the corresponding value.
       */
      const matches = adrFormatAddress.matchAll(regex);

      /**
       * Initializes a Partial<GeoLocation> object to store the parsed address components.
       * The object contains properties for address line 1, city, postcode, and country.
       */
      const geoLocation: Partial<GeoLocation> = {
        address_line1: "",
        city: "",
        postcode: "",
        country: country.value,
      };

      /**
       * Iterates over each match found by the regex pattern.
       * Depending on the class name, assigns the corresponding value to the appropriate property in the geoLocation object.
       */
      for (const match of matches) {
        const [_, className, value] = match;
        switch (className) {
          case "street-address":
            geoLocation.address_line1 = value;
            break;
          case "postal-code":
            geoLocation.postcode = value;
            break;
          case "locality":
            geoLocation.city = value;
            break;
        }
      }

      const data = {
        geoLocation,
        businessStatus,
        displayName,
      };

      emits("select", data);

      closeSuggestions();
    });
  };

  useOutsideClick(wrapper, () => {
    closeSuggestions();
  });

  const openSuggestions = () => {
    open.value = true;
    const search = inputValue.value as string;
    if (search && search.length >= 3 && predictions.value.length === 0) {
      getPredictions(search);
    }
  };

  const closeSuggestions = () => {
    wrapper.value?.removeEventListener("focusout", handleFocusOutside);

    open.value = false;
  };

  onUnmounted(() => {
    wrapper.value?.removeEventListener("focusout", handleFocusOutside);
    input.value?.input?.removeEventListener("input", handleInput);
  });

  const emits = defineEmits(["select", "suggestions", "click"]);
</script>
