import { useNewTicket } from "~/modules/createticket/runtime/service";
import type { ITableRowAction } from "~/components/Table/types";
import type { INewTicketService, ITicketTypesResponse } from "~/modules/createticket/runtime/types";

export default () => {
  const { t } = useI18n();

  const createNewTicket = (package_number: string) => {
    $newticket.open();

    homeFetch(`tickets/types/${package_number}`).then((r) => {
      if (!r.data) return;

      $newticket.add(r.data as ITicketTypesResponse, package_number);
    });
  };

  const { open: openNewTicket } = useNewTicket() as INewTicketService;

  const goToTicket = (ticketId: number) => {
    const link = useRouter().resolve({ name: "tickets-id", params: { id: ticketId } })?.href;
    useRouter().push(link);
  };

  const submitTicket = (ticket: object) => {
    return homeFetch(`tickets`, {
      method: "POST",
      body: ticket,
    }).then((r) => {
      const ticketId = r.data.data.id;
      $toast.add({
        title: t("ticket_created_successfully"),
        actions: [
          {
            text: t("go_to_entity", { entity: t("ticket", 1).toLowerCase() }),
            handler: () => goToTicket(ticketId),
          },
        ],
      });

      return { ticketId };
    });
  };

  const { focusEntity, unfocusEntity } = useFocus("tickets");

  const useActions = (): (ITableRowAction | null)[] => {
    const { hasCapability } = useCapabilities();

    return [
      {
        key: "focus",
        label: t("focus_entity"),
        handler: (item: object) => focusEntity(item),
        hidden: (item: object) => item.redacted || item.is_focused || !hasCapability("service:ticket.action.focus"),
      },
      {
        key: "unfocus",
        label: t("unfocus_entity"),
        handler: (item: object) => unfocusEntity(item),
        hidden: (item: object) => item.redacted || !item.is_focused || !hasCapability("service:ticket.action.focus"),
      },
    ];
  };

  const getBadgeType = (status: string) => {
    switch (status) {
      case "solved":
        return "subtle";
      case "pending":
        return "tangerine";
      case "rejected":
        return "negative";
      case "claimed":
        return "positive";
      case "created":
        return "brand";
      case "ready_for_investigation":
        return "tangerine";
      case "awaiting_customer":
        return "warning";
      default:
        return "default";
    }
  };

  return {
    createNewTicket,
    submitTicket,
    useActions,
    getBadgeType,
    openNewTicket,
  };
};
