<template>
  <div class="flex w-full flex-col gap-3 p-3">
    <template v-for="(item, index) in items" :key="'item_' + index">
      <ToggleListItem
        :item="item"
        :model-value="isItemSelected(item)"
        :expand-on-toggle="expandOnToggle"
        @update:model-value="(val) => toggleItem(val, item, index)"
      >
        <slot v-bind="{ item, index }" name="item" />
      </ToggleListItem>

      <Divider v-if="index < items.length - 1" :spacing="false" />
    </template>
  </div>
</template>

<script setup lang="ts">
  import type { IToggleListItemEntry } from "./partials/ToggleListItem.vue";

  import ToggleListItem from "./partials/ToggleListItem.vue";

  defineOptions({
    name: "AccordionList",
  });

  withDefaults(
    defineProps<{
      items: IToggleListItemEntry[];
      expandOnToggle?: boolean;
    }>(),
    {
      items: () => [],
      expandOnToggle: false,
    }
  );

  const selected = defineModel<string[] | number[]>();

  const emit = defineEmits(["update:model-value"]);

  const isItemSelected = (item: IToggleListItemEntry) => {
    if (!selected.value) {
      return item.active || item.selected;
    }
    return selected.value.includes(item.value);
  };

  const toggleItem = (value: boolean, item: IToggleListItemEntry, idx: number) => {
    const s = selected.value ? [...selected.value] : [];
    const index = s.indexOf(item.value);
    if (index === -1) {
      s.push(item.value);
    } else {
      s.splice(index, 1);
    }
    emit("update:model-value", s, item, idx, value);
  };
</script>
