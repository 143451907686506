<template>
  <div class="flex flex-col">
    <div class="p-1">
      <div
        class="group hover:border-image hover:bg-00 flex cursor-pointer items-center gap-3 rounded-xl p-3"
        @click="emit('toggle')"
      >
        <Button :variant="ButtonVariant.Tertiary" type="button" :active="active" icon-only :size="ComponentSize.sm">
          <Icon
            class="transform transition-all duration-300"
            src="bold/CaretDown"
            :class="{
              '-rotate-90': !active,
            }"
          />
        </Button>
        <Avatar
          :entity="{
            image: getCarrierLogoUrl(shippingMethod.carrier),
          }"
        />
        <div class="group-hover:text-primary flex flex-grow flex-col justify-center self-stretch">
          <div class="text-body-lg-heavy">
            {{ name }}
          </div>
          <div class="text-body-default text-quarterary">
            {{ description }}
          </div>
        </div>
        <div class="flex shrink-0 items-center gap-2">
          <template v-if="active && rulesHaveChanges">
            <Button :variant="ButtonVariant.Destructive" type="button" @click.stop.prevent="undo">
              {{ t("undo_changes") }}
            </Button>
          </template>
          <div class="text-body-default text-tertiary">
            {{ t("n_rules", { rules: shippingMethod.rules.length }, shippingMethod.rules.length) }}
          </div>
        </div>
      </div>
    </div>
    <template v-if="active">
      <Divider :spacing="false" />
      <div class="flex gap-3 px-5 py-2.5">
        <InputText v-model="name" class="flex-grow" :label="t('name')" />
        <InputText v-model="description" class="flex-grow" :label="t('description')" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import _ from "lodash";

  const props = withDefaults(
    defineProps<{
      active: boolean;
      shippingMethod: ICheckoutShippingMethod;
    }>(),
    {
      shippingMethod: () => {},
    }
  );

  const name = defineModel<string>("name");
  const description = defineModel<string>("description");

  const originalName = ref<string>("");
  const originalDescription = ref<string>("");
  const orginalRules = ref<IRule[]>([]);

  onMounted(() => {
    originalName.value = name.value || "";
    originalDescription.value = description.value || "";
    orginalRules.value = props.shippingMethod.rules;
  });

  const rules = computed(() => {
    return props.shippingMethod.rules;
  });

  const rulesHaveChanges = computed(() => {
    return (
      !_.isEqual(rules.value, orginalRules.value) ||
      name.value !== originalName.value ||
      description.value !== originalDescription.value
    );
  });

  const undo = () => {
    name.value = originalName.value;
    description.value = originalDescription.value;

    emit("undo");
  };

  const { t } = useI18n();
  const emit = defineEmits(["toggle", "undo", "save", "update:name", "update:description"]);
</script>
