import _ from "lodash";
import type { ViewDescription } from "./useDescribe";
import type { ITableHeader, ITableHeaderAdditional } from "~/components/Table/types";

const defaultDescription: ViewDescription = {
  rowOptions: [],
  filterOperators: {},
  filterKeys: [],
  sortColumns: [],
  viewColumns: [],
};

const getState = (stateKey: string) =>
  useState<{
    filters: Record<string, any>;
    search: string;
    page: number | string;
    isCursor: boolean;
    description: ViewDescription;
    viewId?: string;
    sort?: string[];
    query?: Record<string, any>;
    modalSettings: {
      toggle: null | HTMLElement;
      show: boolean;
    };
    columns?: AvailableColumn[];
    addonForHeaders?: Partial<ITableHeaderAdditional[]>;
    additionalHeaders?: ITableHeader[];
    views?: View[];
    additionalViews?: View[];
  }>(stateKey, () => ({
    filters: {},
    search: "",
    page: "1",
    isCursor: false,
    description: defaultDescription,
    viewId: "all",
    sort: ["id:desc"],
    query: {},
    modalSettings: {
      toggle: null,
      show: false,
    },
    columns: [],
    addonForHeaders: [],
    additionalHeaders: [],
    views: [],
    additionalViews: [],
  }));

const keysToUpdateQuery = ["filters", "search", "page", "viewId", "sort", "query"];

export default (view?: string, initializer = false, updateQueryOnUpdate = true) => {
  const { getQueryParam, setQueryParam, setQuery, getQueryFromRoute } = useQuery();

  const stateKey = view ? `${view}` : "default";
  const addonForHeaders = ref<Partial<ITableHeaderAdditional[]>>([]);
  const additionalHeaders = ref<ITableHeader[]>([]);

  const setAdditionalHeaders = (headers: ITableHeader[]) => {
    additionalHeaders.value = headers;
  };

  const setAddonForHeaders = (headers: Partial<ITableHeaderAdditional[]>) => {
    addonForHeaders.value = headers;
  };

  const state = getState(stateKey);
  const createComputedState = <T>(key: keyof typeof state.value, defaultValue: T) => {
    const setState = (newValue: T, updateQuery = updateQueryOnUpdate) => {
      state.value[key] = newValue;

      if (updateQueryOnUpdate && keysToUpdateQuery.includes(key) && updateQuery) {
        if (key === "page" && state.value.isCursor) {
          setQueryParam(newValue, "cursor");
          return;
        } else if (key === "query") {
          newValue = newValue || {};
          //get all keys from current url query and remove all keys that are not in the new query or in the keysToUpdateQuery array
          Object.keys(getQueryFromRoute()).forEach((k) => {
            if (!Object.keys(newValue as Record<string, any>).includes(k) && !keysToUpdateQuery.includes(k)) {
              //setQueryParam(null, k);
            }
          });

          Object.entries(newValue as Record<string, any>).forEach(([key, value]) => {
            setQueryParam(value, key);
          });

          return;
        } else if (key == "viewId" && newValue == "all") {
          setQueryParam(null, key);
          return;
        } else if (["filters", "sort", "limit", "search"].includes(key)) {
          setPage("1", false);
        }

        setQueryParam(newValue, key);
      }
    };

    const computedState = computed({
      get: () => state.value[key] as T,
      set: setState,
    });

    const resetState = (updateQuery = true) => {
      setState(defaultValue, updateQuery);
    };

    return { computedState, setState, resetState };
  };

  const {
    computedState: filters,
    setState: setFilters,
    resetState: resetFilters,
  } = createComputedState<Record<string, any>>("filters", {});
  const { computedState: search, setState: setSearch, resetState: resetSearch } = createComputedState("search", "");
  const {
    computedState: isCursor,
    setState: setIsCursor,
    resetState: resetIsCursor,
  } = createComputedState("isCursor", false);
  const { computedState: page, setState: setPage } = createComputedState("page", "1");
  const {
    computedState: description,
    setState: setDescription,
    resetState: resetDescription,
  } = createComputedState("description", defaultDescription);

  const defaultColumns = computed(() => {
    return description.value.viewColumns.map(
      ({ key, visible, label }: ITableHeader): AvailableColumn => ({
        key,
        visible: visible ?? true,
        label: label || key,
      })
    );
  });
  const {
    computedState: columns,
    setState: setColumnsInState,
    resetState: resetColumns,
  } = createComputedState("columns", defaultColumns.value);
  const { computedState: viewId, setState: setViewId, resetState: resetViewId } = createComputedState("viewId", "all");
  const { computedState: sort, setState: setSort, resetState: resetSort } = createComputedState("sort", ["id:desc"]);
  const { computedState: query, setState: setQueryInState, resetState: resetQuery } = createComputedState("query", {});
  const { computedState: modalSettings, setState: setModalSettings } = createComputedState("modalSettings", {
    toggle: null,
    show: false,
  });
  const { computedState: views, setState: setViews } = createComputedState("views", []);
  const { computedState: additionalViews, setState: setAdditionalViews } = createComputedState("additionalViews", []);

  const filterModalToggle = computed({
    get: () => modalSettings.value.toggle,
    set: (toggle: null | HTMLElement) => {
      setModalSettings({ ...modalSettings.value, toggle });
    },
  });

  const showFilterModal = computed({
    get: () => modalSettings.value.show,
    set: (show: boolean) => {
      setModalSettings({ ...modalSettings.value, show });
    },
  });

  const updateQuery = () => {
    setQuery(objectToSetQuery(), false);
  };

  const resetPage = (shouldUpdateQuery = true) => {
    if (isCursor.value) {
      setPage("", shouldUpdateQuery);
    } else {
      setPage("1", shouldUpdateQuery);
    }
  };

  const filtersWithoutEmpty = computed(() => {
    if (!filters?.value) return {};
    //filter out entries where [key].$or[*][operator] is empty
    return Object.entries(filters?.value).reduce(
      (acc, [key, value]) => sanitiseFiltersForApi(acc, [key, value], description.value.filterKeys),
      {}
    );
  });

  const hasFilters = computed(() => Object.keys(filters.value).length > 0);
  const hasSearch = computed(() => search.value.length > 0);
  const hasFiltersOrSearch = computed(() => hasFilters.value || hasSearch.value);

  const removeFilterForKey = (key: string) => {
    const newFilters = { ...filters.value };
    delete newFilters[key];
    setFilters(newFilters);
  };

  const getFilterForKey = (key: string) => {
    return filters.value[key];
  };

  const setFilterForKey = (key: string, operator: string, value: any) => {
    if (!key) return;
    const newFilters = { ...filters.value };

    newFilters[key] = {
      $or: [
        {
          [operator]: value,
        },
      ],
    };

    setFilters(newFilters);
  };

  onUnmounted(() => {
    if (!initializer) return;
    resetAll(false);
  });

  const resetAll = (shoulUpdateQuery = true) => {
    resetFilters(false);
    resetSearch(false);
    resetPage(false);
    resetIsCursor(false);
    resetViewId(false);
    resetSort(false);
    resetQuery(false);

    //we dont update headers and description because they are not part of the query
    if (shoulUpdateQuery) updateQuery();
  };

  const setInitialValues = () => {
    const initialFilters = (getQueryParam("filters") as Record<string, any>) || {};
    const initialSearch = (getQueryParam("search") as string) || "";
    const initialIsCursor = getQueryParam("cursor") ? true : false;
    const initialViewId = (getQueryParam("viewId") as string | null) || "all";
    const initialSort = (getQueryParam("sort") as string[] | null) || ["id:desc"];
    let initialQuery = {};

    //get all keys from current url query and remove all keys that are in keysToUpdateQuery array
    Object.keys(getQueryFromRoute()).forEach((k) => {
      if (!keysToUpdateQuery.includes(k)) {
        initialQuery = {
          ...initialQuery,
          [k]: getQueryFromRoute()[k],
        };
      }
    });

    const initialPage =
      (getQueryParam("cursor") as string | null) || (getQueryParam("page")?.toString() as string | null) || "1";

    setFilters(initialFilters, false);
    setSearch(initialSearch, false);
    setIsCursor(initialIsCursor, false);
    setViewId(initialViewId, false);
    setSort(initialSort, false);

    setPage(initialPage, false);
    setQueryInState(initialQuery, false);
  };

  const objectToSetQuery = () => {
    return {
      filters: filtersWithoutEmpty.value,
      search: search.value,
      page: page.value,
      viewId: viewId.value == "all" ? null : viewId.value,
      sort: sort.value,
      ...(query.value || {}),
    };
  };

  const setColumns = (columns: AvailableColumn[]) => {
    if (!columns || !columns.length) columns = defaultColumns.value;
    let columnsToSet = _.cloneDeep(columns);

    //assure that all columns have a label else fetch the label from the view columns and set it
    columnsToSet = columnsToSet.map((column) => {
      if (column.label) return column;

      const viewColumn = description.value.viewColumns.find((vc) => vc.key === column.key);
      return { ...column, label: viewColumn?.label || column.key };
    });

    setColumnsInState(columnsToSet, false);
  };

  const headers = computed(() => {
    let h: ITableHeader[] = [];

    if (columns.value && columns.value.length) {
      h = description.value.viewColumns.map((column: ITableHeader) => {
        const vc = columns.value.find((vc) => vc.key === column.key);
        return {
          ...column,
          visible: vc?.visible,
        };
      });
    } else h = description.value.viewColumns;

    const vcKeys = columns.value.map((vc) => vc.key);
    //order headers by order in columns
    h = h.sort((a, b) => {
      return vcKeys.indexOf(a.key) - vcKeys.indexOf(b.key);
    });

    //foreach header in additional headers, check if it exists in the view columns and merge them together to one header
    addonForHeaders.value.forEach((header) => {
      h = h.map((h) => {
        if (!header) return h;
        if (h.key === header.key) {
          return { ...h, ...header };
        }
        return h;
      });
    });

    h = [...h, ...additionalHeaders.value];

    return h;
  });

  const hasHeadersBesidesActions = computed(() => {
    return headers.value.filter((h) => h.key !== "actions").length > 0;
  });

  const filterOperators = computed(() => {
    return description.value.filterOperators;
  });

  const filtersCount = computed(() => {
    //return the total count of filter entries
    return Object.keys(filters.value).reduce((acc, key) => {
      const filter = filters.value[key];
      if (!filter.$or) return acc + 1;
      return acc + filter.$or.length;
    }, 0);
  });

  onMounted(() => {
    if (!initializer) return;
    setInitialValues();
  });

  return {
    state,
    filters,
    filtersWithoutEmpty,
    setFilters,
    resetFilters,
    search,
    setSearch,
    resetSearch,
    hasFilters,
    hasSearch,
    hasFiltersOrSearch,
    setIsCursor,
    isCursor,
    resetIsCursor,
    setPage,
    page,
    resetPage,
    headers,
    defaultColumns,
    columns,
    setColumns,
    resetColumns,
    description,
    setDescription,
    resetDescription,
    hasHeadersBesidesActions,
    setFilterForKey,
    removeFilterForKey,
    getFilterForKey,
    updateQuery,
    viewId,
    setViewId,
    resetViewId,
    sort,
    setSort,
    resetSort,
    query,
    setQuery: setQueryInState,
    resetQuery,
    resetAll,
    filterModalToggle,
    showFilterModal,
    setModalSettings,
    filterOperators,
    setAddonForHeaders,
    setAdditionalHeaders,
    views,
    setViews,
    additionalViews,
    setAdditionalViews,
    filtersCount,
  };
};
