import { defu } from "defu";
import _ from "lodash";

export const homeFetchRaw = (url: string, options: Record<string, any> = {}) => {
  return homeFetch(url, options, true);
};

export const homeFetch = async (url: string, options: Record<string, any> = {}, raw?: boolean) => {
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;

  const defaultOptions = {
    timeout: 60000,
  };
  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaultOptions);

  const method = raw ? $fetch.raw : $fetch;
  return await method(getStringifiedUrl(url), params).catch((error: IServiceError) => {
    const silent = params.silent || false;

    if (error.statusCode == 401 && error.data.url != "/api/auth/logout" && error.data.url != "/api/auth/login") {
      navigateTo({ name: "auth-logout" });
      if ($toast && !silent)
        $toast.add({
          group: "session_expired",
          title: t("session_expired"),
          text: t("session_expired_login_again"),
        });
      else console.error(t("session_expired"));
    } else if ($toast && !silent) {
      handleErrors(error);
    } else console.error(error);

    throw error;
  });
};

const getStringifiedUrl = (url: string | (() => string)) => {
  let stringifiedUrl = typeof url === "function" ? url() : url;

  //if url is not prepended with /api/ then prepend it and not if it is a full url
  if (stringifiedUrl.indexOf("http") !== 0) stringifiedUrl = "/api/" + stringifiedUrl;
  return stringifiedUrl;
};

const handleErrors = (error: IServiceError) => {
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;
  const httpCode = error.data?.statusCode;
  const data = error.data?.data?.data;
  const message = error.data?.data?.message || t("error_occurred_contact_support");
  const session = error.data?.data?.session;
  const toastActions = [
    {
      text: t("copy_for_support"),
      handler: () => {
        copyToClipboard(
          JSON.stringify({
            url: error.data.url,
            message,
            data,
            session,
          })
        );
      },
    },
  ];

  if (httpCode === 422) {
    const validationErrors = (data as IValidationError)?.errors;
    //if validationErrors is not an object, show the error message
    if (!_.isObject(validationErrors)) {
      $toast.add({
        type: "error",
        group: error.data?.url + "_validation",
        title: message,
        text: t("error_occurred_contact_support"),
        duration: 10000,
        actions: toastActions,
      });
      return;
    } else
      Object.entries(validationErrors).forEach(([veKey, ve]) => {
        $toast.add({
          type: "error",
          group: error.data?.url + veKey,
          title: message || t("error"),
          text: ve[0],
          duration: 10000,
          actions: toastActions,
        });
      });
  } else
    $toast.add({
      type: "error",
      group: error.data?.url + "_validation",
      title: message,
      text: _.isObject(data) ? JSON.stringify(data) : data || t("error_occurred_contact_support"),
      duration: 10000,
      actions: toastActions,
    });
};
