import type {
  CarrierOption,
  CarrierOptionField,
  IShipment,
  Receiver,
  Servicepoint,
  ShipmentService,
} from "~/types/shipment";

export const servicePointAddressString = (servicePoint: Servicepoint | null) => {
  if (!servicePoint) return "";

  const {
    name: servicePointName,
    street: servicePointStreet,
    zip_code: servicePointZip,
    city: servicePointCity,
  } = servicePoint;
  return `${servicePointName} - ${servicePointStreet}, ${servicePointZip} ${servicePointCity}`;
};

export const receiverAddressString = (receiver: Receiver | null) => {
  if (!receiver) return "";

  const { street1, zip_code, city } = receiver;
  return `${street1}, ${zip_code} ${city}`;
};

export const isDroppoint = (cps: string) => {
  return cps.includes("droppoint");
};

export const getCarrierLogoUrl = (carrier: string): string => {
  if (!carrier) return "";
  return `/carrier/icon/${carrier}`;
};

export const buildCps = (shipment: IShipment) => {
  const { carrier_product, carrier, carrier_service } = shipment;
  return `${carrier}_${carrier_product}_${carrier_service}`;
};

export const isReturn = (shipment: IShipment) => {
  return shipment.carrier_product_service?.includes("return");
};

export const hasTickets = (shipment: IShipment) => {
  return shipment.has_ticket || shipment.tickets?.length > 0 || shipment.ticket_ids?.length > 0;
};

export const getFirstTicketId = (shipment: IShipment) => {
  if (shipment.tickets?.length > 0) return shipment.tickets[0].id;
  if (shipment.ticket_ids?.length > 0) return shipment.ticket_ids[0];

  return null;
};

export const isShipmentRedacted = (shipment: IShipment) => {
  return (
    shipment.receiver?.name === "REDACTED" &&
    shipment.receiver?.street1 === "REDACTED" &&
    shipment.receiver?.email === "REDACTED"
  );
};

export const getUsedCarrierOptions = (shipment: IShipment): CarrierOption[] => {
  if (!shipment) return [];
  if (!shipment.services) return [];
  if (!shipment.possible_services) return [];

  const carrierOptions: CarrierOption[] = [];
  //return the shipment.carrier_options that are used by checking shipment.services
  shipment.services.forEach((service) => {
    //check if multiple carrier services has same carrier_key
    const options =
      shipment.possible_services?.filter((option) => {
        return option.carrier_key === service.service_code;
      }) || [];

    if (options.length === 0) {
      return null;
    }

    if (options.length > 1 && Array.isArray(service.value)) {
      service.value.forEach((value) => {
        const foundOption = options.find((option) => option.service_code === value);
        if (foundOption) {
          carrierOptions.push(foundOption);
        }
      });
      return;
    }

    carrierOptions.push(options[0]);
  });

  carrierOptions.filter((option) => option !== null && option !== undefined);

  return carrierOptions;
};

export const transformExtraServicesForPresentation = (
  carrierServices?: CarrierOption[] | null | undefined,
  shipmentServices?: Record<string, ShipmentService> | null | undefined,
  checkIfSet = true
) => {
  if (!carrierServices) return []; // Return empty array if no carrier services
  if (!shipmentServices) return []; // Return empty array if no shipment services
  const services: { title?: string; values?: unknown[]; price?: string }[] = [];

  const serviceArray = Object.entries(shipmentServices); // Convert shipment services to array of entries

  serviceArray.forEach(([serviceKey, serviceValue]) => {
    let service;

    if (serviceKey === "service_codes") {
      // Handle special case for service codes
      service = carrierServices.filter((service) => {
        return service.carrier_key === "service_codes";
      });
    } else
      service = carrierServices.find((service) => {
        // Find the matching carrier service
        return (
          service.carrier_key === serviceKey ||
          (Array.isArray(service.fields)
            ? service.fields.some((field) => field.key === serviceKey)
            : service.fields.key === serviceKey)
        );
      });

    if (!service) return; // Skip if no matching service found

    const isSet = checkIfSet ? shipmentServices[serviceKey].isSet : true; // Determine if the service is set
    let values: any[] = [];
    if (isSet) {
      if (serviceKey === "service_codes") {
        // Handle special case for service codes
        values = serviceValue.forEach((value) => {
          const foundService = carrierServices.find((service) => service.service_code === value);

          services.push({
            title: foundService?.label,
          });
        });
      } else {
        if (Array.isArray(service.fields)) {
          values = [...service.fields]; // Copy service fields
        }

        if (service.type == "field_array") {
          // Handle field array type
          values.forEach((value) => {
            value.value = (shipmentServices[serviceKey as keyof typeof shipmentServices] as any)[value.key];
          });

          values = values.filter((value) => value.value); // Filter out empty values
        } else if (service.type == "select") {
          // Handle select type
          const newValues: {
            label: string | undefined;
            value: string | undefined;
          }[] = [];
          values.forEach((value) => {
            if (value.options) {
              newValues.push({
                label: value.options.find((option) => option.key == shipmentServices[serviceKey][value.key])?.label,
                value: "",
              });
            } else {
              newValues.push({
                label: value.label,
                value: getServiceValueForPresentation(value, shipmentServices[serviceKey], serviceKey),
              });
            }
          });
          values = newValues;
        } else if (service.type == "multi_select") {
          // Handle multi-select type
          const newValues = [];

          const selected = Array.isArray(shipmentServices[serviceKey])
            ? shipmentServices[serviceKey]
            : shipmentServices[serviceKey].array;

          selected?.forEach((value) => {
            newValues.push({ label: value.toUpperCase(), value: "" });
          });
          values = newValues;
        } else if (service.type == "dot_delivery") {
          // Handle dot delivery type
          const formValue = shipmentServices[serviceKey];

          const fields = service.fields as CarrierOptionField[];
          if (formValue.type == "DOT1") {
            values = [{ label: fields[0].label, value: "DOT1" }];
          } else {
            values = [
              { label: fields[0].label, value: formValue.type },
              {
                label: fields[1].label,
                value: formatDate(formValue.interval_start, "HH:mm"),
              },
            ];
          }
        }

        services.push({
          title: service.label,
          values: values,
          price: service.price,
        }); // Add service to the list
      }
    }
  });
  return services; // Return the transformed services
};

const getServiceValueForPresentation = (service, shipmentService, serviceKey) => {
  const value = shipmentService[service.key];
  const suffix = service.suffix ? ` ${service.suffix}` : "";
  const prefix = service.prefix ? `${service.prefix} ` : "";

  if (serviceKey === "insurance" && !isNaN(value)) {
    return useFormatPrice(value, true, suffix);
  }

  return `${prefix}${value}${suffix}`;
};

export const transformExtraServicesForSelection = (shipmentServices?: ShipmentService[] | null | undefined) => {
  const services = {};
  shipmentServices?.forEach((service) => {
    if (service.service_code === "service_codes") {
      service.value.forEach((value) => {
        services[value] = { isSet: true };
      });
    } else if (Array.isArray(service.value)) {
      services[service.service_code] = { isSet: true, array: service.value };
    } else {
      services[service.service_code] = {
        ...service.value,
      };
    }
  });

  return services;
};

export const getCurrencyFromShipment = (shipment: IShipment) => {
  if (!shipment) return "";

  let currency = shipment?.price?.currency || "";
  if (!currency) {
    //extract currency from readable_price
    const matches = shipment.readable_price?.match(/([A-Z]{3})/);
    currency = matches ? matches[0] : "";
  }
  return currency;
};
