<template>
  <div
    class="calendar-cell relative flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl text-sm font-medium transition-colors duration-200"
    :class="{
      'bg-00 text-secondary hover:bg-01 hover:text-primary': !active && today,
      '!hover:bg-brand-dark !bg-brand !text-white': active && position !== 'middle',
      'bg-surface-lvl-01 text-secondary hover:bg-01': active && position === 'middle',
      'text-secondary hover:bg-01 hover:text-primary': !active && !today,
      'bg-disabled': disabled && (active || today),
      '!text-disabled': disabled,
      'before:content[\'\'] before:absolute before:z-[-1] before:h-full': connector != 'none',
      'before:bg-calendar-cell-connector-left before:right-8 before:w-8': connector == 'left',
      'before:bg-calendar-cell-connector-right before:left-full before:w-8': connector == 'right',
      'before:bg-calendar-cell-connector-both before:w-16': connector == 'both',
    }"
  >
    <slot />
    <div v-if="dot" class="absolute bottom-1 flex justify-center">
      <div
        class="h-1.5 w-1.5 rounded-full"
        :class="{
          '': active,
          'bg-brand-dark': !active && !disabled,
          'bg-foreground-disabled': !active && disabled,
        }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";

  const props = withDefaults(
    defineProps<{
      position?: "leading" | "trailing" | "middle" | null;
      dot?: boolean;
      today?: boolean;
      disabled?: boolean;
      connector?: "none" | "left" | "right" | "both";
    }>(),
    {
      dot: false,
      position: undefined,
      today: false,
      disabled: false,
      connector: "none",
    }
  );

  const active = computed(() => {
    return !!props.position;
  });
</script>
