<template>
  <div class="flex flex-col gap-1">
    <label v-if="label" class="text-body-sm-heavy text-secondary text-left">{{ label }}</label>

    <div class="flex w-full gap-3">
      <div>
        <DropdownSelect
          v-model="countrycode"
          class="w-full max-w-[100px]"
          filterable
          :name="prefixName"
          :options="phonePrefixes.prefixes"
          @update:model-value="updatePhone"
        />
      </div>
      <div>
        <InputText v-model="number" :name="name" :placeholder="t('phone')" @update:model-value="updatePhone" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const { t } = useI18n();

  const emit = defineEmits(["update:modelValue"]);

  const props = defineProps<{
    name?: string;
    prefixName?: string;
    label?: string;
    values?: any;
    setPrefix?: any;
  }>();

  onMounted(() => {
    if (props.values) {
      number.value = props.values.phone_number;
    }
  });

  watch(
    () => props.setPrefix,
    (val) => {
      if (val) {
        const foundPrefix = phonePrefixes.value.prefixes.find((prefix) => prefix.iso === val);
        if (foundPrefix) {
          countrycode.value = foundPrefix.value;
        }
      }
    }
  );

  const { data: phonePrefixes } = await useCountries();

  const countrycode = ref("+45");

  const number = ref("");

  watchEffect(() => {});

  const updatePhone = () => {
    emit("update:modelValue", { prefix: countrycode.value, phone_number: number.value });
  };
</script>
