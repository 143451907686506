import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import { flare } from "@flareapp/js";

export default () => {
  const s3Client = new S3Client({
    region: "eu-central-1",
    credentials: fromCognitoIdentityPool({
      identityPoolId: "eu-central-1:000c114b-5d29-4237-80ea-be9b890b6456",
      clientConfig: { region: "eu-central-1" },
    }),
  });

  const uploadTempFile = async (file: File, path: string): Promise<{ fileName: string; filePath: string }> => {
    // Put an object into an Amazon S3 bucket.
    const filePath = `${path}/${file.name}`;
    try {
      await s3Client.send(
        new PutObjectCommand({
          Bucket: "cr-temporary",
          Key: filePath,
          Body: file,
          ACL: "public-read" /* This makes the image public, but only works if your S3 bucket allows public access */,
          ContentType: file.type /* This is important to handle jpg vs png etc */,
        })
      );
      return { fileName: file.name, filePath };
      // process data.
    } catch (error) {
      $toast.add({
        type: "error",
        text: error,
      });
      flare.report(error);

      throw error;
    }
  };

  return {
    uploadTempFile,
  };
};
