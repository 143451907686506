<template>
  <div class="h-full w-full">
    <div class="flex h-full items-center">
      <div ref="datePickerWrapper" class="relative h-full w-full">
        <input type="hidden" name="date" />
        <Button
          v-if="button && buttonType == 'caret'"
          :ref="(elm) => (button ? (trigger = elm) : null)"
          icon-only
          :variant="ButtonVariant.Tertiary"
          @click="toggleDropdown"
        >
          <Icon class="text-tertiary cursor-pointer" src="bold/caret_down" />
        </Button>
        <Button
          v-if="button && buttonType == 'calendar'"
          :ref="(elm) => (button ? (trigger = elm) : null)"
          class="h-full"
          icon-only
          :size="ComponentSize.lg"
          @click="toggleDropdown"
        >
          <Icon class="text-foreground-secondary" src="calendar_blank" :size="ComponentSize.lg" />
        </Button>
        <p
          v-if="simple"
          :ref="(elm) => (simple ? (trigger = elm) : null)"
          class="text-body-default-heavy text-brand cursor-pointer text-center"
          @click="toggleDropdown"
        >
          {{ t("change") }} {{ t("date").toLocaleLowerCase() }}
        </p>
        <InputText
          id="datepicker"
          :ref="(elm) => (!simple && !button ? (trigger = elm) : null)"
          class="text-secondary"
          :class="simple || button ? 'hidden' : ''"
          readonly
          :model-value="stringifiedDate"
          :placeholder="placeholder || t('select_date')"
          :error="error"
          :label="label"
          :size="inputSize"
          :dark-bg="darkBg"
          @click="toggleDropdown"
          @keydown.escape="toggleDropdown"
        >
          <template v-if="calendarIcon" #prefix>
            <Icon class="text-foreground-quarterary" src="bold/CalendarBlank" />
          </template>
        </InputText>
      </div>
    </div>
    <Modal
      v-if="trigger"
      ref="dropdown"
      v-model:open="showDropdown"
      class="!p-0"
      :calculate-position="calculatePosition"
      anchor="top"
      :toggle-element="trigger.el"
      :width="showPresets ? 461 : 312"
    >
      <div class="gap relative flex flex-row">
        <div v-if="showPresets" ref="presetWrapper" class="flex w-37 flex-col items-stretch overflow-auto p-4">
          <Button
            v-for="preset in presets"
            :key="preset.value"
            class="!justify-start text-nowrap"
            :variant="ButtonVariant.Tertiary"
            :active="localValue === preset.value"
            @click="() => setPreset(preset.value)"
          >
            {{ preset.label }}
          </Button>
        </div>
        <Divider v-if="showPresets" rotation="vertical" :spacing="false" />
        <div ref="calendarWrapper" class="flex h-fit flex-col gap-4 p-4">
          <div class="flex items-center justify-between">
            <Button
              icon-only
              type="button"
              :size="ComponentSize.sm"
              :variant="ButtonVariant.Tertiary"
              @click="calculateDaysGrid(true)"
            >
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2434_1440)">
                  <path
                    d="M20.25 14C20.25 14.1989 20.171 14.3897 20.0303 14.5303C19.8897 14.671 19.6989 14.75 19.5 14.75H10.3125L13.5325 17.9694C13.6734 18.1103 13.7525 18.3014 13.7525 18.5006C13.7525 18.6999 13.6734 18.891 13.5325 19.0319C13.3916 19.1728 13.2005 19.2519 13.0012 19.2519C12.802 19.2519 12.6109 19.1728 12.47 19.0319L7.96998 14.5319C7.90006 14.4622 7.84458 14.3794 7.80673 14.2882C7.76888 14.1971 7.74939 14.0993 7.74939 14.0006C7.74939 13.9019 7.76888 13.8042 7.80673 13.713C7.84458 13.6219 7.90006 13.5391 7.96998 13.4694L12.47 8.96938C12.5397 8.89961 12.6226 8.84427 12.7137 8.80652C12.8049 8.76876 12.9026 8.74933 13.0012 8.74933C13.0999 8.74933 13.1976 8.76876 13.2887 8.80652C13.3799 8.84427 13.4627 8.89961 13.5325 8.96938C13.6022 9.03914 13.6576 9.12197 13.6953 9.21312C13.7331 9.30427 13.7525 9.40197 13.7525 9.50063C13.7525 9.59929 13.7331 9.69699 13.6953 9.78814C13.6576 9.87929 13.6022 9.96211 13.5325 10.0319L10.3125 13.25H19.5C19.6989 13.25 19.8897 13.329 20.0303 13.4697C20.171 13.6103 20.25 13.8011 20.25 14Z"
                    fill="#42464C"
                    style="fill: #42464c; fill: color(display-p3 0.2588 0.2745 0.298); fill-opacity: 1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2434_1440">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      style="fill: white; fill-opacity: 1"
                      transform="translate(6 6)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </Button>
            <div class="flex gap-1">
              <select
                v-model="calendar.month"
                class="text-secondary cursor-pointer appearance-none px-0 text-sm font-normal capitalize focus:outline-none"
                @change="(val) => calculateDaysGrid()"
              >
                <option v-for="(value, index) in MONTH_NAMES" :key="value" :value="index">
                  {{ value }}
                </option>
              </select>
              <select
                v-model="calendar.year"
                class="text-secondary cursor-pointer appearance-none px-0 text-sm font-normal focus:outline-none"
                @change="(val) => calculateDaysGrid()"
              >
                <option v-for="value in YEARS" :key="value">{{ value }}</option>
              </select>
            </div>
            <Button
              icon-only
              type="button"
              :size="ComponentSize.sm"
              :variant="ButtonVariant.Tertiary"
              @click="calculateDaysGrid(false)"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2494_194)">
                  <path
                    d="M14.0306 8.53061L9.53063 13.0306C9.38973 13.1715 9.19863 13.2507 8.99938 13.2507C8.80012 13.2507 8.60902 13.1715 8.46813 13.0306C8.32723 12.8897 8.24807 12.6986 8.24807 12.4994C8.24807 12.3001 8.32723 12.109 8.46813 11.9681L11.6875 8.74999H2.5C2.30109 8.74999 2.11032 8.67097 1.96967 8.53032C1.82902 8.38967 1.75 8.1989 1.75 7.99999C1.75 7.80108 1.82902 7.61031 1.96967 7.46966C2.11032 7.329 2.30109 7.24999 2.5 7.24999H11.6875L8.46937 4.02999C8.32848 3.88909 8.24932 3.69799 8.24932 3.49874C8.24932 3.29948 8.32848 3.10838 8.46937 2.96749C8.61027 2.82659 8.80137 2.74744 9.00062 2.74744C9.19988 2.74744 9.39098 2.82659 9.53187 2.96749L14.0319 7.46749C14.1018 7.53726 14.1573 7.62016 14.1951 7.71142C14.2329 7.80269 14.2523 7.90052 14.2522 7.99931C14.252 8.09809 14.2324 8.19588 14.1944 8.28706C14.1564 8.37824 14.1007 8.46101 14.0306 8.53061Z"
                    fill="#42464C"
                    style="fill: #42464c; fill: color(display-p3 0.2588 0.2745 0.298); fill-opacity: 1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2494_194">
                    <rect width="16" height="16" fill="white" style="fill: white; fill-opacity: 1" />
                  </clipPath>
                </defs>
              </svg>
            </Button>
          </div>
          <div class="grid w-[280px] grid-cols-7 gap-y-1 overflow-hidden">
            <Cell v-for="day in DAYS" :key="day" class="pointer-events-none touch-none capitalize">
              {{ day }}
            </Cell>
            <Cell
              v-for="(day, lastDayIndex) in dayArrays.last.daysArray"
              :key="'last_month_' + day"
              :position="getStateOfDay(day, calendar.month - 1)"
              :dot="isMarked(day, calendar.month - 1, calendar.year)"
              :disabled="isDisabled(day, calendar.month - 1, calendar.year)"
              :connector="getCellConnector(lastDayIndex, day, -1)"
              :today="isToday(day as number, calendar.month - 1)"
              @click="setDateValue(day, calendar.month - 1, isDisabled(day, calendar.month - 1, calendar.year))"
            >
              {{ day }}
            </Cell>
            <Cell
              v-for="(day, dateIndex) in dayArrays.current.daysArray"
              :key="`${calendar.month}_${day}`"
              :position="getStateOfDay(day, calendar.month)"
              :connector="getCellConnector(dateIndex, day, 0)"
              :today="isToday(day as number, calendar.month)"
              :disabled="isDisabled(day, calendar.month, calendar.year)"
              :dot="isMarked(day, calendar.month, calendar.year)"
              @click="setDateValue(day, calendar.month, isDisabled(day, calendar.month, calendar.year))"
            >
              {{ day }}
            </Cell>
            <Cell
              v-for="(day, nextDayIndex) in dayArrays.next.daysArray"
              :key="'next_month_' + day"
              :position="getStateOfDay(day, calendar.month + 1)"
              :dot="isMarked(day, calendar.month + 1, calendar.year)"
              :disabled="isDisabled(day, calendar.month + 1, calendar.year)"
              :connector="getCellConnector(nextDayIndex, day, 1)"
              :today="isToday(day as number, calendar.month + 1)"
              @click="setDateValue(day, calendar.month + 1, isDisabled(day, calendar.month + 1, calendar.year))"
            >
              {{ day }}
            </Cell>
          </div>
          <div
            class="flex justify-end gap-2"
            :class="{
              'flex-col': range && type === 'datetime',
            }"
          >
            <div
              v-if="type === 'datetime'"
              class="flex items-center gap-2"
              :class="{
                'w-full': range,
              }"
            >
              <InputTime v-model="fromTime" class="w-full" :readonly="!fromDate" />
              <template v-if="range">
                <div>-</div>
                <InputTime v-model="toTime" class="w-full" :readonly="!toDate" />
              </template>
            </div>
          </div>
          <div v-if="bottomButtons" class="flex w-full items-end justify-between gap-2">
            <Button type="button" :variant="ButtonVariant.Tertiary" @click="toggleDropdown">{{ t("cancel") }}</Button>
            <Button
              v-if="requiresConfirmation"
              type="button"
              :variant="ButtonVariant.Primary"
              :disabled="saveButtonDisabled"
              @click="update"
              >{{ t("save_changes") }}</Button
            >
          </div>
        </div>
        <Overlay v-if="loading" class="absolute top-0 left-0 h-full w-full" />
      </div>
    </Modal>
  </div>
</template>

<script setup lang="ts">
  import { formatDate as sharedFormatDate } from "#imports";
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import { DateTime, Info } from "luxon";
  import { computed, nextTick, onMounted, ref, unref, watch } from "vue";
  import { useI18n } from "vue-i18n";

  import Cell from "./partials/Cell.vue";

  defineOptions({
    name: "Datepicker",
    inheritAttrs: false,
  });

  interface Preset {
    label: string;
    value: string;
  }

  const props = withDefaults(
    defineProps<{
      label?: string;
      hideValidate?: boolean;
      defaultToday?: boolean;
      showClearButtonInDialog?: boolean;
      simple?: boolean;
      button?: boolean;
      type?: "date" | "datetime";
      range?: boolean;
      placeholder?: string;
      error?: string;
      requiresConfirmation?: boolean;
      required?: boolean;
      readonly?: boolean;
      markedDates?: any[];
      loading?: boolean;
      bottomButtons?: boolean;
      presets?: Preset[];
      buttonType?: "caret" | "calendar";
      inputSize?: InputSize;
      todayFormat?: "human" | string | Intl.DateTimeFormatOptions;
      calendarIcon?: boolean;
      darkBg?: boolean;
      disableWeekends?: boolean;
      name?: string;
      min?: string;
      max?: string;
    }>(),
    {
      label: undefined,
      type: "date",
      hideValidate: false,
      defaultToday: false,
      showClearButtonInDialog: false,
      simple: false,
      button: false,
      range: false,
      placeholder: "",
      error: "",
      requiresConfirmation: true,
      required: false,
      readonly: false,
      markedDates: () => [],
      loading: false,
      bottomButtons: true,
      presets: () => [
        { label: "Today", value: "today" },
        { label: "Yesterday", value: "yesterday" },
        { label: "Last 7 days", value: "last_7_days" },
        { label: "Last 30 days", value: "last_30_days" },
        { label: "This month", value: "this_month" },
        { label: "Last month", value: "last_month" },
        { label: "This year", value: "this_year" },
        { label: "Last year", value: "last_year" },
        { label: "All time", value: "all_time" },
      ],
      buttonType: "caret",
      inputSize: ComponentSize.default,
      todayFormat: "human",
      calendarIcon: false,
      darkBg: false,
      disableWeekends: false,
      name: "",
      min: undefined,
      max: undefined,
    }
  );

  const { t } = useI18n();

  const modelValue = defineModel<string | Date | { from: string; to: string }>();
  const localValue = ref();

  const dayArrays = ref<{
    current: { daysArray: number[]; fullDaysArray: Date[] };
    last: { daysArray: number[]; fullDaysArray: Date[] };
    next: { daysArray: number[]; fullDaysArray: Date[] };
  }>({
    current: { daysArray: [], fullDaysArray: [] },
    last: { daysArray: [], fullDaysArray: [] },
    next: { daysArray: [], fullDaysArray: [] },
  });

  const datePickerWrapper = ref<HTMLElement | null>(null);
  const presetWrapper = ref<HTMLElement | null>(null);
  const calendar = ref({
    month: 0,
    year: 0,
  });

  const calendarWrapper = ref<HTMLElement | null>(null);
  const { dropdown, trigger, showDropdown, toggleDropdown, calculatePosition } = useDropdown();

  onMounted(() => {
    if (!modelValue.value && !inputValue.value && props.defaultToday) {
      emit("update:modelValue", new Date());
    }

    nextTick(() => {
      if (inputValue.value) {
        modelValue.value = inputValue.value;
      }
      modelValueToLocalValue();

      initCalendar();
      calculateDaysGrid();
    });
  });

  watch(modelValue, () => {
    modelValueToLocalValue();
  });

  const isMarked = (day: number, month: number, year: number) => {
    const exists = props.markedDates.find((date) => {
      return date == DateTime.fromObject({ day: day, month: month + 1, year: year }).toSQLDate();
    });
    if (exists) return true;
  };

  const isDisabled = (day: number, month: number, year: number) => {
    const date = DateTime.fromJSDate(new Date(year, month, day));
    if (props.disableWeekends && date.isWeekend) {
      return true;
    }
    if (props.min && date.startOf("day") < DateTime.fromISO(props.min).startOf("day")) {
      return true;
    }
    if (props.max && date.startOf("day") > DateTime.fromISO(props.max).startOf("day")) {
      return true;
    }
    return false;
  };

  // Function to convert the modelValue ref to a local value representation
  const modelValueToLocalValue = () => {
    // Unwrap the modelValue reference to get its actual value
    const unrefedModelValue = unref(modelValue);

    // Helper function to check if a value is a valid Date object
    const isValidDate = (date) => date instanceof Date && !isNaN(date.getTime());

    // Function to create a date object from a string or return null if invalid
    const createDateOrNull = (dateString) => {
      const date = new Date(dateString);
      // Use isValidDate function to determine if the date creation was successful
      return isValidDate(date) ? date : null;
    };

    // Set localValue based on the type and validity of unrefedModelValue
    if (isString(unrefedModelValue)) {
      if (presetsContainsValue(unrefedModelValue)) {
        localValue.value = unrefedModelValue;
      } else {
        // If it's a string, attempt to parse it as a date
        localValue.value = { from: createDateOrNull(unrefedModelValue) };
      }
    } else if (unrefedModelValue instanceof Date) {
      // If it's a Date object, set it directly
      localValue.value = { from: unrefedModelValue };
    } else if (unrefedModelValue && typeof unrefedModelValue === "object") {
      // If it's an object, handle both cases where it could have date objects or strings for 'from' and 'to'
      if (isValidDate(unrefedModelValue.from) && isValidDate(unrefedModelValue.to)) {
        // If both 'from' and 'to' are valid dates, set them directly
        localValue.value = { from: unrefedModelValue.from, to: unrefedModelValue.to };
      } else {
        // Otherwise, attempt to parse the strings into dates, or default to the current date
        localValue.value = {
          from: createDateOrNull(unrefedModelValue.from) || new Date(),
          to: props.range ? createDateOrNull(unrefedModelValue.to) || new Date() : undefined,
        };
      }
    } else {
      // For any other type (including null and undefined), default to the current date
      localValue.value = { from: new Date() };
    }
  };

  /**
   * Computes the stringified date based on the provided model value and props.
   * @returns {string} The stringified date.
   */
  const stringifiedDate = computed(() => {
    // Helper function to format date based on type 'date' or 'datetime'
    // Helper function to format date based on type 'date' or 'datetime'
    const formatDate = (date) => {
      if (props.type === "datetime") {
        return dateToDatetimeFormat(date);
      }
      return isToday(date.getDate(), date.getMonth())
        ? props.todayFormat === "human"
          ? t("today")
          : sharedFormatDate(date, props.todayFormat)
        : dateToDateFullFormat(date);
    };
    // Checks if the fromDate exists and is valid
    const isValidFromDate = fromDate.value instanceof Date && !isNaN(fromDate.value.getTime());
    // In case of range, check if toDate also exists and is valid
    const isValidToDate = props.range ? toDate.value instanceof Date && !isNaN(toDate.value.getTime()) : true;

    // If modelValue.value is a string return it directly
    if (isString(modelValue.value) && presetsContainsValue(modelValue.value)) {
      return getPresetLabel(modelValue.value);
    }
    // Check for a valid date range
    if (props.range && isValidFromDate && isValidToDate) {
      // Return formatted date range
      return `${formatDate(fromDate.value)} - ${formatDate(toDate.value)}`;
    } else if (!props.range && isValidFromDate) {
      // Return single formatted date
      return formatDate(fromDate.value);
    }

    // Default fallback when dates are not valid or missing
    return "";
  });

  const saveButtonDisabled = computed(() => {
    return false;

    if (!fromDate.value) return true;

    if (props.range) {
      if (!(fromDate.value && toDate.value)) return true;
      if (!isString(localValue.value)) return true;
    }

    if (fromDate.value && isString(fromDate.value)) return true;
    return !!fromDate.value;
  });

  const fromDate = computed({
    get: () => {
      if (!localValue.value || isString(localValue.value)) return null;
      return localValue.value.from;
    },
    set: (val) => {
      if (isString(localValue.value)) {
        if (props.range)
          localValue.value = {
            from: new Date(),
            to: new Date(),
          };
        else
          localValue.value = {
            from: new Date(),
          };
      }

      localValue.value.from = val;
    },
  });

  const toDate = computed({
    get: () => {
      if (!localValue.value || isString(localValue.value)) return null;
      return localValue.value.to;
    },
    set: (val) => {
      if (!localValue.value) return;
      localValue.value.to = val;
    },
  });

  const fromTime = computed({
    get: () => {
      if (!fromDate.value) return null;
      return fromDate.value.toTimeString().substring(0, 5);
    },
    set: (val) => {
      if (!fromDate.value) return;
      const [hour, minute] = val.split(":");
      const date = new Date(fromDate.value);

      date.setHours(parseInt(hour));
      date.setMinutes(parseInt(minute));

      fromDate.value = date;
    },
  });

  const toTime = computed({
    get: () => {
      if (!toDate.value) return null;
      return toDate.value.toTimeString().substring(0, 5);
    },
    set: (val) => {
      if (!toDate.value) return;
      const [hour, minute] = val.split(":");
      toDate.value.setHours(parseInt(hour));
      toDate.value.setMinutes(parseInt(minute));
    },
  });

  const presetIsSelected = computed(() => {
    if (!localValue.value) return false;
    return presetsContainsValue(localValue.value);
  });

  const presetsContainsValue = (value) => {
    return props.presets.some((preset) => preset.value === value);
  };

  const initCalendar = () => {
    if (isString(localValue.value)) {
      calendar.value = {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      };
      return;
    }

    calendar.value = {
      month: fromDate.value ? fromDate.value.getMonth() : new Date().getMonth(),
      year: fromDate.value ? fromDate.value.getFullYear() : new Date().getFullYear(),
    };
  };

  const numberOfWeeks = computed(() => {
    return Math.ceil(
      (dayArrays.value.current.daysArray.length +
        dayArrays.value.last.daysArray.length +
        dayArrays.value.next.daysArray.length) /
        7
    );
  });

  const hasPresets = computed(() => {
    return props.presets && props.presets.length > 0;
  });

  const showPresets = computed(() => {
    return hasPresets.value && !props.range;
  });

  const MONTH_NAMES = Info.months();
  const DAYS = Info.weekdays().map((day) => day.substr(0, 2));
  const YEARS = computed(() => {
    const max = new Date().getFullYear();
    const min = max - 9;
    const years: number[] = [];

    for (let i = max + 10; i >= min; i--) {
      years.push(i);
    }
    return years.reverse();
  });

  const setDateValue = (d, m, disabled) => {
    if (disabled) return;
    if (props.range) {
      if (!fromDate.value) {
        fromDate.value = getDateObject(d, m, calendar.value.year);
      } else if (!toDate.value) {
        if (fromDate.value > getDateObject(d, m, calendar.value.year)) {
          toDate.value = fromDate.value;
          fromDate.value = getDateObject(d, m, calendar.value.year);
        } else {
          toDate.value = getDateObject(d, m, calendar.value.year);
        }
      } else {
        fromDate.value = getDateObject(d, m, calendar.value.year);
        toDate.value = null;
      }
    } else {
      fromDate.value = getDateObject(d, m, calendar.value.year);
      inputValue.value = fromDate.value;
      if (m !== calendar.value.month) {
        calendar.value = {
          month: m,
          year: calendar.value.year,
        };
        calculateDaysGrid();
      }
    }

    if (!props.requiresConfirmation) update();
  };

  const update = () => {
    if (props.range) {
      if (!localValue.value.to) {
        localValue.value.to = localValue.value.from;
      }
      emit("update:modelValue", { ...localValue.value });
    } else emit("update:modelValue", presetIsSelected.value ? localValue.value : fromDate.value);

    toggleDropdown();
  };

  const getStateOfDay = (d, m) => {
    if (!fromDate.value) return null;
    d = new Date(calendar.value.year, m, d);

    if (fromDate.value.toDateString() == d.toDateString()) return "leading";
    if (toDate.value && toDate.value.toDateString() == d.toDateString()) return "trailing";

    if (fromDate.value < d && toDate.value > d) return "middle";

    return null;
  };

  const getCellConnector = (index, day, month) => {
    const state = getStateOfDay(day, calendar.value.month + month);
    if (!state || state !== "middle") return "none";

    let positionInWeek = index;

    if (month === -1) positionInWeek = positionInWeek + 1;
    else if (month === 0) positionInWeek = positionInWeek + dayArrays.value.last.daysArray.length + 1;
    else if (month === 1)
      positionInWeek =
        positionInWeek + dayArrays.value.last.daysArray.length + dayArrays.value.current.daysArray.length + 1;

    positionInWeek = positionInWeek % 7;

    if (positionInWeek === 1) return "right";
    else if (positionInWeek === 0) return "left";

    return "both";
  };

  const isToday = (dayOfMonth: number, month) => {
    const today = new Date();
    const d = new Date(calendar.value.year, month, dayOfMonth);
    return today.toDateString() == d.toDateString();
  };

  const getDateObject = (d, m, y) => {
    if (m === -1) {
      m = 11;
      y--;
    } else if (m === 12) {
      m = 0;
      y++;
    }

    if (props.type === "date") return new Date(y, m, d);
    else {
      let time = fromTime.value;

      if (!time) {
        const today = new Date();
        time = `${today.getHours()}:${today.getMinutes()}`;
      }
      const [hour, minute] = time.split(":");
      return new Date(y, m, d, parseInt(hour), parseInt(minute));
    }
  };

  const setPreset = (preset) => {
    localValue.value = preset;
  };

  const getPresetLabel = (preset) => {
    const presetObject = props.presets.find((p) => p.value === preset);
    if (presetObject) return presetObject.label;
    return "";
  };

  const calculateDaysGrid = (increment?) => {
    let month = calendar.value.month;
    let year = calendar.value.year;
    if (increment !== undefined)
      if (increment) {
        if (month === 0) {
          month = 11;
          year--;
        } else {
          month--;
        }
      } else {
        if (month === 11) {
          month = 0;
          year++;
        } else {
          month++;
        }
      }

    calendar.value = {
      month,
      year,
    };

    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const currentMonthDaysObject = {
      daysArray: Array.from({ length: daysInMonth }, (_, i) => i + 1),
      fullDaysArray: Array.from({ length: daysInMonth }, (_, i) => getDateObject(i + 1, month, year)),
    };

    const dayOfWeek = DateTime.fromJSDate(new Date(year, month)).weekday - 1;

    //get the n last days of the previous month
    const lastMonth = new Date(year, month, 0);
    const lastMonthDays = lastMonth.getDate();
    const lastMonthStart = lastMonthDays - dayOfWeek;

    const lastMonthDaysObject = {
      daysArray: Array.from({ length: dayOfWeek }, (_, i) => lastMonthStart + i + 1),
      fullDaysArray: Array.from({ length: dayOfWeek }, (_, i) =>
        getDateObject(lastMonthStart + i + 1, month - 1, year)
      ),
    };

    //get the n first days of the next month
    const nextMonthDaysObject = {
      daysArray: Array.from(
        { length: 7 - ((daysInMonth + lastMonthDaysObject.daysArray.length) % 7) },
        (_, i) => i + 1
      ),
      fullDaysArray: Array.from({ length: 7 - ((daysInMonth + lastMonthDaysObject.daysArray.length) % 7) }, (_, i) =>
        getDateObject(i + 1, month + 1, year)
      ),
    };

    dayArrays.value = {
      current: currentMonthDaysObject,
      last: lastMonthDaysObject,
      next: nextMonthDaysObject,
    };

    emit("dateChange");

    nextTick(() => {
      dropdown.value?.updateHeight();
    });
  };

  const setPresetWrapperHeight = () => {
    if (presetWrapper.value && calendarWrapper.value) {
      if (!calendarWrapper.value.clientHeight) presetWrapper.value.style.maxHeight = null;
      else presetWrapper.value.style.maxHeight = `${calendarWrapper.value.clientHeight}px`;
    }
  };

  watch(showDropdown, (val) => {
    if (val) {
      initCalendar();
      calculateDaysGrid();

      nextTick(() => {
        dropdown.value?.updateHeight();
        nextTick(() => {
          setPresetWrapperHeight();
          dropdown.value?.updateHeight();
        });
        //reset scroll in preset wrapper
        if (presetWrapper.value) presetWrapper.value.scrollTop = 0;
      });
    }
  });

  watch(numberOfWeeks, () => {
    nextTick(() => {
      //get the height of the calendar wrapper and set preset wrapper to the same height
      setPresetWrapperHeight();
      dropdown.value?.updateHeight();
    });
  });

  const emit = defineEmits(["update:modelValue", "dateChange"]);

  const dateToDatetimeFormat = (date) => {
    return DateTime.fromJSDate(date).toFormat("yyyy-LL-dd HH:mm");
  };

  const dateToDateFullFormat = (date) => {
    return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL);
  };

  const { value: inputValue } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  defineExpose({
    calenderRef: {
      days: dayArrays,
      calendar: calendar,
    },
  });
</script>

<style scoped></style>
