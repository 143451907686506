export interface IServices {
  frontend_options: Options;
  pickup_intervals: PickupIntervals;
  dfm_pickup: string;
  has_fixed_pickup: boolean;
  transfer_enabled: boolean;
  transfer_delivery_method: string;
  container: string;
}

export interface PickupIntervals {
  sunday: Interval;
  monday: Interval;
  tuesday: Interval;
  wednesday: Interval;
  thursday: Interval;
  friday: Interval;
  saturday: Interval;
}

export interface Interval {
  latest_booking: string | null;
  warehouse_closing: string | null;
}

export enum Day {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday",
}

export interface Options {
  units: Units;
}

export interface Units {
  weight: string;
}
