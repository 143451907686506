<template>
  <aside class="group/sidebar bg-surface-lvl-01 z-50 h-full overflow-x-hidden transition-all" :data-open="expanded">
    <div
      class="w-sidebar-closed group-hover/sidebar:w-sidebar-open group-data-[open=true]/sidebar:w-sidebar-open flex h-full flex-col py-1.5 transition-all group-hover/sidebar:delay-200 group-data-[open=true]/sidebar:delay-200"
    >
      <header class="px-1.5">
        <slot name="header" />
      </header>

      <nav class="w-sidebar-open flex-grow px-1.5 pt-2 pb-4 transition-all md:overflow-y-auto">
        <slot />
      </nav>
      <footer class="px-1.5">
        <slot name="footer" />
      </footer>
    </div>
  </aside>
</template>

<script setup lang="ts">
  import { computed, provide, ref } from "vue";

  const openEntry = ref();
  const expanded = computed(() => hasOpenDropdown.value || locked.value);
  const hasOpenDropdown = ref(false);
  const locked = ref(false);

  function lock() {
    locked.value = !locked.value;
  }

  useDoubleClick({
    key: "Control",
    callback: () => {
      lock();
    },
  });

  provide("navigationBarOpen", {
    open: hasOpenDropdown,
    toggleNavigationBar: (val: boolean) => (hasOpenDropdown.value = val),
  });

  defineExpose({
    expanded,
    openEntry,
    hasOpenDropdown,
    lock,
    locked,
  });
</script>
