<template>
  <Modal
    ref="modal"
    v-model:open="modalOpen"
    class="shadow-lvl-05"
    :click-to-close="false"
    max-width="1020px"
    :content-padding="false"
    :overlay="false"
    :center="true"
    anchor="top"
    @closed="() => emit('close')"
  >
    <div class="h-[756px] max-h-full w-[1020px] max-w-full">
      <FormWizard
        v-if="!shopCreated"
        ref="wizard"
        class="flex h-full w-full flex-col"
        :validation-schema="validationSchema"
        show-progress
        :name="t('set_up_entity', { entity: t('webshop').toLocaleLowerCase() })"
        :step-names="[
          t('name_webshop'),
          t('select_shopsystem'),
          t('select shopify_plan'),
          t('other'),
          t('link_account'),
          t('link_checkout'),
        ]"
        header-type="create"
        footer-type="create"
        :submit-button-text="t('create')"
        @next="(val) => handleNext(val)"
        @submit="onSubmit"
        @exit="emit('close')"
      >
        <FormStep>
          <div class="mx-auto flex h-full w-full max-w-[540px] flex-col items-center justify-center gap-5">
            <p class="text-title-section text-secondary text-center">{{ t("create_webshop_titles.1") }}</p>
            <InputText
              id="name"
              class="w-full"
              :size="ComponentSize.lg"
              name="name"
              :placeholder="t('webshop_name_example', { name: accountName })"
            />
          </div>
        </FormStep>
        <FormStep>
          <div class="mx-auto flex h-full w-full max-w-[540px] flex-col items-center justify-center gap-5">
            <p class="text-title-section text-secondary text-center">
              {{ t("create_webshop_titles.2", { name: values.name }) }}
            </p>
            <div class="grid w-full grid-cols-2 gap-3">
              <Button
                v-for="system in shopSystems"
                :key="system.value"
                class="w-full"
                type="button"
                active-checkmark
                :active="values?.platform == system.value"
                :size="ComponentSize.lg"
                @click="selectSystem(system.value)"
              >
                <Icon
                  v-if="system.value == 'shopify'"
                  class="text-[#7AB55C]"
                  :size="ComponentSize.lg"
                  src="custom/shopIcons/Shopify"
                />
                <Icon
                  v-else-if="system.value == 'shopware'"
                  class="text-[#3E9FFF]"
                  :size="ComponentSize.lg"
                  src="custom/shopIcons/Shopware"
                />
                <Icon
                  v-else-if="system.value == 'magento-1' || system.value == 'magento-2'"
                  class="text-[#E56624]"
                  :size="ComponentSize.lg"
                  src="custom/shopIcons/Magento"
                />
                <Icon
                  v-else-if="system.value == 'woocommerce'"
                  class="text-[#92588B]"
                  :size="ComponentSize.lg"
                  src="custom/shopIcons/Woo"
                />
                <Icon
                  v-else-if="system.value == 'custom'"
                  class="text-foreground-brand"
                  :size="ComponentSize.lg"
                  src="custom/shopIcons/Custom"
                />
                <p>{{ system.label }}</p>
              </Button>
            </div>
          </div>
        </FormStep>
        <FormStep>
          <div class="mx-auto flex h-full w-full max-w-[540px] flex-col items-center justify-center gap-4">
            <p class="text-title-section text-secondary mb-1 text-center">
              {{ t("create_webshop_titles.3", { name: values.name }) }}
            </p>
            <p class="text-body-default-heavy text-quarterary text-center">{{ t("setup_automatic_with") }}</p>
            <div class="flex w-full flex-col gap-3">
              <Button
                v-for="plan in shopifyPlans"
                :key="plan.value"
                class="w-full"
                type="button"
                active-checkmark
                :active="values?.shopify_plan == plan.value"
                :size="ComponentSize.lg"
                @click="selectPlan(plan.value)"
              >
                <Icon class="text-[#7AB55C]" :size="ComponentSize.lg" src="custom/shopIcons/Shopify" />
                <p>{{ plan.label }}</p>
              </Button>
            </div>
            <p class="text-body-default-heavy text-quarterary text-center">{{ t("payment_frequency") }}</p>
            <div class="grid w-full grid-cols-2 gap-3">
              <Button
                v-for="interval in paymentIntervals"
                :key="interval.value"
                class="w-full"
                type="button"
                active-checkmark
                :active="values?.payment_interval == interval.value"
                :size="ComponentSize.lg"
                @click="selectInterval(interval.value)"
              >
                <p>{{ interval.label }}</p>
              </Button>
            </div>
          </div>
        </FormStep>
        <FormStep>
          <div class="mx-auto flex h-full w-full max-w-[540px] flex-col items-center justify-center gap-4">
            <p class="text-title-section text-secondary mb-5 text-center">{{ t("create_webshop_titles.6") }}</p>
            <InputText
              id="shop_url"
              class="w-full"
              :size="ComponentSize.lg"
              name="shop_url"
              :label="t('url')"
              :placeholder="t('shop_url_example')"
            />
          </div>
        </FormStep>
        <FormStep>
          <div class="mx-auto flex h-full w-full max-w-[540px] flex-col items-center justify-center gap-5">
            <p class="text-title-section text-secondary mb-1 text-center">
              {{ t("create_webshop_titles.5", { system: _.capitalize(values.platform) }) }}
            </p>
            <div class="bg-00 flex w-full gap-5 rounded-lg p-3">
              <Badge class="flex h-7 w-7 min-w-7 items-center justify-center" type="bold">1</Badge>
              <div class="w-full">
                <p class="text-body-lg-heavy text-primary">{{ step1Title }}</p>
                <p class="text-body-default text-secondary mb-2">{{ step1Desc }}</p>
                <a :href="returnLinkToPlugin" target="_blank" rel="noopener noreferrer">
                  <Button type="button" :variant="ButtonVariant.Default">
                    <p>{{ t("open_item_in_a_new_tab", { item: _.capitalize(values.platform) }) }}</p>
                    <Icon src="arrow_up_right" />
                  </Button>
                </a>
              </div>
            </div>
            <div class="bg-00 flex w-full gap-5 rounded-lg p-3">
              <Badge class="flex h-7 w-7 min-w-7 items-center justify-center" type="bold">2</Badge>
              <div class="w-full">
                <p class="text-body-lg-heavy text-primary">
                  {{ t("setup_homerunner_in_shop_system", { system: _.capitalize(values.platform) }) }}
                </p>
                <div
                  v-if="
                    values.shopify_plan == 'basic' ||
                    (values.shopify_plan == 'shopify' && values.payment_interval == 'monthly')
                  "
                >
                  <p class="text-body-default text-secondary mb-2">{{ t("shopify plan unsupported") }}</p>
                  <a
                    href="https://help.homerunner.com/categories/it/shop-integrationer/shopify#fd60d75b-b225-4498-af96-e07881cc4b75"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button type="button" :variant="ButtonVariant.Default">
                      <p>{{ t("see_guide") }}</p>
                      <Icon src="arrow_up_right" />
                    </Button>
                  </a>
                </div>
                <div>
                  <p
                    v-if="
                      values.platform == 'shopify' &&
                      values.shopify_plan !== 'basic' &&
                      values.shopify_plan !== 'shopify' &&
                      values.payment_interval !== 'monthly'
                    "
                    class="text-body-default text-secondary"
                  >
                    {{ t("setup_homerunner_in_shop_system_desc", { system: _.capitalize(values.platform) }) }}
                  </p>
                  <div
                    class="mt-2 mb-2 flex w-full cursor-pointer gap-2"
                    @click="copyToClipboard(values.activation_code || '')"
                  >
                    <p class="text-title-section text-primary">{{ values.activation_code }}</p>
                    <Icon class="mt-1.5" src="copy" :size="ComponentSize.lg" />
                  </div>
                  <a
                    v-if="
                      values.platform == 'shopify' &&
                      values.shopify_plan !== 'basic' &&
                      values.shopify_plan !== 'shopify' &&
                      values.payment_interval !== 'monthly'
                    "
                    class="mb-2"
                    href="https://help.homerunner.com/categories/it/shop-integrationer/shopify"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button class="mb-2" type="button" :variant="ButtonVariant.Default">
                      <p>{{ t("see_guide") }}</p>
                      <Icon src="arrow_up_right" />
                    </Button>
                  </a>
                  <a
                    v-if="values.platform == 'woocommerce'"
                    class="mb-2"
                    href="https://help.homerunner.com/categories/it/shop-integrationer/woocommerce"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button class="mb-2" type="button" :variant="ButtonVariant.Default">
                      <p>{{ t("see_guide") }}</p>
                      <Icon src="arrow_up_right" />
                    </Button>
                  </a>
                  <p class="text-body-sm text-quarterary">
                    {{
                      t("activation_code_desc_with_time", {
                        time: DateTime.fromISO(values?.activation_expires_at).toFormat("dd/MM/y HH:mm"),
                      })
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </FormStep>
        <FormStep>
          <div class="mx-auto flex h-full w-full max-w-[540px] flex-col items-center justify-center gap-3">
            <EmptyView
              v-if="checkouts.length == 0"
              :title="t('create_webshop_titles.4')"
              :description="t('no_checkouts_found')"
            >
              <template #illustration>
                <img src="~/assets/illustrations/emptyViews/ec-no-result.svg" alt="No return portals illustration" />
              </template>
            </EmptyView>
            <p v-if="checkouts.length !== 0" class="text-title-section text-secondary mb-2 text-center">
              {{ t("create_webshop_titles.4") }}
            </p>
            <div v-if="checkouts.length !== 0" class="h-full w-full overflow-auto">
              <ListSelector name="checkout_id" :values="checkouts" value-key="id" radio-position="top">
                <template #title="{ value }">
                  <div class="w-full">
                    <p class="text-body-lg-heavy text-secondary">{{ value.name }}</p>
                    <p class="text-body-default text-tertiary">
                      {{ t("created_at") }} {{ DateTime.fromISO(value.created_at).toFormat("DDD") }}
                    </p>
                  </div>
                </template>
              </ListSelector>
            </div>
            <Button :variant="ButtonVariant.Tertiary">{{ t("add_checkout_at_later_date") }}</Button>
          </div>
        </FormStep>
      </FormWizard>
      <div v-if="shopCreated" class="relative flex h-full w-[1020px] max-w-full">
        <div class="mx-auto my-auto max-w-[540px] -translate-x-1 transform">
          <SuccessBlob />
          <p class="text-title-section text-secondary mb-2 w-full text-center">{{ t("webshop_is_created") }}</p>
          <p class="text-body-lg text-tertiary mb-4">{{ t("webshop_is_created_desc") }}</p>
          <Button class="mt-4 w-full" type="button" :variant="ButtonVariant.Default" @click="emit('close')">{{
            t("close")
          }}</Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import _ from "lodash";
  import { DateTime } from "luxon";

  const props = defineProps<{
    open: boolean;
    setShop: any;
  }>();

  const modalOpen = ref(props.open);

  const shopCreated = ref(false);

  const createdShop = ref({});

  const checkouts = ref<any[]>([]);

  const loading = ref(false);

  const yup = useYup();

  const { t } = useI18n();

  const emit = defineEmits(["close"]);

  const wizard = ref(null);

  const values = computed(() => wizard.value?.values);

  const { accountName } = storeToRefs(useWorkspacesStore());

  const shopSystems = [
    { label: "Shopify", value: "shopify" },
    { label: "Magento 1", value: "magento-1" },
    { label: "Magento 2", value: "magento-2" },
    { label: "WooCommerce", value: "woocommerce" },
    { label: "Shopware", value: "shopware" },
    { label: t("other"), value: "custom" },
  ];

  const shopifyPlans = [
    { label: "Basic", value: "basic" },
    { label: "Shopify", value: "shopify" },
    { label: "Advanced", value: "advanced" },
  ];

  const paymentIntervals = [
    { label: t("monthly_payment"), value: "monthly" },
    { label: t("yearly_payment"), value: "yearly" },
  ];

  onMounted(() => {
    if (props.setShop) {
      setTimeout(() => {
        wizard.value?.setValues({
          platform: props.setShop.platform,
          shop_url: props.setShop.shop_url,
          name: props.setShop.name,
          checkout_id: props.setShop.checkout_id,
          id: props.setShop.id,
        });
      }, 50);
    }
  });

  const step1Title = computed(() => {
    switch (values.value.platform) {
      case "shopify":
        return t("add_homerunner_in_appstore");
      case "woocommerce":
        return t("add_homerunner_in_woocommerce");
      default:
        return t("get_our_plugin");
    }
  });

  const step1Desc = computed(() => {
    switch (values.value.platform) {
      case "shopify":
        return t("go_to_shopify_and_andd");
      case "woocommerce":
        return t("go_to_woocommerce_and_add");
      default:
        return t("get_our_plugin_and_add");
    }
  });

  const returnLinkToPlugin = computed(() => {
    switch (values.value.platform) {
      case "shopify":
        return "https://apps.shopify.com/coolrunner-app";
      case "woocommerce":
        return "https://wordpress.org/plugins/homerunner-smartcheckout/";
      case "shopware":
        return "https://github.com/Homerunner-com/shopware";
      case "magento-1":
        return "https://github.com/Homerunner-com/magento1";
      case "magento-2":
        return "https://github.com/Homerunner-com/magento2";
      default:
        return "https://homerunner.com";
    }
  });

  const selectSystem = (system: string) => {
    wizard.value?.setValues({ platform: system });
    if (system == "shopify") {
      validationSchema.value[2].spec.meta.autoskip = false;
    } else {
      validationSchema.value[2].spec.meta.autoskip = true;
    }
    if (system == "custom") {
      validationSchema.value[3].spec.meta.autoskip = false;
      validationSchema.value[4].spec.meta.autoskip = true;
    } else {
      validationSchema.value[3].spec.meta.autoskip = true;
      validationSchema.value[4].spec.meta.autoskip = false;
    }
  };

  const { checkoutsForSelect } = useCheckouts();

  const handleNext = (val: number) => {
    if (val === 1) {
      getCheckouts();
    }
    if (val == 2 && values.value.platform !== "custom") {
      if (values.value.id) {
        updateShop();
      } else {
        createShop();
      }
    }
    if (val == 4 && values.value.platform !== "shopify") {
      if (values.value.id) {
        updateShop();
      } else {
        createShop();
      }
    }
    if (val === 5 && values.value.platform == "custom") {
      if (values.value.id) {
        updateShop();
      } else {
        createShop();
      }
    }
    if (val === 5 && checkouts.value.length == 0) {
      if (values.value.id) {
        updateShop();
      } else {
        createShop();
      }
      shopCreated.value = true;
    }
  };

  const getCheckouts = async () => {
    const fetchedCheckouts = await checkoutsForSelect();
    checkouts.value = fetchedCheckouts.checkouts;
    if (checkouts.value.length == 0) {
      validationSchema.value[4].spec.meta.continueButtonText = t("create");
    }
  };

  const selectPlan = (plan: string) => {
    wizard.value?.setValues({ shopify_plan: plan });
  };

  const selectInterval = (interval: string) => {
    wizard.value?.setValues({ payment_interval: interval });
  };

  const createShop = () => {
    loading.value = true;

    if (values.value.id) {
      updateShop().then(() => {
        loading.value = false;
      });
      return;
    }

    homeFetch(`workspace/webshops`, {
      method: "POST",
      body: {
        name: values.value.name,
        platform: values.value.platform,
        shop_url: values.value.shop_url,
      },
    })
      .then((r) => {
        wizard.value?.resetForm({
          values: {
            ...r.data,
          },
        });
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const updateShop = () => {
    return homeFetch(`workspace/webshops/${values.value.id}`, {
      method: "PUT",
      body: {
        ...values.value,
      },
    }).then((r) => {
      wizard.value?.resetForm({
        values: {
          ...r.data.data,
        },
      });
    });
  };

  const addCheckout = () => {
    if (values.value.id) {
      updateShop();
      return;
    }
    createdShop.value.checkout_id = values.value.checkout_id;
    homeFetch(`workspace/webshops/${createdShop.value.id}`, {
      method: "PUT",
      body: {
        ...createdShop.value,
      },
    }).then(() => {});
  };

  const validationSchema = ref([
    yup
      .object({
        name: yup.string().required(),
      })
      .meta({ continueButtonText: t("continue") }),
    yup
      .object({
        platform: yup.string().required(),
      })
      .meta({ continueButtonText: t("continue") }),
    yup
      .object({
        shopify_plan: yup.string().required(),
        payment_interval: yup.string().required(),
      })
      .meta({ continueButtonText: t("continue"), autoskip: true }),
    yup
      .object({
        shop_url: yup.string().required().matches(/[.:]/),
      })
      .meta({ continueButtonText: t("continue"), autoskip: true }),
    yup
      .object({
        code: yup.number().nullable(),
      })
      .meta({ continueButtonText: t("continue"), autoskip: false }),
    yup
      .object({
        checkout_id: yup.number().nullable(),
      })
      .meta({ continueButtonText: t("continue"), autoskip: false }),
  ]);

  const onSubmit = () => {
    if (values.value.checkout_id) {
      addCheckout();
    }
    shopCreated.value = true;
  };
</script>
