import type { IBoardingFlow } from "../types";

export default (): IBoardingFlow => {
  const { t } = useI18n();
  const icon = "plus";
  const color = "kiwi";
  const title = t("boarding.setup_shop.title");
  const description = t("boarding.setup_shop.description");
  const key = "shop";
  const yup = useYup();

  const schema = [
    yup
      .object({
        shopName: yup
          .string()
          .required()
          .label(t("shop_name"))
          .meta({ placeholder: t("enter_organisation_name"), size: "default" }),
      })
      .meta({
        title: t("page.create_organisations.title"),
        subtitle: t("page.create_organisations.subtitle"),
        canGoBack: false,
        key: "createOrganisation",
      }),
  ];

  return {
    icon,
    color,
    title,
    description,
    key,
    schema,
  };
};
