import _ from "lodash";
import { defineStore } from "pinia";
import type { IUser, IWorkspace } from "~/types/auth";

const setAccountIdCookie = (accountId: number): void => {
  setCookie({
    name: "hr_account_id",
    value: accountId.toString(), //Conversion of 'accountId' to string for setting cookie value.
    expiration: 60 * 60 * 24 * 1000,
  });
};

const setUserIdCookie = (userId: number): void => {
  setCookie({
    name: "hr_user_id",
    value: userId.toString(),
    expiration: 60 * 60 * 24 * 1 * 1000,
  });
};

export const useWorkspacesStore = defineStore(
  "Workspaces",
  () => {
    const workspaces = ref<IWorkspace[]>([]);

    const workspace = computed(() => {
      return getCurrentWorkspace();
    });

    /**
     * Returns a Promise that resolves to an array of IWorkspace objects representing the user's capabilities.
     * @returns {Promise<IWorkspace[]>} A Promise that resolves to an array of IWorkspace objects representing the user's capabilities.
     */
    const fetchWorkspaces = async (redirect: boolean = true) => {
      const { fetchServices } = useServicesStore();
      const { fetchOptions } = useOptionsStore();
      const { $i18n } = useNuxtApp();
      const { t } = $i18n;

      const router = useRouter();
      const route = useRoute();

      // Send a GET request to the server to get the user's capabilities
      return homeFetch("auth/capabilities").then((response) => {
        // Extract the capabilities data from the response and return it
        const _workspaces = response.data;

        setWorkspaces(_workspaces);
        //Set workspace if current is not available
        if (!hasWorkspace(accountId.value)) setWorkspace(_workspaces[0]);
        setTimeout(() => {
          Promise.all([fetchOptions(), fetchServices()]).then(() => {
            //if redirect param, go to redirect param
            if (route.query.redirect) {
              router.push(route.query.redirect as string);
              return;
            }

            if (redirect) {
              $toast.add({
                title: t("redirected_to_account"),
                icon: "check",
                duration: 2000,
              });
              setTimeout(() => {
                router.push({ name: "shipments" });
              }, 1500);
            }
            return;
          });
        }, 500);
      });
    };

    const refreshWorkspaces = async (): Promise<void> => {
      const { fetchServices } = useServicesStore();
      fetchWorkspaces().then(fetchServices);
    };

    const setWorkspace = (_workspace: IWorkspace | null, reload?: boolean): void => {
      if (!_workspace) {
        return;
      }

      setAccountIdCookie(_workspace.account.id);
    };

    const hasWorkspace = (accountId: number | "") => {
      if (!accountId) return false;

      return !!workspaces.value.find((_workspace) => _workspace.account.id === accountId);
    };

    const updateWorkspace = (_workspace: IWorkspace): void => {
      const _workspaces = _.cloneDeep(workspaces.value);
      const index = _workspaces.findIndex((_workspace) => _workspace.account.id === _workspace.account.id);
      _workspaces[index] = _workspace;
      setWorkspaces(_workspaces);
    };

    const setWorkspaces = (_workspaces?: IWorkspace[] | null) => {
      workspaces.value = _workspaces || [];

      if (workspaces.value.length > 0) {
        setUserIdCookie(workspaces.value[0].user.id);
      }
    };

    const clearWorkspaces = () => {
      workspaces.value = [];
    };

    const getWorkspace = (id?: number): IWorkspace | undefined => {
      if (!id) return;

      return workspaces.value.find((_workspace) => _workspace.account.id === id);
    };

    const getCurrentWorkspace = (): IWorkspace | undefined => {
      return getWorkspace(getAccountId());
    };

    /**
     * Returns the account ID as a number or null, obtained from 'hr_account_id' cookie.
     *
     * @returns {number | null} Returns a number if 'hr_account_id' cookie exists, otherwise returns null.
     */
    const getAccountId = (): number | undefined => {
      // Get the account ID from the 'hr_account_id' cookie.
      const accountId = useCookie("hr_account_id");

      // If 'hr_account_id' cookie exists, then parse it to a number and return it.
      if (accountId.value) {
        return parseInt(accountId.value);
      }

      // If 'hr_account_id' cookie does not exist, then return null.
      return;
    };

    const updateAccountLogo = (logo: string | null): void => {
      if (!workspace.value) {
        return;
      }

      const _workspace = _.cloneDeep(workspace.value);

      _workspace.account.logo = logo;
      updateWorkspace(_workspace);
    };

    const getAccountFromSubdomain = (subDomain: string): IWorkspace | null => {
      if (!workspaces.value) {
        return null;
      }

      return workspaces.value.find((_workspace) => _workspace.subdomain === subDomain) || null;
    };

    const checkIfSubdomainExists = (subDomain: string): boolean => {
      if (!subdomains.value) {
        return false;
      }

      return subdomains.value.includes(subDomain);
    };

    const checkIsActive = (_workspace: IWorkspace): boolean => {
      if (!workspace.value || !workspaceAccount.value) {
        return false;
      }

      return _workspace.account.id === workspaceAccount.value.id;
    };

    const updateUser = (_user: IUser): void => {
      if (!workspace.value) {
        return;
      }

      const _workspace = _.cloneDeep(workspace.value);

      _workspace.user = _user;
      updateWorkspace(_workspace);
    };

    const updateUserPicture = (picture: string | null): void => {
      if (!user.value) {
        return;
      }

      const _user = _.cloneDeep(user.value);

      _user.picture = picture;
      updateUser(_user);
    };

    const workspaceAccount = computed(() => {
      if (!workspace.value?.account) {
        return null;
      }

      return workspace.value.account;
    });

    const user = computed(() => {
      if (!workspace.value) {
        return null;
      }

      return workspace.value.user;
    });

    const userName = computed(() => {
      if (!user.value) {
        return "";
      }

      return user.value?.name;
    });

    const userEmail = computed(() => {
      if (!user.value) {
        return "";
      }

      return user.value.email;
    });

    const userPicture = computed(() => {
      if (!user.value) {
        return "";
      }

      return user.value.picture || "";
    });

    const userId = computed(() => {
      if (!user.value) {
        return "";
      }

      return user.value.id;
    });

    const accountName = computed(() => {
      if (!workspaceAccount.value) {
        return "";
      }

      return workspaceAccount.value.name;
    });

    const accountLogo = computed(() => {
      if (!workspaceAccount.value) {
        return "";
      }

      return workspaceAccount.value.logo || "";
    });

    const accountIsBusiness = computed(() => {
      if (!workspaceAccount.value) {
        return false;
      }

      return workspaceAccount.value.business;
    });

    const accountLevel = computed(() => {
      if (!workspaceAccount.value) {
        return "";
      }

      return workspaceAccount.value.level;
    });

    const accountOptions = computed(() => {
      if (!workspaceAccount.value) {
        return {};
      }

      return workspaceAccount.value.options;
    });

    const isDFM = computed(() => {
      return accountLevel.value === "DFM";
    });

    const accountId = computed(() => {
      if (!workspaceAccount.value) {
        return "";
      }

      return workspaceAccount.value.id;
    });

    const hasMultipleWorkspaces = computed(() => {
      if (!workspaceAccount.value) {
        return false;
      }

      return workspaces.value.length > 1;
    });

    const subdomains = computed(() => {
      if (!workspaces.value) {
        return [];
      }

      return workspaces.value.map((account) => account.subdomain || "");
    });

    const subdomain = computed(() => {
      if (!workspace.value) {
        return "";
      }

      return workspace.value.subdomain || "";
    });

    const hasSubdomains = computed(() => {
      return subdomains.value.length > 0;
    });

    const accountServices = computed(() => {
      if (!workspaceAccount.value) {
        return [];
      }

      return workspaceAccount.value.services;
    });

    const currentDomain = computed(() => {
      if (!workspace.value) {
        return "";
      }
      return buildDomainForWorkspace(workspace.value);
    });

    const buildDomainForWorkspace = (workspace: IWorkspace): string => {
      if (!workspace.subdomain) return "";

      const { domain } = decideDomainInfo();
      return [workspace.subdomain, domain].filter(Boolean).join(".");
    };

    return {
      setWorkspaces,
      setWorkspace,
      fetchWorkspaces,
      refreshWorkspaces,
      updateWorkspace,
      getWorkspace,
      getCurrentWorkspace,
      clearWorkspaces,
      updateAccountLogo,
      getAccountFromSubdomain,
      checkIfSubdomainExists,
      checkIsActive,
      updateUserPicture,
      workspaces,
      workspace,
      account: workspaceAccount,
      accountId,
      accountName,
      accountLogo,
      accountIsBusiness,
      accountLevel,
      accountOptions,
      isDFM,
      hasMultipleWorkspaces,
      subdomain,
      subdomains,
      hasSubdomains,
      user,
      userName,
      userEmail,
      userPicture,
      userId,
      accountServices,
      currentDomain,
      buildDomainForWorkspace,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
    },
  }
);
