export default ({
  validationSchema,
  submitButtonText,
  emit,
}: {
  validationSchema: any[];
  submitButtonText?: string | null;
  emit: (event: "submit" | "exit" | "skip", ...args: any[]) => void;
}) => {
  const { t } = useI18n();
  const currentStepIdx = ref(0);

  // extracts the indivdual step schema
  const currentSchema = computed(() => {
    if (!validationSchema) {
      return {};
    }
    return validationSchema[currentStepIdx.value];
  });

  // The initial values for the all entries in validationSchema
  const initialValues = computed(() => {
    if (!validationSchema) {
      return {};
    }
    const entries = validationSchema.map((schema) => {
      const acc = {};
      Object.entries(schema.fields).map((field) => {
        if (field[1].fields) {
          acc[field[0]] = {};
          Object.entries(field[1].fields).map((subField) => {
            acc[field[0]][subField[0]] = subField[1].spec.default;
          });
        } else {
          acc[field[0]] = schema.fields[field[0]].spec.default;
        }
      });
      return acc;
    });

    return entries.reduce((acc, entry) => {
      return { ...acc, ...entry };
    }, {});
  });

  // Injects the starting step, child <form-steps> will use this to generate their ids
  const stepCounter = ref(0);
  provide("STEP_COUNTER", stepCounter);
  // Inject the live ref of the current index to child components
  // will be used to toggle each form-step visibility
  provide("CURRENT_STEP_INDEX", currentStepIdx);

  provide("CURRENT_STEP_SCHEMA", currentSchema);

  // if this is the last step
  const isLastStep = computed(() => {
    return currentStepIdx.value === stepCounter.value - 1;
  });

  // If the `previous` button should appear
  const hasPrevious = computed(() => {
    return currentStepIdx.value > 0 && canGoBack.value !== false;
  });

  // If the `skip` button should appear
  const hasSkip = computed(() => {
    return currentSchema.value?.spec?.meta?.skip;
  });

  const canGoBack = computed<boolean>(() => {
    return currentSchema.value?.spec?.meta?.canGoBack ?? true;
  });

  const stepKey = computed(() => {
    return currentSchema.value?.spec?.meta?.key || currentStepIdx.value + 1;
  });

  const hasContinueButton = computed(() => {
    return currentSchema.value?.spec?.meta?.continueButton !== false;
  });

  const continueButtonText = computed(() => {
    if (isLastStep.value) {
      return submitButtonText || t("submit");
    }

    return currentSchema.value?.spec?.meta?.continueButtonText || submitButtonText || t("continue");
  });

  const schemaFields = (schema) => {
    return Object.keys(schema.fields);
  };

  const currentStepIsEmpty = computed(() => {
    return Object.keys(values).length === 0;
  });

  function goToPrev() {
    if (currentSchema.value?.spec?.meta?.onPrev) {
      currentSchema.value.spec.meta.onPrev();
      return;
    }

    if (currentStepIdx.value === 0) {
      return;
    }
    for (let i = currentStepIdx.value - 1; i >= 0; i--) {
      if (!validationSchema[i]?.spec?.meta?.autoskip) {
        currentStepIdx.value = i;
        return;
      }
    }
  }

  const { values, handleSubmit, setValues, setFieldValue, resetForm, setErrors } = useForm({
    // vee-validate will be aware of computed schema changes
    validationSchema: currentSchema,
    // turn this on so each step values won't get removed when you move back or to the next step
    keepValuesOnUnmount: true,
    initialValues: initialValues.value,
  });

  const onSubmit = handleSubmit((v) => {
    if (!isLastStep.value) {
      for (let i = currentStepIdx.value + 1; i < validationSchema.length; i++) {
        if (!validationSchema[i]?.spec?.meta?.autoskip) {
          currentStepIdx.value = i;
          return;
        }
      }
    }
    if (isLastStep.value) {
      emit("submit", v);
    }
  });

  provide("SUBMIT_STEP", onSubmit);
  provide("FORM_VALUES", values);

  const isValid = useIsFormValid();
  const isDirty = useIsFormDirty();

  const canSubmit = computed(() => {
    return isValid.value && (isDirty.value || currentStepIsEmpty.value);
  });

  const goToLastStep = () => {
    currentStepIdx.value = stepCounter.value - 1;
  };

  const goToStepByKeyName = (key: string) => {
    for (let i = 0; i < validationSchema.length; i++) {
      if (validationSchema[i]?.spec?.meta?.key === key) {
        currentStepIdx.value = i;
        return;
      }
    }
  };

  const exit = () => {
    emit("exit");
  };

  const skip = () => {
    for (let i = currentStepIdx.value + 1; i < validationSchema.length; i++) {
      if (!validationSchema[i]?.spec?.meta?.autoskip) {
        currentStepIdx.value = i;
        return;
      }
    }
  };

  return {
    currentStepIdx,
    currentSchema,
    initialValues,
    stepCounter,
    isLastStep,
    hasPrevious,
    hasSkip,
    canGoBack,
    stepKey,
    schemaFields,
    hasContinueButton,
    continueButtonText,
    currentStepIsEmpty,
    goToPrev,
    form: { values, handleSubmit, setValues, setFieldValue },
    canSubmit,
    onSubmit,
    skip,
    exit,
    goToLastStep,
    goToStepByKeyName,
    resetForm,
    setErrors,
  };
};
