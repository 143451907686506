<template>
  <Button ref="button" class="dropdown-trigger" type="button" :size="size" :disabled="disabled">
    <template #prefix>
      <slot name="prefix" />
    </template>
    <div class="line-clamp-1 w-full truncate overflow-hidden text-left">
      <slot>{{ t("select") }}</slot>
    </div>
    <template #suffix>
      <Icon class="ml-auto" src="bold/caret_down" :size="ComponentSize.sm" />
    </template>
  </Button>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";
  import { computed, ref } from "vue";
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();

  withDefaults(
    defineProps<{
      size?: ButtonSize;
      disabled?: boolean;
    }>(),
    {
      size: ComponentSize.default,
      disabled: false,
    }
  );

  defineOptions({
    name: "DropdownTriggerButton",
  });

  const button = ref<HTMLElement | null>(null);
  const rawButton = computed(() => button.value?.el);

  defineExpose({
    el: rawButton,
  });
</script>
