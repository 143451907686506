<template>
  <Tooltip v-if="event" :bottom="bottom" :text="event.title">
    <div
      class="h-7 w-fit max-w-full rounded-r-[3px] px-2 py-1"
      :class="{
        'text-brand': !isSpecialEvent,
        'bg-positive-01 text-positive': event.event_code === 'DELIVERED',
        'bg-warning-01 text-warning': event.event_code === 'DEVIATION',
        'bg-destructive-01 text-destructive': event.event_code === 'EXCEPTION',
      }"
      :style="{
        background: background,
      }"
    >
      <p class="text-body-default-heavy flex w-full gap-1.5 truncate overflow-hidden text-nowrap text-ellipsis">
        <span v-if="timestamp">
          {{ eventTime }}
        </span>
        <span v-if="timestamp"> • </span>
        <span class="truncate">{{ titleWithOutHtml }}</span>
      </p>
    </div>
    <template #header>
      <p class="text-wrap">
        {{ `${eventDateTime} • ${titleWithOutHtml}` }}
      </p>
    </template>
  </Tooltip>

  <div
    v-else
    class="h-7 max-w-full rounded-r-[3px] px-2 py-1"
    :style="{
      background: background,
      filter: 'grayscale(1)',
    }"
  >
    <p class="text-disabled w-full truncate overflow-hidden text-[13px] font-medium text-nowrap text-ellipsis">
      {{ t("not_available") }}
    </p>
  </div>
</template>

<script setup lang="ts">
  import { DateTime } from "luxon";

  const props = withDefaults(
    defineProps<{
      event?: {
        created_at: string;
        title: string;
        event_code: string;
        event_at_utc?: string;
        event_at?: string;
      };
      timestamp?: boolean;
      bottom?: boolean;
    }>(),
    {
      timestamp: false,
      bottom: false,
      event: undefined,
    }
  );

  const { locale, t } = useI18n();

  const specialEvents = ["DELIVERED", "DEVIATION", "EXCEPTION"];

  const isSpecialEvent = computed(() => {
    if (!props.event) return false;

    return specialEvents.includes(props.event.event_code);
  });

  const background = computed(() => {
    if (!props.event || !isSpecialEvent.value)
      return "linear-gradient(90deg, rgba(205, 221, 246, 0.12) 40%, rgba(4, 86, 209, 0.12) 100%)";
    return "";
  });

  const eventTime = computed(() => {
    if (!props.event) return;

    return DateTime.fromISO(props.event.event_at_utc || props.event.event_at).toFormat("T");
  });

  const eventDateTime = computed(() => {
    if (!props.event) return;

    return DateTime.fromISO(props.event.event_at_utc || props.event.event_at)
      .setLocale(locale.value)
      .toFormat("d. LLL y, HH:mm");
  });

  const titleWithOutHtml = computed(() => {
    if (!props.event) return;

    return removeHtmlTags(props.event.title);
  });
</script>
