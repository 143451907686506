import EventBus from "@/plugins/eventbus";
import { inject, ref } from "vue";
import type { ISettingsFlow, ISettingsService } from "./types";

declare global {
  const $workspace_settings: ISettingsService;
}

export const open = ref(false);
export const flows = ref<ISettingsFlow[]>([]);
export const currentFlowKey: Ref<null | string> = ref(null);
export const currentHeader: Ref<null | string> = ref(null);
export const currentFlow = computed(() => {
  if (!currentFlowKey.value) return null;
  return flows.value.find((b) => b.key === currentFlowKey.value);
});

/**
 * A unique identifier to access the provided/injected method
 */
export const SettingsSymbol = Symbol();

/**
 * Composable which allows accessing the Settings service in Composition API
 * @returns Settings
 */
export function useSettings() {
  const Settings = inject(SettingsSymbol);
  if (!Settings) {
    throw new Error("No Settings provided!");
  }

  return Settings;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const SettingsService = {
  install: (app) => {
    const SettingsService: ISettingsService = {
      toggle: () => {
        EventBus.$emit("SETTINGS_TOGGLE");
      },
      open: () => {
        EventBus.$emit("SETTINGS_OPEN");
      },
      close: () => {
        EventBus.$emit("SETTINGS_CLOSE");
      },
      add: (b: ISettingsFlow) => {
        EventBus.$emit("SETTINGS_ADD", b);
      },
      remove: (b: string) => {
        EventBus.$emit("SETTINGS_REMOVE", b);
      },
      start: (b: string) => {
        EventBus.$emit("SETTINGS_START", b);
      },
    };
    app.config.globalProperties.$workspace_settings = SettingsService;
    if (typeof window !== "undefined") window.$workspace_settings = SettingsService;
    app.provide(SettingsSymbol, SettingsService);
  },
};
