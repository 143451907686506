<template>
  <div class="flex scroll-m-2.5 items-center gap-2.5 text-sm font-normal whitespace-nowrap select-none">
    <div class="flex w-full gap-2.5">
      <div class="w-full max-w-[157.2px]">
        <DropdownSelect
          class="w-full"
          :options="filterKeys"
          :model-value="filterKey"
          filterable
          :empty-text="t('filter.no_filters_available')"
          @select="updateFilterKey"
        />
      </div>

      <div class="w-full min-w-[157.2px]">
        <DropdownSelect
          v-model="filterOperator"
          class="w-full"
          :options="operatorSelectOption"
          filterable
          :empty-text="t('filter.no_operators_matching')"
        />
      </div>
    </div>
    <div
      class="flex items-center gap-2.5"
      :class="{
        'w-full': hasInputs,
      }"
    >
      <div v-if="hasInputs" ref="userInputWrapper" class="flex w-full items-center gap-2.5">
        <template v-if="isRangeOperator">
          <Datepicker v-if="inputType === 'date'" v-model="filterValue" class="filter-value w-full" :range="true" />
          <div v-else-if="inputType === 'time'" class="flex items-center">
            <InputTime
              class="filter-value w-full"
              :model-value="filterValue[0]"
              :max="filterValue[1]"
              :placeholder="t('value')"
              :minutes-interval="1"
              @update:model-value="(val) => (filterValue = [val, filterValue[1]])"
            />
            <span class="text-gray-400">-</span>
            <InputTime
              class="filter-value w-full"
              :model-value="filterValue[1]"
              :min="filterValue[0]"
              :placeholder="t('value')"
              :minutes-interval="1"
              @update:model-value="(val) => (filterValue = [filterValue[0], val])"
            />
          </div>
          <template v-else>
            <InputNumber
              class="filter-value w-full"
              :model-value="filterValue[0]"
              :max="filterValue[1]"
              :placeholder="t('value')"
              :step="0.01"
              @update:model-value="(val) => (filterValue = [val, filterValue[1]])"
            />
            <span class="text-gray-400">-</span>
            <InputNumber
              class="filter-value w-full"
              :model-value="filterValue[1]"
              :min="filterValue[0]"
              :placeholder="t('value')"
              :step="0.01"
              @update:model-value="(val) => (filterValue = [filterValue[0], val])"
            />
          </template>
        </template>
        <template v-else>
          <DropdownSelect
            v-if="isSelect"
            :key="`filterKey_${operatorAcceptsList}`"
            v-model="filterValue"
            class="filter-value w-full"
            :options="options"
            :placeholder="t('value')"
            :multiple="operatorAcceptsList"
            filterable
          />
          <template v-else>
            <Datepicker v-if="inputType === 'date'" v-model="filterValue" class="filter-value w-full" />
            <InputTime
              v-else-if="inputType === 'time'"
              v-model="filterValue"
              class="filter-value"
              :minutes-interval="1"
            />
            <ButtonGroup
              v-else-if="inputType === 'boolean'"
              v-model="filterValue"
              class="filter-value w-full"
              :buttons="booleanButtons"
            />

            <InputText
              v-else
              v-model="filterValue"
              class="filter-value w-full"
              :multi="operatorAcceptsList"
              :placeholder="t('value')"
            />
          </template>
        </template>
      </div>
      <div class="flex">
        <Button :variant="ButtonVariant.Tertiary" :size="ComponentSize.sm" type="button" @click="emits('remove')"
          ><Icon src="close"
        /></Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import _ from "lodash";
  import { computed } from "vue";
  import { useI18n } from "vue-i18n";

  defineOptions({
    name: "RuleFilterEntry",
  });

  const { t } = useI18n();

  const userInputWrapper = ref<HTMLElement | null>(null);

  const props = withDefaults(
    defineProps<{
      modelValue: Record<string, any>;
      filterKey: string;
    }>(),
    {
      modelValue: () => ({}),
    }
  );

  const booleanButtons = [
    {
      text: t("yes"),
      value: true,
    },
    {
      text: t("no"),
      value: false,
    },
  ];
  const description = inject<IRuleFilterDescription>("description");
  const filterKeys = computed(() => {
    return Object.entries(description.value || {})
      .map(([key, value]) => {
        return {
          ...value,
          label: value.label || key,
          value: key,
        };
      })
      .filter((val) => val);
  });

  const filterOperators = computed(() => {
    return _.get(description.value, `${props.filterKey}.operators`, []);
  });

  const operatorSelectOption = computed<{ label: string; value: string }[]>(() => {
    const fo = unref(filterOperators);

    return fo.map((o) => {
      return {
        label: t(`filter.operators.${o}`),
        value: o,
      };
    });
  });

  const filterOperator = computed({
    get: () => {
      return props.modelValue.operator || "eq";
    },
    set: (value) => {
      let mv = _.cloneDeep(props.modelValue) as Record<string, any>;

      //if new operator is range operator, make sure filterValue is an object
      if (checkIsRangeOperator(value)) {
        if (isRangeOperator.value) {
          //just swap the operator
          mv = {
            ...mv,
            operator: value,
          };
        } else {
          if (inputType.value === "time") {
            mv = {
              ...mv,
              operator: value,
              value: [formatDate(getDateRounded5Minute(), "HH:mm"), formatDate(getDateRounded5Minute(), "HH:mm")],
            };
          } else
            mv = {
              ...mv,
              operator: value,
              value: [value],
            };
        }
      } else {
        if (!checkIfHasInputs(value)) {
          mv = {
            ...mv,
            operator: value,
            value: 1,
          };
        } else if (checkIfOperatorAcceptsList(value) && !Array.isArray(mv.value)) {
          mv = {
            ...mv,
            operator: value,
            value: mv.value ? [mv.value] : [],
          };
        } else if (Array.isArray(mv.value)) {
          mv = {
            ...mv,
            operator: value,
            value: mv.value?.length > 0 ? mv.value[0] : null,
          };
        } else
          mv = {
            ...mv,
            operator: value,
          };
      }

      emits("update:modelValue", mv);
    },
  });

  const filterValue = computed({
    get: () => {
      let val = props.modelValue?.value;

      if (inputType.value === "date") {
        if (isRangeOperator.value)
          val = {
            from: _.get(val, "0") ? new Date(val[0]) : new Date(),
            to: _.get(val, "1") ? new Date(val[1]) : new Date(),
          };
        else if (inputType.value === "time") {
          if (isRangeOperator.value) val = Object.values(val).map((v) => (v ? getDateRounded5Minute(v) : null));
          else val = val ? getDateRounded5Minute(val) : getDateRounded5Minute();
        } else val = val ? getDateRounded5Minute(val) : getDateRounded5Minute();
      }

      return val;
    },
    set: (val: string | any[]) => {
      if (inputType.value === "date") {
        if (isRangeOperator.value) val = Object.values(val).map((v) => (v ? formatDate(v, "y-MM-dd") : null));
        else val = val ? formatDate(val, "y-MM-dd") : null;
      } else if (inputType.value === "time") {
        if (isRangeOperator.value) val = Object.values(val).map((v) => (v ? formatDate(v, "HH:mm") : null));
        else val = val ? formatDate(val, "HH:mm") : null;
      }
      const mv = _.cloneDeep(props.modelValue) as Record<string, any>;
      emits("update:modelValue", { ...mv, value: val });
    },
  });

  watch(
    () => filterOperator.value,
    () => {
      if (userInputWrapper.value) {
        //if filter has inputs, click on the first button
        if (isSelect.value) {
          userInputWrapper.value.querySelector("button")?.click();
        }
        //if filter is date, click on the input to open the datepicker
        else if (inputType.value === "date") {
          userInputWrapper.value.querySelector("input:not([type='hidden'])")?.click();
        }
        // else, focus on the input
        else {
          userInputWrapper.value.querySelector("input")?.focus();
        }
      }
    }
  );

  const isRangeOperator = computed(() => {
    return checkIsRangeOperator(filterOperator.value);
  });

  const checkIsRangeOperator = (operator) => {
    return operator === "between" || operator === "notBetween";
  };

  const checkIfHasInputs = (operator) => {
    return operator !== "null" && operator !== "notNull";
  };

  const hasInputs = computed(() => {
    return checkIfHasInputs(filterOperator.value);
  });

  const getOptions = (filterKey) => {
    return filterKeys.value.find((f) => f.value === filterKey)?.options;
  };

  const options = computed(() => {
    return Object.entries(getOptions(props.filterKey)).map(([key, value]) => {
      return {
        label: value,
        value: key,
        image: props.filterKey.includes("_country") ? `/images/flags/${key.toLowerCase()}.svg` : null,
      };
    });
  });

  const checkIsSelect = (filterKey: string) => {
    return getInputType(filterKey) === "select";
  };

  const isSelect = computed(() => {
    return checkIsSelect(props.filterKey);
  });

  const operatorAcceptsList = computed(() => {
    return checkIfOperatorAcceptsList(filterOperator.value);
  });

  const checkIfOperatorAcceptsList = (operator: string) => {
    return ["in", "notIn"].includes(operator);
  };

  const getInputType = (filterKey: string) => {
    return filterKeys.value.find((f) => f.value === filterKey)?.type;
  };

  const inputType = computed(() => {
    return getInputType(props.filterKey);
  });

  const updateFilterKey = (value) => {
    const filterkey = filterKeys.value.find((f) => f.value === value);
    //check if new filterkey has same operators as the old one or not and set the new value accordingly
    const operator = filterkey?.operators?.includes(filterOperator.value)
      ? filterOperator.value
      : filterkey?.operators[0];
    let newEmptyValue = null;
    if (checkIfOperatorAcceptsList(operator)) {
      newEmptyValue = [];
    } else if (checkIsRangeOperator(operator)) {
      if (filterkey?.type === "date")
        newEmptyValue = [formatDate(new Date(), "y-MM-dd"), formatDate(new Date(), "y-MM-dd")];
      else if (filterkey?.type === "time")
        newEmptyValue = [formatDate(getDateRounded5Minute(), "HH:mm"), formatDate(getDateRounded5Minute(), "HH:mm")];
      else newEmptyValue = [null, null];
    } else if (filterkey?.type === "date") {
      newEmptyValue = formatDate(new Date(), "y-MM-dd");
    } else if (filterkey?.type === "time") {
      newEmptyValue = formatDate(getDateRounded5Minute(), "HH:mm");
    }

    let mv = _.cloneDeep(props.modelValue) as Record<string, any>;
    //if new filterKey has options or not same type, set filterValue to null
    if (checkIsSelect(value) || inputType.value !== filterkey?.type) {
      mv = {
        type: filterkey?.type,
        key: value,
        value: newEmptyValue,
        operator,
      };
    }

    emits("update:filterKey", value, mv);
  };

  const emits = defineEmits(["remove", "update:filterKey", "update:modelValue"]);
</script>
