<template>
  <Modal
    ref="modal"
    v-model:open="open"
    :click-to-close="false"
    max-width="1020px"
    :content-padding="false"
    :center="true"
    anchor="top"
  >
    <div class="relative w-full">
      <FormShipment
        v-show="!shipmentCreated"
        ref="wizard"
        class="w-[90vw] max-w-[1020px]"
        :validation-schema="validationSchema"
        last-step-positive
        :continue-button-text="t('continue')"
        :submit-button-text="t('create_shipment')"
        :step-names="stepNames"
        :name="mode == 'return' ? t('new_return_shipment') : t('new_shipment')"
        header-type="create"
        @submit="(val) => submitForm(val)"
        @exit="close()"
      >
        <FormStep>
          <ReceiverSender
            :mode="mode == 'return' ? 'sender' : 'receiver'"
            :values="values"
            @update-city="(val) => setReceiverSenderCity(val)"
            @emit-contact="(val) => setReceiverSender(val)"
            @update-address="(val) => setReceiverSender(val, true)"
          />
        </FormStep>
        <FormStep>
          <div class="w-full overflow-hidden">
            <Dimensions />
          </div>
        </FormStep>
        <FormStep>
          <Carrier
            :values="values"
            @skip-insurance="(val) => skipInsurance(val)"
            @goback="changeStep(1)"
            @add-extra-services="(val) => addExtraServices(val)"
          />
        </FormStep>
        <FormStep>
          <ReturnProducts :values="values" />
        </FormStep>
        <FormStep>
          <ExtraServices
            :services="services"
            :values="values"
            :mode="mode"
            @update-validation="(val) => updateValidation(val)"
            @date-update="(val) => setNewServiceDate(val)"
            @update-select-value="(val) => setNewSelectValue(val)"
          />
        </FormStep>
        <FormStep>
          <OrderLines />
        </FormStep>

        <FormStep>
          <Insurance :values="values" />
        </FormStep>
        <FormStep>
          <Confirm
            :values="values"
            :services="services"
            :mode="mode"
            @change-step="(val) => changeStep(val)"
            @update-price="(val) => (billingInfo = val)"
          />
        </FormStep>
      </FormShipment>
      <Overlay v-if="loading" class="absolute inset-x-0 top-0 left-0 h-full w-full" />
      <div v-if="shipmentCreated" class="relative flex h-[70vh] w-[1020px]">
        <div class="absolute top-4 left-4 w-0 max-w-0 overflow-visible">
          <Button type="button" icon-only :variant="ButtonVariant.Tertiary" @click="close()">
            <Icon class="text-secondary cursor-pointer" src="close" />
          </Button>
        </div>
        <div class="mx-auto my-auto max-w-[540px] -translate-x-1 transform">
          <SuccessBlob />
          <p class="text-title-section text-secondary mb-2 w-full text-center">{{ t("shipment_is_created") }}</p>
          <div class="mb-2 flex items-center justify-center gap-2">
            <p class="text-body-default text-quarterary">{{ t("package_number") }}</p>
            <Button
              :variant="ButtonVariant.Tertiary"
              type="button"
              :size="ComponentSize.sm"
              @click="copyToClipboard(packageNumber || '')"
            >
              {{ packageNumber }}
              <Icon class="cursor-pointer" src="copy" />
            </Button>
          </div>
          <p class="text-body-lg text-tertiary mb-4">{{ t("shipment_is_created_desc") }}</p>
          <div class="border-00 flex w-full gap-2 rounded-xl border px-4 py-0.5">
            <Icon class="text-secondary mt-[5px]" src="file_text" />
            <p class="text-body-default-heavy text-secondary mt-1">Label.pdf</p>
            <div class="ml-auto">
              <a :href="returnLink()" target="_blank" rel="noopener noreferrer">
                <Button
                  class="!inline-block"
                  type="button"
                  :variant="ButtonVariant.Tertiary"
                  :size="ComponentSize.sm"
                  >{{ t("download") }}</Button
                >
              </a>
              <Button
                class="!inline-block"
                type="button"
                :variant="ButtonVariant.Tertiary"
                :size="ComponentSize.sm"
                @click="openPrinterModal"
                >{{ t("print_entity") }}</Button
              >
            </div>
          </div>
          <Button class="mt-4 w-full" type="button" :variant="ButtonVariant.Default" @click="close()">{{
            t("close")
          }}</Button>
          <Button class="mt-2 w-full" type="button" :variant="ButtonVariant.Secondary" @click="navigateToShipments()">{{
            t("go_to_entity", { entity: t("shipment").toLowerCase() })
          }}</Button>
          <Button class="mt-2 w-full" type="button" :variant="ButtonVariant.Tertiary" @click="createNewShipment()">{{
            t("create_another_shipment")
          }}</Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import EventBus from "@/plugins/eventbus";
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import { DateTime } from "luxon";

  import { loading, mode, open, return_shipment, wizard } from "../service";
  import Carrier from "./steps/carrier.vue";
  import Confirm from "./steps/confirm.vue";
  import Dimensions from "./steps/dimensions.vue";
  import ExtraServices from "./steps/extraServices.vue";
  import Insurance from "./steps/insurance.vue";
  import OrderLines from "./steps/orderLines.vue";
  import ReceiverSender from "./steps/receiver_sender.vue";
  import ReturnProducts from "./steps/returnProducts.vue";

  const { t } = useI18n();
  const yup = useYup();

  defineOptions({
    name: "NewShipmentModal",
  });

  const stepNames = computed(() => {
    const names = [];

    if (mode.value === "return") {
      names.push(t("set_sender"));
    } else {
      names.push(t("set_receiver"));
    }

    return [
      ...names,
      t("specify_dimensions"),
      t("select_carrier_product"),
      t("select_products"),
      t("select_extra_services"),
      t("describe_content"),
      t("add_insurance"),
      t("summary"),
    ];
  });

  const values = computed(() => wizard.value?.values);
  const { isDFM } = storeToRefs(useWorkspacesStore());

  const orderId = ref(null);

  const billingInfo = ref(null);

  const packageNumber = ref(null);

  const packageId = ref(null);

  const services = ref([]);

  const shipmentCreated = ref(false);

  const close = () => {
    return_shipment.value = null;
    open.value = false;
  };

  const changeStep = (val) => {
    wizard.value.currentStepIdx = val;
  };

  const editDuplicate = () => {
    changeStep(0);
    validationSchema.value[validationSchema.value.length - 1].spec.meta.onPrev = null;
  };

  const createNewShipment = () => {
    shipmentCreated.value = false;
  };

  const skipInsurance = (val = false) => {
    const insuranceStepIdx = validationSchema.value.findIndex((step) => step.spec.meta.key === "insurance");

    validationSchema.value[insuranceStepIdx].spec.meta.autoskip = val;
  };

  const validationSchema = ref<any[]>([
    yup
      .object({
        height: yup.number().required(),
        length: yup.number().required(),
        width: yup.number(),
        weight: yup.string().required(),
      })
      .meta({ continueButtonText: t("continue") }),
    yup
      .object({
        carrier_product_service: yup.string().required(),
        cpsUid: yup.string().required(),
        carrier_product_title: yup.string().required(),
        carrier_product_price: yup.string().required(),
        droppoint: yup
          .string()
          .when("carrier_product_service", {
            is: (val) => val && val.includes("droppoint"),
            then: (schema) => schema.required(),
          })
          .nullable(),
      })
      .meta({ continueButtonText: t("continue"), key: "carrier_product_service" }),
    yup
      .object({
        set_order_lines: yup.array(),
      })
      .meta({ continueButtonText: t("continue"), autoskip: true, key: "return_products" }),
    yup
      .object({
        services: yup.object({}),
      })
      .meta({ continueButtonText: t("continue"), skip: true, autoskip: false, key: "services" }),
    yup
      .object({
        order_lines: yup.array(),
        /*
      order_lines: yup.array().of(yup.object({
        qty: yup.number().required(),
        item_number: yup.string().required(),
        dangerous_goods_set: yup.boolean(),
        customs: yup.array().of(yup.object({
          description: yup.string(),
          total_price: yup.number(),
          currency_code: yup.string(),
          sender_tariff: yup.string(),
          origin_country: yup.string(),
          receiver_tariff: yup.string(),
          weight: yup.number(),
          unit: yup.string(),
        })).min(1),
      })).min(1).required(),*/
      })
      .meta({ continueButtonText: t("continue"), autoskip: true }),
    yup
      .object({
        insurance: yup.string(),
        insurance_coverage: yup.string(),
        insurance_price: yup.string(),
      })
      .meta({ continueButtonText: t("continue"), autoskip: true, key: "insurance" }),
    yup
      .object({
        accept_terms: yup.boolean().required().isTrue(),
        reference: yup.string().nullable(),
      })
      .meta({ onPrev: mode.value === "duplicate" ? editDuplicate : null }),
  ]);

  const { injectValidationFields } = useValidation();

  const updateValidation = (val: object) => {
    const servicesStepIdx = validationSchema.value.findIndex((step) => step.spec.meta.key === "services");
    injectValidationFields(val.fields, validationSchema.value, servicesStepIdx, "services");

    const hasNestedObjects = () => {
      const serviceFields = validationSchema.value[servicesStepIdx].fields.services.fields;
      for (const key in serviceFields) {
        if (typeof serviceFields[key] === "object" && Object.keys(serviceFields[key].fields).length > 0) {
          return true;
        }
      }
      return false;
    };

    if (hasNestedObjects()) {
      validationSchema.value[servicesStepIdx].spec.meta.skip = false;
    } else {
      validationSchema.value[servicesStepIdx].spec.meta.skip = true;
    }
  };

  const formatExtraservices = (values: object) => {
    const extraservices = {};
    extraservices.service_codes = [];
    Object.entries(values).forEach(([key, value]) => {
      if (!value.isSet) {
        return;
      }
      if (/^([A-Z0-9]{3})$/.test(key)) {
        extraservices.service_codes.push(key);
        return;
      }
      if (value.array) {
        extraservices[key] = value.array;
        return;
      } else {
        extraservices[key] = value;
        return;
      }
    });
    return extraservices;
  };

  onBeforeMount(() => {
    validationSchema.value[3].spec.meta.autoskip = true;
    if (mode.value === "return") {
      validationSchema.value.unshift(
        yup
          .object({
            save_sender: yup.boolean().nullable(),
            sender: yup.object().shape({
              name: yup.string().required().label(t("name")),
              email: yup.string().email().required().label(t("email")),
              phone: yup.string().required().label(t("phone_number")),
              phone_prefix: yup.number().required().label(t("phone_prefix")).default(45),
              street1: yup.string().required().label(t("street1")),
              street2: yup.string().nullable().label(t("street2")),
              zip_code: yup.string().required().label(t("zip_code")),
              city: yup.string().required().label(t("city")),
              country: yup.string().required().label(t("country")).default("DK"),
              business: yup.boolean().required().default(isDFM.value),
              attention: yup.string().nullable().label(t("attention_name")),
            }),
          })
          .meta({ continueButtonText: t("continue") })
      );
    } else {
      validationSchema.value.unshift(
        yup
          .object({
            save_receiver: yup.boolean().nullable(),
            receiver: yup.object().shape({
              name: yup.string().required().label(t("name")),
              email: yup.string().email().required().label(t("email")),
              phone: yup.string().required().label(t("phone_number")),
              phone_prefix: yup.number().required().label(t("phone_prefix")).default(45),
              street1: yup.string().required().label(t("street1")),
              street2: yup.string().nullable().label(t("street2")),
              zip_code: yup.string().required().label(t("zip_code")),
              city: yup.string().required().label(t("city")),
              country: yup.string().required().label(t("country")).default("DK"),
              business: yup.boolean().required().default(isDFM.value),
              company_name: yup.string().nullable().label(t("company_name")),
            }),
          })
          .meta({ continueButtonText: t("continue") })
      );
    }
    if (return_shipment.value?.order_lines && return_shipment.value?.order_lines.length > 0) {
      wizard.value?.setValues({
        order_lines: return_shipment.value.order_lines,
      });
      validationSchema.value[3].spec.meta.autoskip = false;
    } else {
      validationSchema.value[3].spec.meta.autoskip = true;
    }
  });

  const submitForm = async (formData) => {
    let pickup_datetime = null;
    if (formData.pickup_date) {
      const pickup_time = new Date(formData.pickup_time);
      const pickup_date = new Date(
        new Date(formData.pickup_date).setHours(pickup_time.getHours(), pickup_time.getMinutes())
      );
      pickup_datetime = DateTime.fromJSDate(pickup_date).toFormat("yyyy-MM-dd HH:mm:ss");
    }
    const services = await formatExtraservices(formData.services);
    loading.value = true;
    const cps = formData.carrier_product_service.split("_");
    const [carrier, carrier_product, ...carrier_service] = cps;

    const data = {
      shipment: {
        sender: {
          ...formData.sender,
          country: formData.sender.country.toUpperCase(),
          type: formData.sender.business ? "business" : "private",
          name: formData.sender.name,
          attention: formData.sender.attention,
        },
        receiver: {
          ...formData.receiver,
          country: formData.receiver.country.toUpperCase(),
          type: formData.receiver.business ? "business" : "private",
          name: formData.receiver.name,
          attention: formData.receiver.attention,
        },
        height: formData.height,
        length: formData.length,
        width: formData.width,
        weight: formData.weight,
        carrier,
        carrier_product,
        carrier_service: carrier_service.join("_"),
        servicepoint_id: formData.droppoint,
        insurance: formData.insurance,
        reference: formData.reference,
        order_lines: formData.set_order_lines || formData.order_lines || [],
        meta: {
          billing_info: billingInfo.value,
        },
        services: services,
        pickup_date: pickup_datetime,
        comment: formData.comment,
      },
      final_url: window.location.origin + "/redirects/ordersuccess",
    };
    homeFetch("shipments", {
      method: "POST",
      body: data,
    })
      .then((response) => {
        orderId.value = response.data?.order?.id ?? null;
        if (formData.save_receiver) {
          saveReceiver(formData.receiver);
        } else if (formData.save_sender) {
          saveReceiver(formData.sender);
        }
        if (response.data.processed) {
          loading.value = false;
          shipmentCreated.value = true;
          packageNumber.value = response.data.shipment.package_number;
          packageId.value = response.data.shipment.id;
          EventBus.$emit("NEWSHIPMENT_CREATED");
          wizard.value.currentStepIdx = 0;
          return_shipment.value = null;

          $toast.add({
            group: "shipment_created",
            title: t("shipment_created_with_id", { id: response.data.shipment.package_number }),
          });
        } else {
          handlePayment(response.data);
        }
      })
      .catch(() => {
        loading.value = false;
      });
  };

  const saveReceiver = (receiver) => {
    homeFetch("contacts", {
      method: "POST",
      body: {
        company_name: receiver.business ? receiver.name : null,
        name: receiver.business ? receiver.attention : receiver.name,
        email: receiver.email,
        phone: receiver.phone,
        phone_prefix: receiver.phone_prefix,
        street1: receiver.street1,
        street2: receiver.street2,
        zip_code: receiver.zip_code,
        city: receiver.city,
        country: receiver.country,
      },
    });
  };

  const setNewSelectValue = (object: object) => {
    wizard.value?.setValues({
      services: {
        [object.service.carrier_key]: {
          [object.field.key]: object.val,
        },
      },
    });
  };
  const setNewServiceDate = (object: object) => {
    wizard.value?.setValues({
      services: {
        [object.service.carrier_key]: {
          [object.extraField.key]: DateTime.fromJSDate(object.val).toFormat("yyyy-MM-dd T"),
        },
      },
    });
  };

  const addExtraServices = ({ services: s = [], reset = true }) => {
    if (reset) {
      wizard.value?.setFieldValue("services", {});
    }

    if (s.length !== 0) {
      services.value = s;
      validationSchema.value[4].spec.meta.autoskip = false;
    } else {
      validationSchema.value[4].spec.meta.autoskip = true;
    }
  };

  const handlePayment = (data: object) => {
    if (data.type !== "invoice" && data.type !== "balance") {
      window.open(data.payment_url);
      window.addEventListener("message", eventHandler);
    } else {
      processOrder(orderId.value);
    }
  };

  const eventHandler = (event) => {
    if (event.data === "payment:success") {
      processOrder(orderId.value);
      window.removeEventListener("message", eventHandler);
    }
  };

  const processOrder = (orderId) => {
    homeFetch(`shipments/${orderId}/process`, {
      method: "POST",
    })
      .then((response) => {
        loading.value = false;
        shipmentCreated.value = true;
        packageNumber.value = response.data.shipment.package_number;
        packageId.value = response.data.shipment.id;
        EventBus.$emit("NEWSHIPMENT_CREATED");
        wizard.value.currentStepIdx = 0;
        return_shipment.value = null;

        $toast.add({
          group: "shipment_created",
          title: t("shipment_created_with_id", { id: response.data.shipment.package_number }),
        });
      })
      .catch(() => {
        loading.value = false;
      });
  };

  const openPrinterModal = () => {
    $printing.add(packageNumber.value, null, null, null);
    $printing.open();
  };

  const setReceiverSenderCity = (city: string) => {
    wizard.value?.setFieldValue(`${[mode.value == "return" ? "sender" : "receiver"]}.city`, city);
  };

  const setReceiverSender = (contact: { data: Record<string, any> }, reverse = false) => {
    let key = mode.value == "return" ? "sender" : "receiver";
    if (reverse) {
      key = mode.value == "return" ? "receiver" : "sender";
    }
    wizard.value?.setValues({
      [key]: {
        name: contact.data.business ? contact.data.company_name : contact.data.name,
        attention: contact.data.business ? contact.data.attention : contact.data.name,
        email: contact.data.email,
        phone_prefix: contact.data.phone_prefix,
        phone: contact.data.phone,
        street1: contact.data.street1,
        street2: contact.data.street2,
        zip_code: contact.data.zip_code,
        city: contact.data.city,
        country: contact.data.country_code,
        business: contact.data.business,
      },
    });
  };

  const returnLink = () => {
    return useRouter().resolve({ name: "shipments-id-label", params: { id: packageNumber.value } })?.href;
  };
  const navigateToShipments = () => {
    const link = useRouter().resolve({ name: "shipments-id", params: { id: packageId.value } })?.href;
    window.open(link, "_blank");
  };

  defineExpose({
    addExtraServices,
  });
</script>
