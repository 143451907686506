<template>
  <div>
    <p v-if="label" class="text-body-sm-heavy text-secondary mb-1">
      {{ label }}
    </p>
    <div class="shadow-lvl-01 flex cursor-pointer items-stretch rounded-xl">
      <div
        v-for="(button, index) in buttons"
        :key="index"
        class="group border-01 flex flex-1 items-center justify-center gap-2 border px-5 transition-all duration-300"
        :class="{
          'rounded-l-xl': index === 0,
          '!border-l-0': index !== 0,
          'rounded-r-xl': index === buttons.length - 1,
          'h-11 p-3': size === ComponentSize.lg,
          'h-9 p-2.5': size === ComponentSize.default,
          'h-7 p-1.5': size === ComponentSize.sm,
          '!px-2': button.icon,
          '!bg-00': isSelectedButton(button) && !button.icon,
        }"
        @click="() => selectValue(button.value)"
      >
        <Transition name="checkmark-show">
          <div v-show="isSelectedButton(button)" class="flex items-center overflow-hidden">
            <Icon class="text-inherit" src="check" />
          </div>
        </Transition>
        <p class="text-body-default-heavy text-secondary group-hover:text-primary transition-all duration-300">
          {{ button.text }}
        </p>
        <Icon v-if="button.icon" class="text-inherit" :src="button.icon" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  export interface IButton {
    iconOnly?: string;
    text?: string;
    prefix?: string;
    suffix?: string;
    variant?: string;
    value?: string;
  }

  const props = withDefaults(
    defineProps<{
      buttons: any;
      size?: ButtonSize;
      name?: string;
      label?: string;
    }>(),
    {
      name: "",
      size: ComponentSize.default,
      label: undefined,
    }
  );
  const emit = defineEmits(["emitValue"]);
  const _modelValue = defineModel<string>();

  const isSelectedButton = (button: { value: any }): boolean => {
    return button.value === selectedValue.value;
  };

  const selectValue = (value: any) => {
    emit("emitValue", value);
    selectedValue.value = value;
  };

  const { value: selectedValue } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    validateOnMount: false,
    controlled: !!props.name,
  });
</script>
