import QueryString from "qs";
import type { RouterConfig } from "@nuxt/schema";

export default {
  stringifyQuery: (query: LocationQueryRaw) => {
    return QueryString.stringify(query, {
      encodeValuesOnly: true,
    });
  },
} satisfies RouterConfig;
