import { onMounted, onUnmounted, ref } from "vue";

const timesClicked = ref(0);

export default ({ key, clicksRequired = 2, callback = () => {} } = {}) => {
  onMounted(() => document.addEventListener("keyup", controlEvent));
  onUnmounted(() => document.removeEventListener("keyup", controlEvent));

  function controlEvent(event) {
    if (event.key === key) {
      timesClicked.value++;

      if (timesClicked.value >= clicksRequired) {
        callback();
        timesClicked.value = 0;
      }
      setTimeout(() => (timesClicked.value = 0), 250);
    }
  }
};
