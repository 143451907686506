<template>
  <Transition name="fade">
    <div
      v-if="open"
      class="bg-loader-overlay absolute top-0 left-0 z-50 flex h-dvh w-full overflow-hidden p-1"
      @click="emit('close')"
    >
      <div
        ref="modalContent"
        class="slide-in border-00 bg-surface-lvl-00 relative ml-auto flex w-full max-w-[450px] flex-col overflow-hidden rounded-xl border-2"
        @click.stop
      >
        <div class="flex p-5 pb-3">
          <p class="text-title-subsection text-secondary">{{ title }}</p>
          <Button class="ml-auto" type="button" icon-only :variant="ButtonVariant.Tertiary" @click="emit('close')">
            <Icon class="cursor-pointer" src="close" />
          </Button>
        </div>
        <slot />
        <div v-if="showFooter" class="surface-lvl-00 w-full">
          <div class="bg-surface-lvl-00 w-full p-5 pt-3">
            <Button
              class="w-full"
              :disabled="!submitReady"
              type="button"
              :variant="ButtonVariant.Primary"
              @click="emit('submit')"
              >{{ buttonText }}</Button
            >
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
  import { ButtonVariant } from "~/types/global";

  defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: "",
    },
    submitReady: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(["close", "submit"]);
</script>
