<template>
  <Input
    v-bind="$attrs"
    ref="input"
    tag="input"
    :model-value="inputValue"
    :error="error"
    :size="size"
    :name="name"
    :dark-bg="darkBg"
    :modifiers="allModifiers"
    :disabled="disabled"
    @update:model-value="(val) => setValue(val, false)"
    @blur="(evt) => handleBlur(evt, true)"
    @click="() => emit('click')"
  >
    <template v-for="(slot, index) of slotNames" :key="index" #[slot]>
      <slot :name="slot" />
    </template>
  </Input>
</template>

<script setup lang="ts">
  import { ComponentSize } from "@/types/global";
  import { computed, ref } from "vue";

  const slots = useSlots();
  const slotNames = Object.keys(slots) as "default"[];
  const _modelValue = defineModel<string>();

  const props = withDefaults(
    defineProps<{
      disabled?: boolean;
      selected?: boolean;
      active?: boolean;
      name?: string;
      error?: string;
      darkBg?: boolean;
      multi?: boolean;
      modifiers?: string[];
      size?: InputSize;
    }>(),
    {
      size: ComponentSize.default,
      disabled: false,
      selected: false,
      active: false,
      name: "",
      darkBg: false,
      multi: false,
      modifiers: () => [],
      error: undefined,
    }
  );

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    setValue,
  } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    validateOnMount: false,
    controlled: !!props.name,
  });

  const error = computed(() => {
    return props.error || errorMessage.value;
  });

  const input = ref<HTMLElement | null>(null);

  const el = computed(() => {
    return input.value?.input;
  });
  const wrapper = computed(() => {
    return input.value?.wrapper;
  });

  const allModifiers = computed(() => {
    const m = [...props.modifiers];
    if (props.multi) m.push("multi");

    return m;
  });

  const emit = defineEmits(["click"]);

  defineExpose({
    el,
    wrapper,
  });
</script>
