export default defineNuxtRouteMiddleware(async (to) => {
  const { fetchWorkspaces } = useWorkspacesStore();
  if (to.meta.layout == "auth") {
    return;
  }

  const nuxtApp = useNuxtApp();

  if (nuxtApp.isHydrating && nuxtApp.payload.serverRendered && !to.name?.toString().startsWith("auth-")) {
    await fetchWorkspaces(false);
  }
});
