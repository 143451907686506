<template>
  <ModalLegalBase ref="modal" v-model:open="open" :center="true" anchor="top">
    <div class="bg-surface-lvl-00 relative flex w-[90vw] max-w-[1152px] gap-2 rounded-lg p-1">
      <div class="h-[70dvh] w-full overflow-auto">
        <embed
          v-if="pendingDocument?.files"
          class="h-[inherit] rounded-lg"
          :src="pendingDocument?.urls[getLocale()]"
          type="application/pdf"
          width="100%"
        />
      </div>
      <aside class="flex max-w-86 flex-col gap-2 px-4 pt-3 pb-2">
        <h2 class="text-secondary text-title-body">{{ t("legal_documents.title") }}</h2>
        <p
          v-if="pendingDocument && pendingDocument.changes"
          class="text-secondary text-body-default"
          v-html="pendingDocument.changes[getLocale()]"
        />
        <div class="mt-auto flex flex-col gap-2 py-3">
          <div class="bg-00 rounded-xl px-4 py-3">
            <TickerCheckbox
              v-model="consentChecked"
              :label="t('i_hereby_accept_document', { document: pendingDocument?.readable_type })"
              dark-bg
              :error="error"
            />
          </div>
        </div>
        <footer class="pt-2">
          <Button :disabled="!consentChecked" :variant="ButtonVariant.Primary" class="w-fit" @click="updateConsent">
            {{ t("save_and_close") }}
          </Button>
        </footer>
      </aside>
      <Overlay v-if="loading" class="aboslute top-0 left-0 h-full w-full" />
    </div>
  </ModalLegalBase>
</template>

<script setup lang="ts">
  import { ButtonVariant } from "~/types/global";

  const props = defineProps<{
    pendingDocument: any;
    loading: boolean;
  }>();

  const emit = defineEmits(["updateAccept"]);

  const { locale, t } = useI18n();
  const open = ref(true);
  const error = ref(false);

  const consentChecked = ref(false);

  const getLocale = () => {
    if (locale.value.toLowerCase().includes("da")) {
      return "da";
    } else {
      return "en";
    }
  };

  const updateConsent = () => {
    if (!consentChecked.value) {
      error.value = true;
    } else {
      emit("updateAccept", props.pendingDocument);
      consentChecked.value = false;
    }
  };
</script>
