<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <FlowSection :title="t('user_settings.security.password.title')">
      <div class="flex w-full flex-col justify-between gap-3">
        <div class="flex flex-col gap-3">
          <InputText
            v-model="passwordResetBody.currentPassword"
            class="w-full"
            type="password"
            :label="t('current_password')"
            :placeholder="t('current_password')"
          />
          <InputPassword
            ref="passwordInput"
            v-model="passwordResetBody.newPassword"
            class="w-full"
            :label="t('new_password')"
            :placeholder="t('new_password')"
          />
          <InputText
            v-model="passwordResetBody.confirmPassword"
            type="password"
            :label="t('confirm_password')"
            :placeholder="t('confirm_password')"
            :error="passwordMatches ? '' : t('password_does_not_match')"
          />
          <div class="mt-4">
            <Button
              v-if="passwordMatches && isFullfilled && passwordResetBody.currentPassword"
              :loading="loading"
              :variant="ButtonVariant.Destructive"
              @click="updatePassword"
            >
              {{ t("update_password") }}
            </Button>
          </div>
        </div>
      </div>
    </FlowSection>
  </div>
  <NotificationWrapper />
</template>

<script setup lang="ts">
  import { ButtonVariant } from "~/types/global";
  import _ from "lodash";
  import type { ISettingsFlow } from "../types";

  import NotificationWrapper from "../components/NotificationWrapper.vue";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
      loading?: boolean;
    }>(),
    {
      currentHeader: null,
      loading: false,
    }
  );
  const { t } = useI18n();
  const passwordInput = ref(null);
  const passwordResetBody = ref({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const isFullfilled = computed(() => passwordInput.value?.isFullfilled);

  const updatePassword = () => {
    $confirm({
      message: t("confirm_update_password"),
      confirmText: t("update_password"),
      cancelText: t("cancel"),
      destructive: true,
      onCancel: () => {
        passwordResetBody.value = {
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        };
      },
      onConfirm: () => {
        setLoading(true);
        homeFetch("settings/profile", {
          method: "PUT",
          body: passwordResetBody.value,
        })
          .then(() => {
            $toast.add({
              title: t("password_updated"),
            });

            passwordResetBody.value = {
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            };
          })
          .finally(() => {
            setLoading(false);
          });
      },
    });
  };

  const passwordMatches = computed(() => {
    return (
      passwordResetBody.value.newPassword === passwordResetBody.value.confirmPassword &&
      passwordResetBody.value.newPassword.length > 0
    );
  });

  onMounted(() => {
    goToCurrentHeader();
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);
</script>

<script lang="ts">
  export const useSecuritySettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const icon = "regular/LockSimple";
    const title = t("user_settings.security.title");
    const pageTitle = t("user_settings.security.page_title");
    const key = "security";
    const headers = [t("user_settings.security.password.title")];

    return {
      icon,
      pageTitle,
      title,
      key,
      headers,
    };
  };
</script>
