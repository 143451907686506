import _ from "lodash";
import type { CursorPagination, PageData, Pagination, PaginationApiData } from "~/types/paginate";

export default ({ endpoint, viewKey }: { endpoint: string; viewKey?: string }) => {
  const {
    filtersWithoutEmpty: filters,
    search,
    isCursor,
    setIsCursor,
    page: currentPage,
    setPage,
    sort,
    query,
  } = useViewsStore(viewKey);

  const data = ref<PaginationApiData>({
    items: [],
    pagination: {
      total: 0,
      current_page: 1,
      cursor: "",
      per_page: 50,
      next_cursor: "",
      prev_cursor: "",
      is_cursor: true,
    },
    isCursor: isCursor.value,
  });

  const pending = ref(false);
  const error = ref(null);

  watch(
    [filters, search, sort, query],
    (newValues, oldValues) => {
      const [newFilters, newSearch, newSort, newQuery] = newValues;
      const [oldFilters, oldSearch, oldSort, oldQuery] = oldValues;
      if (
        _.isEqual(newFilters, oldFilters) &&
        newSearch === oldSearch &&
        newSort === oldSort &&
        _.isEqual(newQuery, oldQuery)
      )
        return;
      paginate();
    },
    { deep: true }
  );

  watch(currentPage, (page, oldPage) => {
    if (page != oldPage) {
      paginate();
    }
  });

  const paginate = () => {
    let url = `${endpoint}`;
    if (endpoint.indexOf("http") === 0) url = endpoint;

    const { filters, search, sort, query: q } = useViewsStore(viewKey);

    pending.value = true;
    const query: {
      page?: string;
      cursor?: string;
      filters?: Record<string, any>;
      search?: string;
      sort?: string[];
      query?: Record<string, any>;
    } = { filters: filters.value, search: search.value, sort: sort.value, ...q.value };

    if (isCursor.value) {
      query.cursor = currentPage.value;
    } else {
      query.page = currentPage.value;
    }

    return homeFetch(url, {
      query: query,
    })
      .then((response) => {
        if (response.data) {
          setIsCursor(checkIfPageDataIsCursor(response.data));

          data.value = {
            items: response.data.data,
            pagination: getPagination(response.data),
            isCursor: isCursor.value,
          };
        }

        return data.value;
      })
      .catch((e) => {
        error.value = e;
      })
      .finally(() => {
        pending.value = false;
      });
  };

  const getPagination = (pd: PageData) => {
    if (isCursor.value) {
      return getCursorData(pd);
    } else return getPaginationData(pd);
  };

  function getCursorData(pd: PageData): CursorPagination {
    const pagination: CursorPagination = {
      per_page: pd.per_page ? pd.per_page + "" : 50,
      prev_cursor: "",
      next_cursor: "",
      is_cursor: true,
    };

    if (pd) {
      pagination.next_cursor = getCursorFromPageData(pd, "next");
      pagination.prev_cursor = getCursorFromPageData(pd, "prev");
    }

    return pagination;
  }

  const checkIfPageDataIsCursor = (pd: PageData) => {
    return !!getCursorFromPageData(pd, "prev") || !!getCursorFromPageData(pd, "next");
  };

  const getCursorFromPageData = (pd: PageData, direction: "prev" | "next") => {
    if (direction === "prev") {
      if (pd.prev_cursor) return pd.prev_cursor;
      else if (pd.prev_page_url) {
        return extractKeyFromUrl(pd.prev_page_url, "cursor");
      }
    } else {
      if (pd.next_cursor) return pd.next_cursor;
      else if (pd.next_page_url) {
        return extractKeyFromUrl(pd.next_page_url, "cursor");
      }
    }

    return null;
  };

  provide("refreshPagination", paginate);

  return {
    data,
    endpoint,
    navigating: pending,
    setPageCursor: (val) => setPage(val, true),
    refresh: paginate,
    paginate,
  };
};

export const getPaginationData = (pd: PageData): Pagination => {
  const pagination: Pagination = {
    per_page: pd.per_page ? parseInt(pd.per_page.toString()) : 50,
    last_page: pd.last_page,
    total: pd.total,
    current_page: null,
    next_page: pd.next_page_url ? parseInt(extractKeyFromUrl(pd.next_page_url, "page")) : null,
    prev_page: pd.prev_page_url ? parseInt(extractKeyFromUrl(pd.prev_page_url, "page")) : null,
    is_cursor: false,
  };

  if (pd.from === 1 || !pd.from) pagination.current_page = 1;
  else pagination.current_page = pd.current_page;

  return pagination;
};

function extractKeyFromUrl(url: string, key: string): string {
  const param = url.split("?")[1];
  if (!param) return "";
  const params = new URLSearchParams(param);
  return params.get(key) || "";
}
