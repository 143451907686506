<template>
  <div class="group flex items-stretch">
    <Avatar
      v-for="(entity, idx) in visibleEntities"
      :key="idx"
      class="border-surface-lvl-00 rounded-lg border-2 group-hover:border-inherit [&_.shadow-avatar-border]:shadow-none"
      v-bind="props"
      :index="idx"
      :entity="entity"
      :class="{
        '-ml-3': idx !== 0,
      }"
    />
    <div v-if="numberOfHiddenEntities" class="h-full">
      <Tooltip class="first-child:flex first-child:h-full h-full">
        <div
          class="border-surface-lvl-00 bg-01 text-body-default-heavy text-tertiary -ml-3 flex h-full w-fit items-center justify-center rounded-lg border-2 px-0.5 group-hover:border-inherit"
        >
          +{{ numberOfHiddenEntities }} <Icon src="bold/CaretDown" :size="ComponentSize.sm" />
        </div>

        <template #body>
          <div class="text-body-sm text-surface-lvl-00 flex w-48 flex-col gap-1 py-1">
            {{ hiddenEntities.map((entity) => entity.name).join(", ") }}
          </div>
        </template>
      </Tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  export interface AvatarGroupProps {
    size?: "3xs" | "2xs" | "xs" | "sm" | "md" | "lg" | "xl";
    entities?: {
      name?: string;
      email?: string;
      image?: string | null | File;
    }[];
    useLetters?: boolean;
    color?: "tangerine" | "raspberry" | "lemon" | "grape" | "kiwi";
    randomColor?: boolean;
    maxLetters?: 1 | 2;
    maxAvatars?: number;
  }

  const props = withDefaults(defineProps<AvatarGroupProps>(), {
    maxAvatars: 4,
    size: "md",
    entities: undefined,
    color: undefined,
    maxLetters: undefined,
  });

  const visibleEntities = computed(() => props.entities?.slice(0, props.maxAvatars));
  const hiddenEntities = computed(() => props.entities?.slice(props.maxAvatars));
  const numberOfHiddenEntities = computed(() => hiddenEntities.value?.length);
</script>
