export default ({
  validationSchema,
  submitButtonText,
  emit,
}: {
  validationSchema: any[];
  submitButtonText?: string | null;
  emit: (event: "submit" | "exit" | "skip" | "next", ...args: any[]) => void;
}) => {
  const { t } = useI18n();
  const currentStepIdx = ref(0);

  // extracts the indivdual step schema
  const currentSchema = computed(() => {
    if (!validationSchema) {
      return {};
    }
    return validationSchema[currentStepIdx.value];
  });

  // The initial values for the all entries in validationSchema
  const initialValues = computed(() => {
    if (!validationSchema) {
      return {};
    }
    const entries = validationSchema.map((schema) => {
      return Object.keys(schema.fields).reduce((acc, key) => {
        acc[key] = schema.fields[key].spec.default;
        return acc;
      }, {});
    });

    return entries.reduce((acc, entry) => {
      return { ...acc, ...entry };
    }, {});
  });

  // Injects the starting step, child <form-steps> will use this to generate their ids
  const stepCounter = ref(0);
  provide("STEP_COUNTER", stepCounter);
  // Inject the live ref of the current index to child components
  // will be used to toggle each form-step visibility
  provide("CURRENT_STEP_INDEX", currentStepIdx);

  // if this is the last step
  const isLastStep = computed(() => {
    return currentStepIdx.value === stepCounter.value - 1;
  });

  // If the `previous` button should appear
  const hasPrevious = computed(() => {
    return currentStepIdx.value > 0 && canGoBack.value !== false;
  });

  // If the `skip` button should appear
  const hasSkip = computed(() => {
    return currentSchema.value?.spec?.meta?.skip;
  });

  const canGoBack = computed<boolean>(() => {
    return currentSchema.value?.spec?.meta?.canGoBack ?? true;
  });

  const stepKey = computed(() => {
    return currentSchema.value?.spec?.meta?.key || currentStepIdx.value + 1;
  });

  const hasContinueButton = computed(() => {
    return currentSchema.value?.spec?.meta?.continueButton !== false;
  });

  const continueButtonText = computed(() => {
    if (isLastStep.value) {
      return submitButtonText || t("submit");
    }

    return currentSchema.value?.spec?.meta?.continueButtonText || t("continue");
  });

  const schemaFields = (schema) => {
    return Object.keys(schema.fields);
  };

  const currentStepIsEmpty = computed(() => {
    return Object.keys(values).length === 0;
  });

  function goToPrev() {
    if (currentStepIdx.value === 0) {
      return;
    }
    for (let i = currentStepIdx.value - 1; i >= 0; i--) {
      if (!validationSchema[i]?.spec?.meta?.autoskip) {
        setStep(i);
        return;
      }
    }
  }

  const goToNext = () => {
    for (let i = currentStepIdx.value + 1; i < validationSchema.length; i++) {
      if (!validationSchema[i]?.spec?.meta?.autoskip) {
        setStep(i);
        emit("next", currentStepIdx.value);
        return;
      }
    }
  };

  const { values, handleSubmit, setValues, setFieldValue, resetForm, setTouched, meta } = useForm({
    // vee-validate will be aware of computed schema changes
    validationSchema: currentSchema,
    // turn this on so each step values won't get removed when you move back or to the next step
    keepValuesOnUnmount: true,
    initialValues: initialValues.value,
  });

  const onSubmit = handleSubmit((v) => {
    const canSubmit = validationSchema[currentStepIdx.value]?.spec?.meta?.submit;
    if (canSubmit) {
      emit("submit", v);
      return;
    }

    // Check each step in schema starting with currentStepIdx and go to the next that doesn't have autoskip set
    if (!isLastStep.value) {
      goToNext();
      return;
    }
    // Let the parent know the form was filled across all steps
    if (isLastStep.value) {
      emit("submit", v);
    }
  });

  provide("SUBMIT_STEP", onSubmit);
  provide("FORM_VALUES", values);
  provide("FORM_META", meta);
  provide("FORM_SET_VALUES", setValues);
  provide("FORM_SET_FIELD_VALUE", setFieldValue);
  provide("FORM_SCHEMA", currentSchema);

  const isValid = useIsFormValid();

  const canSubmit = computed(() => {
    return isValid.value || currentStepIsEmpty.value;
  });

  const goToLastStep = () => {
    currentStepIdx.value = stepCounter.value - 1;
  };

  const exit = () => {
    emit("exit");
  };

  const skip = () => {
    emit("skip");
  };

  const next = () => {
    emit("next");
  };

  const setStep = (step: number) => {
    currentStepIdx.value = step;
  };

  return {
    currentStepIdx,
    currentSchema,
    initialValues,
    stepCounter,
    isLastStep,
    hasPrevious,
    hasSkip,
    canGoBack,
    stepKey,
    schemaFields,
    hasContinueButton,
    continueButtonText,
    currentStepIsEmpty,
    goToPrev,
    goToNext,
    form: { values, handleSubmit, setValues, setFieldValue },
    canSubmit,
    onSubmit,
    skip,
    exit,
    next,
    goToLastStep,
    resetForm,
    setTouched,
    setStep,
  };
};
