export default (type: string) => {
  const { t } = useI18n();

  const focusEntity = (entity: { id: string | number }) => {
    if (!entity.id) return console.error("Entity has no id", entity);
    homeFetch(`/${type}/${entity.id}/focus`, {
      method: "POST",
    }).then(() => {
      $toast.add({
        title: t("entity_focused"),
      });
      entity.is_focused = true;
    });
  };

  const unfocusEntity = (entity: { id: string | number }) => {
    if (!entity.id) return console.error("Entity has no id", entity);

    $confirm({
      message: t("confirm_unfocus"),
      destructive: true,
      onConfirm: () => {
        homeFetch(`/${type}/${entity.id}/focus`, {
          method: "DELETE",
        }).then(() => {
          $toast.add({
            title: t("entity_unfocused"),
          });
          entity.is_focused = false;
        });
      },
    });
  };

  return {
    focusEntity,
    unfocusEntity,
  };
};
