export const getCountryName = (countryCode: string) => {
  const { $i18n } = useNuxtApp();

  if (!countryCode) {
    return $i18n.t("unknown");
  }

  if (countryCode === "GLOBAL") {
    return $i18n.t("global");
  }

  return $i18n.t(`countries.${countryCode.toUpperCase()}`);
};

export const getCountrySelectOptions = (locale: string = "da") => {
  const { $i18n } = useNuxtApp();

  const countries = ($i18n.messages.value as Record<string, Record<string, Record<string, string>>>)[locale].countries;
  return formatCountriesList(countries);
};

export const formatCountriesList = (countries: { [key: string]: string }): DropdownOption[] => {
  if (!countries) {
    return [];
  }

  const { $i18n } = useNuxtApp();

  let formattedCountries = Object.keys(countries).map((key) => ({
    label: $i18n.t(`countries.${key}`),
    value: key,
    image: `/images/flags/${key.toLowerCase()}.svg`,
  }));
  //sort the list with the da on top anf the scandic countries next
  formattedCountries = formattedCountries.sort(sortCountries);

  return formattedCountries;
};

const sortCountries = (a, b) => {
  if (a.value === "DK") return -1;
  if (b.value === "DK") return 1;
  if (a.value === "NO") return -1;
  if (b.value === "NO") return 1;
  if (a.value === "SE") return -1;
  if (b.value === "SE") return 1;
  if (a.value === "FI") return -1;
  if (b.value === "FI") return 1;

  return 0;
};
