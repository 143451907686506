<template>
  <div class="h-[67vh] w-full">
    <div class="mx-6 flex max-h-[550px] flex-col gap-0.5 overflow-y-auto">
      <div
        v-for="order_line in _order_lines_copy"
        :key="order_line.item_number"
        class="hover:bg-00 flex cursor-pointer items-center gap-3 rounded-xl p-3 transition-all duration-300"
        :class="set_order_lines.some((item) => item.item_number == order_line.item_number) ? 'shadow-inset-01-01' : ''"
        @click="selectOrderLine(order_line)"
      >
        <TickerCheckbox :active-value="set_order_lines.some((item) => item.item_number == order_line.item_number)" />
        <div
          v-if="!order_line.image_url"
          class="bg-01 flex h-16 w-16 items-center justify-center rounded-2xl bg-no-repeat"
        >
          <Icon class="text-disabled" :size="ComponentSize.xl" src="camera_slash" />
        </div>
        <div
          v-if="order_line.image_url"
          class="h-16 w-16 rounded-2xl bg-no-repeat"
          :style="`background-image: url('${order_line.image_url}'); background-position: center; background-size: contain;`"
        />
        <div>
          <p>{{ returnProductTitle(order_line) }}</p>
          <p>{{ order_line.item_number }}</p>
        </div>
        <DropdownSelect
          class="ml-auto"
          trigger-type="button"
          :options="returnQtyOptions(order_line)"
          pre-selected
          @click.prevent.stop
          @update:model-value="(val) => setQty(order_line, val)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  defineOptions({
    name: "ReturnProducts",
  });

  const props = withDefaults(
    defineProps<{
      values: any;
    }>(),
    {
      values: {},
    }
  );
  const _order_lines_copy = ref<any[]>([]);
  onMounted(() => {
    const orderlines = props.values.order_lines ? [...props.values.order_lines] : [];
    const orderlinesMerged = [];
    orderlines.forEach((line) => {
      const existing = orderlinesMerged.find((item) => item.item_number === line.item_number);
      if (existing) {
        existing.qty += line.qty;
      } else {
        orderlinesMerged.push({ ...line });
      }
    });
    _order_lines_copy.value = orderlinesMerged;
    if (!set_order_lines.value) {
      set_order_lines.value = [];
    }
  });
  const returnProductTitle = (order_line: any) => {
    if (order_line.title) {
      return order_line.title;
    }
    if (order_line.customs.description) {
      return order_line.customs.description;
    }
    if (order_line.customs[0]) {
      return order_line.customs[0].description;
    }
  };
  const returnQtyOptions = (order_line: any) => {
    const options = [];
    for (let i = 1; i <= order_line.qty; i++) {
      options.push({ label: i.toString(), value: i });
    }
    options.reverse();
    return options;
  };
  const selectOrderLine = (order_line: any) => {
    const index = set_order_lines.value.findIndex((line) => line.item_number === order_line.item_number);
    if (index > -1) {
      set_order_lines.value.splice(index, 1);
    } else {
      set_order_lines.value.push({ ...order_line });
    }
  };
  const setQty = (order_line: any, qty: number) => {
    const index = set_order_lines.value.findIndex((line) => line.item_number === order_line.item_number);
    if (index > -1) {
      set_order_lines.value[index].qty = qty;
    } else {
      return;
    }
  };
  const { value: set_order_lines } = useField(() => "set_order_lines", undefined, {
    syncVModel: true,
  });
</script>
