<template>
  <div class="relative h-[67vh] w-full overflow-y-scroll px-6">
    <div v-if="products.length !== 0" class="flex w-full gap-3 px-3 py-2">
      <div class="min-w-9" />
      <p class="text-body-sm text-quarterary w-full pr-4 text-left">{{ t("product") }}</p>
      <p v-if="!isDFM" class="text-body-sm text-quarterary ml-auto min-w-[80px] text-right">{{ t("price") }}</p>
    </div>
    <div class="max-h-[55vh] pb-5">
      <div v-for="(product, index) in products" :key="product.uid">
        <div
          class="hover:bg-00 relative mb-1 w-auto cursor-pointer rounded-lg transition-all duration-300"
          :class="cpsValue === product.uid ? 'bg-00' : ''"
          @click="selectCps(product)"
        >
          <div class="flex gap-3 px-3 py-2">
            <Avatar class="transition duration-300" :entity="{ image: getCarrierLogoUrl(product.carrier) }" size="xs" />
            <p class="text-body-lg-heavy text-secondary mt-1.5">{{ product.title }}</p>
            <p v-if="!isDFM" class="text-body-sm text-quarterary mt-2 ml-auto w-[80px] text-right">
              {{
                useFormatPrice(
                  useDecidePriceExclOrIncl({
                    price_excl_tax: product.sales_price_excl_vat,
                    price_incl_tax: product.sales_price_incl_vat,
                  }),
                  "auto",
                  product.currency
                )
              }}
            </p>
          </div>
          <div
            class="grid overflow-hidden transition-all duration-300"
            :class="
              isDroppoint(product.carrier_product_service) && droppoints.length !== 0 && cpsValue == product.uid
                ? 'grid-rows-1fr pb-3'
                : 'grid-rows-0'
            "
          >
            <div class="flex w-full gap-2.5 overflow-hidden pr-3 pl-[60px]" @click.stop="">
              <InputText v-model="zip_code" class="mb-1 w-[188px]" dark-bg :label="t('zip_code')" />
              <DropdownSelect
                v-model="droppoint"
                class="w-full"
                :options="droppoints"
                pre-selected
                :label="t('select_droppoint')"
                :placeholder="t('select_droppoint')"
              />
            </div>
          </div>
          <div
            class="grid overflow-hidden transition-all duration-300"
            :class="
              cpsValue == product.uid && product.carrier_product_service.split('_')[0] == 'dfm' && isDFM
                ? 'grid-rows-1fr pb-3'
                : 'grid-rows-0'
            "
          >
            <div class="overflow-hidden pr-3 pl-[60px]" @click.stop="">
              <label class="text-body-sm-heavy text-secondary text-left">{{ t("shipment_ready_for_pickup") }}</label>
              <div class="flex items-center gap-3">
                <div class="my-1 !max-w-[190px]">
                  <Datepicker
                    ref="calenderRef"
                    class="filter-value my-1 w-full"
                    name="pickup_date"
                    :presets="[]"
                    :bottom-buttons="false"
                    :requires-confirmation="false"
                    dark-bg
                    calendar-icon
                  />
                </div>
                <div class="flex items-center gap-1">
                  <p class="text-body-default text-secondary">Kl.</p>
                  <InputTime
                    class="filter-value my-1 w-full max-w-max"
                    value-as-date
                    :minutes-interval="15"
                    name="pickup_time"
                    dark-bg
                  />
                </div>
              </div>
            </div>
          </div>
          <Overlay
            v-if="isDroppoint(product.carrier_product_service) && loading && cpsValue == product.uid"
            class="absolute top-0 left-0 h-full w-full"
          />
        </div>
        <Divider v-if="index !== products.length - 1" class="mb-1" />
      </div>
    </div>
    <Overlay v-if="loading && droppoints.length == 0" class="absolute top-0 left-0 h-full w-full" />
    <EmptyCarrier v-if="products.length == 0 && !loading" @goback="emit('goback')" />
  </div>
</template>

<script setup lang="ts">
  import type { Product } from "~/types/shipment";

  import useCps from "../../composables/useCps";
  import { mode } from "../../service";
  import EmptyCarrier from "./emptyViews/emptyCarrier.vue";

  const { t } = useI18n();

  const emit = defineEmits(["addExtraServices", "goback", "skipInsurance"]);

  const props = defineProps<{
    values: any;
  }>();

  const zip_code = ref("");

  const receiver = computed(() => ({
    street1: props.values.receiver.street1,
    country: props.values.receiver.country,
    zip_code: props.values.receiver.zip_code,
    city: props.values.receiver.city,
    business: props.values.receiver.business,
  }));

  const { products, droppoints, loading, fetchProducts, fetchServicePoints, fetchExtraServices } = await useCps();

  const { accountIsBusiness, isDFM, accountLevel } = useWorkspacesStore();

  onMounted(() => {
    const sender = {
      street1: props.values.sender.street1,
      country: props.values.sender.country,
      zip_code: props.values.sender.zip_code,
      city: props.values.sender.city,
    };

    zip_code.value = receiver.value.zip_code;

    fetchProducts(
      sender,
      receiver.value,
      props.values.weight,
      props.values.height,
      props.values.width,
      props.values.length,
      { return: mode.value == "return" }
    ).then(() => {
      if (cpsValue && products.value && products.value.length > 0) {
        const selectedProduct = products.value.find((product: Product) => product.uid === cpsValue.value);
        if (!selectedProduct) return;

        selectCps(selectedProduct);
      }
    });
  });

  const debounceTimeout = ref(null);

  const debounce = async () => {
    if (debounceTimeout.value) clearTimeout(debounceTimeout.value);
    debounceTimeout.value = setTimeout(async () => {
      if (!cpsValue.value) return;
      const cps = products.value.find((product: Product) => product.uid === cpsValue.value);
      if (!cps) return;
      loading.value = true;
      const receiverObject = { ...receiver.value, zip_code: zip_code.value };

      await fetchServicePoints(cps.carrier, receiverObject, true);

      loading.value = false;
    }, 500); // delay for half second
  };

  watch(
    () => zip_code.value,
    (value) => {
      if (value && carrier_product_service.value && isDroppoint(carrier_product_service.value)) {
        debounce();
      }
    }
  );

  const selectCps = async (product: Product) => {
    const isSameCps = carrier_product_service.value === product.carrier_product_service;
    cpsValue.value = product.uid;
    carrier_product_service.value = product.carrier_product_service;
    carrier_product_title.value = product.title;
    carrier_product_price.value = accountIsBusiness ? product.sales_price_excl_vat : product.sales_price_incl_vat;
    if (isDroppoint(product.carrier_product_service)) {
      loading.value = true;
      await fetchServicePoints(product.carrier, receiver.value);
    }
    if (product.carrier === "dfm" && isDFM) {
      emit("skipInsurance", true);
    } else if (accountLevel === "ENTERPRISE") {
      emit("skipInsurance", true);
    } else {
      emit("skipInsurance", false);
    }
    if (product.carrier === "dfm") {
      if (product.service_codes.length > 0) {
        loading.value = true;
        const extraServices = await fetchExtraServices(
          product.carrier,
          product.carrier_product_service,
          product.service_codes
        );
        emit("addExtraServices", { services: extraServices.data, reset: !isSameCps });
      } else {
        emit("addExtraServices", { services: [], reset: !isSameCps });
      }
    } else {
      emit("addExtraServices", { services: [], reset: !isSameCps });
    }
  };

  const { value: cpsValue } = useField(() => "cpsUid", undefined, {
    syncVModel: true,
  });

  const { value: carrier_product_service } = useField(() => "carrier_product_service", undefined, {
    syncVModel: true,
  });

  const { value: droppoint } = useField(() => "droppoint", undefined, {
    syncVModel: true,
  });

  const { value: carrier_product_title } = useField(() => "carrier_product_title", undefined, {
    syncVModel: true,
  });

  const { value: carrier_product_price } = useField(() => "carrier_product_price", undefined, {
    syncVModel: true,
  });
</script>
