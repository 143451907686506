import EventBus from "@/plugins/eventbus";
import { inject, ref } from "vue";

declare global {
  const $loader: {
    toggle: (text?: string) => void;
    close: () => void;
    open: (text?: string) => void;
  };
}

export const loading = ref<boolean>(false);
export const loadingText = ref<string>("");

/**
 * A unique identifier to access the provided/injected method
 */
export const LoaderSymbol = Symbol();

/**
 * Composable which allows accessing the Loader service in Composition API
 * @returns Loader
 */
export function useLoader() {
  const Loader = inject(LoaderSymbol);
  if (!Loader) {
    throw new Error("No Loader provided!");
  }

  return Loader;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const LoaderService = {
  install: (app) => {
    const LoaderService = {
      toggle: (text?) => {
        EventBus.$emit("LOADER_TOGGLE", text);
      },
      close: () => {
        EventBus.$emit("LOADER_CLOSE");
      },
      open: (text?) => {
        EventBus.$emit("LOADER_OPEN", text);
      },
    };
    app.config.globalProperties.$Loader = LoaderService;
    if (typeof window !== "undefined") window.$loader = LoaderService;
    app.provide(LoaderSymbol, LoaderService);
  },
};
