<template>
  <div class="!relative">
    <Input
      v-bind="$attrs"
      ref="input"
      v-model.noWhitespace="inputValue"
      autocomplete="new-password"
      type="password"
      tag="input"
      :error="!hasFocus ? error : ''"
      :size="size"
      @blur="onBlur"
      @update:model-value="(val) => setValue(val, false)"
      @focus="onFocus"
      @click="() => emit('click')"
    />
    <div
      v-if="hasFocus"
      class="border-00 bg-surface-lvl-00 text-body-sm absolute z-10 mt-1 w-full rounded-xl border-2 px-4 py-3 shadow-[0px_4px_8px_0px_rgba(38,_40,_44,_0.1)]"
    >
      <div class="mb-3">
        <div class="text-primary mb-1 font-medium">{{ t("passwords_must_contain") }}</div>
        <ul
          class="text-secondary list-none space-y-1 pl-4 font-normal"
          :li="{
            '[&>li]:-ml-5 [&>li]:flex [&>li]:items-center [&>li]:gap-2 [&>li]:marker:aspect-square [&>li]:marker:text-xs [&>li]:before:inline-block [&>li]:before:h-4 [&>li]:before:w-4 [&>li]:before:bg-[url(assets/icons/dot.svg)] [&>li]:before:bg-cover [&>li]:before:bg-center [&>li]:before:bg-no-repeat [&>li]:before:content-[\'\']': true,
          }"
        >
          <li
            class="password-requirment"
            :class="{
              'text-positive before:bg-positive-list before:bg-blend-multiply': fulFills('length'),
            }"
          >
            {{ t("minimum_n_characters", { n: min }) }}
          </li>
          <li
            class="password-requirment"
            :class="{
              'text-positive before:bg-positive-list before:bg-blend-multiply': fulFills('case'),
            }"
          >
            {{ t("uppercase_and_lowercase") }}
          </li>
        </ul>
      </div>
      <div>
        <div>
          <div class="mb-2">{{ t("strength") }}</div>
          <PasswordStrength :password="inputValue as string" />
          <div class="text-quarterary mt-2">
            {{ t("avoid_passwords") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";
  import { computed, ref } from "vue";

  const props = withDefaults(
    defineProps<{
      size?: InputSize;
      disabled?: boolean;
      selected?: boolean;
      active?: boolean;
      name?: string;
      error?: string;
      min?: number;
    }>(),
    {
      size: ComponentSize.default,
      disabled: false,
      selected: false,
      active: false,
      name: "",
      min: 8,
      error: undefined,
    }
  );

  const { t } = useI18n();
  const _modelValue = defineModel<string>();

  const hasFocus = ref(false);

  const onFocus = () => {
    hasFocus.value = true;
  };

  const onBlur = (event: Event) => {
    hasFocus.value = false;
    handleBlur(event, true);
  };

  const {
    value: inputValue,
    errorMessage,
    handleBlur,
    setValue,
  } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  const fulFills = (rule) => {
    if (!inputValue.value || inputValue.value.length === 0) return false;

    switch (rule) {
      case "length":
        return inputValue.value.length >= props.min;
      case "case":
        return /[a-z]/.test(inputValue.value) && /[A-Z]/.test(inputValue.value);
      default:
        return false;
    }
  };

  const error = computed(() => {
    return props.error || errorMessage.value;
  });

  const input = ref<HTMLElement | null>(null);

  const el = computed(() => {
    return input.value?.input;
  });

  const emit = defineEmits(["click"]);

  const isFullfilled = computed(() => {
    return fulFills("length") && fulFills("case");
  });

  defineExpose({
    el,
    isFullfilled,
  });
</script>
