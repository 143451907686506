<template>
  <div class="grid h-full min-h-0 w-full grid-cols-2">
    <div class="flex h-full flex-col items-center">
      <div class="m-auto flex w-full flex-col gap-5 pr-6 pl-2">
        <TabLine
          class="mx-auto max-w-[203px]"
          :options="tabOptions"
          @update:model-value="(val) => (selectedView = val)"
        />
        <p class="text-title-section text-secondary m-auto w-full text-center">{{ t("portal_communication") }}</p>
        <InputText v-if="selectedView == 'form'" name="title" :label="t('portal_title')" />
        <ClientOnly>
          <Editor v-if="selectedView == 'form'" name="description" :label="t('portal_description')" />
        </ClientOnly>
        <InputText v-if="selectedView == 'orderGate'" name="settings.order_gate_title" :label="t('portal_title')" />
        <InputText
          v-if="selectedView == 'orderGate'"
          name="settings.order_gate_description"
          :label="t('portal_description')"
        />
      </div>
    </div>
    <div class="bg-00 relative h-full w-full rounded-l-lg">
      <div class="bg-00 absolute top-0 -right-4 h-full w-4" />
      <p class="text-body-sm-heavy text-quarterary p-6 pb-3">{{ t("preview") }}</p>
      <div class="border-00 absolute top-[55px] left-6 w-full rounded-xl border-2">
        <div class="px-6 py-4">
          <p class="text-body-sm-heavy text-primary">
            {{ values.settings.alternative_address.attention || setAddress?.name }}
          </p>
          <div class="mt-1 flex gap-6">
            <div>
              <p class="text-body-sm text-primary">
                {{ values.settings.alternative_address.street || setAddress?.street1 }}
              </p>
              <p class="text-body-sm text-primary">
                {{ values.settings.alternative_address.zip_code || setAddress?.zip_code }}
                {{ values.settings.alternative_address.city || setAddress?.city }}
              </p>
              <p class="text-body-sm text-primary">
                {{
                  getCountryName(values.settings.alternative_address.country) || getCountryName(setAddress?.country_iso)
                }}
              </p>
            </div>
            <div class="flex flex-col">
              <p class="text-body-sm text-primary mt-auto">
                {{ values.settings.alternative_address.telephone || setAddress?.phone }}
              </p>
              <p class="text-body-sm text-primary">
                {{ values.settings.alternative_address.notification_email || setAddress?.email }}
              </p>
            </div>
          </div>
        </div>
        <div class="bg-surface-lvl-00 h-full max-h-[500px] w-full overflow-auto rounded-b-xl p-6">
          <div v-if="selectedView == 'orderGate'">
            <p class="text-title-section text-primary mb-2">
              {{ values.settings.order_gate_title || t("order_gate_title") }}
            </p>
            <p class="text-body-l text-quarterary mb-6">
              {{ values.settings.order_gate_description || t("order_gate_description") }}
            </p>
            <InputText class="mb-3" placeholder="Ordrenummer" />
            <InputText placeholder="Email" />
          </div>
          <div v-if="selectedView == 'form'">
            <p class="text-title-section text-primary mb-2">{{ values.title }}</p>
            <div
              class="lookDescription text-body-lg text-quarterary mb-6 [&_ol]:list-inside [&_ol]:list-decimal [&_ol]:pl-3 [&_ul]:list-inside [&_ul]:list-disc [&_ul]:pl-3"
              v-html="values.description"
            />
            <p class="text-title-section text-primary mb-6">{{ t("fill_form") }}</p>
            <InputText label="label" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const { t } = useI18n();
  const props = defineProps<{
    schema: any;
    values: any;
    information: any;
  }>();

  const setAddress = ref(null);

  const selectedView = ref("form");

  const tabOptions = [
    { label: t("form"), value: "form" },
    { label: t("order_gate"), value: "orderGate" },
  ];

  onMounted(() => {
    for (const address of props.information.contact_info) {
      if (address.type === "SHIPPING") {
        setAddress.value = address;
      }
    }
  });
</script>
