import type { IAuthResponse, ICredentials } from "@/types/auth";

export default () => {
  const { clearWorkspaces } = useWorkspacesStore();
  const { clearServices } = useServicesStore();
  /**
   * Logs a user into the CoolRunner API using the provided credentials.
   * @param {ICredentials} credentials - An object containing a 'username' and 'password' property, which will be sent to the API for authentication.
   */
  const login = async (credentials: ICredentials): Promise<any> => {
    // A POST request is sent to the CoolRunner API's '/api/auth/login' endpoint with the provided credentials.
    // The response received from the server contains an access token and its expiration time.
    // This data is then used to set a new cookie called 'hr_token'.
    return $fetch("/api/auth/login", {
      method: "POST",
      body: credentials,
    }).then((response: IAuthResponse) => {
      setAuthCookie(response.access_token, response.expires_in);
    });
  };

  /**
   * Logs the user out by sending a POST request to the server and removing the 'hr_token' cookie.
   * @returns {Promise<any>} A Promise that resolves when the user is successfully logged out, or rejects with an error if there was a problem logging out.
   */
  const logout = (redirect = true): Promise<any> => {
    const { $i18n } = useNuxtApp();

    if (import.meta.client) $loader.open($i18n.t("logging_out"));

    // Send a POST request to the server to log the user out
    return $fetch("/api/auth/logout")
      .catch((error) => {
        console.error("Error logging out", error);
      })
      .finally(() => {
        clearCookies();

        if (!redirect) return;

        if (import.meta.client) $loader.close();
        const route = useRoute();
        // If the user is not already on the login page, redirect them to the login page
        useRouter().replace({ name: "auth-login", query: route.query });
      });
  };

  const resetPassword = async (
    token: string,
    body: {
      password: string;
      password_confirmation: string;
      name?: string;
      toc?: boolean;
    }
  ): Promise<any> => {
    const isCreate = !!body.name;
    let url = `auth/reset-password/${token}/`;
    if (isCreate) {
      url += "new-user";
    }

    return homeFetch(url, {
      method: "POST",
      body: body,
    });
  };

  const requestResetPassword = async (email: string): Promise<any> => {
    //const get current url without patg
    const form_url = window.location.toString().replace(
      window.location.pathname,
      useRouter().resolve({
        name: "auth-reset-process",
        query: {
          token: ":token",
        },
      }).href
    );

    const redirect_url = window.location.toString().replace(
      window.location.pathname,
      useRouter().resolve({
        name: "auth-login",
      }).href
    );

    return homeFetch("auth/reset-password/", {
      method: "POST",
      body: {
        email,
        form_url,
        title: "password",
        type: "password",
        origin: "homerunnner_platform",
        redirect_url,
      },
    });
  };

  /**
   * Logs the user out if they are logged in, then logs them in as a superuser by setting the 'hr_token' cookie.
   * @param {string} token - The authentication token to set as the cookie value.
   * @param {number} expiration - The number of seconds until the cookie expires.
   */
  const superuserLogin = async ({ token, expiration }: { token: string; expiration: number }): Promise<any> => {
    // Check if the user is logged in
    if (isUserLoggedIn.value) {
      // If the user is logged in, log them out
      await logout(true);
    }

    // Set the 'hr_token' cookie to the provided token and expiration values
    setAuthCookie(token, expiration);
  };

  const getLegalDocuments = async (): Promise<any> => {
    return homeFetch("customers/legal-documents").then((response) => {
      return response.data;
    });
  };

  /**
   * Returns the value of the 'hr_token' cookie, which contains the user's access token.
   * @returns {string | null} The value of the 'hr_token' cookie, or null if cookie is not found.
   */
  const getAuthCookie = (): Ref<string | null> => {
    return useCookie("hr_token");
  };

  const getUserCookie = (): Ref<string | null> => {
    return useCookie("hr_user_id");
  };

  const getAuthCookieExpiresIn = (): number | null => {
    const expires = useCookie("hr_token_expire").value;
    if (!expires) {
      return null;
    }

    const expirationDate = new Date(expires).getTime();

    //get milliseconds until expiration
    return expirationDate - Date.now();
  };

  /**
   * Sets the 'hr_token' cookie, which contains the user's access token.
   * @param {string} token The access token to set in the cookie.
   * @param {number} expiration The expiration time of the cookie, in miliseconds.
   * @returns {void}
   */
  const setAuthCookie = (token: string, expiration: number): void => {
    // Set the 'hr_token' cookie with the given token and expiration
    setCookie({
      name: "hr_token",
      value: token,
      expiration: expiration,
    });
    setCookie({
      name: "hr_token_expire",
      value: new Date(Date.now() + expiration).toUTCString(),
      expiration: expiration,
    });

    //Set LEGACY cookie
    setCookie({
      name: "cr_access_token",
      value: JSON.stringify({
        token,
        expires_in: expiration,
        expires: Date.now() + expiration,
      }),
      expiration: expiration,
    });
  };

  /**
   * Removes four cookies related to the user's account.
   *
   * @returns {void}
   */
  const clearCookies = (): void => {
    // Remove the 'hr_token' cookie using a helper function.
    removeCookie("hr_token");
    // Remove the 'hr_account_id' cookie using a helper function.
    removeCookie("hr_account_id");
    // Remove the 'workspaces' cookie using a helper function.
    clearWorkspaces();
    // Remove the 'hr_token_expire' cookie using a helper function.
    removeCookie("hr_token_expire");
    // Remove the 'cr_access_token' cookie using a helper function.
    removeCookie("cr_access_token");
    clearServices();
    // Remove the 'external_access' cookie using a helper function.
    removeCookie("external_access");
    // Remove the 'hr_user_id' cookie using a helper function.
    removeCookie("hr_user_id");
  };

  const isUserLoggedIn = computed(() => {
    // Check if the 'hr_token' cookie exists
    // If it does, return true (user is logged in) - otherwise, return false (user is not logged in)
    return !!getAuthCookie().value && !!getUserCookie().value;
  });

  const isExternalAccess = computed(() => {
    return !!useCookie("external_access").value;
  });

  return {
    login,
    logout,
    isUserLoggedIn,
    superuserLogin,
    getLegalDocuments,
    getAuthCookie,
    clearCookies,
    setAuthCookie,
    getAuthCookieExpiresIn,
    resetPassword,
    requestResetPassword,
    isExternalAccess,
  };
};
