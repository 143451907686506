import { defineStore } from "pinia";
import type { IOptions } from "@/types/options";

export const useOptionsStore = defineStore(
  "Options",
  () => {
    const options = ref<Partial<IOptions>>({});

    const setOptions = (o: IOptions) => {
      options.value = o;
    };

    const setOption = (name: keyof IOptions, value: any) => {
      options.value[name] = value;
    };

    const getOption = (name: keyof IOptions) => {
      return options.value[name];
    };

    const fetchOptions = () => {
      return Promise.resolve();
      return homeFetch("settings/options").then((response) => {
        setOptions(response.data);
      });
    };

    const fetchOption = (name: keyof IOptions) => {
      return homeFetch(`option/${name}`).then((response) => {
        setOption(name, response.data);
      });
    };

    const updateOptions = (newOptions: Partial<IOptions>) => {
      return homeFetch("user/options", {
        method: "POST",
        body: JSON.stringify(newOptions),
      }).then((response) => {
        setOptions(response.data);
      });
    };

    const updateOption = (name: keyof IOptions, value: any) => {
      return homeFetch(`user/option/${name}`, {
        method: "POST",
        body: JSON.stringify({ value }),
      }).then((response) => {
        setOption(name, response.data);
      });
    };

    const hasWorkspaceSidebar = computed(() => {
      return options.value.workspace_sidebar;
    });

    return {
      options,
      setOptions,
      setOption,
      getOption,
      fetchOptions,
      fetchOption,
      updateOptions,
      updateOption,
      hasWorkspaceSidebar,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
    },
  }
);
