<template>
  <Modal
    ref="modal"
    v-model:open="noTransferOpen"
    :click-to-close="false"
    max-width="1020px"
    :content-padding="false"
    :center="true"
    anchor="top"
  >
    <div class="relative w-[342px]">
      <div class="p-4">
        <p class="text-body-default text-secondary mb-3">{{ t("your_profile_requires_transfer") }}</p>
        <a
          href="https://knowledge.homerunner.com/categories/freight/afhentning-med-danske-fragtmaend/aftale-med-danske-fragtmaend"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button class="gap-1" :variant="ButtonVariant.SecondaryLink">
            <template #prefix>
              <Icon class="text-quarterary" src="bold/info" />
            </template>
            <p>{{ t("read_more_transfer") }}</p>
          </Button>
        </a>
      </div>
      <div class="p-4">
        <Button class="mb-2 w-full" :variant="ButtonVariant.Primary" @click="goToTransfer">{{
          t("book_a_transfer")
        }}</Button>
        <Button class="w-full" :variant="ButtonVariant.Default" @click="closeModal()">{{ t("cancel") }}</Button>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import EventBus from "@/plugins/eventbus";
  import { ButtonVariant } from "~/types/global";

  import { noTransferOpen } from "../service";

  const { t } = useI18n();
  const router = useRouter();

  const goToTransfer = () => {
    noTransferOpen.value = false;
    EventBus.$emit("NEWSHIPMENT_CLOSE");
    router.push({ path: "sortings", query: { openTransfer: "true" } });
  };

  const closeModal = () => {
    noTransferOpen.value = false;
    EventBus.$emit("NEWSHIPMENT_CLOSE");
  };
</script>
