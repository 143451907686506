<template>
  <Modal
    ref="modal"
    :max-width="maxWidth"
    min-width="fit-content"
    :calculate-position="calculatePosition"
    :overlay="overlay"
    :content-padding="false"
  >
    <slot />
  </Modal>
</template>

<script setup lang="ts">
  import { computed, ref } from "vue";

  defineOptions({
    name: "Dropdown",
  });

  withDefaults(
    defineProps<{
      anchor?: "top-left" | "top-right" | "bottom-left" | "bottom-right" | "top" | "bottom";
      maxWidth?: string;
      calculatePosition?: () => void;
      overlay?: boolean;
    }>(),
    {
      anchor: "top-left",
      calculatePosition: () => {},
      overlay: false,
      maxWidth: undefined,
    }
  );

  const modal = ref<HTMLElement | null>(null);

  const height = computed(() => {
    if (!modal.value) return 0;
    return modal.value?.height;
  });

  const width = computed(() => {
    if (!modal.value) return 0;
    return modal.value?.width;
  });

  const openingDuration = computed(() => {
    if (!modal.value) return 0;
    return modal.value?.openingDuration;
  });

  const setStyleProperty = (property: string, value: string) => {
    modal.value?.setStyleProperty(property, value);
  };

  const updateHeight = () => {
    modal.value?.updateHeight();
  };

  defineExpose({
    dropdown: modal,
    height,
    width,
    setStyleProperty,
    updateHeight,
    openingDuration,
  });
</script>
