import { DateTime } from "luxon";

export const useGetDOTTimespan = (type: "DOT1" | "DOT2" | "DOT3", time: string | Date) => {
  return useGetDOTFromTime(type, time) + " - " + useGetDOTToTime(type, time);
};

export const useGetDOTFromTime = (type: "DOT1" | "DOT2" | "DOT3", time: string | Date) => {
  if (time) {
    const datetime = typeof time == "string" ? DateTime.fromISO(time) : DateTime.fromJSDate(time);

    if (type == "DOT2") {
      return datetime.minus({ minutes: 60 }).toFormat("HH:mm");
    }
    if (type == "DOT3") {
      return datetime.minus({ minutes: 15 }).toFormat("HH:mm");
    }
  }
  return "";
};

export const useGetDOTToTime = (type: "DOT1" | "DOT2" | "DOT3", time: string | Date) => {
  if (time) {
    const datetime = typeof time == "string" ? DateTime.fromISO(time) : DateTime.fromJSDate(time);

    if (type == "DOT2") {
      return datetime.plus({ minutes: 60 }).toFormat("HH:mm");
    }
    if (type == "DOT3") {
      return datetime.plus({ minutes: 15 }).toFormat("HH:mm");
    }
  }
  return "";
};
