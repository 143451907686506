<template>
  <div class="w-full">
    <p v-if="label" class="mb-3">{{ label }}</p>
    <div class="flex flex-col gap-3">
      <div
        v-for="(option, index) in values"
        :key="index"
        class="list-select-option flex cursor-pointer text-left transition duration-300"
        :class="{
          'shadow-inset-01-01': isSelected(option) && !simple,
          'shadow-inset-00-01': !isSelected(option) && !simple,
          'gap-4 rounded-xl px-4 py-3': !simple,
          'gap-2.5': simple,
        }"
        @click="() => setValue(option)"
      >
        <TickerRadio
          v-if="ticker && !multi"
          class="pointer-events-none mt-px cursor-pointer"
          :class="{
            'my-auto': radioPosition == 'center',
            'mb-auto': radioPosition == 'top',
            'mt-auto': radioPosition == 'bottom',
          }"
          :selected="isSelected(option)"
        />

        <TickerCheckbox
          v-if="ticker && multi"
          class="pointer-events-none mt-px cursor-pointer"
          :class="{
            'my-auto': radioPosition == 'center',
            'mb-auto': radioPosition == 'top',
            'mt-auto': radioPosition == 'bottom',
          }"
          :model-value="isSelected(option)"
        />

        <slot name="avatar" :value="option" />
        <slot name="title" :value="option" />
        <slot name="value" :value="option" />
      </div>
      <Divider v-if="simple && index !== values.length - 1" class="!my-3" />
    </div>
  </div>
</template>

<script setup lang="ts">
  const emit = defineEmits(["update:modelValue", "fullOption"]);
  const props = withDefaults(
    defineProps<{
      values: any[] | Record<string, any>;
      name?: string;
      valueKey?: string;
      radioPosition?: string;
      simple?: boolean;
      label?: string;
      ticker?: boolean;
      multi?: boolean;
    }>(),
    {
      values: () => [],
      name: "",
      valueKey: "id",
      radioPosition: "center",
      simple: false,
      label: "",
      ticker: true,
      multi: false,
    }
  );

  const _modelValue = defineModel<boolean>();

  const setValue = (option: object) => {
    if (!props.multi) {
      if (value.value === option[props.valueKey]) {
        emit("fullOption", option);
        emit("update:modelValue", null);
        setFormValue(null);
      } else {
        setFormValue(option[props.valueKey]);
        emit("fullOption", option);
        emit("update:modelValue", option[props.valueKey]);
      }
    } else {
      const selectedValues = value.value || [];
      const index = selectedValues.indexOf(option[props.valueKey]);
      if (index === -1) {
        selectedValues.push(option[props.valueKey]);
      } else {
        selectedValues.splice(index, 1);
      }
      setFormValue(selectedValues);
      emit("update:modelValue", selectedValues);
    }
  };

  const isSelected = (option: object) => {
    if (!props.multi) {
      return value.value === option[props.valueKey];
    }

    const selectedValues = value.value || [];

    return selectedValues?.includes(option[props.valueKey]);
  };

  const { value, setValue: setFormValue } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });
</script>
