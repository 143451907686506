export enum SearchResultType {
  document = "document",
  return = "return",
  shipment = "shipment",
  ticket = "ticket",
  transfer = "transfer",
}
export enum SearchResultCategory {
  documents = "documents",
  recents = "recents",
  returns = "returns",
  shipments = "shipments",
  tickets = "tickets",
  transfers = "transfers",
}

export interface SearchResult {
  returns: SearchResultItem[];
  shipments: SearchResultItem[];
  tickets: SearchResultItem[];
}

export interface SearchResultItem {
  [key: string]: any;
  carrier: string;
  highlight: Highlight;
  id: number;
  labelless_code: null | string;
  package_number: string;
  pallet_number: string[] | null;
  receiver: Receiver;
  reference?: string;
  tickets?: number[];
  type: SearchResultType;
}

export interface Receiver {
  email: string;
  name: string;
  phone: string;
  street1: string;
}

export interface Highlight {
  [key: string]: string[];
}

export interface Search {
  [key: string]: any;
}
