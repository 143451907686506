<template>
  <div class="w-full">
    <InputText
      v-if="type == 'text'"
      class="w-full"
      :error="localAttribute.error"
      :model-value="localAttribute.value"
      @update:model-value="(val) => emit('emitValue', val)"
    />
    <InputNumber
      v-if="type == 'number'"
      class="w-full"
      :error="localAttribute.error"
      :model-value="localAttribute.value"
      @update:model-value="(val) => emit('emitValue', val)"
    />
    <InputParagraph
      v-if="type == 'textarea'"
      :error="localAttribute.error"
      :model-value="localAttribute.value"
      @update:model-value="(val) => emit('emitValue', val)"
    />
    <FileUpload
      v-if="type == 'file' || type == 'dor' || type == 'file_with_pdf'"
      :error="localAttribute.error"
      :model-value="localAttribute.value"
      :index="index"
      :shipment-id="shipmentId"
      s3
      :message="t('pdf_size')"
      @update:model-value="(val) => emit('emitValue', val)"
    />
    <FileUpload
      v-if="type == 'file[]'"
      :error="localAttribute.error"
      :model-value="localAttribute.value"
      :index="index"
      image-preview
      multiple
      :shipment-id="shipmentId"
      s3
      @update:model-value="(val) => emit('emitValue', val)"
    />
    <DropdownSelect
      v-if="type == 'select'"
      v-model="localAttribute.value"
      class="w-full"
      :error="localAttribute.error"
      :options="localAttribute.options"
      :trigger-text="t('select')"
      trigger-type="button"
      @update:model-value="(val) => emit('emitValue', val)"
    />
    <TickerCheckbox
      v-if="type == 'boolean'"
      v-model="localAttribute.value"
      class="mt-1"
      :error="localAttribute.error"
      @update:model-value="(val) => emit('emitValue', val)"
      >{{ localAttribute.value ? t("yes") : t("no") }}</TickerCheckbox
    >
  </div>
</template>

<script setup lang="ts">
  const { t } = useI18n();

  const emit = defineEmits(["emitValue"]);

  const props = defineProps<{
    type: string;
    name?: string;
    shipmentId: number;
    index: number;
    attribute: any;
  }>();

  const attribute = computed(() => props.attribute);

  const localAttribute = ref<any>();

  watch(
    attribute,
    (attr) => {
      localAttribute.value = { ...attr };
    },
    { immediate: true, deep: true }
  );
</script>
