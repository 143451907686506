<template>
  <div class="realtive m-auto flex min-h-0 w-full max-w-[556px] flex-col gap-5 overflow-auto rounded-xl px-2 pb-3">
    <p class="text-title-section text-secondary w-full text-center">{{ t("set_return_portal_legacy") }}</p>
    <DropdownSelect
      v-if="countries"
      v-model="country"
      :options="countries"
      size="large"
      trigger-type="button"
      :label="t('country')"
    />
    <DropdownSelect
      v-if="sortedProducts"
      v-model="product"
      :options="sortedProducts"
      size="large"
      :label="t('product')"
      trigger-type="button"
    />
    <div>
      <label class="text-body-sm-heavy">{{ t("return_portal_price") }}</label>
      <div class="mt-1 flex items-center gap-2">
        <InputText class="!max-w-[100px]" :size="ComponentSize.lg" name="price" />
        <DropdownSelectCurrency v-model="currency" class="!max-w-[100px]" size="large" trigger-type="button" />
      </div>
    </div>
    <Overlay v-if="loading" class="absolute top-0 left-0 h-full w-full" />
  </div>
</template>
<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const { t } = useI18n();
  const loading = ref(false);
  const products = ref([]);
  const countries = ref(null);

  const props = defineProps<{
    schema: any;
    values: any;
  }>();

  onMounted(async () => {
    await fetchCountries();
    await fetchProducts();
  });

  const fetchProducts = () => {
    loading.value = true;
    homeFetch(`/workspace/return-portals/products`)
      .then((r) => {
        products.value = r.data;
      })
      .catch(() => {
        loading.value = false;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const fetchCountries = () => {
    loading.value = true;
    homeFetch(`/workspace/return-portals/countries`)
      .then((r) => {
        countries.value = r.data.map((country: any) => {
          return {
            label: country.full_name,
            value: country.iso,
          };
        });
      })
      .catch(() => {
        loading.value = false;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const sortedProducts = computed(() => {
    if (!products.value[props.values.country]) {
      return null;
    }
    return products.value[props.values.country].map((product: any) => {
      return {
        label: product.title,
        value: product.value,
      };
    });
  });

  const { value: country } = useField(() => "country", undefined, {
    syncVModel: true,
  });

  const { value: product } = useField(() => "product", undefined, {
    syncVModel: true,
  });

  const { value: currency } = useField(() => "currency", undefined, {
    syncVModel: true,
  });
</script>
