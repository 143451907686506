<template>
  <aside class="flex h-fit min-h-[500px] w-full flex-col gap-6 px-2.5 pt-8 pb-2">
    <GlobalSearchInfoShipment
      v-if="
        gsStore.currentInformation &&
        gsStore.currentResult &&
        (gsStore.currentResult.type === SearchResultType.shipment ||
          gsStore.currentResult.type === SearchResultType.return)
      "
      :data="gsStore.currentInformation"
    />
    <GlobalSearchInfoTicket
      v-else-if="
        gsStore.currentInformation && gsStore.currentResult && gsStore.currentResult.type === SearchResultType.ticket
      "
      :data="gsStore.currentInformation"
    />
  </aside>
</template>

<script lang="ts" setup>
  import { SearchResultType } from "~/types/search";

  defineOptions({
    name: "GlobalSearchInfoContainer",
  });

  const gsStore = useGlobalSearchStore();

  watch(
    () => gsStore.currentResult,
    () => getData()
  );

  const getData = () => {
    if (!gsStore.currentResult) return;

    switch (gsStore.currentResult.type) {
      case SearchResultType.shipment:
      case SearchResultType.return:
        getShipment(gsStore.currentResult.id);
        break;
      case SearchResultType.document:
        // getDocument(gsStore.currentResult.id);
        break;
      case SearchResultType.ticket:
        getTicket(gsStore.currentResult.id);
        break;
      case SearchResultType.transfer:
        // getTransfer(gsStore.currentResult.id);
        break;
      default:
        assertNever(gsStore.currentResult.type);
        break;
    }
  };

  onMounted(() => {
    getData();
  });

  const getShipment = (id: number) => {
    if (gsStore.shipments.length && gsStore.shipments.find((x) => x.id === id)) return;

    gsStore.loading = true;
    homeFetch(`shipments/${id}/simple`).then(({ data }: { data: IShipmentSimple }) => {
      if (data) {
        gsStore.shipments.push(data);
      }
      gsStore.loading = false;
    });
  };

  const getTicket = (id: number) => {
    if (gsStore.tickets.length && gsStore.tickets.find((x) => x.id === id)) return;
    gsStore.loading = true;
    homeFetch(`tickets/${id}/simple`).then(({ data }: { data: ITicketSimple }) => {
      if (data) {
        gsStore.tickets.push(data);
        gsStore.loading = false;
      }
    });
  };
</script>
