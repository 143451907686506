<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-10">
    <div
      class="flex flex-col items-center justify-between gap-3 rounded-xl px-5 py-7 @xl:flex-row @xl:gap-6 @xl:px-10 @xl:py-14"
      :class="{
        'bg-utility-grape': isPickup,
        'bg-utility-mocca': !isPickup,
      }"
    >
      <div class="flex flex-col gap-1">
        <div class="text-body-default-heavy">{{ t("settings.plan.account_is_using") }}</div>
        <div class="text-title-screen text-primary">{{ isPickup ? t("pickup") : t("hand_in") }}</div>
      </div>
      <Button :variant="ButtonVariant.PrimaryLink" @click="() => (showChangeModal = true)">
        {{ t("settings.plan.change") }}
      </Button>
    </div>
    <PlanPickup v-if="isPickup" @update:loading="setLoading" />
    <PlanHandIn v-else />
  </div>
  <SwitchModal v-model:open="showChangeModal" :is-pickup="isPickup" />
</template>

<script setup lang="ts">
  import { ButtonVariant } from "~/types/global";
  import type { ISettingsFlow } from "../types";

  import PlanHandIn from "../components/Plans/HandIn.vue";
  import PlanPickup from "../components/Plans/Pickup.vue";
  import SwitchModal from "../components/Plans/SwitchModal.vue";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );

  const { accountLevel } = useWorkspacesStore();

  const { t } = useI18n();

  const isPickup = computed<boolean>(() => {
    return accountLevel === "SMV";
  });

  const showChangeModal = ref(false);

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);

  const { flowWrapper } = useFlowWrapper(toRef(() => props.currentHeader));
</script>

<script lang="ts">
  export const usePlanSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const { hasCapability } = useCapabilities();
    const icon = "regular/CheckSquareOffset";
    const title = t("settings.plan.title");
    const pageTitle = t("settings.plan.page_title");
    const key = "plan";
    const { isDFM, accountLevel } = useWorkspacesStore();

    const condition = computed(() => {
      return !isDFM && accountLevel !== "ENTERPRISE" && accountLevel !== "SCALE_UP" && hasCapability("account.plan");
    });

    return {
      icon,
      pageTitle,
      title,
      key,
      condition,
    };
  };
</script>
