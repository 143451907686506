<template>
  <LayoutNavigationUserDropdown v-slot="slotProps">
    <div
      class="dropdown-toggle hover:bg-01 relative flex w-9 cursor-pointer items-center gap-2.5 rounded-[10px] p-1 group-hover/sidebar:w-full group-hover/sidebar:delay-200 group-data-[open=true]/sidebar:w-full group-data-[open=true]/sidebar:delay-200"
      :class="{ 'bg-surface-lvl-00 shadow-lvl-01': slotProps.open }"
    >
      <AvatarUser size="2xs" />

      <div class="h-[37px] w-full truncate px-1">
        <div class="text-body-default-heavy text-primary truncate">
          {{ userName }}
        </div>
        <div class="text-body-sm text-quarterary truncate">
          {{ userEmail }}
        </div>
      </div>
      <div class="flex items-center">
        <Icon
          class="transition-transform"
          src="bold/CaretDown"
          :size="ComponentSize.sm"
          :class="{
            '-rotate-90 transform': slotProps.open,
          }"
        />
      </div>
    </div>
  </LayoutNavigationUserDropdown>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  defineOptions({
    name: "LayoutNavigationUser",
  });
  const { userName, userEmail } = storeToRefs(useWorkspacesStore());
</script>
