<template>
  <Avatar
    v-if="account"
    :type="AvatarType.Webshop"
    :entity="{
      name: accountName,
      image: accountLogo,
    }"
    :size="ComponentSize.xs"
  />
</template>

<script setup lang="ts">
  import { AvatarType, ComponentSize } from "~/types/global";

  const { account, accountName, accountLogo } = storeToRefs(useWorkspacesStore());
</script>
