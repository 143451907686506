<template>
  <div class="relative">
    <label v-if="label" class="text-body-sm-heavy text-secondary text-left">{{ label }}</label>
    <div id="editorWrapper">
      <QuillyEditor ref="editor" v-model="text" :options="options" @update:model-value="onModelValueChange" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import Quill from "quill"; // Core build

  const props = defineProps<{
    name: string;
    label?: string;
  }>();

  const editor = ref();

  const text = ref<string>("");

  let quill: Quill | null = null;

  const options = ref({
    theme: "snow",
    bounds: "#editorWrapper",
    modules: {
      toolbar: [["bold", "italic", "underline"], ["link"], [{ list: "ordered" }, { list: "bullet" }]],
    },
    readOnly: false,
  });

  const onModelValueChange = () => {
    inputValue.value = quill.getSemanticHTML();
  };

  onMounted(() => {
    quill = editor.value?.initialize(Quill);
    if (inputValue.value) {
      text.value = inputValue.value;
    }
  });

  const { value: inputValue } = useField(() => props.name, undefined, {
    syncVModel: true,
  });
</script>
