<template>
  <div
    class="border-01 bg-surface-lvl-00 shadow-lvl-01 shadow-surface-lvl-01 hover:bg-00 flex h-7 w-full items-center rounded-lg border px-3 transition-all duration-300"
    :class="{
      'cursor-pointer': file.link,
      'cursor-default': !file.link,
    }"
    @click="openLink(file.link)"
  >
    <Icon class="text-secondary mt-0.5" src="file_text" />
    <p class="text-body-default-heavy text-secondary mx-1.5 truncate">{{ file.name }}</p>
    <p class="text-body-default text-quarterary">{{ formattedSize(file.size) }}</p>
    <div v-if="canRemove" class="ml-auto" @click.stop>
      <Icon
        class="text-quarterary mt-0.5"
        src="close"
        :size="ComponentSize.sm"
        @click="emit('removeFile', file.name)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  defineProps({
    file: {
      type: Object,
      default: () => ({}),
    },
    canRemove: {
      type: Boolean,
      default: true,
    },
  });

  const emit = defineEmits(["removeFile"]);

  const openLink = (link: string) => {
    if (!link) return;
    window.open(link, "_blank");
  };

  const formattedSize = (size: number) => {
    if (!size) {
      return "";
    }
    const units = ["B", "KB", "MB", "GB", "TB"];
    let i = 0;
    while (size >= 1024 && i < units.length - 1) {
      size /= 1024;
      i++;
    }
    return `${size.toFixed(2)} ${units[i]}`;
  };
</script>
