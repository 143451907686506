<template>
  <div
    class="bg-00 hover:bg-01 flex h-12 w-full cursor-pointer items-center rounded-xl px-4 py-3 transition-all duration-300"
    @click="() => (parentClicked = !parentClicked)"
  >
    <p class="text-body-default-heavy text-secondary">{{ text }}</p>
    <Toggle
      class="ml-auto"
      :parent-clicked="parentClicked"
      :name="name"
      @click.stop=""
      @update:model-value="
        (val) => {
          (inputValue = val), emitUpdate(val);
        }
      "
    />
  </div>
</template>

<script setup lang="ts">
  const emit = defineEmits(["update:modelValue"]);

  withDefaults(
    defineProps<{
      text?: string;
      name?: string;
    }>(),
    {
      text: "",
      name: "",
    }
  );

  const parentClicked = ref(false);

  const emitUpdate = (val) => {
    emit("update:modelValue", val);
  };
</script>
