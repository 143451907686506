export const getNestedValue = (obj: any, path: string, defaultValue: any = null) => {
  const pathArray = path.split(".");
  const value = pathArray.reduce((o, key) => (o && o[key] !== "undefined" ? o[key] : defaultValue), obj);
  return value;
};

export const copyToClipboard = (text: string, toast?: string) => {
  const { $i18n } = useNuxtApp();
  const t = $i18n.t;
  return navigator.clipboard
    .writeText(text)
    .then(() => {
      $toast.add({
        group: "clipboard_success",
        title: toast || t("value_copied_to_clipboard"),
        duration: 750,
      });
    })
    .catch(() => {
      $toast.add({
        group: "clipboard_error",
        title: toast || t("clipboard_permission_denied"),
        duration: 750,
      });
    });
};

export const isDomainDev = (): boolean => {
  const hostname = useRequestURL().hostname;
  const domain = hostname?.split(".").slice(1).join(".");

  return (
    !hostname ||
    hostname == "localhost" ||
    hostname == "127.0.0.1" ||
    hostname == "account-test.homerunner.com" ||
    hostname == "account-test.homerunner.services" ||
    domain == "cloudfront.net" ||
    domain == "devtunnels.ms" ||
    domain == "homerunner.dev"
  );
};

export const getOrganisationInfo = () => {
  return homeFetch("workspace/organisation")
    .then((response) => {
      if (response?.data) {
        return { ...response.data } as OrganisationInformation;
      }
    })
    .finally(() => {});
};

export function assertNever(x: never): any {
  const _exhaustiveCheck: never = x;
  return x;
}

export function getOS(): "Android" | "iOS" | "Windows" | "Mac" | "Unknown" {
  const { userAgent } = window.navigator;
  if (/Android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    return "iOS";
  }
  if (/Windows/i.test(userAgent)) {
    return "Windows";
  }
  if (/Mac/i.test(userAgent)) {
    return "Mac";
  }

  return "Unknown";
}

export const notificationIsSupported = () =>
  "Notification" in window && "serviceWorker" in navigator && "PushManager" in window;

export const decideDomainInfo = () => {
  const nuxtApp = useNuxtApp();

  const hostname = useRequestURL().hostname;
  const isDfmDomain = nuxtApp.$config.public.dfmDomains.some((dfmDomain: string) => hostname.endsWith(dfmDomain));
  let domain = hostname;
  let currentSubdomain = "";
  const hostNameSplits = hostname.split(".");

  //get domain + tld from hostname
  if (!isDfmDomain) {
    domain = hostNameSplits.slice(1).join(".");
    currentSubdomain = hostNameSplits[0];
  } else {
    if (hostNameSplits.length === 3) {
      domain = hostNameSplits.join(".");
      currentSubdomain = "";
    } else {
      domain = hostNameSplits.slice(1).join(".");
      currentSubdomain = hostNameSplits[0];
    }
  }

  if (!domain) domain = "homerunner.com";

  return { domain, currentSubdomain, isDfmDomain };
};

export const proxyImage = (url: string, width?: number, height?: number) => {
  const params = new URLSearchParams({ url });
  if (width) params.append("w", width.toString());
  if (height) params.append("h", height.toString());
  return `/proxy/image/?${params.toString()}`;
};
