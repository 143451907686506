import { defineStore } from "pinia";

export const usePresentationStore = defineStore(
  "Presentation",
  () => {
    const seenGuides = ref<string[]>([]);

    const addSeenGuide = (guideId: string) => {
      seenGuides.value.push(guideId);
    };

    return {
      seenGuides,
      addSeenGuide,
    };
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
    },
  }
);
