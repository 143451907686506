const blacklisted = [
  "api",
  "account",
  "coolrunner",
  "homerunner",
  "www",
  "app",
  "admin",
  "user",
  "administrator",
  "support",
  "help",
  "helpdesk",
  "blog",
  "docs",
  "documentation",
  "test",
  "tests",
  "testing",
  "dev",
  "devel",
  "development",
  "staging",
  "stage",
  "demo",
  "account-test",
  "auth",
  "claims",
  "pallets-lite",
  "pallets",
  "partner",
  "return-flow",
  "api.smartcheckout",
  "status",
  "tracking",
];

export default (subdomain: string): string => {
  if (blacklisted.includes(subdomain)) {
    subdomain += "-" + generateRandomNumber(0, 8);
  }

  return subdomain;
};

const generateRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
