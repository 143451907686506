<template>
  <div class="paginator h-paginator border-t-00 bg-surface-lvl-00 flex items-center justify-evenly border-t px-5 py-3">
    <ClientOnly>
      <TablePaginatorCursor v-if="isCursor" :pagination="pagination" @update:cursor="updateCursor" />
      <TablePaginatorLengthaware
        v-else
        :pagination="pagination"
        :entity-name="entityName"
        :keep-length="keepLength"
        :limit="limit"
        @update:current_page="updateCurrentPage"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
  import { computed } from "vue";
  import type { CursorPagination, Pagination } from "~/types/paginate";

  defineOptions({
    name: "Paginator",
  });

  //props for a laravel paginator
  const props = defineProps<{
    pagination: Pagination | CursorPagination | null;
    entityName?: string;
    keepLength?: boolean;
    limit?: number;
  }>();

  const emit = defineEmits(["paginate"]);

  const updateCurrentPage = (page: number) => {
    emit("paginate", page);
  };

  const updateCursor = (cursor: string) => {
    emit("paginate", cursor);
  };

  const isCursor = computed(() => {
    //check if pagination is cursor based
    return props.pagination?.is_cursor ?? false;
  });
</script>
