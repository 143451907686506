<template>
  <form @submit.prevent="onSubmit">
    <slot name="hidden" />
    <div class="mb-2 flex items-center">
      <slot name="image" />
    </div>
    <slot>
      <FormStep v-for="(schema, idx) in validationSchema" :key="idx">
        <slot :name="`step-${stepKey}`" :schema="schema">
          <FormBuilderVeeStep :schema="schema">
            <template #header>
              <slot :name="`step-${stepKey}-header`" />
            </template>

            <template v-for="field in schemaFields(schema)" #[`${field}`]>
              <slot :name="`step-${stepKey}-${field}`" :schema="schema" :field="field" />
            </template>

            <template v-for="field in schemaFields(schema)" #[`${field}-label`]>
              <slot :name="`step-${stepKey}-${field}-label`" :schema="schema" :field="field" />
            </template>
          </FormBuilderVeeStep>
        </slot>
      </FormStep>
    </slot>

    <div class="mt-5 space-y-3">
      <Button
        v-if="hasContinueButton"
        class="w-full"
        type="submit"
        :disabled="!canSubmit"
        :variant="ButtonVariant.Primary"
        :size="ComponentSize.lg"
        :loading="loading"
      >
        {{ continueButtonText }}
      </Button>

      <Button
        v-if="hasSkip"
        class="w-full"
        type="button"
        :variant="ButtonVariant.Default"
        :size="ComponentSize.lg"
        @click="skip"
        >{{ t("skip_this_step") }}</Button
      >

      <Button
        v-if="hasPrevious"
        class="w-full"
        type="button"
        :variant="ButtonVariant.Tertiary"
        :size="ComponentSize.lg"
        @click="goToPrev"
        >{{ t("back") }}</Button
      >
    </div>
  </form>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";

  const { t } = useI18n();

  const props = withDefaults(
    defineProps<{
      submitButtonText: string;
      validationSchema: Record<string, any>[];
      loading: boolean;
    }>(),
    {
      submitButtonText: "",
      validationSchema: () => [],
      loading: false,
    }
  );

  const emit = defineEmits(["submit", "exit", "skip"]);
  const {
    hasPrevious,
    hasSkip,
    stepKey,
    hasContinueButton,
    continueButtonText,
    schemaFields,
    goToPrev,
    canSubmit,
    skip,
  } = useFormBuilder({
    submitButtonText: props.submitButtonText,
    validationSchema: props.validationSchema,
    emit,
  });
</script>
