<template>
  <ModalPresentationBase v-if="currentBoardingGuide && open" ref="modal" :open="open" center anchor="top">
    <div class="flex h-[450px] gap-2">
      <div class="relative w-screen max-w-[750px] rounded-xl bg-white">
        <div class="absolute top-5 left-5">
          <Button
            :href="currentBoardingGuide.link"
            target="_blank"
            :size="ComponentSize.sm"
            :variant="ButtonVariant.Default"
          >
            <div>{{ t("read_in_helpcenter") }}</div>
            <Icon src="ArrowUpRight" skeleton="false" />
          </Button>
        </div>
        <RivePlayer :key="currentBoardingGuide.fileName" :src="getRivUrl(currentBoardingGuide.fileName)" />
      </div>
      <div class="flex w-screen max-w-[200px] flex-col justify-between rounded-xl bg-white px-5 pt-6 pb-5">
        <div class="flex flex-col gap-6">
          <div class="text-title-body">{{ currentBoardingGuide.title }}</div>
          <div class="text-body-default text-tertiary">{{ currentBoardingGuide.description }}</div>
        </div>
        <Button :variant="ButtonVariant.Default" @click="closeModal">
          <div>{{ t("close_modal") }}</div>
        </Button>
      </div>
    </div>
  </ModalPresentationBase>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";

  const { t, locale } = useI18n();
  const open = ref(false);
  const presentationStore = usePresentationStore();
  const route = useRoute();

  const currentLocale = ref(locale.value.split("-")[0]);

  const getRivUrl = (name: string) => {
    if (currentLocale.value != "da") currentLocale.value = "en";

    return "/rive/platform/boarding/" + currentLocale.value + "/" + name;
  };

  const boardingGuides: Record<string, any> = {
    shipments: {
      title: t("introductions.shipments.title"),
      description: t("introductions.shipments.description"),
      fileName: "shipments",
      link: "https://help.homerunner.com/",
    },
    returns: {
      title: t("introductions.returns.title"),
      description: t("introductions.returns.description"),
      fileName: "returns",
      link: "https://help.homerunner.com/",
    },
    tickets: {
      title: t("introductions.tickets.title"),
      description: t("introductions.tickets.description"),
      fileName: "tickets",
      link: "https://help.homerunner.com/",
    },
    sortings: {
      title: t("introductions.sortings.title"),
      description: t("introductions.sortings.description"),
      fileName: "sortings",
      link: "https://help.homerunner.com/",
    },
    "shipments-id": {
      title: t("introductions.shipment.title"),
      description: t("introductions.shipment.description"),
      fileName: "shipments_detail_page",
      link: "https://help.homerunner.com/",
    },
    global_search: {
      title: t("introductions.global_search.title"),
      description: t("introductions.global_search.description"),
      fileName: "global_search",
      link: "https://help.homerunner.com/",
    },
  };

  const skippedRoutes = [
    "auth-login",
    "auth-logout",
    "auth-register",
    "auth-register-user",
    "auth-register-workspace",
    "auth-reset-process",
    "auth-reset-request",
  ];

  const currentBoardingGuide = computed(() => {
    const routeKey = route.name as string;

    if (!routeKey || skippedRoutes.includes(routeKey)) {
      return null;
    }

    if (!presentationStore.seenGuides.includes(routeKey)) {
      return boardingGuides[routeKey] || null;
    }

    if (!presentationStore.seenGuides.includes("global_search")) {
      return boardingGuides.global_search;
    }

    return null;
  });

  watch(
    currentBoardingGuide,
    (cbg) => {
      if (cbg) open.value = true;
    },
    {
      immediate: true,
    }
  );

  const closeModal = () => {
    const routeKey = route.name as string;
    if (!presentationStore.seenGuides.includes(routeKey)) {
      presentationStore.addSeenGuide(routeKey);
    } else if (!presentationStore.seenGuides.includes("global_search")) {
      presentationStore.addSeenGuide("global_search");
    }
    open.value = false;
  };
</script>

<style></style>
