<template>
  <Modal
    ref="modal"
    v-model:open="open"
    max-width="410px"
    min-width="410px"
    :content-padding="false"
    :center="true"
    anchor="top"
  >
    <div
      class="relative"
      :class="{
        'overflow-auto p-2': !pending && hasPrinters,
        'p-4': !pending && !hasPrinters,
        'min-h-48': pending,
        'max-h-96': !pending && hasPrinters,
      }"
    >
      <Overlay v-if="pending" />
      <template v-else>
        <div v-if="hasPrinters" class="space-y-2">
          <ButtonListSelector
            v-for="printer in printers"
            :key="printer.value"
            :title="printer.label"
            :subtitle="printer.data?.default ? t('default') : ''"
            icon="printer"
            @click="() => print(printer.value)"
          />
        </div>
        <div v-if="!printers.length && !pending">
          <p class="text-body-default text-secondary mb-2">{{ t("print.no_printers") }}</p>
          <p class="text-body-default text-secondary">{{ t("print.to_print") }}</p>
          <div class="mt-8 flex w-full gap-3">
            <Button class="ml-auto" :variant="ButtonVariant.Default" @click="() => (open = false)">{{
              t("cancel")
            }}</Button>
            <Button :variant="ButtonVariant.Primary" @click="() => openSettings()">{{ t("go_to_settings") }}</Button>
          </div>
        </div>
      </template>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import { ButtonVariant } from "~/types/global";

  import { open } from "../service";

  const { t } = useI18n();

  const props = defineProps<{
    packageNumber: string;
    transferId?: number;
    selectAll: boolean;
    selected: string[] | number[];
    filters: { [key: string]: string | number | boolean };
  }>();

  const { getPrinters } = usePrinters();

  const { data: printers, pending, hasPrinters } = await getPrinters();

  const { printLabel, printBulkLabels, printTransfer } = usePrinters();

  const print = (printer: any) => {
    if (props.packageNumber) printLabel(printer, props.packageNumber);
    else if (props.transferId) printTransfer(printer, props.transferId);
    else printBulkLabels(printer, props?.selectAll, props?.selected, props?.filters);
  };

  const openSettings = () => {
    window.$workspace_settings.start("printers");
  };
</script>
