<template>
  <Badge v-if="event" :type="findType">{{ event.label }}</Badge>
  <p v-else>{{ t("not_specified") }}</p>
</template>

<script setup lang="ts">
  defineOptions({
    name: "status-indicator-container",
  });

  const { t } = useI18n();

  const props = defineProps<{
    event?: {
      label: string;
    };
  }>();

  const findType = computed(() => {
    if (!props.event) return "default";
    if (props.event.label === "Draft") return "subtle";
    if (props.event.label === "Approved by Consignee") return "brand";
    if (props.event.label === "Accepted by Forwarder") return "kiwi";
    if (props.event.label === "Arrived at Port") return "lemon";
    if (props.event.label === "Finalized") return "positive";
    if (props.event.label === "Cancelled") return "negative";
    if (props.event.label === "Shipped") return "tangerine";
    if (props.event.label === "Delivered at Consignee") return "kiwi";
    return "default";
  });
</script>
