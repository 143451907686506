<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <div
      v-if="hasCapability('account.users.edit')"
      class="flex flex-col items-end justify-between gap-2 @sm:flex-row @sm:gap-5"
    >
      <div class="settings-flow-header hidden">
        {{ t("settings.users.invite_with_mail") }}
      </div>
      <InputText
        v-model="invitation.email"
        class="w-full"
        :size="ComponentSize.lg"
        :label="t('settings.users.invite_with_mail')"
        :placeholder="t('settings.users.invite_placeholder')"
      >
        <template #button>
          <DropdownSelect v-model="invitation.role" :options="roles" item-max-width="420px">{{
            t("settings.users.send_invitation")
          }}</DropdownSelect>
        </template>
      </InputText>
      <Button
        class="w-full @sm:w-auto"
        :size="ComponentSize.lg"
        :disabled="!isEmailValid"
        :loading="creating"
        @click="createUser"
        >{{ t("settings.users.send_invitation") }}</Button
      >
    </div>

    <div>
      <TabBar class="bg-surface-lvl-00 z-50 mb-3 !h-11">
        <Tab active>
          {{ t("settings.users.members") }}
          <div class="border-01 text-button-sm text-secondary rounded-full border px-2">{{ usersCount }}</div>
        </Tab>
        <div class="mb-1.5 flex w-full items-end justify-end">
          <InputText v-model="search" :size="ComponentSize.sm" :placeholder="t('settings.users.search')">
            <template #prefix>
              <Icon class="text-quarterary" src="MagnifyingGlass" />
            </template>
          </InputText>
        </div>
      </TabBar>

      <div>
        <UserRow v-for="user in filteredUsers" :key="user" :user="user" :roles="roles" @fetch="fetchUsers" />
      </div>
    </div>
  </div>
  <NotificationWrapper />
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";
  import type { ISettingsFlow } from "../types";

  import NotificationWrapper from "../components/NotificationWrapper.vue";
  import UserRow from "../components/UserRow.vue";

  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );
  const { t } = useI18n();
  const { hasCapability } = useCapabilities();

  const users = ref<Record<string, any>>([]);
  const creating = ref(false);

  const { roles } = useCapabilities();

  const invitation = ref<{
    email: string;
    role: string;
  }>({
    email: "",
    role: "3",
  });

  const search = ref("");

  const filteredUsers = computed(() => {
    return users.value.filter((u) => {
      return (
        u.email.toLowerCase().includes(search.value.toLowerCase()) ||
        u.name.toLowerCase().includes(search.value.toLowerCase())
      );
    });
  });

  const usersCount = computed(() => users.value?.length || 0);

  const fetchUsers = () => {
    setLoading(true);
    return homeFetch("workspace/users")
      .then((response) => {
        if (response?.data) {
          users.value = response.data?.users || [];
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createUser = () => {
    $confirm({
      message: t("settings.users.confirm_invitation", {
        user: invitation.value.email,
        role: roles.value.find((r) => r.value == invitation.value.role)?.label,
      }),
      onConfirm: () => {
        creating.value = true;

        homeFetch(`workspace/users`, {
          method: "POST",
          body: {
            emails: [invitation.value.email],
            roles: [invitation.value.role],
          },
        })
          .then(() => {
            $toast.add({
              title: t("settings.users.invitation.success", {
                user: invitation.value.email,
                role: roles.value.find((r) => r.value == invitation.value.role)?.label,
              }),
              icon: "users",
              target: "#settings-modal .notification-wrapper",
            });
            invitation.value.email = "";
            invitation.value.role = "1";
            fetchUsers();
          })
          .finally(() => {
            creating.value = false;
          });
      },
    });
  };

  onMounted(() => {
    fetchUsers().then(() => {
      goToCurrentHeader();
    });
  });

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);

  const isEmailValid = computed(() => {
    if (!invitation.value.email) return false;
    return !!invitation.value.email.match(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/);
  });
</script>

<script lang="ts">
  export const useUserSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const { hasCapability } = useCapabilities();

    const icon = "regular/users";
    const title = t("settings.users.title");
    const pageTitle = t("settings.users.page_title");
    const key = "users";
    const description = t("settings.users.description");
    const headers = [t("settings.users.invite_with_mail")];
    const condition = computed(() => hasCapability("account.users") || hasCapability("account.users.edit"));

    return {
      icon,
      pageTitle,
      title,
      key,
      description,
      headers,
      condition,
    };
  };
</script>
