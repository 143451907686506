export default () => {
  const loading = ref(false);
  const fetchInsurances = (object: Record<string, any>) => {
    loading.value = true;
    const carrier = object.carrier || object.carrier_product_service.split("_")[0];

    return homeFetch(`insurance/?carrier=${carrier}&weight=${object.weight}`, {})
      .then((response) => {
        return response.data;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  return {
    fetchInsurances,
    loading,
  };
};
