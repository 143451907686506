<template>
  <div
    class="relative box-content flex aspect-square shrink-0 items-center justify-center overflow-hidden text-center select-none"
    :style="{ width: `${sizeInPx}px`, height: `${sizeInPx}px` }"
    :class="{
      'rounded-md': size === '3xs' || size === '2xs',
      'rounded-lg': size === 'xs',
      'rounded-[10px]': size === 'sm',
      'rounded-xl': size === 'md',
      'rounded-2xl': size === 'lg',
      'rounded-[18px]': size === 'xl',
      'bg-utility-tangerine text-utility-tangerine-darkest': color === 'tangerine' && !image,
      'text-utility-raspberry-darkest bg-utility-raspberry': color === 'raspberry' && !image,
      'bg-utility-lemon text-utility-lemon-darkest': color === 'lemon' && !image,
      'bg-utility-grape text-utility-grape-darkest': color === 'grape' && !image,
      'bg-utility-kiwi text-utility-kiwi-darkest': color === 'kiwi' && !image,
      'bg-brand text-white': !color && !image,
    }"
  >
    <slot>
      <img
        v-if="image"
        class="h-auto w-full text-center"
        :src="image"
        :alt="entity?.name"
        :height="sizeInPx"
        :width="sizeInPx"
        @error="onError"
      />
      <div v-else class="font-bold text-inherit" :class="fontSize">
        {{ twoLetterName }}
      </div>
      <div
        v-if="hasBorder"
        class="absolute h-full w-full rounded-[inherit]"
        :class="{
          'shadow-avatar-border-xs': size === '3xs',
          'shadow-avatar-border-sm': size === '2xs',
          'shadow-avatar-border-md': size === 'xs',
          'shadow-avatar-border-lg': size === 'sm',
          'shadow-avatar-border-xl': size === 'md' || size === 'lg' || size === 'xl',
        }"
      />
    </slot>
    <slot name="overlay" />
  </div>
</template>

<script setup lang="ts">
  import { AvatarType } from "~/types/global";

  export interface AvatarProps {
    size?: "3xs" | "2xs" | "xs" | "sm" | "md" | "lg" | "xl";
    entity?: {
      name?: string;
      email?: string;
      image?: string | null | File;
    };
    useLetters?: boolean;
    color?: "tangerine" | "raspberry" | "lemon" | "grape" | "kiwi";
    randomColor?: boolean;
    index?: number;
    maxLetters?: 1 | 2;
    type?: AvatarType;
  }

  const props = withDefaults(defineProps<AvatarProps>(), {
    size: "md",
    useLetters: true,
    color: "grape",
    maxLetters: 2,
    entity: undefined,
    index: undefined,
    type: AvatarType.User,
  });

  const image = ref<string | null>();
  const fallbackImage = "/images/logo.png";

  defineOptions({
    name: "Avatar",
  });

  const sizeInPx = computed(() => {
    switch (props.size) {
      case "3xs":
        return 20;
      case "2xs":
        return 28;
      case "xs":
        return 36;
      case "sm":
        return 44;
      case "md":
        return 52;
      case "lg":
        return 60;
      case "xl":
        return 68;
      default:
        return 52;
    }
  });

  watchEffect(() => {
    const img = props.entity?.image || (props.useLetters ? null : fallbackImage);

    if (img instanceof File) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result;
        if (typeof result === "string") {
          image.value = result;
        }
      };
      reader.readAsDataURL(img);
    } else {
      image.value = img;
      if (image.value && !image.value.startsWith("http")) {
        const separator = image.value.includes("?") ? "&" : "?";
        image.value += `${separator}s=${sizeInPx.value * 2}`;
      }
    }
  });

  const hasBorder = computed(() => {
    if (props.type === AvatarType.Sophie) return true;
    if (props.type === AvatarType.User && !image.value) return true;

    return false;
  });

  const fontSize = computed(() => {
    if (props.type === AvatarType.Webshop) {
      switch (props.size) {
        case "3xs":
          return "text-[11.11px] leading-[normal]";
        case "2xs":
          return "text-[15.56px] leading-[normal]";
        case "xs":
          return "text-[20px] leading-[normal]";
        case "sm":
          return "text-[24.44px] leading-[normal]";
        case "md":
          return "text-[28.89px] leading-[normal]";
        case "lg":
          return "text-[33.33px] leading-[normal]";
        case "xl":
          return "text-[37.78px] leading-[normal]";
        default:
          return "text-[20px] leading-[normal]";
      }
    } else {
      switch (props.size) {
        case "3xs":
          return "text-2xs";
        case "2xs":
          return "text-xs";
        case "xs":
          return "text-base";
        case "sm":
          return "text-lg";
        case "md":
          return "text-[21px]";
        case "lg":
          return "text-2xl";
        case "xl":
          return "text-3xl";
        default:
          return "text-[21px]";
      }
    }
  });

  const color = computed(() => {
    if (props.type === AvatarType.Webshop) return "";

    //if randomColor is true, return random color if index is not provided, else return color based on index, if index is too high, start over from 0
    if (props.randomColor) {
      const colors = ["tangerine", "raspberry", "lemon", "grape", "kiwi"];
      if (props.index) return colors[props.index % colors.length];

      //hash the name to get a number
      let hash = props.entity?.name
        ? props.entity.name.split("").reduce((a: number, b: string) => a + b.charCodeAt(0), 0)
        : 0;

      //reduce the hash to a number between 0 and 4

      while (hash > 4) {
        hash = hash % 5;
      }

      return colors[hash];
    }

    return props.color;
  });

  const twoLetterName = computed(() => {
    if (!props.entity?.name && !props.entity?.email) return "";
    const name = props.entity.name || props.entity.email;
    //split name on space or - and get first letter of each word
    const nameArray = name!.split(/[\s-]/);
    const firstLetter = nameArray[0].charAt(0);

    if (nameArray.length === 1 || props.maxLetters == 1 || props.type === AvatarType.Webshop)
      return firstLetter.toUpperCase();
    const secondLetter = nameArray[1].charAt(0);
    return (firstLetter + secondLetter).toUpperCase();
  });

  const onError = () => {
    image.value = fallbackImage;

    /*  flare.reportMessage("Avatar image not found", {
      entity: props.entity,
    });*/
  };
</script>
