<template>
  <div class="flex flex-col gap-10 @xl:flex-row @xl:px-10">
    <div class="flex flex-1 flex-col gap-3">
      <div v-for="point in definition.points" :key="point" class="flex gap-3">
        <Icon src="Check" :size="ComponentSize.lg" />
        <div class="text-body-lg">{{ point }}</div>
      </div>
    </div>
    <div class="flex flex-1 flex-col gap-1.5">
      <template v-for="(tableEntry, idx) in definition.table" :key="idx">
        <div class="text-body-default text-tertiary flex justify-between gap-2">
          <div>{{ tableEntry.left }}</div>
          <div class="flex items-center gap-1 text-right">
            {{ tableEntry.right }}
            <Icon v-if="tableEntry.infoLink" class="text-tertiary" src="Info" />
          </div>
        </div>
        <Divider v-if="idx !== definition.table.length - 1" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const definition = useHandin();
</script>

<script lang="ts">
  export const useHandin = (): Record<string, any> => {
    return {
      points: [
        "Dine pakker skal indleveres i en Bring pakkeshop.",
        "Indlevering anbefales hvis du sender mindre end 10 pakker om dagen.",
        "Indlevering kan ikke kombineres med afhentning.",
      ],
      table: [
        { left: "Indlevering", right: "0,00 DKK" },
        { left: "Pakkepriser inkl. gebyr", right: "fra 27,70 DKK" },
        { left: "Automatisk optankning", right: "Valgfrit" },
        { left: "Geografisk dækning", right: "Hele EU" },
      ],
    };
  };
</script>
