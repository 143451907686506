<template>
  <div class="mx-auto flex h-full min-h-0 w-full max-w-[556px] flex-col gap-5 overflow-auto rounded-xl px-2 pb-3">
    <div class="m-auto">
      <p class="text-title-section text-secondary mb-5 w-full text-center">{{ t("select_checkout") }}</p>
      <div v-if="checkouts.checkouts.length !== 0" class="h-full max-h-[400px] w-full overflow-auto">
        <ListSelector
          v-model="selectedCheckout"
          :values="checkouts.checkouts"
          radio-position="top"
          @full-option="(val) => setCheckout(val)"
        >
          <template #title="{ value }">
            <div class="w-full">
              <p class="text-body-lg-heavy text-secondary">{{ value.name }}</p>
              <p class="text-body-default text-tertiary">
                {{ t("created_at") }} {{ DateTime.fromISO(value.created_at).toFormat("DDD") }}
              </p>
            </div>
          </template>
        </ListSelector>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { DateTime } from "luxon";

  const { t } = useI18n();
  defineProps<{
    checkouts: any[];
  }>();

  const selectedCheckout = ref(null);

  onMounted(() => {
    if (checkout.value) {
      selectedCheckout.value = checkout.value.id;
    }
  });

  const setCheckout = (val: any) => {
    checkout.value = val.id;
    selectedCheckout.value = val.id;
  };

  const { value: checkout } = useField(() => "settings.checkout_id", undefined, {
    syncVModel: true,
  });
</script>
