export default async () => {
  const { t } = useI18n();
  const contacts = ref([]);
  const loading = ref(false);

  const fetchContacts = () => {
    loading.value = true;
    homeFetch("contacts")
      .then((r) => {
        contacts.value = r.data?.map((contact: Contact) => ({
          label: buildLabel(contact),
          value: contact.id,
          data: contact,
        }));
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const deleteContact = async (id: number) => {
    $confirm({
      message: t("contact_delete_confirm"),
      onConfirm: () => {
        homeFetch(`contacts/${id}`, {
          method: "Delete",
        })
          .then(() => {})
          .finally(() => {
            fetchContacts();
          });
      },
      destructive: true,
    });
  };

  const buildLabel = (contact: Contact) => {
    if (!contact) return "";

    if (contact.company_name)
      return `${contact.company_name}, ${contact.name}, ${contact.street1}, ${contact.zip_code} ${contact.city}, ${getCountryName(contact.country_code)}`;

    return `${contact.name}, ${contact.street1}, ${contact.zip_code} ${contact.city}, ${getCountryName(contact.country_code)}`;
  };

  fetchContacts();

  return {
    data: contacts,
    pending: loading,
    deleteContact,
  };
};
