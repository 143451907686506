<template>
  <div :class="`filter__group select-none`">
    <div class="relative flex transform-none items-center gap-3">
      <div class="text-quarterary flex w-[30px] flex-col items-center self-stretch text-sm font-normal">
        <div class="flex flex-col gap-y-2.5 text-center">
          <div class="filter-group-label py-2">{{ first ? t("filter.where") : t("filter.and") }}</div>
        </div>
      </div>
      <div class="flex w-full flex-col">
        <div class="flex flex-col gap-y-2.5">
          <RuleFilterEntry
            v-for="(entry, idx) in modelValue"
            :key="filterKey + '-' + idx + '-' + lastArrayUpdate"
            :filter-key="filterKey"
            :model-value="entry"
            @update:model-value="(mv) => updateFilters(idx, mv)"
            @update:filter-key="(newFk, mv) => updateFilterKey(newFk, idx, mv)"
            @remove="() => removeEntry(idx)"
          />
        </div>
      </div>
    </div>
    <Divider v-if="!last" class="my-2.5 ml-[42px]" />
  </div>
</template>

<script setup lang="ts">
  import _ from "lodash";
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();
  defineOptions({
    name: "RuleFilterGroup",
  });

  const lastArrayUpdate = ref(Date.now());

  const props = withDefaults(
    defineProps<{
      modelValue: IRuleFilter[];
      filterKey: string;
      first: boolean;
      last: boolean;
      index: number;
    }>(),
    {
      modelValue: () => [],
    }
  );

  const updateFilterKey = (newKey: string, idx: number, mv: Record<string, any>) => {
    emits("update:filterKey", newKey, idx, mv);
    lastArrayUpdate.value = Date.now();
  };

  const updateFilters = (idx: number, value: IRuleFilter) => {
    const group = _.cloneDeep(props.modelValue);
    group[idx] = value;

    emits("update:modelValue", group);
  };

  const removeEntry = (idx: number) => {
    const group = _.cloneDeep(props.modelValue);
    group.splice(idx, 1);

    emits("remove", group);
    lastArrayUpdate.value = Date.now();
  };

  const emits = defineEmits(["remove", "update:filterKey", "update:modelValue"]);
</script>
