import EventBus from "@/plugins/eventbus";
import { inject, ref } from "vue";
import type { IInviteUsersService } from "./types";

export const open = ref(false);

/**
 * A unique identifier to access the provided/injected method
 */
export const InviteUsersSymbol = Symbol();

/**
 * Composable which allows accessing the InviteUsers service in Composition API
 * @returns InviteUsers
 */
export function useInviteUsers() {
  const InviteUsers = inject(InviteUsersSymbol);
  if (!InviteUsers) {
    throw new Error("No invite provided!");
  }

  return InviteUsers;
}

/**
 * Vue app install. Global property for Options API and provided service for Composition API
 */
export const InviteUsersService = {
  install: (app) => {
    const InviteUsersService: IInviteUsersService = {
      toggle: () => {
        EventBus.$emit("INVITEUSERS_TOGGLE");
      },
      open: () => {
        EventBus.$emit("INVITEUSERS_OPEN");
      },
      close: () => {
        EventBus.$emit("INVITEUSERS_CLOSE");
      },
    };
    app.config.globalProperties.$InviteUsers = InviteUsersService;
    if (typeof window !== "undefined") window.$inviteusers = InviteUsersService;
    app.provide(InviteUsersSymbol, InviteUsersService);
  },
};
