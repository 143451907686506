<template>
  <div
    class="bg-surface-lvl-00 flex h-11 cursor-pointer items-center gap-2 rounded-xl px-4 py-2 transition-colors"
    @click="() => emit('toggle')"
  >
    <Button :variant="ButtonVariant.Tertiary" type="button" icon-only :size="ComponentSize.sm" :active="expanded">
      <Icon
        class="transform transition-all duration-300"
        src="bold/CaretDown"
        :class="{
          '-rotate-90': !expanded,
        }"
      />
    </Button>
    <div class="flex flex-grow items-center gap-2">
      <Badge type="subtle">
        <Icon src="FlowArrow" :size="ComponentSize.sm" />
        {{ filtersCount }}
      </Badge>
      <Divider class="self-stretch" :spacing="false" rotation="vertical" />

      <Badge v-for="value in values" :key="value" type="grape">
        {{ getPrettyValue(value) }}
      </Badge>

      <div class="text-body-default-heavy text-quarterary">
        {{ price }}
      </div>
    </div>

    <DropdownActions
      trigger-icon-prefix="bold/DotsThreeVertical"
      :actions="[
        {
          label: t('delete'),
          destructive: true,
          handler: () => emit('remove'),
        },
      ]"
    />
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";

  const props = withDefaults(
    defineProps<{
      rule: IRule;
      expanded: boolean;
    }>(),
    {}
  );

  const description = inject("description");
  const { t } = useI18n();
  const emit = defineEmits(["toggle", "remove"]);

  const filters = computed(() => Object.entries(props.rule.filters));
  const filtersCount = computed(() => filters.value?.length);

  const values = computed(() => {
    const values = Object.entries(props.rule.filters)
      .map(([key, filter]) => {
        return {
          key,
          value: filter[0].value,
          operator: filter[0].operator,
        };
      })
      .flat()
      .filter((filter) => {
        const type = description.value[filter.key]?.type;
        if (type === "boolean") {
          return true;
        }

        return filter.value && filter.value?.length;
      });

    //sort values by key so that receiver_country is always first, and then everything else
    return values.sort((a, b) => {
      if (a.key === "receiver_country") {
        return -1;
      }
      if (b.key === "receiver_country") {
        return 1;
      }
      return 0;
    });
  });

  const getPrettyValue = (filter: IRuleFilter) => {
    const { options = [], type, label } = description.value[filter.key] || {};

    if (type === "boolean") {
      return label;
    }

    const getOption = (value: string) => {
      return options[value] || value;
    };

    if (filter.operator === "between" || filter.operator === "notBetween") {
      return `${getOption(filter.value[0])} - ${getOption(filter.value[1])}`;
    }

    if (filter.operator === "in" || filter.operator === "notIn") {
      if (filter.value.length === 0) return null;
      //return the first value and the count of the rest
      const text = `${getOption(filter.value[0])}`;
      if (filter.value.length > 1) return `${text} +${filter.value.length - 1}`;

      return text;
    }

    return getOption(filter.value);
  };

  const price = computed(() => {
    return useFormatPrice(props.rule.price || 0, true, "");
  });
</script>
