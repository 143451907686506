<template>
  <NuxtLink
    v-if="entry.route_key"
    ref="item"
    :class="componentClasses"
    :href="entry.route"
    :target="entry.target || '_self'"
    prefetch
    prefetched-class="prefetched"
    @click="blurItem"
  >
    <LayoutNavigationItemIcon :name="entry.icon" :class="iconClasses" />
    <span class="px-1">{{ entry.label }}</span>
  </NuxtLink>
  <button v-else ref="item" :class="componentClasses" type="button" @click="onClick">
    <LayoutNavigationItemIcon
      :name="entry.icon"
      :class="iconClasses"
      :indicator="(entry as ISidebarAction).indicator"
    />
    <span class="px-1">{{ entry.label }}</span>
  </button>
</template>

<script setup lang="ts">
  import { LayoutNavigationItemIcon, NuxtLink } from "#components";

  const route = useRoute();
  const item = useTemplateRef("item");

  defineOptions({
    name: "LayoutNavigationItem",
  });

  const props = defineProps<{
    entry: ISidebarEntry | ISidebarAction;
  }>();

  const componentClasses = computed(() => {
    let classes =
      "h-[38px] outline-none! focus:shadow-focus-blue group flex items-center gap-1.5 whitespace-nowrap rounded-[10px] p-1 text-body-default text-secondary hover:text-primary hover:bg-01 transition-all focus:text-gray-900 group-hover/sidebar:delay-200 group-data-[open=true]/sidebar:delay-200 group-hover/sidebar:w-full group-data-[open=true]/sidebar:w-full w-[38px] ";

    if (route.path.includes(props.entry.route_key)) {
      classes += " active bg-surface-lvl-00 shadow-lvl-01";
    }

    return classes;
  });

  const iconClasses = "text-secondary shrink-0 rounded-[10px]";

  const onClick = () => {
    if ((props.entry as ISidebarAction).action) {
      (props.entry as ISidebarAction).action(undefined);
    }

    nextTick(() => {
      blurItem();
    });
  };

  const blurItem = () => {
    if (item.value) {
      if (item.value.$el) item.value?.$el?.blur();
      else item.value?.blur();
    }
  };
</script>
