const readFile = (file: Blob) => {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error("Problem parsing file"));
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.readAsText(file);
  });
};

export const readJsonFromBlob = (blob: Blob) => {
  return readFile(blob).then((r) => {
    return Promise.resolve(JSON.parse(r as string));
  });
};

export const getContentDisposition = (response: FetchResponse<unknown>) => {
  return response.headers.get("content-disposition");
};

export const getFilenameFromContentDisposition = (contentDisposition: string) => {
  return contentDisposition.split("filename=")[1];
};

export const getCachedData = (key: string, seconds: number) => {
  const data = useNuxtApp().payload.data[key] || useNuxtApp().static.data[key];

  if (!data || !data.fetchedAt) return null;

  const expiration = new Date(data.fetchedAt);
  expiration.setTime(expiration.getTime() + 1000 * seconds);
  const isExpired = expiration.getTime() < Date.now();

  if (isExpired) return;
  return data;
};

export const buildBulkActionBody = ({
  action,
  selectAll,
  selected,
  filters,
  options,
}: {
  action?: string;
  options?: Record<string, any>;
  selectAll: boolean;
  selected: number[] | string[];
  filters: any;
}) => {
  const body: {
    format?: string;
    exclude?: number[] | string[];
    filters?: any;
    include?: number[] | string[];
    export_options?: Record<string, any>;
  } = {};

  if (action) body.format = action;
  if (options) body.export_options = options;

  if (selectAll) {
    body.exclude = selected;
    body.filters = filters.value;
  } else {
    body.include = selected;
  }

  return body;
};

export const downloadFile = (
  data: string | ArrayBuffer | ArrayBufferView | Blob,
  filename: string,
  mime?: string,
  bom?: string | Uint8Array
): void => {
  const blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime || "application/octet-stream" });
  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};
