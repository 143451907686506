<template>
  <div class="h-[67vh] overflow-y-auto p-6">
    <p v-if="insuranceData" class="text-title-section text-secondary mb-3">{{ t("be_safe") }}</p>
    <div v-if="insuranceData" class="flex">
      <p class="text-body-default text-tertiary">{{ t("insurance_text_1", { value: returnStandartCoverage() }) }}</p>
      <a
        class="text-body-default text-tertiary ml-1 underline"
        href="https://www.homerunner.com/handelsbetingelser/"
        target="_blank"
        rel="noopener noreferrer"
        >{{ t("as_our_terms") }}</a
      >
    </div>
    <p v-if="insuranceData" class="text-body-default text-tertiary mb-5">{{ t("insurance_text_2") }}</p>
    <Overlay v-if="loading" class="h-[60vh] w-full" />
    <div class="mb-5 grid grid-cols-4 gap-4">
      <div
        v-for="insurance in insuranceData"
        :key="insurance.id"
        class="shadow-inset-00-01 hover:shadow-inset-01-01 flex h-full min-h-[400px] w-auto cursor-pointer flex-col rounded-lg p-3 transition-all duration-300"
        :class="selectedInsurance === insurance.id ? 'shadow-inset-01-01' : ''"
        @click="() => selectInsurance(insurance)"
      >
        <div class="relative h-28 w-full">
          <img
            v-if="findColor(insurance.id) == 'grape'"
            src="~/assets/illustrations/backgrounds/grape_grid.svg"
            alt=""
          />
          <img
            v-if="findColor(insurance.id) == 'lemon'"
            src="~/assets/illustrations/backgrounds/lemon_grid.svg"
            alt=""
          />
          <img
            v-if="findColor(insurance.id) == 'raspberry'"
            src="~/assets/illustrations/backgrounds/raspberry_grid.svg"
            alt=""
          />
          <img
            v-if="findColor(insurance.id) == 'tangerine'"
            src="~/assets/illustrations/backgrounds/tangerine_grid.svg"
            alt=""
          />
          <div class="absolute top-0 left-0">
            <Avatar :entity="{ name: insurance.title }" :color="findColor(insurance.id)" size="sm" />
          </div>
        </div>
        <p class="text-body-lg text-secondary">{{ t("up_to") }}</p>
        <p class="text-title-subsection text-primary mb-5">{{ useFormatPrice(insurance.coverage, true) }}</p>
        <p class="text-body-lg text-tertiary">{{ returnInsuranceText(insurance.id) }}</p>
        <div class="mt-auto flex w-full">
          <p class="text-body-default text-quarterary">
            {{ t("price") }}
            <br />
            <span class="text-body-lg-heavy text-secondary">{{ returnPrice(insurance) }}</span>
          </p>
          <Badge v-if="!insurance.id" class="ml-auto flex h-7" type="bold">
            <Icon class="mt-0.5 mr-1" src="check" />
            {{ t("included") }}
          </Badge>
        </div>
      </div>
    </div>
    <p v-if="insuranceData" class="text-body-sm text-tertiary">{{ t("insurance_disclaimer") }}</p>
  </div>
</template>

<script setup lang="ts">
  const { t } = useI18n();

  const { accountIsBusiness } = useWorkspacesStore();

  const props = defineProps<{
    values: any;
  }>();

  const { loading, fetchInsurances } = useInsurance();

  const insuranceData = ref<any>(null);

  fetchInsurances(props.values).then((data) => {
    insuranceData.value = data;
  });

  const { value: selectedInsurance } = useField(() => "insurance", undefined, {
    syncVModel: true,
  });

  const { value: selectedInsuranceCoverage } = useField(() => "insurance_coverage", undefined, {
    syncVModel: true,
  });

  const { value: selectedInsurancePrice } = useField(() => "insurance_price", undefined, {
    syncVModel: true,
  });

  const selectInsurance = (insurance: any) => {
    selectedInsurance.value = insurance.id;
    selectedInsuranceCoverage.value = useFormatPrice(insurance.coverage, false);
    selectedInsurancePrice.value = useDecidePriceExclOrIncl(insurance);
  };

  const returnStandartCoverage = () => {
    if (insuranceData.value) {
      return useFormatPrice(insuranceData.value[0].coverage, true);
    }
    return useFormatPrice(0, true);
  };

  const returnPrice = (insurance: any) => {
    if (insurance.price_incl_tax == 0) {
      return t("free");
    }
    return useFormatPrice(useDecidePriceExclOrIncl(insurance), true);
  };

  const returnInsuranceText = (id: number) => {
    switch (id) {
      case 1:
        return t("free_insurance");
      case 2:
        return t("insurance_text_with_value", { value: t("moderate_value").toLowerCase() });
      case 3:
        return t("insurance_text_with_value", { value: t("high_value").toLocaleLowerCase() });
      case 4:
        return t("insurance_text_with_value", { value: t("very_high_value").toLowerCase() });
      default:
        return t("free_insurance");
    }
  };

  const findColor = (id: number) => {
    switch (id) {
      case 1:
        return "lemon";
      case 2:
        return "tangerine";
      case 3:
        return "raspberry";
      case 4:
        return "grape";
      default:
        return "lemon";
    }
  };
</script>
