import _ from "lodash";
import { DateTime } from "luxon";

export const formatDate = (
  date?: string | null | undefined | DateTime | Date,
  format: string | Intl.DateTimeFormatOptions | "relative" = "relative",
  fallbackFormat: string = "ff",
  relativeTimestamp: boolean = false
) => {
  const { $i18n } = useNuxtApp();
  if (!date) {
    return "";
  }

  if (!(date instanceof DateTime)) {
    if (date instanceof Date) {
      date = DateTime.fromJSDate(date);
    } else {
      date = DateTime.fromISO(date);
    }
  }

  date = date.setLocale($i18n.locale.value);

  if (format === "relative") {
    const relativeStyle = $i18n.locale.value === "da" ? "short" : "long";

    //if more than 3 days ago or in the future, show the full date
    if (date.diffNow("days").days > 3 || date.diffNow("days").days < -3) {
      return date.toFormat(fallbackFormat);
    }

    if (relativeTimestamp) {
      return `${date.toRelative({ style: relativeStyle })}, ${date.toFormat("t")}`;
    }

    return date.toRelative({ style: relativeStyle }) || "";
  }

  if (isString(format)) return date.toFormat(format);

  return date.toLocaleString(format);
};

export const isToday = (date?: string | DateTime | Date | null | undefined) => {
  if (!date) return false;

  if (date instanceof DateTime) {
    return date.hasSame(DateTime.now(), "day");
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date).hasSame(DateTime.now(), "day");
  }

  return DateTime.fromISO(date).hasSame(DateTime.now(), "day");
};

export const isTomorrow = (date?: string | DateTime | Date | null | undefined) => {
  if (!date) return false;

  if (date instanceof DateTime) {
    return date.hasSame(DateTime.now().plus({ days: 1 }), "day");
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date).hasSame(DateTime.now().plus({ days: 1 }), "day");
  }

  return DateTime.fromISO(date).hasSame(DateTime.now().plus({ days: 1 }), "day");
};

export const isYesterday = (date?: string | DateTime | Date | null | undefined) => {
  if (!date) return false;

  if (date instanceof DateTime) {
    return date.hasSame(DateTime.now().minus({ days: 1 }), "day");
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date).hasSame(DateTime.now().minus({ days: 1 }), "day");
  }

  return DateTime.fromISO(date).hasSame(DateTime.now().minus({ days: 1 }), "day");
};

export const getWeekdays = (): { value: string; label: string }[] => {
  const { $i18n } = useNuxtApp();

  //generate a list of all weekdays in the given locale in the format {label: "Monday", value: 1}
  const weekdays = [];
  for (let i = 1; i <= 7; i++) {
    weekdays.push({
      label: _.capitalize(DateTime.fromObject({ weekday: i }).setLocale($i18n.locale.value).toFormat("cccc")),
      value: DateTime.fromObject({ weekday: i }).setLocale("en").toFormat("ccc").toLowerCase(),
    });
  }

  return weekdays;
};

export const getDateRounded5Minute = (date = new Date()) => {
  const isDateString = typeof date === "string";
  if (isDateString) {
    date = new Date(date);
  }

  if (!(date instanceof Date)) {
    throw new Error("Invalid date provided");
  }

  //round to nearest 5 minutes
  date.setMinutes(Math.ceil(date.getMinutes() / 5) * 5);

  return date;
};
