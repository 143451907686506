<template>
  <component
    :is="context === 'header' ? 'th' : 'td'"
    class="border-b border-inherit bg-inherit text-inherit group-hover:bg-inherit group-[:last-of-type]/row:border-0"
    :class="{
      'label h-9 py-2 pr-3 pl-5': context === 'header',
      'h-12 py-3 pr-3 pl-5': context === 'row',
      'cursor-pointer': show,
    }"
    width="52px"
    @click.stop="handleClick"
  >
    <Checkbox
      v-if="show"
      :tooltip="tooltip"
      :model-value="modelValue"
      :indeterminate="indeterminate"
      :readonly="readonly"
    />
    <div v-else class="size-5" />
  </component>
</template>

<script setup lang="ts">
  import Checkbox from "../Ticker/Checkbox.vue";

  const props = withDefaults(
    defineProps<{
      context: "header" | "row";
      indeterminate?: boolean;
      show?: boolean;
      readonly?: boolean;
      tooltip?: string;
    }>(),
    {
      show: true,
      tooltip: "",
    }
  );

  const modelValue = defineModel<boolean>();

  const handleClick = () => {
    if (!props.show) return;
    modelValue.value = !modelValue.value;
  };
</script>
