export default () => {
  const checkoutsForSelect = async () => {
    let checkouts = [];
    await homeFetch(`workspace/checkouts/select`)
      .then((r) => {
        checkouts = r.data;
      })
      .catch(() => {});
    return {
      checkouts: checkouts,
    };
  };

  return {
    checkoutsForSelect,
  };
};
