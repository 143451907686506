<template>
  <div class="flex flex-1 justify-center gap-1">
    <Button
      :size="ComponentSize.sm"
      icon-only
      :disabled="!pagination.prev_cursor"
      @click="() => goToPage(pagination.prev_cursor)"
      ><Icon src="arrow_left"
    /></Button>
    <Button
      :size="ComponentSize.sm"
      icon-only
      :disabled="!pagination.next_cursor"
      @click="() => goToPage(pagination.next_cursor)"
      ><Icon src="arrow_right"
    /></Button>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";
  import type { CursorPagination } from "~/types/paginate";

  //props for a laravel paginator
  withDefaults(
    defineProps<{
      pagination: CursorPagination;
    }>(),
    {}
  );

  const emit = defineEmits(["update:cursor"]);

  const goToPage = (cursor?: string | null) => {
    if (!cursor) return;

    emit("update:cursor", cursor);
  };
</script>
