<template>
  <component
    v-bind="$attrs"
    :is="componentType"
    ref="el"
    class="button relative box-border flex items-center gap-2 text-ellipsis transition-all duration-300 disabled:cursor-not-allowed [&>*]:pointer-events-none [&>*]:touch-none"
    :disabled="disabled"
    :to="props.to || props.href || ''"
    :href="componentType == 'a' ? props.href : undefined"
    :external="!props.to && props.href && props.href.startsWith('http')"
    :target="props.target"
    :class="{
      'items-center justify-center': !textLeft,
      'text-body-default-heavy': !isLink,
      'h-9 rounded-xl': props.size === ComponentSize.default && !isLink,
      'h-7 rounded-lg': props.size === ComponentSize.sm && !isLink,
      'h-11 rounded-xl': props.size === ComponentSize.lg && !isLink,

      'px-4 py-2': props.size === ComponentSize.default && !isLink && !iconOnly,
      'px-3 py-1': props.size === ComponentSize.sm && !isLink && !iconOnly,
      'px-5 py-3': props.size === ComponentSize.lg && !isLink && !iconOnly,

      'w-9 min-w-9': props.size === ComponentSize.default && !isLink && iconOnly,
      'w-7 min-w-7': props.size === ComponentSize.sm && !isLink && iconOnly,
      'w-11 min-w-11': props.size === ComponentSize.lg && !isLink && iconOnly,

      'text-body-default-heavy rounded-xl': props.size === ComponentSize.default && isLink,
      'text-body-sm-heavy rounded-xl': props.size === ComponentSize.sm && isLink,
      'text-body-lg-heavy rounded-xl': props.size === ComponentSize.lg && isLink,

      'border-01 bg-surface-lvl-00 text-secondary shadow-lvl-01 hover:border-02 active:bg-00 border active:shadow-none':
        props.variant === ButtonVariant.Default,
      '!bg-00 !shadow-none': props.variant === ButtonVariant.Default && active,

      'bg-inversed-00 text-inversed hover:bg-inversed-01 focus:bg-inversed-01 active:bg-inversed-02':
        props.variant === ButtonVariant.Primary,
      'bg-inversed-02': props.variant === ButtonVariant.Primary && active,

      'bg-01 text-primary hover:bg-02 focus:text-secondary': props.variant === ButtonVariant.Secondary,
      'bg-02': props.variant === ButtonVariant.Secondary && active,

      'text-secondary hover:bg-01 hover:text-primary active:bg-02': props.variant === ButtonVariant.Tertiary,
      'bg-02 text-primary': props.variant === ButtonVariant.Tertiary && active,

      'text-secondary-inversed hover:bg-01-inversed hover:text-primary-inversed active:bg-02-inversed':
        props.variant === ButtonVariant.TertiaryInversed,
      'bg-02-inversed': props.variant === ButtonVariant.TertiaryInversed && active,

      'bg-positive hover:bg-positive-dark active:bg-positive-dark text-white': props.variant === ButtonVariant.Positive,
      'bg-positive-dark': props.variant === ButtonVariant.Positive && active,

      'bg-destructive-01 text-destructive hover:bg-destructive-02 active:bg-destructive active:text-white':
        props.variant === ButtonVariant.Destructive,
      'bg-destructive text-white': props.variant === ButtonVariant.Destructive && active,

      'bg-warning-01 text-warning hover:bg-warning-02 active:bg-warning': props.variant === ButtonVariant.Warning,
      'bg-warning': props.variant === ButtonVariant.Warning && active,

      'text-destructive hover:bg-white active:bg-white': props.variant === ButtonVariant.Attention,
      'bg-white': props.variant === ButtonVariant.Attention && active,

      'text-brand hover:text-brand-dark active:bg-01': props.variant === ButtonVariant.PrimaryLink,
      'bg-01': props.variant === ButtonVariant.PrimaryLink && active,

      'text-secondary hover:text-primary active:bg-01 active:text-primary':
        props.variant === ButtonVariant.SecondaryLink,
      'active text-primary': props.variant === ButtonVariant.SecondaryLink && active,

      'text-destructive hover:text-destructive-dark active:bg-01 active:text-destructive-dark':
        props.variant === ButtonVariant.DestructiveLink,
      'active text-destructive-dark': props.variant === ButtonVariant.DestructiveLink && active,

      '!text-disabled !border-none !shadow-none': props.disabled,
      '!bg-disabled':
        props.disabled && props.variant !== ButtonVariant.SecondaryLink && props.variant !== ButtonVariant.Tertiary,
      '!text-disabled': props.disabled && isTextButton,
      'aspect-square': hasIcon,
      'shrink-0': !shrink,
      'pointer-events-none touch-none': loading,
    }"
  >
    <slot name="prefix" />
    <slot />
    <slot name="suffix" />
    <Transition name="checkmark-show">
      <div v-show="active && activeCheckmark" class="flex items-center overflow-hidden">
        <Icon class="text-inherit" src="check" />
      </div>
    </Transition>
    <div
      v-if="loading"
      class="absolute flex w-full items-center justify-center rounded bg-inherit"
      :class="{
        'h-9 rounded-xl': props.size === ComponentSize.default,
        'h-7 rounded-lg': props.size === ComponentSize.sm,
        'h-11 rounded-xl': props.size === ComponentSize.lg,
      }"
    >
      <div class="border-00 aspect-square h-2/5 animate-ping rounded-full border" />
    </div>
  </component>
</template>

<script setup lang="ts">
  import { NuxtLink } from "#components";
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import { computed, ref } from "vue";

  const props = withDefaults(
    defineProps<{
      size?: ButtonSize;
      variant?: ButtonVariant;
      disabled?: boolean;
      active?: boolean;
      loading?: boolean;
      href?: string;
      to?: string | { name: string; params?: Record<string, string | number> };
      iconOnly?: boolean;
      textLeft?: boolean;
      target?: string;
      activeCheckmark?: boolean;
      shrink?: boolean;
    }>(),
    {
      size: ComponentSize.default,
      variant: ButtonVariant.Default,
      disabled: false,
      active: false,
      loading: false,
      iconOnly: false,
      textLeft: false,
      target: "_self",
      activeCheckmark: false,
      shrink: false,
      href: undefined,
      to: undefined,
    }
  );

  const el = ref<HTMLElement>();

  const isTextButton = computed(() => isLink.value || props.variant === ButtonVariant.Tertiary);
  const isLink = computed(() => props.variant.endsWith("link"));

  const slots = defineSlots();

  //computed value to check if default slot is element with class 'nuxt-icon'
  const hasIcon = computed(() => {
    const defaultslot = slots.default?.()[0];

    if (!defaultslot) return false;

    return defaultslot?.type.name === "Icon";
  });

  const componentType = computed(() => {
    if (props.to) {
      return NuxtLink;
    }

    if (!props.href) {
      return "button";
    } else {
      //check if href is full url or relative
      if (props.href.startsWith("http")) {
        return "a";
      } else {
        return NuxtLink;
      }
    }
  });

  defineExpose({
    el,
  });
</script>
