<template>
  <div ref="flowWrapper" class="@container flex flex-col gap-6">
    <div>
      <div class="text-body-default text-tertiary">
        {{ t("balance") }}
      </div>
      <div class="flex justify-between gap-4">
        <div class="text-title-subsection text-secondary">{{ balance?.toLocaleString() || 0 }} DKK</div>
        <div class="flex items-center gap-2">
          <div class="text-body-default-heavy">
            {{ t("settings.payment.autofill.balance") }}
          </div>
          <InputNumber v-model="manualFillValue" class="w-[136px]" placeholder="min. 500" suffix="DKK" :min="500" />
          <Button
            :variant="ButtonVariant.Primary"
            :disabled="!manualFillValue || manualFillValue < 500"
            @click="manualFill"
          >
            {{ t("settings.payment.autofill.fill") }}
          </Button>
        </div>
      </div>
    </div>

    <div class="border-00 rounded-xl border p-4">
      <div class="flex justify-between">
        <div class="flex gap-3">
          <div class="text-body-default-heavy text-secondary">
            {{ t("settings.payment.autofill.activate") }}
          </div>
          <Chip type="kiwi">
            {{ t("recommended") }}
          </Chip>
        </div>
        <div>
          <Toggle v-model="options.autofill" :disabled="!savedCards?.length" />
        </div>
      </div>
      <div class="text-body-default text-tertiary">
        {{ t("settings.payment.autofill.description") }}
      </div>
      <div v-if="hasCards" class="my-4 grid grid-cols-1 gap-4 @lg:grid-cols-3">
        <div class="col-span-1">
          <DropdownSelect
            v-model="options.autofill_threshold"
            :label="t('settings.payment.autofill.threshold.placeholder')"
            :readonly="!options.autofill"
            :options="autofillOptions"
          />
        </div>
        <div class="col-span-1">
          <InputText
            v-model="options.autofill_amount"
            :label="t('settings.payment.autofill.amount.placeholder')"
            suffix="DKK"
            light-bg
            :readonly="!options.autofill"
          />
        </div>
        <div class="col-span-1">
          <DropdownSelect
            :model-value="savedCardsAsDropdownOptions[0]?.value"
            :options="savedCardsAsDropdownOptions"
            :label="t('settings.payment.autofill.card.placeholder')"
            readonly
          />
        </div>
      </div>
      <div class="flex flex-col items-end justify-between gap-4 md:flex-row md:items-center">
        <div v-if="hasCards" class="flex w-full justify-end gap-2">
          <Button :variant="ButtonVariant.Secondary" :disabled="!isOptionsDirty" @click="resetAutofillOptions">
            {{ t("undo") }}
          </Button>
          <Button :variant="ButtonVariant.Primary" :disabled="!isOptionsDirty" @click="updateAutofill">
            {{ t("save") }}
          </Button>
        </div>
        <div v-else class="text-body-default-heavy text-disabled mt-4 w-full">
          {{ t("settings.payment.autofill.requires_saved_card") }}
        </div>
      </div>
    </div>

    <Divider />

    <FlowSection :title="t('settings.payment.card.title')">
      <ListSelector :values="savedCardsAsDropdownOptions" name="card" radio-position="top">
        <template #avatar="{ value }">
          <CreditcardType :type="value.type" />
        </template>
        <template #title="{ value }">
          <div class="flex flex-col items-start gap-3">
            <div class="flex flex-col">
              <div class="text-body-lg-heavy text-secondary">
                {{ buildCardTitle(value.value) }}
              </div>
              <div class="text-body-default text-tertiary">
                {{ t("settings.payment.card.expiry") }}: {{ value.value.expiry_month }}/{{ value.value.expiry_year }}
              </div>
            </div>
            <div>
              <Button :variant="ButtonVariant.PrimaryLink" @click="removeCard">
                {{ t("settings.payment.card.remove") }}
              </Button>
            </div>
          </div>
        </template>
      </ListSelector>
      <Button v-if="!hasCards" class="mt-4" :variant="ButtonVariant.PrimaryLink" @click="addCard">
        {{ t("settings.payment.card.add") }}
      </Button>
    </FlowSection>

    <Divider />

    <FlowSection :title="t('settings.payment.history.title')" :description="t('settings.payment.history.description')">
      <template #headerButtons>
        <Button :variant="ButtonVariant.PrimaryLink" @click="exportBalanceModalOpen = true">
          {{ t("export_entity") }}
        </Button>
      </template>
      <Table :items="history" :headers="historyHeaders" />
      <TablePaginator :pagination="historyPaginationData" @paginate="onHistoryPaginate" />
    </FlowSection>
    <Modal v-model:open="exportBalanceModalOpen" max-width="400px">
      <section class="flex flex-col gap-4 p-4">
        <h2 class="text-body-default text-secondary">
          {{ t("export_entity_history", { entity: t("settings.payment.history.title").toLowerCase() }) }}
        </h2>
        <Datepicker
          v-model="exportBalanceDateRange"
          range
          default-today
          :label="t('date_range')"
          :max="DateTime.now().toISO()"
        />
        <footer class="flex flex-col gap-2 pt-4">
          <Button :variant="ButtonVariant.Primary" @click="exportBalance">
            {{ t("export_entity", { entity: t("settings.payment.history.title").toLowerCase() }) }}
          </Button>
          <Button :variant="ButtonVariant.Default" @click="closeExportBalanceModal">
            {{ t("cancel") }}
          </Button>
        </footer>
      </section>
    </Modal>
    <Divider />

    <FlowSection
      :title="t('settings.payment.autofills.title')"
      :description="t('settings.payment.autofills.description')"
    >
      <template #headerButtons>
        <Button :variant="ButtonVariant.PrimaryLink" @click="exportAutofillsModalOpen = true">
          {{ t("export_entity") }}
        </Button>
      </template>
      <Table :items="autofills" :headers="autofillsHeaders">
        <template #col.invoice="{ item }">
          <Button class="ml-auto" :variant="ButtonVariant.PrimaryLink" type="button" @click="() => getReceipt(item)">
            {{ t("settings.payment.history.invoice") }}
          </Button>
        </template>
      </Table>
      <TablePaginator :pagination="autofillsPaginationData" @paginate="onAutofillsPaginate" />
    </FlowSection>
    <Modal v-model:open="exportAutofillsModalOpen" max-width="400px">
      <section class="flex flex-col gap-4 p-4">
        <h2 class="text-body-default text-secondary">
          {{ t("export_entity_history", { entity: t("settings.payment.autofills.title").toLowerCase() }) }}
        </h2>
        <Datepicker
          v-model="exportAutofillsDateRange"
          range
          default-today
          :label="t('date_range')"
          :max="DateTime.now().toISO()"
        />
        <footer class="flex flex-col gap-2 pt-4">
          <Button :variant="ButtonVariant.Primary" @click="exportAutofills">
            {{ t("export_entity", { entity: t("settings.payment.autofills.title").toLowerCase() }) }}
          </Button>
          <Button :variant="ButtonVariant.Default" @click="closeExportAutofillsModal">
            {{ t("cancel") }}
          </Button>
        </footer>
      </section>
    </Modal>

    <NotificationWrapper />
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant } from "@/types/global";
  import Chip from "~/components/Badge/Chip.vue";
  import { DateTime } from "luxon";
  import type { ISettingsFlow } from "../types";
  import type { ITableHeader } from "~/components/Table/types";
  import type { Pagination } from "~/types/paginate";

  import NotificationWrapper from "../components/NotificationWrapper.vue";

  export interface ISubscription {
    payment_type: string;
    balance: Balance;
    card: Card;
  }

  export interface Balance {
    amount: number;
    autofill: number;
    autofill_amount: number;
    autofill_threshold: number;
  }

  export interface Card {
    type: null;
    active: null;
    number: null;
    expiry_month: null;
    expiry_year: null;
  }
  const props = withDefaults(
    defineProps<{
      currentHeader?: string | null;
    }>(),
    {
      currentHeader: null,
    }
  );
  const { t } = useI18n();

  const balance = ref();
  const manualFillValue = ref();

  let initialOptions = {
    autofill: 0,
    autofill_threshold: 0,
    autofill_amount: 0,
  };

  const options = ref({
    autofill: 0,
    autofill_threshold: 0,
    autofill_amount: 0,
  });

  const autofillOptions = [
    { label: "500 DKK", value: 500 },
    { label: "1000 DKK", value: 1000 },
    { label: "2000 DKK", value: 2000 },
    { label: "5000 DKK", value: 5000 },
    { label: "10000 DKK", value: 10000 },
  ];

  const isOptionsDirty = computed(() => {
    return (
      options.value.autofill != initialOptions.autofill ||
      options.value.autofill_threshold != initialOptions.autofill_threshold ||
      options.value.autofill_amount != initialOptions.autofill_amount
    );
  });

  const savedCards = ref<Card[]>([]);
  const savedCardsAsDropdownOptions = computed(() => {
    return savedCards.value.map((card) => {
      return {
        label: buildCardTitle(card),
        value: card,
      };
    });
  });
  const historyPage = ref(1);
  const history = ref<any[]>([]);
  const historyPaginationData = ref<Pagination>({
    current_page: 1,
    last_page: 1,
    per_page: 1,
    total: 1,
  });
  const autofillsPage = ref(1);
  const autofills = ref<any[]>([]);
  const autofillsPaginationData = ref<Pagination>({
    current_page: 1,
    last_page: 1,
    per_page: 1,
    total: 1,
  });

  const fetchSubscriptionInfo = () =>
    homeFetch("workspace/payments/subscription").then((response) => {
      if (response?.data) {
        const data = response.data as ISubscription;
        balance.value = data.balance.amount;
        options.value.autofill = data.balance.autofill;
        options.value.autofill_threshold = data.balance.autofill_threshold;
        options.value.autofill_amount = data.balance.autofill_amount;

        initialOptions = {
          autofill: data.balance.autofill,
          autofill_threshold: data.balance.autofill_threshold,
          autofill_amount: data.balance.autofill_amount,
        };

        if (data.card && data.card.type) {
          savedCards.value = [data.card];
        } else {
          savedCards.value = [];
        }
      }
    });

  const onHistoryPaginate = (page: number) => {
    historyPage.value = page;
  };

  const onAutofillsPaginate = (page: number) => {
    autofillsPage.value = page;
  };

  const paginateHistory = () => {
    homeFetch("workspace/payments/history", {
      query: {
        page: historyPage.value,
        limit: 20,
      },
    }).then((response) => {
      if (response?.data) {
        history.value = response.data.data;

        historyPaginationData.value = getPaginationData(response.data);
      }
    });
  };

  const paginateAutofills = () => {
    homeFetch("workspace/payments/autofills", {
      query: {
        page: autofillsPage.value,
        limit: 20,
      },
    }).then((response) => {
      if (response?.data) {
        autofills.value = response.data.data;

        autofillsPaginationData.value = getPaginationData(response.data);
      }
    });
  };

  const updateAutofill = () => {
    homeFetch("workspace/payments/update-autofill", {
      method: "POST",
      silent: true,
      body: {
        autofill: options.value.autofill,
        autofill_threshold: options.value.autofill_threshold,
        autofill_amount: options.value.autofill_amount,
      },
    })
      .then((r) => {
        initialOptions = {
          autofill: options.value.autofill,
          autofill_threshold: options.value.autofill_threshold,
          autofill_amount: options.value.autofill_amount,
        };

        $toast.add({
          title: t("settings.payment.autofill.update.success"),
          target: "#settings-modal .notification-wrapper",
        });
      })
      .catch((e) => {
        if (e.response?._data?.data?.message) {
          $toast.add({
            title: e.response._data.data.message,
            target: "#settings-modal .notification-wrapper",
            type: "error",
          });
        } else if (e.message) {
          $toast.add({
            title: e.message,
            target: "#settings-modal .notification-wrapper",
            type: "error",
          });
        } else {
          $toast.add({
            title: t("unexpected_error"),
            target: "#settings-modal .notification-wrapper",
            type: "error",
          });
        }
      });
  };

  const manualFill = () => {
    homeFetch("workspace/payments/balance", {
      method: "POST",
      body: {
        amount: manualFillValue.value,
        final_url: window.location.origin + "/redirects/ordersuccess",
      },
      silent: true,
    })
      .then((response) => {
        window.open(response.data.payment_url);
        window.addEventListener("message", subscriptionEventHandler);
      })
      .catch(() => {
        $toast.add({
          title: t("unexpected_error"),
          target: "#settings-modal .notification-wrapper",
          type: "error",
        });
      });
  };

  const resetAutofillOptions = () => {
    options.value.autofill = initialOptions.autofill;
    options.value.autofill_threshold = initialOptions.autofill_threshold;
    options.value.autofill_amount = initialOptions.autofill_amount;
  };

  watch(historyPage, () => {
    paginateHistory();
  });

  watch(autofillsPage, () => {
    paginateAutofills();
  });

  const historyHeaders = ref<ITableHeader[]>([
    {
      key: "created_at",
      label: t("date"),
      type: "date",
    },
    {
      key: "text",
      label: t("description"),
    },
    {
      key: "amount",
      label: t("amount"),
      align: "right",
      type: "price",
    },
  ]);

  const autofillsHeaders = ref<ITableHeader[]>([
    {
      key: "created_at",
      label: t("date"),
      type: "date",
    },
    {
      key: "amount",
      label: t("amount"),
      align: "right",
      type: "price",
    },
    {
      align: "right",
      key: "invoice",
      label: "",
      labelless: true,
    },
  ]);

  const exportAutofills = () => {
    const from = formatDate(exportAutofillsDateRange.value.from);
    const to = formatDate(exportAutofillsDateRange.value.to);
    homeFetch("workspace/payments/autofills/csv" + `?from=${from}&to=${to}`, {
      method: "GET",
    })
      .then((response) => {
        exportCSV(response, t("settings.payment.autofills.title"), from, to);
        closeExportAutofillsModal();
      })
      .catch(() => {
        $toast.add({
          title: t("unexpected_error"),
          target: "#settings-modal .notification-wrapper",
          type: "error",
        });
      })
      .finally(() => {});
  };

  const exportBalance = () => {
    const from = formatDate(exportBalanceDateRange.value.from);
    const to = formatDate(exportBalanceDateRange.value.to);
    homeFetch("workspace/payments/history/csv" + `?from=${from}&to=${to}`, {
      method: "GET",
    })
      .then((response) => {
        exportCSV(response, t("settings.payment.history.title"), from, to);
        closeExportBalanceModal();
      })
      .catch(() => {
        $toast.add({
          title: t("unexpected_error"),
          target: "#settings-modal .notification-wrapper",
          type: "error",
        });
      });
  };

  const formatDate = (date: Date) => {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
  };

  const exportCSV = (csv: string, name: string, from: string, to: string) => {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8," });
    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objUrl;
    link.download = `export-${name.toLowerCase().replaceAll(" ", "_")}-${from}_${to}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(objUrl);
  };

  const addCard = () => {
    homeFetch("workspace/payments/card", {
      method: "POST",
      body: {
        final_url: window.location.origin + "/redirects/ordersuccess",
      },
    }).then((response) => {
      window.open(response.data.redirect_url);
      window.addEventListener("message", subscriptionEventHandler);
    });
  };

  const removeCard = () => {
    $confirm({
      message: t("settings.payment.card.remove_confirm"),
      onConfirm: () => {
        homeFetch("workspace/payments/card", {
          method: "DELETE",
          silent: true,
        })
          .then(() => {
            fetchSubscriptionInfo();
            $toast.add({
              title: t("settings.payment.card.remove_success"),
              target: "#settings-modal .notification-wrapper",
            });
          })
          .catch(() => {
            $toast.add({
              title: t("settings.payment.card.remove_error"),
              target: "#settings-modal .notification-wrapper",
              type: "error",
            });
          });
      },
    });
  };

  const subscriptionEventHandler = (event) => {
    if (event.data === "payment:success") {
      fetchSubscriptionInfo();
      window.removeEventListener("message", subscriptionEventHandler);
    }
  };

  const buildCardTitle = (card: Card) => {
    let cardType: string = card.type!;

    switch (cardType) {
      case "visa":
        cardType = "Visa";
        break;
      case "visa_dk":
        cardType = "Visa/Dankort";
        break;
      case "visa-electron":
        cardType = "Visa Electron";
        break;
      case "mastercard":
        cardType = "Mastercard";
        break;
      case "mastercard-debet":
        cardType = "Mastercard Debet";
        break;
      default:
        cardType = "";
        break;
    }

    //get last 8 digits of card number and replace X with *
    const cardNumber = (card.number! as string).slice(-8).replace(/X/g, "*");

    let title = "";

    if (cardType) {
      title = `${cardType} ${cardNumber}`;
    } else {
      title = cardNumber;
    }

    return title;
  };

  const hasCards = computed(() => savedCards.value.length > 0);

  const getReceipt = (item) => {
    homeFetch(`workspace/payments/${item.transaction_id}/download`, {
      method: "GET",
      silent: true,
    })
      .then((response) => {
        window.open(response.data.url);
      })
      .catch(() => {
        $toast.add({
          title: t("unexpected_error"),
          target: "#settings-modal .notification-wrapper",
          type: "error",
        });
      });
  };

  onMounted(() => {
    setLoading(true);
    Promise.all([fetchSubscriptionInfo(), paginateHistory(), paginateAutofills()]).then(() => {
      setLoading(false);
      goToCurrentHeader();
    });
  });

  const exportAutofillsModalOpen = ref(false);
  const exportAutofillsDateRange = ref({ from: new Date(), to: new Date() });
  const exportBalanceModalOpen = ref(false);
  const exportBalanceDateRange = ref({ from: new Date(), to: new Date() });

  const closeExportAutofillsModal = () => {
    exportAutofillsDateRange.value = { from: new Date(), to: new Date() };
    exportAutofillsModalOpen.value = false;
  };

  const closeExportBalanceModal = () => {
    exportBalanceDateRange.value = { from: new Date(), to: new Date() };
    exportBalanceModalOpen.value = false;
  };

  const emits = defineEmits(["update:loading"]);
  const setLoading = (l: boolean) => emits("update:loading", l);

  const { flowWrapper, goToCurrentHeader } = useFlowWrapper(toRef(() => props.currentHeader));
</script>

<script lang="ts">
  export const usePaymentSettings = (): ISettingsFlow => {
    const { t } = useI18n();
    const { hasCapability } = useCapabilities();
    const icon = "regular/receipt";
    const title = t("settings.payment.title");
    const key = "payment";
    const condition = computed(() => !useWorkspacesStore().isDFM && hasCapability("account.payment"));

    return {
      icon,
      title,
      key,
      condition,
    };
  };
</script>
