/**
 * Set a cookie with a name, value and expiration date in miliseconds, on current top domain
 * @param {ICookie} cookie - An object containing `name`, `value`, and `expiration` properties.
 */
export const setCookie = (cookie: ICookie) => {
  const { name, value, expiration } = cookie;
  let expires: Date | undefined = new Date();
  if (!expiration) expires = undefined;
  else expires.setTime(expires.getTime() + expiration);

  const c = useCookie(name, {
    expires,
    path: "/",
    domain: getCookieDomain(),
  });

  c.value = value;
};

/**
 * Get a cookie by name
 * @param {string} name - The name of the desired cookie.
 * @returns {string|null} - The value of the desired cookie, or null if it doesn't exist.
 */
export const getCookie = (name: string): string | null | undefined => {
  return useCookie(name).value;
};

/**
 * Remove a cookie by name
 * @param {string} name - The name of the cookie to be removed.
 * @returns {void}
 * @example
 * removeCookie('hr_token')
 * // => 'hr_token' cookie is removed
 */
export const removeCookie = (name: string): void => {
  useCookie(name, {
    path: "/",
    domain: getCookieDomain(),
    expires: new Date(0),
  }).value = "";
};

const getCookieDomain = () => {
  const hostname = useRequestURL().hostname;

  if (hostname === "localhost") {
    return "localhost";
  }

  const domainParts = hostname.split(".");
  const tld = domainParts.pop();
  const domain = domainParts.pop();

  return `.${domain}.${tld}`;
};
