<template>
  <Modal
    ref="modal"
    v-model:open="openParams"
    class="shadow-lvl-05"
    :click-to-close="false"
    max-width="1020px"
    :content-padding="false"
    :center="true"
    anchor="top"
    :bgset="false"
  >
    <div class="!h-[756px] max-h-full w-[1020px] max-w-full py-2.5 bg-blend-overlay">
      <div class="border-00 bg-surface-lvl-00 mx-auto h-full w-full max-w-[400px] rounded-xl border-2">
        <div class="flex items-center px-4 py-2">
          <p class="text-body-lg-heavy text-secondary">{{ t("user_defined_params") }}</p>
          <Button class="ml-auto" :variant="ButtonVariant.Tertiary" :size="ComponentSize.sm" @click="emit('close')"
            ><Icon src="close"
          /></Button>
        </div>
        <div class="p-4">
          <p class="text-body-default text-secondary">
            {{ t("user_defined_params_desc") }}
          </p>
        </div>
        <Overlay v-if="loading" class="mx-auto h-full min-h-16 max-w-[400px]" />
        <div class="w-full p-2.5">
          <Button class="mb-4 w-full" :variant="ButtonVariant.Default" @click="addNew()">{{ t("create_new") }}</Button>
          <div class="max-h-[530px] overflow-auto">
            <AccordionListPartialsItem
              v-for="(filter, index) in filters"
              :key="filter.id"
              class="mb-1"
              :title="filter.title"
              :open="selectedFilter == index"
              :index="index"
              :subtitle="filter.type ? getFilterType(filter.type) : ''"
              @open-item="(val) => selectFilter(val)"
            >
              <div class="flex flex-col gap-3">
                <div class="w-full">
                  <p class="text-body-sm text-quarterary">{{ t("used_on_these_checkouts") }}</p>
                  <div v-if="filter.checkouts.length !== 0" class="flex flex-wrap gap-1">
                    <BadgeChip v-for="checkout in filter.checkouts" :key="checkout">
                      {{ checkout }}
                    </BadgeChip>
                  </div>
                  <div v-else>
                    <p class="text-body-sm text-quarterary">{{ t("not_used") }}</p>
                  </div>
                </div>
                <Divider />
                <div class="flex flex-col gap-2">
                  <div class="flex items-center gap-1">
                    <p class="text-body-sm-heavy text-secondary w-[50px]">{{ t("title") }}</p>
                    <InputText v-model="filter.title" class="w-full" dark-bg @focus="$event.target.select()" />
                  </div>
                  <div class="flex items-center gap-1">
                    <p class="text-body-sm-heavy text-secondary w-[50px]">{{ t("key") }}</p>
                    <InputText v-model="filter.key" class="w-full" dark-bg />
                  </div>
                  <div class="flex items-center gap-1">
                    <p class="text-body-sm-heavy text-secondary w-[50px]">{{ t("type") }}</p>
                    <DropdownSelect v-model="filter.type" class="w-full" :options="types" />
                  </div>
                </div>
                <Divider />
                <div class="flex gap-2">
                  <Button v-if="filter.id" :variant="ButtonVariant.DestructiveLink" @click="deleteFilter(filter.id)">{{
                    t("delete")
                  }}</Button>
                  <Button
                    v-if="
                      (filter.id && filter.title !== _filterCopies[index].title) ||
                      (filter.id && filter.key !== _filterCopies[index].key) ||
                      (filter.id && filter.type !== _filterCopies[index].type)
                    "
                    class="ml-auto"
                    :variant="ButtonVariant.Tertiary"
                    @click="cancelEdit(filter.id)"
                    >{{ t("cancel") }}</Button
                  >
                  <Button
                    v-if="filter.id"
                    :variant="ButtonVariant.Primary"
                    :class="
                      filter.title == _filterCopies[index].title &&
                      filter.key == _filterCopies[index].key &&
                      filter.type == _filterCopies[index].type
                        ? 'ml-auto'
                        : ''
                    "
                    @click="updateFilter(filter)"
                    >{{ t("save_changes") }}</Button
                  >
                  <Button v-if="!filter.id" class="ml-auto" :variant="ButtonVariant.Tertiary" @click="cancelEdit(0)">{{
                    t("cancel")
                  }}</Button>
                  <Button
                    v-if="!filter.id"
                    :disabled="!filter.title || !filter.key || !filter.type"
                    :variant="ButtonVariant.Primary"
                    @click="createFilter(filter)"
                    >{{ t("create") }}</Button
                  >
                </div>
              </div>
            </AccordionListPartialsItem>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";

  export interface Filter {
    created_at: Date;
    customer_id: number;
    id: number;
    key: string;
    title: string;
    type: string;
    updated_at: Date;
  }
  const openParams = defineModel<boolean>("openParams", { default: false });
  const emit = defineEmits(["close"]);
  const { t } = useI18n();
  defineProps<{
    checkoutId?: number;
  }>();
  const loading = ref(false);
  const filters = ref<Filter[]>([]);
  const _filterCopies = ref<Filter[]>([]);
  const types = ref([]);
  const selectedFilter = ref<number>();

  onMounted(() => {
    fetchParams();
  });

  const selectFilter = (index: number) => {
    if (selectedFilter.value === index) {
      selectedFilter.value = undefined;
    } else {
      selectedFilter.value = index;
    }
  };

  const addNew = () => {
    let title = t("user_defined_param");
    if (filters.value.some((f) => f.title.includes(t("user_defined_param")))) {
      const userFilters = filters.value.filter((f) => f.title.includes(t("user_defined_param")));
      if (userFilters[0].title.includes("(")) {
        const index = userFilters[0].title.split("(")[1].split(")")[0];
        title = `${t("user_defined_param")} (${parseInt(index) + 1})`;
      } else {
        title = `${t("user_defined_param")} (1)`;
      }
    }
    const newFilter = {
      title: title,
      key: "",
      type: "text",
      checkouts: [],
    };
    filters.value.unshift(newFilter);
    _filterCopies.value.unshift(newFilter);
    selectedFilter.value = 0;
  };

  const cancelEdit = (id: number) => {
    if (id === 0) {
      filters.value.shift();
      _filterCopies.value.shift();
      selectedFilter.value = undefined;
      return;
    }
    const copy = _filterCopies.value.find((f) => f.id === id);
    const filter = filters.value.find((f) => f.id === id);
    if (copy && filter) {
      filter.title = copy.title;
      filter.key = copy.key;
      filter.type = copy.type;
    }
  };

  const deleteFilter = (id: number) => {
    $confirm({
      message: t("confirm_delete_param"),
      destructive: true,
      onConfirm: () => {
        homeFetch(`workspace/checkouts/filters/${id}`, {
          method: "DELETE",
        })
          .then(() => {
            $toast.add({
              title: t("param_deleted"),
            });
            selectedFilter.value = undefined;
            fetchParams();
          })
          .catch(() => {
            loading.value = false;
          });
      },
    });
  };

  const createFilter = (filter: object) => {
    loading.value = true;
    homeFetch(`workspace/checkouts/filters`, {
      method: "POST",
      body: {
        ...filter,
      },
    })
      .then(() => {
        $toast.add({
          title: t("param_created"),
        });
        fetchParams();
      })
      .catch(() => {
        loading.value = false;
      });
  };

  const updateFilter = (filter: object) => {
    loading.value = true;
    homeFetch(`workspace/checkouts/filters/${filter.id}`, {
      method: "PUT",
      body: {
        ...filter,
      },
    })
      .then(() => {
        $toast.add({
          title: t("param_updated"),
        });
        fetchParams();
      })
      .catch(() => {
        loading.value = false;
      });
  };

  const fetchParams = () => {
    loading.value = true;
    homeFetch("workspace/checkouts/filters")
      .then((response) => {
        if (response?.data) {
          filters.value = response.data.filters as Filter[];
          _filterCopies.value = structuredClone(response.data.filters as Filter[]);
          types.value = response.data.types;
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const getFilterType = (type: string) => {
    const filterType = types.value.find((t) => t.value === type);
    if (filterType) {
      return filterType.label;
    }
    return t("this_type_has_been_outfaced");
  };
</script>
