<template>
  <div class="flex flex-col gap-2">
    <label v-if="label" class="text-body-sm-heavy text-secondary text-left">{{ label }}</label>
    <div class="main-input flex items-center gap-1">
      <Input dark-bg equal-padding :size="size" type="time" :class="{ error }">
        <select
          v-model="hourValue"
          class="disabled:text-disabled flex !min-w-[22px] appearance-none items-center justify-center bg-transparent text-center outline-none disabled:cursor-not-allowed"
          name="hours"
          tabindex="0"
          :disabled="disabled"
        >
          <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}</option>
        </select>
      </Input>
      :
      <Input dark-bg equal-padding :size="size" type="time" :class="{ error }">
        <select
          v-model="minuteValue"
          class="disabled:text-disabled flex appearance-none items-center justify-center bg-transparent text-center outline-none disabled:cursor-not-allowed"
          name="minutes"
          tabindex="0"
          :disabled="disabled"
        >
          <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}</option>
        </select>
      </Input>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";
  import _ from "lodash";
  import { computed } from "vue";

  const props = withDefaults(
    defineProps<{
      size?: InputSize;
      disabled?: boolean;
      selected?: boolean;
      active?: boolean;
      name?: string;
      error?: string;
      minutesInterval?: number;
      label?: string;
      valueAsDate?: boolean;
      darkBg?: boolean;
      minMax?: string;
    }>(),
    {
      size: ComponentSize.default,
      disabled: false,
      selected: false,
      active: false,
      name: "",
      minutesInterval: 15,
      label: "",
      valueAsDate: false,
      darkBg: false,
      minMax: "",
      error: undefined,
    }
  );

  const _modelValue = defineModel<string>();

  onMounted(() => {
    if (props.valueAsDate && !inputValue.value) {
      inputValue.value = getCurrentDateRoundedToTimeInterval();
    } else if (!props.valueAsDate && !inputValue.value) {
      inputValue.value = "00:00";
    }
  });

  const getCurrentDateRoundedToTimeInterval = () => {
    const date = new Date();
    const minutes = date.getMinutes();
    const roundedMinutes = Math.ceil(minutes / props.minutesInterval) * props.minutesInterval;
    if (roundedMinutes === 60) {
      date.setHours(date.getHours() + 1);
      date.setMinutes(15);
    } else if (roundedMinutes === 0) {
      date.setMinutes(15);
    } else {
      date.setMinutes(roundedMinutes);
    }
    if (props.minMax) {
      const [min, max] = props.minMax.split("-");
      if (date.getHours() < parseInt(min.split(":")[0])) {
        date.setHours(parseInt(min.split(":")[0]));
        date.setMinutes(parseInt(min.split(":")[1]));
      }
      if (date.getHours() > parseInt(max.split(":")[0])) {
        date.setHours(parseInt(max.split(":")[0]));
        date.setMinutes(parseInt(max.split(":")[1]));
      }
    }
    return date;
  };
  const {
    value: inputValue,
    setValue,
    errorMessage,
  } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  const error = computed(() => {
    return props.error || errorMessage.value;
  });

  const minMax = computed(() => props.minMax);

  const input = ref<HTMLElement | null>(null);

  const el = computed(() => {
    return input.value?.input;
  });

  const hourChange = ref(false);

  watch(minMax, (value) => {
    if (value) {
      const [min, max] = value.split("-");
      if (inputValue.value) {
        if (new Date(inputValue.value).getHours() < parseInt(min.split(":")[0])) {
          const date = new Date(inputValue.value);
          date.setHours(parseInt(min.split(":")[0]));
          date.setMinutes(parseInt(min.split(":")[1]));
          setValue(date);
        }
        if (new Date(inputValue.value).getHours() > parseInt(max.split(":")[0])) {
          const date = new Date(inputValue.value);
          date.setHours(parseInt(max.split(":")[0]));
          date.setMinutes(parseInt(max.split(":")[1]));
          setValue(date);
        }
      }
    }
  });

  const emit = defineEmits(["click", "update:modelValue"]);

  const hours = computed(() => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      if (minMax.value) {
        const [min, max] = minMax.value.split("-");
        if (i < parseInt(min.split(":")[0]) || i > parseInt(max.split(":")[0])) {
          continue;
        }
      }
      hours.push(i < 10 ? `0${i}` : `${i}`);
    }
    return hours;
  });

  const minutes = computed(() => {
    const minutes = [];
    for (let i = 0; i < 60; i += props.minutesInterval) {
      if (minMax.value) {
        const [min, max] = minMax.value.split("-");
        if (new Date(inputValue.value).getHours() == parseInt(min.split(":")[0]) && i < parseInt(min.split(":")[1])) {
          continue;
        }
        if (new Date(inputValue.value).getHours() == parseInt(max.split(":")[0]) && i > parseInt(max.split(":")[1])) {
          continue;
        }
      }
      minutes.push(i < 10 ? `0${i}` : `${i}`);
    }
    if (inputValue.value && !_.isString(inputValue.value)) {
      const rounded_minutes =
        Math.ceil(inputValue.value.getMinutes() / props.minutesInterval) * props.minutesInterval || "00";
      if (!minutes.includes(rounded_minutes.toString())) {
        const date = new Date(inputValue.value);
        date.setMinutes(parseInt(minutes[0]));
        inputValue.value = date;
      }
    }
    return minutes;
  });

  const hourValue = computed({
    get: () => {
      if (!inputValue.value) return "00";
      if (props.valueAsDate) {
        if (isString(inputValue.value)) {
          const hours = new Date(inputValue.value).getHours();
          if (hours < 10) {
            return `0${hours}`;
          }
          return hours;
        }
        const hours = (inputValue.value as Date).getHours();
        if (hours < 10) {
          return `0${hours}`;
        }
        return hours;
      } else {
        return inputValue.value.split(":")[0];
      }
    },
    set: (value: string) => {
      hourChange.value = !hourChange.value;
      if (props.valueAsDate) {
        if (isString(inputValue.value)) {
          const date = new Date();
          date.setHours(parseInt(value));
          setValue(date);
          emit("update:modelValue", date);
        } else {
          const date = inputValue.value as Date;
          date.setHours(parseInt(value));
          setValue(date);
          emit("update:modelValue", date);
        }
      } else {
        setValue(`${value}:${minuteValue.value}`);
      }
    },
  });

  const minuteValue = computed({
    get: () => {
      if (!inputValue.value) return "00";
      if (props.valueAsDate) {
        if (isString(inputValue.value)) {
          if (new Date(inputValue.value).getMinutes() == 0) {
            return "00";
          }
          return new Date(inputValue.value).getMinutes();
        }
        if ((inputValue.value as Date).getMinutes() == 0) {
          return "00";
        }
        return (inputValue.value as Date).getMinutes();
      } else {
        return inputValue.value.split(":")[1];
      }
    },
    set: (value: string) => {
      if (props.valueAsDate) {
        if (isString(inputValue.value)) {
          const date = new Date(inputValue.value);
          date.setMinutes(parseInt(value));
          setValue(date);
          emit("update:modelValue", date);
        } else {
          const date = inputValue.value as Date;
          date.setMinutes(parseInt(value));
          setValue(date);
          emit("update:modelValue", date);
        }
      } else {
        setValue(`${hourValue.value}:${value}`);
      }
    },
  });

  defineExpose({
    el,
  });
</script>
