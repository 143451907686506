<template>
  <div ref="autoCompleteComponent" class="relative">
    <InputText
      ref="trigger"
      v-model="inputValue"
      :name="name"
      :size="ComponentSize.lg"
      :label="label"
      :placeholder="placeholder"
      @focus="$event.target.select()"
      @click="open = !open"
    >
      <template #prefix>
        <Icon class="text-quarterary" src="bold/magnifying_glass" :size="ComponentSize.default" />
      </template>
    </InputText>
    <div
      v-if="open"
      class="border-00 bg-surface-lvl-00 absolute top-[72px] left-0 z-50 max-h-[323px] w-full overflow-auto rounded-xl border-2 p-1.5 text-left"
    >
      <div
        v-for="option in filteredOptions"
        :key="option.value"
        class="group hover:bg-01 flex items-center gap-1 rounded-lg px-3 py-1.5 transition-all duration-300"
        @click="setOption(option)"
      >
        <Entry :entry="option" :search="inputValue" />
        <Button
          class="pointer-events-none ml-auto opacity-0 group-hover:pointer-events-auto group-hover:opacity-100"
          type="button"
          :variant="ButtonVariant.Tertiary"
          :size="ComponentSize.sm"
          icon-only
          @click.stop="emitDelete(option)"
        >
          <Icon src="close" />
        </Button>
      </div>
      <Overlay v-if="loading" class="h-16 min-h-16" />
      <div
        v-if="filteredOptions.length === 0 && !loading"
        class="text-body-default text-secondary flex rounded-lg px-3 py-1.5"
      >
        {{ emptyText }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import outsideClick from "~/composables/useOutsideClick";
  import { ButtonVariant, ComponentSize } from "~/types/global";

  import Entry from "./Entry.vue";

  const open = ref<boolean>(false);

  const autoCompleteComponent = ref();

  const emit = defineEmits(["update:modelValue", "delete"]);

  const props = withDefaults(
    defineProps<{
      options: any[];
      placeholder: string;
      label: string;
      name: string;
      loading: boolean;
      emptyText: string;
    }>(),
    {
      options: () => [],
      placeholder: "",
      label: "",
      name: "",
      loading: false,
      emptyText: "",
    }
  );

  const setOption = (option: object) => {
    inputValue.value = option.label;
    emit("update:modelValue", option.value);
    open.value = false;
  };

  const emitDelete = (option: object) => {
    emit("delete", option.value);
  };

  outsideClick(autoCompleteComponent, () => {
    open.value = false;
  });

  const filteredOptions = computed(() => {
    const entries = props.options.filter((option) => {
      if (option.label && inputValue.value) {
        if (option.label.toLowerCase().includes(inputValue.value.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    return entries;
  });

  const { value: inputValue } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });
</script>
