<template>
  <div>
    <div class="w-full grid grid-cols-2 px-6 gap-6">
      <div class="w-full h-[67vh] overflow-y-auto">
        <div class="w-full flex">
          <div class="w-full group">
            <div class="flex gap-3 mb-3">
              <p class="text-secondary text-body-default-heavy">{{ t("sender") }}</p>
              <Button
                v-if="canChangeSender"
                class="max-h-max"
                type="button"
                :variant="ButtonVariant.PrimaryLink"
                @click="emit('changeStep', 0)"
                >{{ t("edit") }}</Button
              >
            </div>
            <div>
              <p class="text-tertiary text-body-default mb-1">
                {{ values.sender.business ? values.sender.company_name : values.sender.name }}
              </p>
              <p v-if="values.sender.business" class="text-tertiary text-body-default">{{ values.sender.name }}</p>
              <p class="text-tertiary text-body-default">{{ values.sender.street1 }}</p>
              <p class="text-tertiary text-body-default mb-1">{{ values.sender.street2 }}</p>
              <p class="text-tertiary text-body-default">{{ values.sender.zip_code }} {{ values.sender.city }}</p>
              <p class="text-tertiary text-body-default">{{ getCountryName(values.sender.country) }}</p>
            </div>
          </div>
          <Divider class="mx-6" rotation="vertical" />
          <div class="group w-full pb-3">
            <div class="flex gap-3 mb-3">
              <p class="text-secondary text-body-default-heavy">{{ t("receiver") }}</p>
              <Button
                v-if="canChangeReceiver"
                class="max-h-max"
                type="button"
                :variant="ButtonVariant.PrimaryLink"
                @click="emit('changeStep', 0)"
                >{{ t("edit") }}</Button
              >
            </div>
            <p class="text-tertiary text-body-default mb-1">
              {{ values.receiver.business ? values.receiver.company_name : values.receiver.name }}
            </p>
            <p v-if="values.receiver.business" class="text-tertiary text-body-default">{{ values.receiver.name }}</p>
            <p class="text-tertiary text-body-default">{{ values.receiver.street1 }}</p>
            <p class="text-tertiary text-body-default">{{ values.receiver.street2 }}</p>
            <p class="text-tertiary text-body-default">{{ values.receiver.zip_code }} {{ values.receiver.city }}</p>
            <p class="text-tertiary text-body-default mb-1">{{ getCountryName(values.receiver.country) }}</p>
            <p class="text-tertiary text-body-default">{{ values.receiver.email }}</p>
            <p class="text-tertiary text-body-default">{{ values.receiver.phone }}</p>
          </div>
        </div>
        <Divider class="col-span-2 mb-3 !mt-0" />
        <div class="w-full group">
          <div class="flex gap-3 mb-3">
            <p class="text-secondary text-body-default-heavy">{{ t("size_and_weight") }}</p>
            <Button class="max-h-max" type="button" :variant="ButtonVariant.PrimaryLink" @click="emit('changeStep', 1)">{{
              t("edit")
            }}</Button>
          </div>
          <div class="flex gap-1 items-center">
            <p class="text-tertiary text-body-default-heavy">
              L:{{ values.length }} <span class="text-quarterary">cm</span>
            </p>
            <Icon class="text-quarterary" :size="ComponentSize.sm" src="close" />
            <p class="text-tertiary text-body-default-heavy">
              B:{{ values.width }} <span class="text-quarterary">cm</span>
            </p>
            <Icon class="text-quarterary" :size="ComponentSize.sm" src="close" />
            <p class="text-tertiary text-body-default-heavy">
              H:{{ values.height }} <span class="text-quarterary">cm</span>
            </p>
            <Divider class="mx-3 h-5" rotation="vertical" />
            <p class="text-tertiary text-body-default-heavy">
              {{ useFormatWeight(values.weight, { withoutUnit: true }) }}
              <span class="text-quarterary">{{ usePreferredWeightUnit() }}</span>
            </p>
          </div>
        </div>
        <Divider v-if="values.set_order_lines" class="col-span-2 my-3" />
        <div v-if="values.set_order_lines" class="w-full">
          <div>
            <p class="text-secondary text-body-default-heavy mb-3">{{ t("order_lines") }}</p>
          </div>
          <div>
            <div v-for="(order_line, i) in values.set_order_lines" :key="i" class="flex gap-1.5 flex-wrap mb-1.5">
              <p class="text-tertiary text-body-default w-[30px]">{{ order_line.qty }}x</p>
              <div>
                <p class="text-tertiary text-body-default">
                  {{ order_line?.item_number }} {{ order_line?.customs[0]?.description }}
                  {{ t("weighing").toLocaleLowerCase() }}
                  {{ parseInt(order_line?.customs[0]?.weight) * order_line?.qty }}g
                  {{ t("costing").toLocaleLowerCase() }} {{ parseInt(order_line?.customs[0]?.total_price).toFixed(2) }}
                  {{ t("from").toLocaleLowerCase() }} {{ getCountryName(order_line?.customs[0]?.origin_country) }}
                </p>
                <BadgeChip v-if="order_line?.dangerous_goods_set" type="bold">{{ t("dangerous_goods") }}</BadgeChip>
              </div>
            </div>
          </div>
          <Divider class="col-span-2 my-3" />
        </div>
      </div>
      <div class="w-full h-[67vh] rounded-xl flex flex-col overflow-hidden">
        <div class="w-full bg-00 flex-grow min-h-0 rounded-xl flex flex-col mb-2">
          <div class="overflow-auto py-3 px-3">
            <div class="group">
              <div class="flex gap-3 mb-3 items-center">
                <p class="text-secondary text-body-default-heavy">{{ t("product", 99) }}</p>
                <p
                  v-if="values.carrier_product_service.split('_')[0] == 'dfm'"
                  class="text-quarterary text-body-sm leading-none"
                >
                  {{ t("with_ev_and_oil") }}
                </p>
                <Button class="max-h-max" type="button" :variant="ButtonVariant.PrimaryLink" @click="emit('changeStep', 2)">{{
                  t("edit")
                }}</Button>
              </div>
              <div class="flex items-center">
                <Avatar
                  :entity="{
                    image: getCarrierLogoUrl(values.carrier_product_service.split('_')[0]),
                  }"
                  size="xs"
                />
                <p class="text-secondary text-body-default-heavy ml-3">{{ values.carrier_product_title }}</p>
                <p class="ml-auto text-tertiary text-body-default-heavy">
                  {{ useFormatPrice(calculatedPrice ?? values.carrier_product_price, true) }}
                </p>
              </div>
            </div>
            <div v-if="!isDFM || (isDFM && values.carrier_product_service.split('_')[0] !== 'dfm')">
              <Divider class="!my-3" />
              <div class="group">
                <div class="flex gap-3 mb-3">
                  <p class="text-secondary text-body-default-heavy">Homerunner {{ t("insurance") }}</p>
                  <Button type="button" :variant="ButtonVariant.PrimaryLink" @click="emit('changeStep', 6)">{{ t("edit") }}</Button>
                </div>
                <div class="flex ml-3">
                  <p v-if="values.insurance_coverage" class="text-secondary text-body-default-heavy">
                    {{ t("up_to") }} {{ useFormatPrice(values.insurance_coverage, "auto") }}
                  </p>
                  <p v-else class="text-secondary text-body-default-heavy">
                    {{ t("up_to") }} {{ useFormatPrice(freeCoverage, "auto") }}
                  </p>
                  <p class="ml-auto text-tertiary text-body-default-heavy">
                    {{ useFormatPrice(values.insurance_price, true) || t("free") }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="extraServices">
              <Divider class="!my-3" />
              <div class="group">
                <div class="flex gap-3 mb-3">
                  <p class="text-secondary text-body-default-heavy">{{ t("extra_services") }}</p>
                  <Button type="button" :variant="ButtonVariant.PrimaryLink" @click="emit('changeStep', 4)">{{ t("edit") }}</Button>
                  <p v-if="calculatedFees" class="ml-auto text-tertiary text-body-default">
                    {{ useFormatPrice(calculatedFees, true) }}
                  </p>
                </div>
                <div v-for="(service,i) in filteredExtraServices" :key="i" class="mb-3 ml-3">
                  <div class="flex gap-6 mb-1.5">
                    <p class="text-body-default-heavy text-secondary">{{ service.title }}</p>
                    <p class="text-body-default text-secondary ml-auto">{{ service.price }}</p>
                  </div>
                  <div v-for="(value,ix) in service.values" :key="ix">
                    <div v-if="value.line" class="flex gap-3 mb-1.5">
                      <p v-for="fieldValue in value.values" :key="fieldValue" class="text-body-sm text-tertiary">{{ fieldValue }}</p>
                    </div>
                    <div v-if="!value.line && value.value" class="flex gap-3 mb-1">
                      <p class="text-body-sm text-tertiary">{{ value.label }}:</p>
                      <p class="text-body-sm text-tertiary">{{ value.value }}</p>
                    </div>
                    <p v-else class="text-body-sm text-tertiary">{{ value.label }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-auto">
            <Divider class="my-0" />
            <div class="flex p-3">
              <p
                v-if="isDFM && values.carrier_product_service.split('_')[0] == 'dfm'"
                class="text-secondary text-title-body"
              >
                {{ t("provisional_price") }}
              </p>
              <p v-else class="text-secondary text-title-body">{{ t("total") }}</p>
              <p class="ml-auto text-secondary text-title-body">
                {{ (calculatedTotalPrice ?? parseFloat(values.carrier_product_price) + getInsurancePrice).toFixed(2) }}
                DKK
              </p>
            </div>
          </div>
        </div>
        <div class="w-full bg-00 rounded-xl py-2 px-3 flex gap-4 mb-2 h-max">
          <p class="text-secondary text-body-sm-heavy min-w-max mt-2.5">{{ t("reference_id") }}</p>
          <InputText
            id="reference"
            class="w-full max-w-[255px] ml-auto"
            :dark-bg="true"
            name="reference"
            :placeholder="t('reference_ex')"
          />
        </div>
        <div class="w-full bg-00 rounded-xl py-2 px-3 flex gap-4 mb-2 h-max">
          <p class="text-secondary text-body-sm-heavy min-w-max mt-2.5">{{ t("remark") }}</p>
          <InputText id="comment" class="w-full max-w-[255px] ml-auto" :dark-bg="true" name="comment" placeholder="" />
        </div>
        <div class="w-full bg-00 rounded-xl py-3 px-3 flex gap-2.5 h-max">
          <TickerCheckbox id="accept_terms" :dark-bg="true" name="accept_terms" />
          <p v-if="isDFM" class="text-body-default text-secondary">
            {{ t("i_accept") }}
            <span class="text-brand cursor-pointer">
              <a
                href="https://www.fragt.dk/media/2033/forretningsbetingelser_transport_2021.pdf"
                target="_blank"
                rel="noopener noreferrer"
                >{{ t("the_terms").toLocaleLowerCase() }}</a
              ></span
            >
          </p>
          <p v-else class="text-body-default text-secondary">
            {{ t("i_accept") }}
            <span class="text-brand cursor-pointer">
              <a href="https://www.homerunner.com/handelsbetingelser/" target="_blank" rel="noopener noreferrer">{{
                t("the_terms").toLocaleLowerCase()
              }}</a></span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { loading } from "../../service";
  import type { CarrierOption } from "~/types/shipment";
  import eventbus from "~/plugins/eventbus";
  import { ButtonVariant, ComponentSize } from "~/types/global";

  const { t } = useI18n();
  const emit = defineEmits(["changeStep", "updatePrice"]);

  const props = defineProps<{
    values: object;
    mode: string;
    services: object
  }>();

  const extraServices = computed(() => {
    if (!props.values?.services) return false;
    return Object.keys(props.values.services).length !== 0;
  });

  const { isDFM } = useWorkspacesStore();

  const { loading, fetchInsurances } = useInsurance();

  const freeCoverage = ref(0);

  const calculatedTotalPrice = ref(null);

  const calculatedFees = ref(null);

  const calculatedPrice = ref(null);

  onMounted(() => {
    if (!props.values.insurance) {
      fetchInsurances(props.values).then((res) => {
        freeCoverage.value = res[0].coverage;
      });
    }
    if (isDFM && props.values.carrier_product_service.includes("dfm_")) {
      getPrice();
    }
    if (isDFM && props.values.carrier_product_service.split("_")[0] !== "dfm") {
      $loader.toggle();
      homeFetch("customers/active-transfer").then((res) => {
        $loader.toggle();
        if (res.data) {
          return;
        } else {
          eventbus.$emit("NEWSHIPMENT_NO_TRANSFER");
        }
      });
    }
  });

  const formatExtraservices = (values: object) => {
    const extraservices = {};
    extraservices.service_codes = [];
    Object.entries(values).forEach(([key, value]) => {
      if (!value.isSet) {
        return;
      }
      if (/^([A-Z0-9]{3})$/.test(key)) {
        extraservices.service_codes.push(key);
        return;
      }
      if (value.array) {
        extraservices[key] = value.array;
        return;
      } else {
        extraservices[key] = value;
        return;
      }
    });
    return extraservices;
  };

  const getPrice = async () => {
    loading.value = true;
    const services = await formatExtraservices(props.values.services);
    const cps = props.values.carrier_product_service.split("_");
    const [carrier, carrier_product, ...carrier_service] = cps;
    const data = {
      shipment: {
        sender: {
          ...props.values.sender,
          country: props.values.sender.country.toUpperCase(),
          type: props.values.sender.business ? "business" : "private",
        },
        receiver: {
          ...props.values.receiver,
          country: props.values.receiver.country.toUpperCase(),
          type: props.values.receiver.business ? "business" : "private",
        },
        height: props.values.height,
        length: props.values.length,
        width: props.values.width,
        weight: props.values.weight,
        carrier,
        carrier_product,
        carrier_service: carrier_service.join("_"),
        servicepoint_id: props.values?.droppoint,
        services: services,
      },
    };
    const price = await homeFetch("shipments/calculate-price", {
      method: "POST",
      body: data,
    })
      .then((response) => {
        loading.value = false;
        return response.data;
      })
      .catch(() => {
        loading.value = false;
      });
    calculatedPrice.value = price.price + price.price_surcharge + price.price_capacity_surcharge;
    calculatedFees.value = price.fees;
    calculatedTotalPrice.value = price.total_price;
    emit("updatePrice", price);
    loading.value = false;
  };

  const filteredExtraServices = computed(() => {
    return transformExtraServicesForPresentation(props.services as CarrierOption[], props.values?.services);
  });

  const getInsurancePrice = computed(() => {
    return props.values.insurance ? parseFloat(props.values.insurance_price) : 0;
  });

  const canChangeSender = computed(() => {
    if (!isDFM) return true;
    return props.mode === "return";
  });

  const canChangeReceiver = computed(() => {
    if (!isDFM) return true;
    return props.mode === "new";
  });
</script>
