/* *********************************************************
 * Creates an event bus for use within a Vue 3 application
 ********************************************************* */

import emitter from "tiny-emitter/instance";

export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
};
