<template>
  <div class="m-auto flex min-h-0 w-full max-w-[540px] flex-col gap-5">
    <InputText
      v-model="name"
      :size="ComponentSize.lg"
      :label="t('name_your_checkout')"
      :placeholder="t('checkout_name_placeholder')"
    />

    <div class="flex min-h-0 flex-col gap-3">
      <label class="text-body-sm-heavy text-secondary text-left">{{ t("connect_checkout_to_webshop") }}</label>
      <div class="relative overflow-auto">
        <ListSelector
          v-if="orderedShops.length"
          v-model="shops"
          :values="orderedShops"
          value-key="id"
          multi
          radio-position="top"
        >
          <template #title="{ value }">
            <div class="flex flex-col">
              <div class="text-body-lg-heavy">{{ value.name }}</div>
              <div class="text-body-default text-tertiary">{{ value.shop_url || t("not_specified") }}</div>
            </div>
          </template>
        </ListSelector>
        <div v-else-if="loading" class="flex h-60">
          <Overlay>
            <div class="text-body-default-heavy text-tertiary mt-2">{{ t("loading_webshops") }}</div>
          </Overlay>
        </div>

        <div v-else class="flex flex-col items-center justify-center p-4">
          <img class="mb-4" src="~/assets/illustrations/emptyViews/ec-no-result.svg" alt="No webshops illustration" />
          <p class="text-body-default text-tertiary text-center">{{ t("checkout_no_webshops") }}</p>
        </div>
      </div>
    </div>

    <Button v-if="mode !== 'edit'" :variant="ButtonVariant.Tertiary" :disabled="!canSubmit">{{
      t("connect_checkout_later")
    }}</Button>
  </div>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import type { IWebshop } from "../../../types";

  const { t } = useI18n();
  defineProps<{
    mode: string;
    canSubmit: boolean;
  }>();

  const loading = ref(false);
  const { value: shops } = useField(() => "shops", undefined, {
    syncVModel: true,
  });

  const { value: name } = useField(() => "name", undefined, {
    syncVModel: true,
  });

  const possibleShops = ref<IWebshop[]>([]);

  const orderedShops = computed(() => {
    const selectedShops = possibleShops.value
      .filter((s) => shops.value?.find((ss) => ss === s.id))
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    const unselectedShops = possibleShops.value
      .filter((s) => !shops.value?.find((ss) => ss === s.id))
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

    return [...selectedShops, ...unselectedShops];
  });

  onMounted(() => {
    getWebshops();
  });

  const getWebshops = () => {
    loading.value = true;
    return homeFetch("workspace/webshops")
      .then((response) => {
        if (response?.data) {
          possibleShops.value = response.data;
        }
      })
      .finally(() => {
        loading.value = false;
      });
  };
</script>
