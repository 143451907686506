<template>
  <td
    ref="td"
    class="relative box-border h-12 overflow-hidden border-inherit bg-inherit px-4 text-sm group-hover/row:bg-inherit group-[:last-of-type]/row:border-0"
    :class="{
      'text-disabled': disabled,
      'text-inherit': !disabled,
      '[&>.cell-content]:mr-auto': align === 'left',
      '[&>.cell-content]:mx-auto': align === 'center',
      '[&>.cell-content]:ml-auto': align === 'right',
    }"
  >
    <div class="cell-content flex w-fit max-w-[350px] flex-col text-ellipsis whitespace-nowrap">
      <div class="flex gap-2">
        <slot> {{ value }}</slot>
        <Tooltip v-if="isCopyable" :text="t('copy_entity_to_clipboard', { entity: header?.label?.toLowerCase() })">
          <Button
            icon-only
            :variant="ButtonVariant.Tertiary"
            :size="ComponentSize.sm"
            class="opacity-0 group-hover/row:opacity-100"
            @click.stop.prevent="copyValue"
          >
            <Icon src="copy" />
          </Button>
        </Tooltip>
      </div>
      <div class="text-body-sm text-quarterary">
        <slot name="subValue">
          {{ subValue }}
        </slot>
      </div>
    </div>
  </td>
</template>

<script setup lang="ts">
  import { ButtonVariant, ComponentSize } from "~/types/global";
  import type { ITableData } from "../../types";

  const props = defineProps<{
    disabled?: boolean;
    value?: any;
    subValue?: any;
    header?: any;
    item?: ITableData;
  }>();
  const { t } = useI18n();
  const td = useTemplateRef("td");

  const align = computed(() => {
    if (!props.header) return "left";
    if (props.header.type === "price") return "right";

    return props.header?.align || "left";
  });

  const { isCopyable, copyValue } = useIsCellCopyable(props.header, props.item);

  defineExpose({
    td,
  });
</script>
