<template>
  <div>
    <ListSelector v-model="type" radio-position="top" :values="service.fields[0].options" value-key="key">
      <template #title="{ value }">
        <div class="text-left">
          <p class="text-body-lg-heavy text-secondary">{{ value.label }}</p>
          <p v-if="value.key == 'DOT1'" class="text-body-default text-tertiary">{{ t("dot_option_desc.1") }}</p>
          <p v-if="value.key == 'DOT2'" class="text-body-default text-tertiary">{{ t("dot_option_desc.2") }}</p>
          <p v-if="value.key == 'DOT3'" class="text-body-default text-tertiary">{{ t("dot_option_desc.3") }}</p>
        </div>
      </template>
    </ListSelector>
    <div v-if="type == 'DOT2' || type == 'DOT3'" class="mt-6 flex w-full items-center gap-4 text-left">
      <InputTime
        v-model="time"
        class="filter-value w-full max-w-max"
        value-as-date
        :label="t('define_time')"
        :min-max="type == 'DOT2' ? '10:00-14:00' : '6:15-16:45'"
      />
      <p v-if="(time && type == 'DOT2') || (time && type == 'DOT3')" class="text-body-default text-quarterary mt-6">
        {{ t("the_shipment_will_be_delivered_between", { time_from: time_from, time_to: time_to }) }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  const { t } = useI18n();
  const props = defineProps<{
    service: any;
  }>();

  const { value: type } = useField(() => `services.${props.service.carrier_key}.type`, undefined, {
    syncVModel: false,
    controlled: true,
  });

  const { value: time } = useField(() => `services.${props.service.carrier_key}.interval_start`, undefined, {
    syncVModel: false,
    controlled: true,
  });

  const time_from = computed(() => {
    return time.value ? useGetDOTFromTime(type.value, time.value) : undefined;
  });

  const time_to = computed(() => {
    return time.value ? useGetDOTToTime(type.value, time.value) : undefined;
  });
</script>
