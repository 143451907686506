<template>
  <Modal v-if="open" ref="newShipmentModal" />
  <NoTransferModal v-if="noTransferOpen" ref="noTransferModal" />
</template>

<script setup lang="ts">
  import EventBus from "@/plugins/eventbus";
  import type { Insurance, IShipment, Product } from "~/types/shipment";

  import { getPhonePrefixFromObject, loading, mode, noTransferOpen, open, return_shipment, wizard } from "../service";
  import Modal from "./modal.vue";
  import NoTransferModal from "./NoTransferModal.vue";

  defineOptions({
    name: "NewShipmentModalWrapper",
  });

  const { accountIsBusiness } = useWorkspacesStore();

  const newShipmentModal = ref(null);
  const { t } = useI18n();

  onMounted(() => {
    EventBus.$on("NEWSHIPMENT_CREATED", setSender);

    EventBus.$on("NEWSHIPMENT_TOGGLE", () => {
      noTransferOpen.value = false;
      open.value = !open.value;
      if (open.value)
        watch(
          wizard,
          (newW) => {
            if (newW) {
              setSender();
            }
          },
          {
            once: true,
          }
        );
      mode.value = "new";
    });
    EventBus.$on("NEWSHIPMENT_OPEN", () => {
      noTransferOpen.value = false;
      watch(
        wizard,
        (newW) => {
          if (newW) {
            setSender();
          }
        },
        {
          once: true,
        }
      );
      open.value = true;
      mode.value = "new";
    });
    EventBus.$on("NEWSHIPMENT_NO_TRANSFER", () => {
      noTransferOpen.value = true;
    });
    EventBus.$on("NEWSHIPMENT_CLOSE", () => {
      open.value = false;
      mode.value = "new";
    });
    EventBus.$on("NEWSHIPMENT_DUPLICATE", (shipment: IShipment) => {
      noTransferOpen.value = false;
      open.value = true;
      mode.value = "duplicate";

      watch(
        wizard,
        (newW) => {
          if (newW) {
            duplicate(shipment);
          }
        },
        {
          once: true,
        }
      );
    });
    EventBus.$on("NEWSHIPMENT_RETURN", (shipment?: IShipment) => {
      return_shipment.value = shipment;
      open.value = true;
      mode.value = "return";

      watch(
        wizard,
        (newW) => {
          if (newW) {
            if (shipment) duplicate(shipment, true);
            else setSender();
          }
        },
        {
          once: true,
        }
      );
    });
  });

  const duplicate = (shipment: IShipment, returnShipment?: boolean) => {
    const { products, fetchProducts } = useCps();
    loading.value = true;

    const {
      carrier_name,
      package_number,
      is_focused,
      alt_package_number,
      cancel_at,
      cancellable,
      consignment_id,
      created_at,
      deleted_at,
      id,
      identifier,
      labelless_code,
      latest_tracking_event,
      meta,
      pcn_order_id,
      price,
      price_id,
      rebooked,
      redacted,
      servicepoint,
      shipment_type,
      title,
      updated_at,
      carrier_options,
      possible_services,
      services_insurance,
      default_insurance,
      services,
      ...rest
    } = shipment;

    const shipmentRedacted = isShipmentRedacted(shipment);

    let reference = rest.reference || "";

    if (returnShipment) {
      if (reference.length > 0) {
        reference += " - Return";
      }
    }

    wizard.value?.resetForm({
      values: {
        ...rest,
        receiver: returnShipment
          ? {
              ...transformReceiverSender(rest.sender),
              business: true,
            }
          : {
              ...transformReceiverSender(rest.receiver),
              business: !!rest.receiver.attention,
            },
        sender: transformReceiverSender(returnShipment ? rest.receiver : rest.sender),
        carrier: returnShipment ? "" : rest.carrier,
        carrier_product: returnShipment ? "" : rest.carrier_product,
        carrier_service: returnShipment ? "" : rest.carrier_service,
        carrier_product_service: returnShipment ? "" : buildCps(rest as IShipment),
        droppoint: returnShipment ? "" : rest.servicepoint_id,
        reference,
      },
    });
    if (shipmentRedacted) {
      setSender();
      resetReceiver();
    }

    if (!returnShipment && services && services.length > 0 && possible_services && possible_services.length > 0) {
      newShipmentModal.value?.$?.exposed?.addExtraServices({
        services: possible_services,
        reset: true,
      });

      wizard.value?.setValues({
        services: transformExtraServicesForSelection(services),
      });
    }

    useInsurance()
      .fetchInsurances(rest)
      .then((res) => {
        const insurances = res.value?.data;
        if (insurances && insurances.length > 0 && rest.insurance) {
          const selectedInsurance = insurances.find((insurance: Insurance) => insurance.id === rest.insurance.id);
          if (!selectedInsurance) return;
          setInsuranceValues(selectedInsurance);
        }

        if (returnShipment && !shipmentRedacted) {
          wizard.value?.goToStepByKeyName("carrier_product_service");
          return Promise.resolve();
        } else
          return fetchProducts(rest.sender, rest.receiver, rest.weight, rest.height, rest.width, rest.length, {
            silent: true,
          }).then(({ error, status }) => {
            if (status.value == "error") {
              throw error.value;
            }

            if (products.value && products.value.length > 0) {
              onProductsFetched(products.value, rest);
            }
          });
      })
      .then(() => {
        if (shipmentRedacted) {
          wizard.value?.goToStepByKeyName("receiver");
        }
        if (!returnShipment) wizard.value?.goToLastStep();
      })
      .catch((error) => {
        console.error(error);
        $toast.add({
          title: t("shipment_could_not_be_duplicated"),
          text: error?.data?.data?.data,
        });
        wizard.value.resetForm();
        open.value = false;
      })
      .finally(() => {
        loading.value = false;
      });
  };

  const setInsuranceValues = (insurance: Insurance) => {
    wizard.value?.setValues({
      insurance: insurance.id,
      insurance_coverage: insurance.coverage,
      insurance_price: accountIsBusiness ? insurance.price_excl_tax : insurance.price_incl_tax,
    });
  };

  const onProductsFetched = (products: Product[], shipment: IShipment) => {
    const shipmentCps = `${shipment.carrier}_${shipment.carrier_product}_${shipment.carrier_service}`;
    const selectedProduct = products.find((product: Product) => product.carrier_product_service === shipmentCps);
    if (!selectedProduct) return;

    wizard.value?.setValues({
      cpsUid: selectedProduct.uid,
      carrier_product_service: selectedProduct.carrier_product_service,
      carrier_product_title: selectedProduct.title,
      carrier_product_price: accountIsBusiness
        ? selectedProduct.sales_price_excl_vat
        : selectedProduct.sales_price_incl_vat,
    });
  };

  const setSender = () => {
    useCustomer()
      .fetchCustomer()
      .then((customerData) => {
        if (customerData.data) {
          const mappedData = {
            city: customerData.data.city,
            country: customerData.data.country_iso,
            email: customerData.data.email,
            name: customerData.data.name,
            attention: customerData.data.attention,
            phone: customerData.data.phone,
            phone_prefix: customerData.data.phone_prefix,
            street1: customerData.data.street1,
            street2: customerData.data.street2,
            zip_code: customerData.data.zip_code,
            business: accountIsBusiness,
          };
          if (mode.value === "return") {
            wizard?.value.setValues({
              receiver: mappedData,
            });
          } else {
            wizard?.value.setValues({
              sender: mappedData,
            });
            wizard.value.resetForm();
          }
        }
      });
  };

  const resetReceiver = () => {
    if (mode.value === "return") {
      wizard?.value.setValues({
        sender: {
          city: "",
          email: "",
          name: "",
          attention: "",
          phone: "",
          street1: "",
          street2: "",
        },
      });
    } else {
      wizard?.value.setValues({
        receiver: {
          city: "",
          email: "",
          name: "",
          attention: "",
          phone: "",
          street1: "",
          street2: "",
        },
      });
    }
  };

  const transformReceiverSender = (receiverSender) => {
    return {
      city: receiverSender.city,
      country: receiverSender.country,
      email: receiverSender.email,
      name: receiverSender.name,
      attention: receiverSender.attention,
      phone: getPhonePrefixFromObject(receiverSender.phone_prefix) + "" + receiverSender.phone,
      street1: receiverSender.street1,
      street2: receiverSender.street2,
      zip_code: receiverSender.zip_code,
      business: receiverSender.business,
    };
  };
  onUnmounted(() => {
    EventBus.$off("NEWSHIPMENT_TOGGLE");
    EventBus.$off("NEWSHIPMENT_OPEN");
    EventBus.$off("NEWSHIPMENT_CLOSE");
    EventBus.$off("NEWSHIPMENT_DUPLICATE");
    EventBus.$off("NEWSHIPMENT_RETURN");
    EventBus.$off("NEWSHIPMENT_CREATED");
  });
</script>
