<template>
  <Avatar
    v-if="user"
    :entity="{
      name: userName,
      image: userPicture,
    }"
    random-color
    :size="ComponentSize.xs"
  />
</template>

<script setup lang="ts">
  import { ComponentSize } from "~/types/global";

  const { user, userName, userPicture } = storeToRefs(useWorkspacesStore());
</script>
