<template>
  <Tooltip :text="tooltip">
    <label class="relative flex cursor-pointer gap-2.5" :for="id">
      <input
        :id="id"
        v-model="inputValue"
        class="border-01 before:content[''] !checked:border-inversed-00 !indeterminate:border-inversed-00 peer checked:bg-inversed-00 indeterminate:bg-inversed-00 hover:border-02 checked:hover:border-inversed-01 checked:hover:bg-inversed-01 indeterminate:hover:border-inversed-01 indeterminate:hover:bg-inversed-01 focus-visible:before:border-focus disabled:border-disabled checked:disabled:bg-disabled indeterminate:disabled:bg-disabled relative aspect-square h-5 w-5 shrink-0 cursor-pointer appearance-none border transition-all before:absolute before:-inset-1 focus-visible:outline-none focus-visible:before:border-2 disabled:cursor-default"
        :disabled="readonly"
        type="checkbox"
        :indeterminate="indeterminate"
        :class="{
          'rounded-full before:rounded-full': rounded,
          'rounded-md before:rounded-lg': !rounded,
          'bg-01 border-2': darkBg,
          'bg-00': !darkBg,
          'border-destructive-01 bg-destructive-01 hover:border-destructive checked:hover:border-inversed-01 border-2 checked:border-black':
            error,
        }"
      />
      <div
        class="peer-checked:text-surface-lvl-00 peer-indeterminate:text-surface-lvl-00 peer-disabled:text-foreground-disabled pointer-events-none absolute flex h-5 w-5 items-center justify-center text-inherit opacity-0 transition-opacity peer-checked:opacity-100 peer-indeterminate:opacity-100"
      >
        <svg
          v-if="indeterminate"
          class="h-3.5 w-3.5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="3"
        >
          <line x1="4" y1="10" x2="15" y2="10" stroke-linecap="round" />
        </svg>
        <svg
          v-else-if="inputValue"
          class="h-3.5 w-3.5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          stroke="currentColor"
          stroke-width="1"
        >
          <path
            fill-rule="evenodd"
            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <slot />
      <div
        v-if="label"
        class="text-body-default w-full overflow-hidden text-ellipsis"
        :class="[error ? 'text-destructive' : '', inputValue ? 'text-primary' : '']"
      >
        {{ label }}
      </div>
    </label>
  </Tooltip>
</template>

<script setup lang="ts">
  const id = `checkbox-${useId()}`;

  const props = withDefaults(
    defineProps<{
      readonly?: boolean;
      indeterminate?: boolean;
      name?: string;
      error?: boolean;
      label?: string;
      activeValue?: boolean;
      darkBg?: boolean;
      rounded?: boolean;
      tooltip?: string;
    }>(),
    {
      readonly: false,
      indeterminate: false,
      name: "",
      error: false,
      label: "",
      activeValue: false,
      darkBg: false,
      tooltip: "",
    }
  );

  const _modelValue = defineModel<boolean>();
  const emit = defineEmits(["update:modelValue"]);

  const activeValue = computed(() => props.activeValue);

  onMounted(() => {
    if (props.activeValue) {
      inputValue.value = true;
    }
  });

  const { value: inputValue, errorMessage } = useField(() => props.name, undefined, {
    type: "checkbox",
    checkedValue: true,
    uncheckedValue: false,
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  watch(activeValue, (newValue) => {
    inputValue.value = newValue;
  });

  watch(inputValue, (newValue) => {
    emit("update:modelValue", newValue);
  });

  const error = computed(() => {
    return props.error || errorMessage.value;
  });
</script>
