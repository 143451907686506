<template>
  <div
    class="value-xl__input relative flex w-full flex-col gap-1"
    :class="[{ error: !!error }]"
    @click="() => emit('click')"
  >
    <p v-if="label" class="text-body-sm-heavy text-secondary w-full text-center">{{ label }}</p>
    <div
      class="input-wrapper text-title-screen relative flex h-[82px] cursor-text items-center justify-center gap-2 overflow-hidden rounded-full px-5"
      @click.self="focusInput"
    >
      <div
        ref="input"
        class="min-w-5 overflow-hidden whitespace-nowrap"
        contenteditable
        @blur="handleBlur"
        @keypress="handleChange"
        @keydown.delete="handleChange"
        @keydown.enter.prevent
      >
        {{ inputValue }}
      </div>
      <div class="shrink-0" @click.self="onSuffixClick">
        {{ suffix }}
      </div>
    </div>
    <div v-if="message" class="text-body-default-heavy text-quarterary justify-center text-center">{{ message }}</div>

    <Message v-if="error" variant="invalid">{{ error }}</Message>
  </div>
</template>

<script setup lang="ts">
  import { ref } from "vue";

  defineOptions({
    name: "ValueXL",
  });

  const _modelValue = defineModel<string | number>();

  const input = ref<HTMLElement | null>(null);

  const props = withDefaults(
    defineProps<{
      error?: string | null;
      type?: "text" | "number";
      name?: string;
      suffix?: string;
      label?: string;
      message?: string;
    }>(),
    {
      error: null,
      type: "text",
      name: "",
      suffix: undefined,
      label: undefined,
      message: undefined,
    }
  );

  const {
    value: inputValue,
    handleBlur,
    setValue,
  } = useField(() => props.name, undefined, {
    syncVModel: props.name ? false : true,
    controlled: !!props.name,
  });

  const handleChange = ($evt) => {
    const { target, key, ctrlKey, metaKey } = $evt;

    const isDecimalSeparator = key === "." || key === ",";
    if (isDecimalSeparator) {
      if (target.textContent.includes(".") || target.textContent.includes(",")) {
        $evt.preventDefault();
        return;
      }

      if (target.textContent.length === 0) {
        $evt.preventDefault();
        document.execCommand("insertText", false, "0");
      }

      if (key == ",") {
        $evt.preventDefault();
        //insert dot instead of comma
        document.execCommand("insertText", false, ".");
      }
    }

    const isDeleteOrBackspace = key === "Backspace" || key === "Delete";
    const isNumeric = !isNaN(Number($evt.key));
    if (!isNumeric && !isDecimalSeparator && !isDeleteOrBackspace && !(ctrlKey || metaKey)) {
      $evt.preventDefault();
    } else {
      setTimeout(() => {
        const cursorPosition = getCurrentCursorPosition();

        setValue(target.textContent);
        nextTick(() => {
          setCursorPosition(cursorPosition);
        });
      });
    }
  };

  const emit = defineEmits(["click", "click:suffix"]);

  const focusInput = ($evt) => {
    //check if click events is right or left from the input
    if ($evt.clientX > input.value?.getBoundingClientRect().right) {
      focusRightSide();
    } else {
      input.value?.focus();
    }
  };

  const focusRightSide = () => {
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(input.value as Node);
    range.collapse(false);
    sel?.removeAllRanges();
    sel?.addRange(range);
  };

  const onSuffixClick = ($evt) => {
    emit("click:suffix");
    focusInput($evt);
  };

  const getCurrentCursorPosition = () => {
    const sel = window.getSelection();
    if (sel?.rangeCount) {
      const range = sel.getRangeAt(0);
      return range.startOffset;
    }
    return 0;
  };

  const setCursorPosition = (position: number) => {
    const range = document.createRange();
    const sel = window.getSelection();

    //if input is empty, return
    if (!input.value?.firstChild) return;

    //check if position is bigger than input length and set it to the end
    if (position > input.value?.textContent.length) {
      position = input.value?.textContent.length;
    }

    range.setStart(input.value?.firstChild as Node, position);
    range.collapse(true);
    sel?.removeAllRanges();
    sel?.addRange(range);
  };

  defineExpose({
    input,
  });
</script>
